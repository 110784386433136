/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
import MainLayout from '@/components/layout/index.vue';
import Midware from '@/components/layout/midware.vue';
import { createRoute } from './utils.js';

const createMeta = (title, icon, parentTitle) => {
  return {
    title: title,
    parentTitle: parentTitle,
    icon: icon
  };
};

export default () => {
  return [
    // 登录注册  =======start=========
    {
      path: '/',
      name: '',
      redirect: '/page/dashboard/index',
      meta: {}
    },
    {
      path: '/demo',
      name: 'demo',
      component: () => import('@/pages/demoManager/demoPage/controller.vue')
    },
    {
      path: '*',
      redirect: { path: '/error/404' }
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: '登录'
      },
      component: () => import('@/pages/000__auth/login/index.vue')
    },
    // 登录注册  =======end=========

    // 缺省页  =======start=========
    {
      path: '/error',
      name: 'error',
      redirect: '/error/404',
      meta: {},
      component: Midware,
      children: [
        {
          path: '404',
          name: 'error_404',
          meta: createMeta('无此页面'),
          component: () => import('@/pages/400__error/404.vue')
        },
        {
          path: '300',
          name: 'error_300',
          meta: createMeta('浏览器异常'),
          component: () => import('@/pages/400__error/300.vue')
        },
        {
          path: '401',
          name: 'error_401',
          meta: createMeta('暂无权限访问'),
          component: () => import('@/pages/400__error/401.vue')
        }
      ]
    },
    // 缺省页  =======end=========

    // 外部页  =======start=========
    {
      path: '/board',
      name: 'board',
      meta: {},
      component: Midware,
      children: [
        {
          path: 'dataAnalysis',
          name: 'board_dataAnalysis',
          meta: createMeta('数据看板'),
          component: () => import('@/pages/300__board/dataAnalysis/controller.vue')
        }
      ]
    },
    // 外部页  =======start=========

    // PC端相关界面  =======start=========
    {
      path: '/page',
      name: 'page',
      redirect: '/page/dashboard/index',
      meta: {},
      component: MainLayout,
      children: [
        createRoute({
          path: 'dashboard',
          title: '首页',
          checkPermission: false
        }, [
          { path: 'index', title: '信息面板', component: () => import('@/pages/010__dashboard/index.vue') }
        ]),
        createRoute({
          path: 'organization',
          title: '组织管理'
        }, [
          { path: 'serviceDealerList', title: '服务商管理', component: () => import('@/pages/020__organizationManager/serviceDealerList/controller.vue') },
          { path: 'deviceDealerList', title: '设备商管理', component: () => import('@/pages/020__organizationManager/deviceDealerList/controller.vue') },
          { path: 'propertyList', title: '物业公司管理', component: () => import('@/pages/020__organizationManager/propertyList/controller.vue') },
          { path: 'blockList', title: '小区管理', component: () => import('@/pages/020__organizationManager/blockList/controller.vue') },
          { path: 'officeList', title: '办事处管理', component: () => import('@/pages/020__organizationManager/officeList/controller.vue') }
        ]),
        createRoute({
          path: 'basicSetting',
          title: '基础配置'
        }, [
          { path: 'mainDataList', title: '主数据管理', component: () => import('@/pages/030__basicSetting/mainDataList/controller.vue') },
          { path: 'deviceTypeList', title: '设备类型管理', component: () => import('@/pages/030__basicSetting/deviceTypeList/controller.vue') }
        ]),
        createRoute({
          path: 'location',
          title: '点位管理'
        }, [
          { path: 'list', title: '点位查询', component: () => import('@/pages/040__locationManager/locationList/controller.vue') }
        ]),
        createRoute({
          path: 'device',
          title: '设备管理'
        }, [
          { path: 'initList', title: '设备出厂管理', component: () => import('@/pages/050__deviceManager/deviceInitList/controller.vue') },
          { path: 'chargingPileList', title: '充电桩管理', component: () => import('@/pages/050__deviceManager/chargingPileList/controller.vue') }
        ]),
        createRoute({
          path: 'recharge',
          title: '充电管理'
        }, [
          { path: 'chargingBusinessOrder', title: '充电业务订单', component: () => import('@/pages/060__rechargeManager/chargingBusinessOrder/controller.vue') },
          { path: 'deviceUseRecord', title: '使用记录', component: () => import('@/pages/060__rechargeManager/deviceUseRecord/controller.vue') },
          { path: 'cardUseRecord', title: '刷卡记录', component: () => import('@/pages/060__rechargeManager/cardUseRecord/controller.vue') }
        ]),
        createRoute({
          path: 'deal',
          title: '交易管理'
        }, [
          { path: 'chargingPayOrder', title: '充电支付订单', component: () => import('@/pages/070__dealManager/chargingPayOrder/controller.vue') },
          { path: 'chargingRefundOrder', title: '充电退款订单', component: () => import('@/pages/070__dealManager/chargingRefundOrder/controller.vue') },
          { path: 'chargingCoinSalesOrder', title: '充电币购买订单', component: () => import('@/pages/070__dealManager/chargingCoinSalesOrder/controller.vue') },
          { path: 'chargingCoinTransactionRecord', title: '充电币交易流水', component: () => import('@/pages/070__dealManager/chargingCoinTransactionRecord/controller.vue') }
        ]),
        createRoute({
          path: 'user',
          title: '客户管理'
        }, [
          { path: 'list', title: '客户查询', component: () => import('@/pages/080__userManager/userList/controller.vue') },
          { path: 'chargingCardList', title: '充电卡列表', component: () => import('@/pages/080__userManager/chargingCardList/controller.vue') }
        ]),
        createRoute({
          path: 'capital',
          title: '财务管理'
        }, [
          { path: 'capitalOrder', title: '分账订单管理', component: () => import('@/pages/090__capitalManager/capitalOrder/controller.vue') },
          { path: 'refundCoinApplicationOrder', title: '充电币退币申请订单', component: () => import('@/pages/090__capitalManager/refundCoinApplicationOrder/controller.vue') },
          { path: 'bankCardRefundManage', title: '充电币退款(银行卡)', component: () => import('@/pages/090__capitalManager/bankCardRefundManage/controller.vue') }
        ]),
        createRoute({
          path: 'system',
          title: '系统管理'
        }, [
          { path: 'merchantInfo', title: '商户信息', component: () => import('@/pages/100__systemManager/merchantInfo/controller.vue') },
          { path: 'roleList', title: '角色管理', component: () => import('@/pages/100__systemManager/roleList/controller.vue') },
          { path: 'employeeList', title: '员工管理', component: () => import('@/pages/100__systemManager/employeeList/controller.vue') }
        ]),
        createRoute({
          path: 'outside',
          title: '外部数据'
        }, [
          { path: 'importDevice', title: '导入设备数据', component: () => import('@/pages/105__outsideData/importData/deviceController.vue') },
          // { path: 'importBusiness', title: '导入经营数据', component: () => import('@/pages/105__outsideData/importData/operateController.vue') },
          { path: 'importCustomer', title: '导入用户数据', component: () => import('@/pages/105__outsideData/importData/customerController.vue') }
        ]),
        createRoute({
          path: 'dataStatistics',
          title: '数据统计'
        }, [
          { path: 'dataAnalysis', title: '数据分析', component: () => import('@/pages/106__dataStatistics/dataAnalysis/controller.vue') }
        ]),
        createRoute({
          path: 'operations',
          title: '运营管理'
        }, [
          { path: 'billingTemplate', title: '充电桩计费模版', component: () => import('@/pages/108__operationsManager/billingTemplate/controller.vue') },
          { path: 'separateAccountsTemplate', title: '充电桩分账模版', component: () => import('@/pages/108__operationsManager/separateAccountsTemplate/controller.vue') },
          { path: 'operateTemplate', title: '充电桩运营模版', component: () => import('@/pages/108__operationsManager/operateTemplate/controller.vue') }
        ]),
        createRoute({
          path: 'download',
          title: '下载中心'
        }, [
          { path: 'list', title: '下载列表', component: () => import('@/pages/110__downloadManager/downloadList/controller.vue') }
        ])
      ]
    }
    // PC端相关界面  =======end=========
  ];
};
