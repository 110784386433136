/**
 * @date:2024/1/31
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 订单相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class OrderApiSet {
  constructor () {
    this.getOrderList = BaseApi.createModel('充电业务订单', '/opmp/admin/biz/orderList', true).setMethod(1);
    this.getOrderDetail = BaseApi.createModel('充电业务订单详情', '/opmp/admin/biz/orderDetail', true).setMethod(1);
    this.getOrderDetailLog = BaseApi.createModel('充电业务订单详情-指令', '/opmp/admin/biz/orderDetailInstruction', true).setMethod(1);
    this.getPayList = BaseApi.createModel('充电业支付订单', '/opmp/admin/biz/payList', true).setMethod(1);
    this.getRefundList = BaseApi.createModel('充电退款订单', '/opmp/admin/biz/refundList', true).setMethod(1);
    this.getSalesOrderList = BaseApi.createModel('充电币销售订单', '/opmp/admin/chargeCoin/getSalesOrder', true).setMethod(1);
    this.getRefundOrderSum = BaseApi.createModel('退币申请订单(汇总)', '/opmp/admin/chargeCoin/getRefundOrderSum', true).setMethod(1);
    this.getRefundOrderList = BaseApi.createModel('退币申请订单', '/opmp/admin/chargeCoin/getRefundOrder', true).setMethod(1);
    this.getTransRecordList = BaseApi.createModel('充电币交易流水', '/opmp/admin/chargeCoin/getTransRecord', true).setMethod(1);
    this.endOrder = BaseApi.createModel('终止订单', '/opmp/admin/biz/endOrder', true).setMethod(1);
    this.coinAdjustSubmit = BaseApi.createModel('扣减充电币申请', '/opmp/admin/chargeCoin/coinAdjust', true).setMethod(1);
    this.getCoinAdjust = BaseApi.createModel('扣减充电币记录', '/opmp/admin/chargeCoin/getCoinAdjust', true).setMethod(1);
    this.manualRefund = BaseApi.createModel('人工退款', '/opmp/admin/biz/manualRefund', true).setMethod(1);
    this.orderDetailPowerElec = BaseApi.createModel('充电业务订单详情-功率、电量图', '/opmp/admin/biz/orderDetailPowerElec', true).setMethod(1);
  }
}

export default new OrderApiSet();
