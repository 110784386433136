
// 设备计费数据模型
import BaseModel from '@/libs/base/dataModel/BaseModel';

export default class DevicePriceModel extends BaseModel {
  constructor () {
    super();
    this.level = 0; // 计费档
    this.category = 1; // 计费类别（1：功率 2：电量）
    this.roadRange = ''; // 计费范围（-号分割范围）
    this.chargeDuration = 0; // 充电时长
    this.price = 0; // 计费单价（按功率时用这个, 按时长也用这个）

    this.chargePrice = 0; // 电费单价(按电量用这个)
    this.servicePrice = 0; // 服务费单价(按电量用这个)

    // 前端独有
    this.h5RangeMin = null;
    this.h5RangeMax = null;
    // 按电量计费时需要计算时段的总时长
    this.h5TimeDuration = 0;
  }

  initData (resData) {
    super.initData(resData);
    if (this.roadRange) {
      const rArr = this.roadRange.split(DevicePriceModel.rangeSplitUnit);
      if (this.category === 1) {
        // 按功率
        this.h5RangeMin = parseInt(rArr[0]);
        this.h5RangeMax = rArr[1] ? parseInt(rArr[1]) : 0;
      } else {
        // 按电量
        this.h5RangeMin = rArr[0] || '';
        this.h5RangeMax = rArr[1] || '';
      }
    }
    if (this.category === 2) {
      this.h5RangeMin = this.h5RangeMin || '';
      this.h5RangeMax = this.h5RangeMax || '';
    }
    return this;
  }

  // 新增一项(按功率)
  static createAdditionModel ({ level, h5RangeMax }) {
    const model = new DevicePriceModel();
    model.level = level + 1;
    model.category = 1;
    this.h5RangeMin = h5RangeMax;
    return model;
  }

  // 新增一项（按电量）
  static createAdditionModelByDian ({ level }) {
    const model = new DevicePriceModel();
    model.level = level + 1;
    model.category = 2;
    model.h5RangeMin = '';
    model.h5RangeMax = '';
    return model;
  }

  // 初始化2个(因为需求上是至少要有两个档位)
  static createInitList () {
    const list = [];
    for (let i = 0; i < 2; i++) {
      const model = new DevicePriceModel();
      model.level = i + 1;
      model.category = 1;
      model.h5RangeMin = i * 200;
      model.h5RangeMax = i === 1 ? 0 : 200;
      list.push(model);
    }
    return list;
  }

  // 初始化1个(因为需求上是至少要有两个档位)
  static createInitListByDian () {
    const list = [];
    for (let i = 0; i < 1; i++) {
      const model = new DevicePriceModel();
      model.level = i + 1;
      model.category = 2;
      model.h5RangeMin = '00:00';
      model.h5RangeMax = '24:00';
      list.push(model);
    }
    return list;
  }

  // 初始化1个(因为需求上只有一个)
  static createInitListByTime () {
    const list = [];
    for (let i = 0; i < 1; i++) {
      const model = new DevicePriceModel();
      model.level = i + 1;
      model.category = 3;
      model.price = 1;
      model.chargeDuration = 100;
      list.push(model);
    }
    return list;
  }

  static createFakeDataList (len = 6) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        level: i + 1,
        category: 1,
        roadRange: (i * 100) + DevicePriceModel.rangeSplitUnit + ((i + 1) * 100),
        chargeDuration: 120,
        price: 0
      };
      if (i === (len - 1)) {
        dic.range = (i * 100 + 1) + '';
      }
      list.push(dic);
    }
    return list;
  }

  static rangeSplitUnit = '-';
}
