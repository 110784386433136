/**
 * Created by 程雨喵'mac on 2023/12/1.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-copy-text, value: data-value="需要复制的文本"
 * 功能：将指定内容进行复制
 */

function inserted (el, binding, vnode) {
  // 1.查看功能是否开启
  if (!binding.value) return;
  // 2.查看复制的内容
  if (el.dataset.value) {
    // hover时展示复制
    el.style.cursor = 'copy';
    // 添加点击事件
    el.onclick = () => {
      const copyText = el.dataset.value;
      const cInput = document.createElement('input');
      cInput.value = copyText;
      document.body.appendChild(cInput);
      cInput.select();
      document.execCommand('copy');
      vnode.context.$Message.success('已复制:' + copyText);
      document.body.removeChild(cInput);
    };
  } else {
    el.style.cursor = '';
    el.onclick = null;
  }
}

function update (el, binding, vnode) {
  inserted(el, binding, vnode);
}
export default {
  install (Vue) {
    Vue.directive('copy-text', {
      inserted,
      update
    });
  }
};
