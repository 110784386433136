/**
 * Created by 程雨喵'mac on 2023/12/1.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-hide-phone, value: data-value="文本内容"
 * 功能：如果是手机号就隐藏
 */
import { isPhone } from '@/libs/regularUtils';

function inserted (el, binding, vnode) {
  // 1.查看功能是否开启
  if (!binding.value) return;
  // 2.查看复制的内容
  const text = el.dataset.value;
  if (isPhone(text)) {
    const reg = /^(\d{3})\d{4}(\d{4})$/;
    el.innerText = text.replace(reg, '$1****$2');
    el.onmouseenter = () => {
      el.innerText = text;
    };
    el.onmouseleave = () => {
      el.innerText = text.replace(reg, '$1****$2');
    };
  } else {
    el.onmouseenter = null;
    el.onmouseleave = null;
    el.innerText = text || '-';
  }
}
function update (el, binding, vnode) {
  inserted(el, binding, vnode);
}

export default {
  install (Vue) {
    Vue.directive('hide-phone', {
      inserted,
      update
    });
  }
};
