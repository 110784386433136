import BaseModel from '@/libs/base/dataModel/BaseModel';

export class RefundCoinApplicationOrderTotalModel extends BaseModel {
  constructor () {
    super();
    // 待退款订单数量
    this.waitToRefundOrderNum = '';
    // 待退款金额
    this.waitToRefundMoneySum = '';
    // 退款失败金额
    this.refundFailMoneySum = '';
    // 退款成功金额
    this.refundSuccessMoneySum = '';
  }

  initData (resData) {
    super.initData(resData);
    this.waitToRefundOrderNum = this.waitToRefundOrderNum + '';
    return this;
  }

  static createFakeData (len = 30) {
    return {
      waitToRefundOrderNum: parseFloat((Math.random() * 99999).toFixed(2)),
      waitToRefundMoneySum: parseFloat((Math.random() * 99999).toFixed(2)),
      refundFailMoneySum: parseFloat((Math.random() * 99999).toFixed(2)),
      refundSuccessMoneySum: parseFloat((Math.random() * 99999).toFixed(2))
    };
  }
}

export class RefundCoinApplicationOrderListModel extends BaseModel {
  constructor () {
    super();
    // 用户手机号
    this.applyTel = '';
    // 申请订单号
    this.refundOrderNo = '';
    // 订单状态
    this.status = '';
    // 订单状态：0-待退款 1-退款中 2-退款成功 3-退款失败
    this.statusDesc = '';
    // 充电币明细-退币金额
    this.refundCoinMoney = '';
    // 充电币明细-收回金额
    this.receiveMoney = '';
    // 申请退款金额
    this.applyRefundMoney = '';
    // 退款渠道
    this.refundChannel = '';
    // 退款渠道（1-微信 2-银行卡）
    this.refundChannelDesc = '';
    // 收款信息-收款人
    this.receiveName = '';
    // 收款信息-收款银行卡号
    this.receiveCard = '';
    // 收款信息-收款银行名称
    this.receiveBank = '';
    // 实际退款金额
    this.realRefundMoney = '';
    // 申请时间
    this.applyRefundTime = '';
    // 退款时间
    this.realRefundTime = '';
    // 确认时间
    this.confirmRefundTime = '';
    // 操作人
    this.operator = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    // this._checkId = this.refundOrderNo;
    // 只有退款渠道是微信，且订单状态为待退款的才可以选择，其他的状态均需禁用
    this._disabled = this.refundChannel !== 1 || this.status !== 0;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const statusDesc = ['待退款', '退款中', '退款成功', '退款失败'];
    const refundChannelDesc = ['微信', '银行卡'];
    for (let i = 0; i < len; i++) {
      const dic = {
        applyTel: '1379749022' + i,
        status: i % 4,
        statusDesc: statusDesc[i % 4],
        refundCoinMoney: BaseModel.getFakeNumber().toFixed(2),
        receiveMoney: BaseModel.getFakeNumber().toFixed(2),
        applyRefundMoney: BaseModel.getFakeNumber().toFixed(2),
        realRefundMoney: BaseModel.getFakeNumber().toFixed(2),
        refundChannel: i % 2,
        refundChannelDesc: refundChannelDesc[i % 2],
        receiveName: '收款人HIHKHK' + (i + 1),
        receiveCard: 'Card37584935772959' + (i + 1),
        receiveBank: BaseModel.getFakeTitle(10),
        applyRefundTime: '2024-05-23 12:43:4' + (i + 1),
        realRefundTime: '2024-05-23 12:43:4' + (i + 1)
      };
      list.push(dic);
    }
    return list;
  }
}
