<!--
    Created by 程雨喵'mac on 2024/9/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：按功率计费
-->
<style lang="less">
.device-price-use-power {
  .money-rules-table {
    border: 1px solid @lineColorNormal;
    border-bottom: 0;
    border-radius: @borderRadiusMin;
    .money-rules-table-row {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid @lineColorNormal;
      line-height: @formHeight;
      font-size: 12px;
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
      }
      .table-col {
        padding: 5px;
        .display-flex();
        .table-col-inner {
          width: 100%;
          .display-flex();
          white-space: nowrap;
          .ivu-input-number {
            flex: 1;
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(100px);
          font-weight: 600;
          color: @textColorLight;
        }
        &.col-t-1 {
          flex: 1;
        }
        &.col-t-2 {
          .flex-grow(180px);
        }
        &.col-t-3 {
          .flex-grow(80px);
        }
      }
    }
  }
  .add-btn-row {
    position: relative;
    .add-btn {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .display-flex();
      border: 1px dashed @themeColor;
      background-color: rgba(255,255,255,0.7);
      color: @themeColor;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }
  @keyframes errorAmin {
    0% { background-color: transparent }
    50% { background-color: rgba(254,85,113,0.2) }
    100% { background-color: transparent }
  }
  .row-is-error {
    animation: errorAmin 0.5s;
    animation-iteration-count: 3;
  }
}
</style>

<template>
  <div class="device-price-use-power">
    <div
      v-if="!!dataList.length"
      class="money-rules-table"
    >
      <!--头部-->
      <div class="money-rules-table-row is-header">
        <div
          v-for="(item, i) in tableHeaders"
          :key="i"
          class="table-col"
          :class="'col-t-' + i"
        >
          {{ item }}
        </div>
      </div>
      <!--实际内容-->
      <div
        v-for="(item, i) in dataList"
        :key="'r-' + i"
        class="money-rules-table-row"
        :class="errorIndex === i ? 'row-is-error' : ''"
      >
        <div class="table-col col-t-0">
          第{{ item.level }}档
        </div>
        <div class="table-col col-t-1">
          <div
            v-if="isEdit && i !== (dataList.length - 1)"
            class="table-col-inner"
          >
            <InputNumber
              v-model="item.h5RangeMin"
              :min="0"
              :disabled="true"
            />
            <span style="margin: 0 5px">瓦至</span>
            <InputNumber
              v-model.trim="item.h5RangeMax"
              :min="0"
              @input="onInput"
            />
            <span style="margin-left: 5px">瓦之间</span>
          </div>
          <!--最后一个档位-->
          <div
            v-else-if="isEdit && i === (dataList.length - 1)"
            class="table-col-inner"
          >
            <InputNumber
              v-model="item.h5RangeMin"
              :disabled="true"
            />
            <span style="margin-left: 5px">瓦以上</span>
          </div>
          <div
            v-else
            class="table-col-inner"
          >
            {{ item.h5RangeMin }}瓦至{{ item.h5RangeMax || '∞' }}瓦之间
          </div>
        </div>
        <div
          class="table-col col-t-2"
        >
          <div
            v-if="isEdit"
            class="table-col-inner"
          >
            <span style="margin-right: 5px">1元可充</span>
            <InputNumber
              v-model="item.chargeDuration"
              :min="0"
            />
            <span style="margin-left: 5px">分钟</span>
          </div>
          <div
            v-else
            class="table-col-inner"
          >
            1元可充{{ item.chargeDuration }}分钟
          </div>
        </div>
        <div
          v-if="isEdit"
          class="table-col col-t-3"
        >
          <yg-button
            type="text"
            disabled-text="此项暂时无法删除"
            :disabled="i === 0"
            @click="_clickDel(i)"
          >
            删除
          </yg-button>
        </div>
      </div>
      <!--最底部新增-->
      <div
        v-if="isEdit && dataList.length < 10"
        class="money-rules-table-row add-btn-row"
        @click.stop="_clickAdd"
      >
        <div class="table-col col-t-0">
          第{{ dataList.length + 1 }}档
        </div>
        <div class="table-col col-t-1">
          -
        </div>
        <div class="table-col col-t-2">
          -
        </div>
        <div class="table-col col-t-3">
          -
        </div>
        <div class="add-btn">
          - 增加第{{ dataList.length + 1 }}档位 -
        </div>
      </div>
    </div>
    <div
      v-else
      class="__g-no-data"
      style="height: 300px;"
    />
  </div>
</template>

<script>
import DevicePriceModel from '@/pages/050__deviceManager/chargingPileList/model/DevicePriceModel';

export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      errorIndex: -1
    };
  },
  computed: {
    tableHeaders () {
      return this.isEdit ? ['档位', '瓦数区间', '可充电时长', '操作'] : ['档位', '瓦数区间', '可充电时长'];
    }
  },
  methods: {
    onInput () {
      this.errorIndex = -1;
      this.resetRangeMin();
    },
    // 重置最低档
    resetRangeMin () {
      // 重新校验一遍(N+1档的min 需要等于 N档的max)
      setTimeout(() => {
        this.dataList.forEach((v, i) => {
          v.level = i + 1;
          if (i !== 0) {
            v.h5RangeMin = this.dataList[i - 1].h5RangeMax;
          }
        });
        // 最后一档的max要置空
        const last = this.dataList[this.dataList.length - 1];
        last.h5RangeMax = 0;
      }, 50);
    },
    // 校验时长(必须大于0)
    utilsTimeCheckEmpty () {
      const index = this.dataList.findIndex(v => !v.chargeDuration);
      if (index !== -1) {
        this.$Message.warning(`「第${index + 1}档」的可充时长有误：值不能为空或0`);
        this.errorIndex = index;
        return false;
      }
      return true;
    },
    // 校验瓦数空值
    utilsPowerCheckEmpty () {
      const index = this.dataList.findIndex(v => (!v.h5RangeMin && v.h5RangeMin !== 0) || !v.h5RangeMax);
      if (index !== -1 && index !== (this.dataList.length - 1)) {
        this.$Message.warning(`「第${index + 1}档」的瓦数区间有误：值不能为空或0`);
        this.errorIndex = index;
        return false;
      }
      return true;
    },
    // 校验瓦数值
    utilsPowerCheckValue () {
      const index = this.dataList.findIndex(v => (v.h5RangeMin > v.h5RangeMax));
      if (index !== -1 && index !== (this.dataList.length - 1)) {
        this.$Message.warning(`「第${index + 1}档」的瓦数区间有误：值大小有误`);
        this.errorIndex = index;
        return false;
      }
      return true;
    },
    _clickDel (i) {
      if (this.dataList.length <= 2) {
        return this.$Message.info('至少要保留2个档位');
      }
      this.dataList.splice(i, 1);
      this.resetRangeMin();
    },
    _clickAdd () {
      if (this.utilsPowerCheckEmpty() && this.utilsPowerCheckValue() && this.utilsTimeCheckEmpty()) {
        this.dataList.push(DevicePriceModel.createAdditionModel(this.dataList[this.dataList.length - 1]));
      }
    }
  }
};
</script>
