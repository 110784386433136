<!--
    Created by 程雨喵'mac on 2024/2/1.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：全局下拉
-->
<style lang="less">

</style>

<template>
  <Select
    :value="currentValue"
    :clearable="clearable"
    :disabled="disabled"
    :max-tag-count="1"
    :multiple="multiple"
    @on-change="setCurrentValue"
  >
    <Option
      v-for="(op, p) in dataOption"
      :key="p + '-op'"
      :value="op.value"
    >
      {{ op.label }}
    </Option>
  </Select>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'YgGlobalSelect',
  components: {},
  props: {
    // 一定要传 config里面的mainKeyValue中的值
    selectType: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentValue: this.value,
      dataOption: []
    };
  },
  watch: {
    value (n, o) {
      if (n !== o) {
        this.setCurrentValue(n);
      }
    }
  },
  created () {
    // const mainKeyValue = this.$config.mainKeyValue;
    // if (!mainKeyValue.find(v => v.key === this.selectType)) {
    //   throw (new Error('数据有误，请使用$config.mainKeyValue中的key'));
    // } else {
    const s = this.selectType;
    if (s === 'payTypeChannel') {
      this.getPayTypeChannelList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'deviceType') {
      this.getDeviceTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'chargingPileType') {
      this.getChargingPileTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'factoryType') {
      this.getFactoryTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'devicePowerType') {
      this.getDevicePowerTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'deviceDataSourceType') {
      this.getDeviceDataSourceTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'orderDataSourceType') {
      this.getOrderDataSourceTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'outsideOrderDataType') {
      this.getOutsideOrderDataTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'outsideDeviceDataType') {
      this.getOutsideDeviceDataTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'outsideCustomerDataType') {
      this.getOutsideCustomerDataTypeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'deviceProvider') {
      this.getDeviceProviderList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'officeList') {
      this.getOfficeList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'regionalCompanyList') {
      this.getRegionalCompanyList().then((res) => {
        this.dataOption = res;
      });
    } else if (s === 'endReasonList') {
      this.getEndReasonList().then((res) => {
        this.dataOption = res;
      });
    }
    // }
  },
  methods: {
    ...mapActions([
      'getPayTypeChannelList',
      'getDeviceTypeList',
      'getChargingPileTypeList',
      'getFactoryTypeList',
      'getDevicePowerTypeList',
      'getDeviceDataSourceTypeList',
      'getOrderDataSourceTypeList',
      'getOutsideOrderDataTypeList',
      'getOutsideDeviceDataTypeList',
      'getOutsideCustomerDataTypeList',
      'getDeviceProviderList',
      'getOfficeList',
      'getRegionalCompanyList',
      'getEndReasonList'
    ]),
    setCurrentValue (value) {
      console.log(value);
      if (this.multiple) {
        // 多选
        if (JSON.stringify(value) === JSON.stringify(this.currentValue)) return;
      } else {
        // 非多选
        if (value === this.currentValue) return;
      }
      this.currentValue = value;
      this.$emit('input', value);
      this.$emit('on-change', value);
    }
  }
};
</script>
