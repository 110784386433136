<!--
    Created by 程雨喵'mac on 2020-06-23.
    Copyright © 2020年 金星晖. All rights reserved.
    功能概述：loading
-->
<style lang="less">
.__g-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //全局loading
  z-index: @modalIndex * 2;
  .loading-box {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: @backColorStrong;
    //background-color: rgba(255, 255, 255, .8);
    border-radius: @borderRadiusMid;
    box-shadow: 0 0 6px 0 rgba(43,101,240,0.21);
    .logo {
      width: 38px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      span {
        color: @adornColor;
        font-size: 24px;
        &.text-1 {
          animation: logoAnim1 0.8s infinite;
        }
        &.text-2 {
          animation: logoAnim2 0.8s infinite;
        }
      }
    }
    @keyframes logoAnim1 {
      0% {transform: translateY(0)}
      25% {transform: translateY(-3px)}
      50% {transform: translateY(0)}
      75% {transform: translateY(3px)}
      100% {transform: translateY(0)}
    }
    @keyframes logoAnim2 {
      0% {transform: translateY(0)}
      25% {transform: translateY(3px)}
      50% {transform: translateY(0)}
      75% {transform: translateY(-3px)}
      100% {transform: translateY(0)}
    }
    span {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 600;
      user-select: none;
      color: @textColorLight;
    }
  }
}
</style>

<template>
  <div
    v-show="visible"
    class="__g-loading"
  >
    <div class="loading-box">
      <!-- <img
        src="/logo-blue.png"
        alt=""
      > -->
      <div class="logo">
        <span class="text-1">吾</span>
        <span class="text-2">行</span>
      </div>
      <span>请稍候</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      visible: false
    };
  }
};
</script>
