<!--
   搜索区域

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-setting-area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .setting-left {
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
    color: @textColorLight;
    .__g-info-strong {
      color: @adornColor;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
    }
  }
  .setting-right {
    flex: 1;
    .display-flex();
    justify-content: flex-end;
    font-size: 12px;
    .setting-button-item {
      margin-left: 10px;
      &.is-export-button-outer {
        position: relative;
        &:hover {
          .export-hover-show {
            opacity: 1;
            right: 0;
            width: auto;
            height: auto;
            transform: translateY(10px);
          }
        }
        @keyframes amountAnim1 {
          0% { top: -30px; transform: scale(0); opacity: 1 }
          50% { top: -30px; transform: scale(1.3); opacity: 1 }
          100% { top: -30px; transform: scale(1); opacity: 1 }
        }
        @keyframes amountAnim2 {
          from { top: -30px; opacity: 1 }
          to { top: 0; opacity: 0 }
        }
        @keyframes amountAnim3 {
          0% { top: -21px; opacity: 1;; transform: scale(0) }
          50% { top: -21px; opacity: 1;transform: scale(1.3) }
          100% { top: -21px; opacity: 1;transform: scale(1) }
        }
        @keyframes amountAnim4 {
          from { top: -21px; opacity: 1; }
          to { top: -51px; opacity: 0; }
        }
        @keyframes buttonAnim1 {
          0% { transform: translateY(0) }
          30% { transform: translateY(5px) }
          60% { transform: translateY(-2px) }
          100% { transform: translateY(0) }
        }
        @keyframes buttonAnim2 {
          0% { transform: translateY(0) }
          100% { transform: translateY(10px) }
        }
        @keyframes buttonAnim3 {
          0% { transform: translateY(10px) }
          50% { transform: translateY(-5px) }
          100% { transform: translateY(0) }
        }
        .export-amount-anim {
          position: absolute;
          right: @containerGap;
          top: -30px;
          width: 26px;
          height: 26px;
          line-height: 25px;
          //.display-flex();
          text-align: center;
          font-size: 10px;
          background-color: @adornColor;
          border-radius: 50%;
          border: 1px solid @adornColor;
          font-weight: 600;
          opacity: 0;
          box-shadow: 2px 2px 4px 0 rgba(248,83,0,0.2);
        }
        .export-amount-anim {
          // 加入的动画
          &.export-amount-anim-1 {
            background-color: @adornColor;
            color: @textColorFlip;
            animation: @transitionTime25 amountAnim1 forwards, @transitionTime15 amountAnim2 forwards;
            animation-delay: 0s, 0.5s;
          }
          // 减少的动画
          &.export-amount-anim-2 {
            background-color: @backColorStrong;
            color: @adornColor;
            animation: @transitionTime25 amountAnim3 forwards, @transitionTime15 amountAnim4 forwards;
            animation-delay: 0s, 0.5s;
          }
        }
        // 按钮-加入的动画
        .btn-start-export-anim-1 {
          animation: @transitionTime25 buttonAnim1 forwards;
          animation-delay: 0.65s;
        }
        // 按钮-减少的动画
        .btn-start-export-anim-2 {
          animation: @transitionTime25 buttonAnim2 forwards, @transitionTime25 buttonAnim3 forwards;
          animation-delay: 0s, 0.5s;
        }
        .export-hover-show {
          position: absolute;
          top: 100%;
          right: 0;
          width: 0;
          height: 0;
          opacity: 0;
          background-color: @backColorLight;
          z-index: 1;
          box-shadow: @shadowMid;
          white-space: nowrap;
          overflow: hidden;
          transition: all @transitionTime15;
          &.hover-show-import {
            opacity: 1;
            right: 0;
            width: auto;
            height: auto;
            transform: translateY(10px);
          }
          .export-hover-show-tit {
            padding: @containerGap @containerGap 0;
            .display-flex();
            justify-content: flex-start;
            font-weight: 600;
            .ivu-icon {
              margin-right: 3px;
              color: @themeColor;
            }
          }
          .export-hover-show-desc {
            padding: 0 @containerGap @containerGap;
            margin-top: 5px;
            color: @textColorLight;
            span {
              color: @adornColor;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="__g-setting-area">
    <div
      class="setting-left"
      :style="showLeftAmount ? '' : 'opacity: 0'"
    >
      数据共计: <span :class="total ? '__g-info-strong' : ''">{{ total || '-' }}</span> 条
    </div>
    <div class="setting-right">
      <div
        v-for="(item, i) in items"
        v-if="!item.isExport || (item.isExport && total > -1)"
        :key="i"
        v-permission="item.permission"
        class="setting-button-item"
        :class="(item.isExport) ? 'is-export-button-outer' : ''"
      >
        <yg-button
          :type="getButtonType(i)"
          :class="'btn-start-export-anim-' + exportAmountAnimationType"
          @click="_click(item)"
        >
          <Icon
            v-if="item.icon"
            :type="item.icon"
            style="margin-right: 5px"
          />
          {{ item.title }}
        </yg-button>
        <!-- 导出数量的提示-->
        <div
          v-if="(item.isExport) && (exportAmount || total)"
          class="export-hover-show"
          :class="controlHoverTip(i) ? 'hover-show-import' : ''"
        >
          <div class="export-hover-show-tit">
            <Icon type="md-help-circle" />
            导出小助手
          </div>
          <div class="export-hover-show-desc">
            <div v-if="exportAmount">
              <p>1、当前<span>已勾选</span>数据；</p>
              <p>2、点击后导出<span>已勾选的{{ exportAmount }}条</span>数据；</p>
            </div>
            <div v-else>
              <p>1、当前<span>暂未勾选</span>任何数据；</p>
              <p>2、点击后导出<span>全部{{ total }}条</span>数据；</p>
            </div>
          </div>
        </div>
        <!--勾选项变化时的动效-->
        <div
          v-if="(item.isExport) && (exportAmount || total)"
          class="export-amount-anim"
          :class="'export-amount-anim-' + exportAmountAnimationType"
        >
          {{ exportAmountGap }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadApiSet from '@/request/api/DownloadApiSet';
export default {
  name: 'YgSettingArea',
  props: {
    // 展示左侧总数
    showLeftAmount: {
      type: Boolean,
      default: true
    },
    // 需要其他更多按钮时传进来
    items: {
      type: Array,
      default: () => {
        /**
         * { icon: 'ios-refresh-circle', title: '重置', key: 'refresh' }
         */
        return [];
      }
    },
    total: {
      type: Number,
      default: null
    },
    // 导出相关 ========================= start >
    // 当前导出的数量(勾选导出)，不要直接取selectIdList的length那样就没法watch
    exportAmount: {
      type: Number,
      default: 0
    },
    // 勾选导出的id集合
    exportSelectIdList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 导出使用的筛选条件
    exportCondition: {
      type: Object,
      default: () => {
        return {};
      }
    }
    // 导出相关 ========================= end >
  },
  data () {
    return {
      /**
       * 0: 无动画
       * 1：加入
       * 2：减少
       */
      exportAmountAnimationType: 0,
      // 数量变化差值
      exportAmountGap: '',
      showHoverTip: false,
      timer: null,
      timer1: null
    };
  },
  watch: {
    exportAmount (n, o) {
      this.clearTimer();
      this.exportAmountAnimationType = 0;
      this.exportAmountGap = n - o;
      if (this.exportAmountGap > 0) {
        this.exportAmountGap = '+' + this.exportAmountGap;
      }
      if (o > n) {
        if (n === 0) {
          this.setHoverShowTip();
        }
        this.exportAmountAnimStart(2);
      } else if (n > o) {
        if (o === 0) {
          this.setHoverShowTip();
        }
        this.exportAmountAnimStart(1);
      }
    }
  },
  created () {},
  destroyed () {
    this.clearTimer();
    this.clearTimer1();
  },
  methods: {
    controlHoverTip (i) {
      if (this.showHoverTip) {
        return i === this.items.findIndex(v => v.isExport);
      }
      return false;
    },
    setHoverShowTip () {
      this.clearTimer1();
      this.showHoverTip = true;
      this.timer1 = setTimeout(() => { this.showHoverTip = false; }, 2000);
    },
    exportAmountAnimStart (type) {
      this.exportAmountAnimationType = type;
      this.timer = setTimeout(() => {
        this.exportAmountAnimationType = 0;
      }, 900);
      const limit = this.$config.exportLimit;
      const warningLimit = limit - parseInt(this.$config.pageSize);
      if (this.exportAmount > limit && type === 1) {
        this.$Modal.warning({
          title: '勾选导出温馨提示',
          content: `最多支持：勾选${limit}条数据，</br>当前已勾选：${this.exportAmount}条，</br>较多数据时建议您使用全量导出。`,
          okText: '我知道了'
        });
      } else if (this.exportAmount > warningLimit) {
        this.$Message.info(`勾选导出，最多支持${limit}条数据，当前已勾选：${this.exportAmount}条`);
      }
    },
    clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = null;
    },
    clearTimer1 () {
      if (this.timer1) {
        clearTimeout(this.timer1);
      }
      this.timer1 = null;
    },
    getButtonType (i) {
      if (this.items.length === 1) {
        return 'adorn';
      }
      return i === 0 ? 'adorn-fill' : 'adorn';
    },
    // isExport全量导出
    _click ({ key, isExport, exportReportType }) {
      if (this.exportAmount > this.$config.exportLimit) {
        return this.$Modal.error({
          title: '无法导出',
          content: `最多支持：勾选${this.$config.exportLimit}条数据，</br>当前已勾选：${this.exportAmount}条，</br>较多数据时建议您使用全量导出。`,
          okText: '我知道了'
        });
      }
      // 导出在组件中处理
      if (isExport) {
        if (this.total <= 0) {
          return this.$Modal.warning({
            title: '无数据可导出',
            content: '请先查询到结果后再进行导出',
            okText: '我知道了'
          });
        }
        const api = DownloadApiSet.downloadCreate;
        const reportCondition = JSON.parse(JSON.stringify(this.exportCondition));
        if (this.exportAmount) {
          const idArr = [];
          this.exportSelectIdList.forEach(v => {
            idArr.push(v._checkId);
          });
          reportCondition.bizIds = idArr.join(',');
        }
        api.params = {
          reportType: exportReportType,
          reportCondition
        };
        this.$http(api).then(res => {
          this.$store.commit('setDownloadAmount', +1);
          this.$Modal.confirm({
            title: '操作成功',
            content: '请至下载中心导出',
            okText: '立即前往',
            cancelText: '稍后前往',
            onOk: () => {
              this.$router.push({
                name: 'download_list'
              });
            }
          });
        });
      } else {
        this.$emit('on-click', key);
      }
    }
  }
};
</script>
