<!--
    Created by 程雨喵'mac on 2024/7/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：订单详情-订单信息
-->
<style lang="less">
.order-detail-charts {
  padding: @containerGap;
}
</style>

<template>
  <div class="order-detail-charts">
    <div
      v-if="showHeader"
      class="display-flex display-flex-h-between"
    >
      <p class="h3 margin-primary-bottom">
        {{ !hasElectric ? '订单图表(功率图)' : '订单图表(功率图+电量图)' }}
      </p>
      <yg-button
        type="adorn-fill"
        size="small"
        @click="_clickRefresh"
      >
        刷新图表
      </yg-button>
    </div>
    <div
      v-if="model.powerInfos.orgList && model.powerInfos.orgList.length"
      id="growUp-1"
      style="width: 100%; height: 400px"
    />
    <div
      v-else
      class="__g-no-data"
      style="height: 300px"
    />
  </div>
</template>

<script>
import { DATA_COLOR } from '@/pages/300__board/dataAnalysis/utils/colors';
const echarts = require('echarts');

export default {
  components: {},
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      myChart: null
    };
  },
  computed: {
    hasElectric () {
      return this.model.electricityInfos && this.model.electricityInfos.orgList && this.model.electricityInfos.orgList.length;
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.drawCharts);
  },
  created () {
    window.addEventListener('resize', this.drawCharts);
    this.$nextTick(() => {
      if (this.model.powerInfos.orgList && this.model.powerInfos.orgList.length) {
        setTimeout(() => {
          this.myChart = echarts.init(document.getElementById('growUp-1'));
          this.myChart.setOption(this.getGrowUpOption());
        }, 300);
      }
    });
  },
  methods: {
    drawCharts () {
      if (this.myChart) {
        this.myChart.resize();
      }
    },
    getGrowUpOption (color = 0) {
      const op = {
        title: {
          text: '',
          left: 'center'
        },
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          formatter: (r) => {
            let str = '';
            const chart0 = r[0];
            const dataIndex = chart0.dataIndex;
            const data = this.model.powerInfos.orgList[dataIndex];
            if (data) {
              str = `
                <p style="font-weight: 600;margin-bottom: 3px;">${chart0.marker}${chart0.seriesName}</p>
                已充时间：${data.differenceOfTime}分钟<br/>
                当前时间：${data.time}
                 <p style="font-weight:600;color: ${DATA_COLOR.primary}">当前功率：${data.power}W</p>
              `;
            }
            if (this.hasElectric) {
              const chart1 = r[1];
              const rData = this.model.electricityInfos.orgList[dataIndex];
              if (rData && rData.electricity > 0) {
                const str1 = `
                <p style="font-weight: 600;margin-bottom: 3px;">${chart1.marker}电量图</p>
                <!--已充时间：${rData.differenceOfTime}分钟<br/>-->
                时段区间：${rData.timeRange}<br/>
                时段内最高功率：${rData.power}W<br/>
                <span style="font-weight:600;color: ${DATA_COLOR.light}">结算总电量：${rData.electricity}度</span><br/>
                <span style="font-weight:600;color: ${DATA_COLOR.error}">时段电量：${rData.periodCapacity}度</span>
              `;
                const line = '<div style="margin: 10px 0; border-top: 1px solid #ebebeb"></div>';
                str = str + line + str1;
              }
            }
            return `<div style="font-size: 12px">${str}</div>`;
          },
          alignTicks: true,
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'rgba(255,51,95,1)'
            }
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '5%',
          top: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.model.powerInfos.xList,
            axisLabel: {
              fontSize: 10 // 设置字体大小为16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '功率',
            scale: true,
            min: 0,
            boundaryGap: [0, 0.1],
            axisLabel: {
              formatter: '{value} W'
            }
          }
        ],
        series: [
          {
            name: '功率走势',
            type: 'line',
            lineStyle: {
              type: 'dashed',
              width: 1
            },
            sampling: 'lttb',
            symbolSize: 0,
            smooth: true,
            itemStyle: {
              color: DATA_COLOR.primary
            },
            areaStyle: {
              // 对应右/下/左/上四个方位，而0 0 0 1则代表渐变色从正上方开始
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: DATA_COLOR.primaryAlpha },
                { offset: 1, color: DATA_COLOR.primaryAlphaDeep }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: false,
              color: 'black',
              position: 'top',
              fontSize: 10
            },
            data: this.model.powerInfos.yList
          }
        ]
      };
      if (this.hasElectric) {
        // const labelOption = {
        //   show: true,
        //   position: 'insideBottom',
        //   distance: 5,
        //   align: 'left',
        //   verticalAlign: 'middle',
        //   rotate: 90,
        //   formatter: (r) => {
        //     if (r.seriesIndex === 1) {
        //       return '结算总电量' + r.value;
        //     }
        //     return '时段电量' + r.value;
        //   },
        //   fontSize: 10,
        //   rich: {
        //     name: {}
        //   }
        // };
        // op.xAxis.push({
        //   type: 'category',
        //   splitLine: {
        //     show: true,
        //     lineStyle: {
        //       type: 'dashed'
        //     }
        //   },
        //   boundaryGap: true,
        //   data: this.model.powerInfos.xList,
        //   axisLine: {
        //     lineStyle: {
        //       color: DATA_COLOR.primary
        //     }
        //   },
        //   axisLabel: {
        //     fontSize: 10 // 设置字体大小为16
        //   }
        // });
        let barWidth = 14;
        if (this.model.electricityInfos.yList.length < 30) {
          barWidth = 14;
        } else if (this.model.electricityInfos.yList.length < 60) {
          barWidth = 10;
        } else {
          barWidth = 6;
        }
        for (let i = 0; i < 2; i++) {
          op.yAxis.push({
            type: 'value',
            name: '电量',
            scale: true,
            min: 0,
            boundaryGap: [0, 0.05],
            splitLine: {
              show: false,
              lineStyle: {
                color: DATA_COLOR.primaryAlphaDeep
              }
            },
            axisLine: {
              lineStyle: {
                color: DATA_COLOR.primary
              }
            },
            axisLabel: {
              formatter: '{value} 度'
            }
          });
          op.series.push({
            name: i === 0 ? '结算总电量' : '时段电量',
            yAxisIndex: 1,
            type: 'bar',
            barWidth: barWidth,
            label: {
              show: false
            },
            // label: labelOption,
            emphasis: { focus: 'series' },
            itemStyle: {
              // borderRadius: 2,
              color: i === 0 ? DATA_COLOR.light : DATA_COLOR.error
            },
            data: i === 0 ? this.model.electricityInfos.yList : this.model.electricityInfos.yList2
          });
        }
      }
      return op;
    },
    _clickRefresh () {
      this.$emit('on-refresh');
    }
  }
};
</script>
