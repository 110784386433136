/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
import { YunGuiHttpClient } from '@/request/axios/HttpClient';
import CommunityApiSet from '@/request/api/CommunityApiSet';
import Routers from '../../router/routers';
import Storage from '@/libs/storage/index';
export default {
  state: {
    leftBarOpen: true, // 控制左侧导航栏展开与关闭
    leftBarList: [], // 左侧导航
    // 顶部导航记录
    routerHistory: [],
    // 当前的页面
    currentRouter: {},
    /**
     * 当前项目模式
     * 0：正常模式，
     * 1：本地模式，无网络可访问界面，界面中的所有网络请求返回假数据
     */
    projectMod: 0,
    showReqMonitor: false,
    // 下载中心数量
    downloadAmount: 0,
    // 小区全量数据
    communityData: [],
    // order-list组件层级数(超过5层就不让点)
    orderListDrawerCount: 0,
    // 控制table需要重新渲染，这个计数器变化时table组件中的table需要重新计算高度
    controlTableResizeCount: 0
  },
  mutations: {
    setControlTableResizeCount (state) {
      let controlTableResizeCount = state.controlTableResizeCount + 1;
      if (controlTableResizeCount > 1000) {
        controlTableResizeCount = 1;
      }
      state.controlTableResizeCount = controlTableResizeCount;
    },
    setLeftBarOpen (state, value) {
      state.leftBarOpen = value;
    },
    setLeftBarList (state, value) {
      state.leftBarList = value;
    },
    setRouterHistory (state, value) {
      const h = state.routerHistory;
      const index = h.findIndex(v => v.name === value.name);
      if (index !== -1) {
        // 有就删掉
        h.splice(index, 1);
      }
      const tit = value.meta.title;
      h.splice(0, 0, {
        name: value.name,
        title: tit,
        shortTitle: tit.length > 4 ? (tit.slice(0, 4) + '...') : tit
      });
      state.routerHistory = h;
    },
    deleteRouterHistory (state, value) {
      const h = state.routerHistory;
      const index = h.findIndex(v => v.name === value.name);
      if (index !== -1) {
        // 有就删掉
        h.splice(index, 1);
      }
      state.routerHistory = h;
    },
    setCurrentRouter (state, value) {
      state.currentRouter = value;
    },
    setSmallScreen (state, value) {
      state.smallScreen = value;
    },
    setProjectMod (state, value) {
      state.projectMod = value;
    },
    setShowReqMonitor (state, value) {
      state.showReqMonitor = value;
    },
    setHostInputValue (state, value) {
      switch (value) {
      case 'whosyourdaddy':
        state.projectMod = 1;
        break;
      case 'normal':
        state.projectMod = 0;
        break;
      case 'monitor':
        state.showReqMonitor = true;
        break;
      }
    },
    setDownloadAmount (state, value) {
      if (value > 0) {
        state.downloadAmount += 1;
      } else {
        state.downloadAmount = 0;
      }
    },
    setCommunityData (state, value) {
      state.communityData = value;
    },
    setOrderListDrawerCountAdd (state, value) {
      if (value === 0) {
        state.orderListDrawerCount = 0;
      } else {
        state.orderListDrawerCount = state.orderListDrawerCount + 1;
      }
    },
    setOrderListDrawerCountMinus (state) {
      state.orderListDrawerCount = state.orderListDrawerCount - 1;
    }
  },
  actions: {
    // 这里预留为了以后动态渲染左侧导航用(后期需要跟权限绑定)
    getLeftBarList ({ state, commit }) {
      return new Promise((resolve, reject) => {
        const { level1, level2 } = (new Storage()).leftMenus;
        // if (state.leftBarList.length) {
        //   resolve(state.leftBarList);
        // } else {
        // UserApiSet.sysResources
        // const pcRouters = Routers().find(v => v.path === '/page').children;
        let pcRouters = Routers().find(v => v.path === '/page').children;
        pcRouters = pcRouters.filter(v => level1.includes(v.path));
        pcRouters.forEach(v => {
          v.children = v.children.filter(vv => level2.includes(vv.name));
        });
        commit('setLeftBarList', pcRouters);
        resolve(pcRouters);
      });
    },
    getCommunityData ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.communityData.length) {
          resolve(JSON.parse(JSON.stringify(state.communityData)));
        } else {
          const createDic = (code, name, cate) => {
            return {
              id: code,
              name: name,
              cate,
              childList: []
            };
          };
          const api = CommunityApiSet.communityBindList;
          YunGuiHttpClient(api).then(res => {
            const { data } = res;
            const list = data.rows || [];
            const newList = [];
            list.forEach(v1 => {
              const dic1 = createDic(v1.propertyCompanyId, v1.propertyCompanyName, 1);
              if (v1.provinceList && Array.isArray(v1.provinceList)) {
                v1.provinceList.forEach(v2 => {
                  const dic2 = createDic(v2.code, v2.name, 2);
                  if (v2.cityBindList && Array.isArray(v2.cityBindList)) {
                    v2.cityBindList.forEach(v3 => {
                      const dic3 = createDic(v3.code, v3.name, 3);
                      if (v3.communityBindList && Array.isArray(v3.communityBindList)) {
                        v3.communityBindList.forEach(v4 => {
                          const dic4 = createDic(v4.communityId, v4.communityName, 4);
                          delete dic4.childList;
                          dic3.childList.push(dic4);
                        });
                      }
                      dic2.childList.push(dic3);
                    });
                  }
                  dic1.childList.push(dic2);
                });
              }
              newList.push(dic1);
            });
            commit('setCommunityData', newList);
            resolve(JSON.parse(JSON.stringify(newList)));
          });
        }
      });
    }
  }
};
