<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：列表中使用的信号(最小宽度要30, 高度20)
-->
<style lang="less">
.__g-signal {
  //.display-flex();
  .__g-signal-line-box {
    width: 30px;
    height: 20px;
    display: flex;
    align-items: flex-end;
    .__g-signal-line-item {
      width: 3px;
      margin-right: 1px;
      //border-radius: 2px 2px 0 0;
      background-color: @buttonColorDisabled;
      &.is-highlight {
        background-color: @finishColor;
      }
    }
  }
  .status-box {
    &-1 {
      color: @finishColor;
      font-weight: 600;
    }
    color: @textColorLight;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    font-weight: 300;
  }
}
.__g-signal-hover-content {
  padding: 30px 15px 10px;
  width: 200px;
  font-size: 12px;
  .__g-signal-range {
    display: flex;
    justify-content: space-between;
    color: @textColorLight;
  }
  .__g-signal-bg {
    position: relative;
    height: 10px;
    background: linear-gradient(90deg, rgba(27,197,189,0.15) 40%, rgba(27,197,189,1) 100%);
    .__g-signal-current {
      position: absolute;
      padding: 0 5px;
      height: 20px;
      top: -30px;
      left: 0;
      font-size: 12px;
      background-color: rgba(27,197,189,0.1);
      border: 1px solid rgba(27,197,189,1);
      color: rgba(27,197,189,1);
      border-radius: 3px;
      transform: translateX(-50%);
      font-weight: 600;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 2px;
        bottom: -11px;
        height: 10px;
        transform: translateX(-50%);
        background-color: rgba(27,197,189,1);
      }
    }
  }
}
</style>

<template>
  <div class="__g-signal">
    <Poptip
      trigger="hover"
      transfer
    >
      <a class="__g-signal-line-box">
        <div
          v-for="(item, i) in lineNum"
          :key="i"
          class="__g-signal-line-item"
          :style="getLineHeight(i)"
          :class="item <= highLightIndex ? 'is-highlight' : ''"
        />
      </a>
      <div slot="title">
        信号强度
        <!--{{ currentLeft }}-->
      </div>
      <div slot="content">
        <div class="__g-signal-hover-content">
          <div class="__g-signal-bg">
            <div
              :style="currentLeft"
              class="__g-signal-current"
            >
              {{ value }}
            </div>
          </div>
          <div class="__g-signal-range">
            <div>{{ range[0] }}</div>
            <div>{{ range[1] }}</div>
          </div>
        </div>
      </div>
    </Poptip>
    <div
      class="status-box"
      :class="'status-box-' + statusValue"
    >
      {{ statusDesc }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgTableSignal',
  components: {},
  props: {
    range: {
      type: Array,
      default: () => {
        return [0, 31];
      }
    },
    // 当前的值
    value: {
      type: [Number, String],
      default: 0
    },
    // 当前状态
    statusValue: {
      type: [Number, String],
      default: 0
    },
    statusDesc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 竖线数量
      lineNum: 8,
      // 需要高亮的下标(在这之前的都会高亮)
      highLightIndex: 0,
      currentValue: 0,
      currentLeft: ''
    };
  },
  watch: {
    value (o, n) {
      if (o !== n) {
        this.setCurrentValue();
      }
    }
  },
  created () {
    this.setCurrentValue();
  },
  methods: {
    setCurrentValue () {
      let v = this.value;
      if (typeof this.value === 'string') {
        v = Number(this.value);
      }
      // v = 100;
      this.currentValue = v;
      const s = this.range[0];
      const e = this.range[1];
      // 占比
      const n = this.currentValue / (e - s);
      const lineNum = this.lineNum;
      let h = Math.ceil(lineNum * n);
      if (h < 0) h = 0;
      if (h > lineNum) h = lineNum;
      this.highLightIndex = h;
      let percent = n * 100;
      if (percent > 100) percent = 100;
      this.currentLeft = `left:${percent}%;`;
    },
    getLineHeight (index) {
      return `height:${20 - (this.lineNum - index) * 1.5}px`;
    }
  }
};
</script>
