/**
 * 监听登录状态变更
 *
 * @date:2024/01/12
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
// 登录状态监听

class CheckLoginAuth {
  constructor (token) {
    this.gap = 1000;
    // 首次创建时的token
    this.originalToken = token;
    // 定时器
    this.timer = null;
  }

  // 私有方法-关闭定时器
  _closeTimer () {
    console.log('clear-------loginAuth');
    clearInterval(this.timer);
    this.timer = null;
  }

  // 开始监听
  startObserver (callBack) {
    if (this.timer) {
      this._closeTimer();
    }
    this.timer = setInterval(() => {
      callBack(this.originalToken);
    }, this.gap);
  }

  // 移除
  removeObserver () {
    if (this.timer) {
      this._closeTimer();
    }
  }
}

export default CheckLoginAuth;
