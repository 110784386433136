<!--
   响应式-列

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">

</style>

<template>
  <div
    ref="reactiveCol"
    class="__g-reactive-col"
    :style="colStyle"
  >
    <slot />
  </div>
</template>

<script>
import { onHandler, offHandler } from '@/libs/utils.js';
export default {
  name: 'YgReactiveCol',
  components: {},
  props: {
    // 要占用几列，可取范围 1，2，3，4，5，6
    columnNumber: {
      type: Number,
      default: 2
    },
    // 最小宽度
    minWidth: {
      type: Number,
      default: 200
    }
  },
  data () {
    return {
      colStyle: '',
      widthPercent: '',
      columnWidthArr: [100, 50, 33.333333, 25, 20, 16.5]
    };
  },
  created () {
    onHandler(window, 'resize', this.resetColStyle);
  },
  mounted () {
    if (typeof this.columnNumber !== 'number') {
      return;
    }
    if (this.columnNumber > 6 || this.columnNumber < 0) {
      return;
    }
    this.$nextTick(() => {
      this.resetColStyle();
    });
  },
  destroyed () {
    offHandler(window, 'resize', this.resetColStyle);
  },
  methods: {
    resetColStyle () {
      let widthPercent = 0;
      const parentWidth = this.$parent.$el.offsetWidth;
      let columnNumber = this.columnNumber;
      const minWidth = this.minWidth;
      while (columnNumber > 0) {
        if (parentWidth > minWidth * columnNumber) {
          widthPercent = this.columnWidthArr[columnNumber - 1];
          break;
        }
        columnNumber -= 1;
      }
      if (widthPercent === this.widthPercent) {
        return;
      }
      this.widthPercent = widthPercent;
      this.colStyle = `min-width:${widthPercent}%; max-width:${widthPercent}%`;
    }
  }
};
</script>
