import html2canvas from 'html2canvas';
/**
 * 使用方法-接入mixin后
 * 1.确保div在dom中渲染出来之后，调用mixinImageCreatePic()方法
 */
export default {
  methods: {
    mixinImageBase64ToBlob (code) {
      const parts = code.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]); // 解码base64得到二进制字符串
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength); // 创建8位无符号整数值的类型化数组
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i); // 数组接收二进制字符串
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    mixinImageCreatePic (eleId, fileName = '图片名称') {
      const ele = document.getElementById(eleId);
      if (!ele) {
        this.$Message.warning('图品生成异常');
        return;
      }
      html2canvas(ele, {
        scale: 2,
        width: ele.offsetWidth,
        height: ele.offsetHeight,
        allowTaint: true,
        useCORS: true
      }).then((canvas) => {
        const url = canvas.toDataURL('image/png');
        const aLink = document.createElement('a');
        const blob = this.mixinImageBase64ToBlob(url); // new Blob([content]);
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        this.$Message.success('图片下载成功');
      });
    }
  }
};
