<!--
    Created by 程雨喵'mac on 2024/1/12.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.drawer-log {
  .dialog-title {
    .big {
      line-height: 16px;
      font-weight: bold;
    }
    .small {
      margin-left: 10px;
      font-size: 14px;
      color: @textColorLight;
      line-height: 14px;
      font-weight: 400;
    }
  }
  .div-content {
    .json-box {
      display: flex;
      .json-item {
        .flex-grow(50%);
      }
    }
  }
  .black-ul {
    padding-bottom: 10px;
    border-top: 1px solid #ebebeb;
    .black-li {
      padding: 0 5px;
      display: flex;
      align-items: center;
      min-height: 40px;
      line-height: 20px;
      border: 1px solid #ebebeb;
      border-top: 0;
      .url {
        .flex-grow(90%);
      }
      .del {
        text-align: center;
        flex: 1;
        cursor: pointer;
        font-size: 13px;
        color: @themeColor;
      }
    }
  }
  .no-black-list {
    text-align: center;
    line-height: 200px;
  }
}
</style>

<template>
  <yg-drawer
    width="100%"
    class="drawer-log"
    :show-bottom="false"
    @on-close="dialogClose"
  >
    <p
      slot="title"
      class="dialog-title"
    >
      <span class="big">系统操作日志(仅测试环境可用)</span>
      <span class="small">日志仅保存于此浏览器</span>
    </p>
    <div
      slot="content"
      class="div-content"
    >
      <yg-setting-area
        :items="settingItems"
        @on-click="_clickSetting"
      />
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        style="margin-top: 15px"
        @on-page-change="_changePage"
      />
      <yg-modal
        v-if="currentModel"
        width="1000"
        title="详情"
        :show-bottom="false"
        @on-close="currentModel=null"
      >
        <div slot="content">
          <yg-card
            title="请求参数"
          >
            <div
              slot="content"
              class="json-box"
            >
              <div class="json-item">
                <h4>请求URL</h4>
                <div style="margin-top: 20px">
                  {{ currentModel.title }}: {{ currentModel.apiUrl }}
                </div>
              </div>
              <div class="json-item">
                <h4>请求参数</h4>
                <json-viewer :value="currentModel.body" />
              </div>
            </div>
          </yg-card>
          <yg-card
            title="返回参数"
            style="margin-top: 15px"
          >
            <div
              slot="content"
              class="json-box"
            >
              <div class="json-item">
                <h4>JSON源数据</h4>
                <div style="margin-top: 20px">
                  {{ currentModel.response }}
                </div>
              </div>
              <div class="json-item">
                <h4>JSON美化数据</h4>
                <json-viewer :value="currentModel.response" />
              </div>
            </div>
          </yg-card>
        </div>
      </yg-modal>
      <yg-modal
        v-if="blackListDialog"
        width="500"
        title="黑名单管理"
        :back-type="2"
        :show-bottom="false"
        @on-close="blackListDialog=false"
      >
        <div slot="content">
          <div
            v-if="blackList.length"
            class="black-ul"
          >
            <div
              v-for="(item, i) in blackList"
              :key="i"
              class="black-li"
            >
              <span class="url">
                <span>{{ item.title }}: </span>
                <span>{{ item.apiUrl }}</span>
              </span>
              <span
                class="del"
                @click.stop="_clickDelBlackList(item.apiUrl)"
              >删除</span>
            </div>
          </div>
          <div
            v-else
            class="no-black-list"
          >
            暂无数据
          </div>
        </div>
      </yg-modal>
    </div>
  </yg-drawer>
</template>

<script>
import { MonitorSingle } from '@/libs/monitor/RequestMonitor';
export default {
  components: {},
  data () {
    return {
      settingItems: [
        { icon: 'md-trash', title: '全部删除', key: 'del' },
        { icon: 'md-document', title: '黑名单管理', key: 'black' }
      ],
      page: { current: 1, size: 10, total: 0 },
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('createTime', '时间').setWidth(150),
        BaseTableColumn.initData('method', '请求方式').setWidth(80),
        BaseTableColumn.initData('title', '标题',).setWidth(200),
        BaseTableColumn.initData('apiUrl', '接口'),
        BaseTableColumn.initData('body', '请求参数').setRender((h, { row, index }) => {
          return h('p', {}, JSON.stringify(row.body));
        }),
        BaseTableColumn.initData('response', '返回参数').setRender((h, { row, index }) => {
          return h('p', {}, JSON.stringify(row.response));
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(80).setAlign('center').setButtons([
          { title: '详情', name: 'detail' },
          { title: '删除', name: 'del' },
          { title: '加入黑名单', name: 'black' }
        ], (e, row, index) => {
          if (e.name === 'detail') {
            this.currentModel = row;
          } else if (e.name === 'del') {
            this.monitor.delMonitor(this.page.current, this.page.size, index);
            this.requestData();
          } else if (e.name === 'black') {
            this.$Modal.confirm({
              title: '温馨提示',
              content: '加入黑名单后，<br/>此接口将不再记录到日志中, <br/>原有的记录会被保留。<br/> 是否确定？',
              onOk: () => {
                this.monitor.addToMonitorBlackList(row.apiUrl, row.title);
              }
            });
          }
        })
      ],
      tableData: [],
      monitor: null,
      currentModel: null,
      blackListDialog: false,
      blackList: []
    };
  },
  created () {
    this.page.size = parseInt(this.$config.pageSize);
    this.monitor = MonitorSingle();
    this.requestData();
  },
  methods: {
    dialogClose () {
      this.$store.commit('setShowReqMonitor', false);
    },
    // 分页
    _changePage (page) {
      this.page.current = page;
      this.requestData();
    },
    // 点击setting
    _clickSetting (key) {
      if (key === 'del') {
        if (!this.tableData.length) {
          this.$Message.info('无可删内容');
          return;
        }
        this.$Modal.confirm({
          title: '温馨提示',
          content: '是否确定要全部删除',
          onOk: () => {
            this.monitor.delAllMonitor();
            this.requestData();
          }
        });
      } else if (key === 'black') {
        this.blackList = this.monitor.getMonitorBlackList();
        this.blackListDialog = true;
      }
    },
    // 点击删除黑名单
    _clickDelBlackList (url) {
      this.monitor.delMonitorBlackList(url);
      this.blackList = this.monitor.getMonitorBlackList();
    },
    requestData () {
      const { list, total } = this.monitor.getMonitor(this.page.current, this.page.size);
      console.log(list);
      if (list.length) {
        this.jsonData = list[0].response;
        this.tableData = list;
        this.page.total = total;
      } else {
        this.jsonData = null;
        this.tableData = [];
        this.page.total = 0;
      }
    }
  }
};
</script>
