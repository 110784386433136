<!--
    Created by 程雨喵'mac on 2023/12/15.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
//状态
.__c-order-status-mark {
  position: absolute;
  right: 0;
  top: @containerGap;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: @backColorStrong;
  .mark-inner {
    position: relative;
    width: 100%;
    height: 100%;
    @keyframes outCircleAnim {
      0% { opacity: 0}
      100% { opacity: 1}
    }
    @keyframes circleAnim {
      0% { transform: rotateZ(-450deg)}
      70% { transform: rotateZ(0)}
      100% { transform: rotateZ(-45deg)}
    }
    @keyframes textAnim {
      0% { transform: scale(0)}
      70% { transform: scale(1)}
      100% { transform: rotateZ(-45deg)}
    }
    .outer-circle {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      padding: 9px;
      border-radius: 50%;
      z-index: 1;
      animation: outCircleAnim 1s forwards;
    }
    .inner-circle {
      position: absolute;
      right: 14px;
      top: 14px;
      bottom: 14px;
      left: 14px;
      padding: 9px;
      border-radius: 50%;
      animation: circleAnim 1s forwards;
      &::before {
        content: '';
        position: absolute;
        background-color: @backColorStrong;
        top: 18px;
        left: -3px;
        width: 19px;
        bottom: 18px;
        animation: outCircleAnim 0.5s forwards;
      }
      &::after {
        content: '';
        position: absolute;
        background-color: @backColorStrong;
        top: 12px;
        right: -3px;
        width: 19px;
        bottom: 12px;
        animation: outCircleAnim 0.5s forwards;
      }
    }
    .text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .display-flex();
      flex-direction: column;
      font-size: 30px;
      line-height: 46px;
      z-index: 1;
      font-weight: 600;
      animation: textAnim 1s forwards;
      cursor: default;
      user-select: none;
      span {
        font-size: 12px;
        line-height: 12px;
        transform: scale(0.8);
        font-weight: 400;
      }
    }
  }
}
.__c-status-type{
  color: @buttonColorDisabled;
  .outer-circle {
    border: 6px solid @buttonColorDisabled;
  }
  .inner-circle {
    border: 2px solid @buttonColorDisabled;
  }
  // 成功色
  &-2{
    color: @finishColor;
    .outer-circle {
      border: 6px solid @finishColor;
    }
    .inner-circle {
      border: 2px solid @finishColor;
    }
  }
  // 失败色
  &-3{
    color: @errorColor;
    .outer-circle {
      border: 6px solid @errorColor;
    }
    .inner-circle {
      border: 2px solid @errorColor;
    }
  }
  // 主题色
  &-4{
    color: @themeColor;
    .outer-circle {
      border: 6px solid @themeColor;
    }
    .inner-circle {
      border: 2px solid @themeColor;
    }
  }
  // 辅助色
  &-5{
    color: @adornColor;
    .outer-circle {
      border: 6px solid @adornColor;
    }
    .inner-circle {
      border: 2px solid @adornColor;
    }
  }
}
</style>

<template>
  <!--状态-->
  <div
    class="__c-order-status-mark __c-status-type"
    :class="'__c-status-type-' + currentType"
  >
    <div class="mark-inner">
      <div class="outer-circle" />
      <div class="inner-circle" />
      <p
        class="text"
        :style="text.length < 4 ? 'font-size:30px' : 'font-size: 24px'"
      >
        <span>{{ infoText }}</span>
        {{ text }}
        <span>STATUS</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgStatusMark',
  components: {},
  props: {
    // 状态文案
    text: {
      type: String,
      default: ''
    },
    // 状态值
    value: {
      type: [String, Number],
      default: -1
    },
    infoText: {
      type: String,
      default: '订单状态'
    },
    /**
     * 状态对应的颜色
     * 1: 弱色
     * 2：成功色
     * 3：失败色
     * 4：主题色
     * 5：辅助色
     */
    typeMapItems: {
      type: Array,
      default: () => {
        // 参数的值：
        return [
          // 表示 value为1时展示 弱色
          { value: 1, type: 1 }
        ];
      }
    }
  },
  computed: {
    currentType () {
      const item = this.typeMapItems.find(v => v.value === this.value);
      return item ? item.type : 1;
    }
  }
};
</script>
