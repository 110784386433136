import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isValidString1, isPhone } from '@/libs/regularUtils';

export class LocationListModel extends BaseModel {
  constructor () {
    super();
    this.id = ''; // 点位ID
    this.siteCode = ''; // 点位编码
    this.name = ''; // 点位名称
    this.communityId = ''; // 小区编号
    this.communityName = ''; // 小区名称
    this.propertyCompanyId = ''; // 物业公司ID
    this.propertyCompanyName = ''; // 物业公司名称
    this.province = ''; // 省ID
    this.provinceName = ''; // 省名称
    this.city = ''; // 市ID
    this.cityName = ''; // 市名称
    this.district = ''; // 区ID
    this.districtName = ''; // 区名称
    this.deviceCount = ''; // 设备数量
    this.testAAAAAA = 0; // 充值机设备
    this.serviceCall = ''; // 运维服务电话
    this.manager = ''; // 运维服务联系人
    this.createTime = ''; // 创建时间
    this.status = ''; // 数据状态（1：启用，0：禁用）
    this.address = ''; // 详情地址
    this.lng = ''; // 经度
    this.lat = ''; // 纬度
    this.providerName = ''; // 所属服务商
    this.officeName = [];
    // 前端自有
    this.h5StatusSwitch = false;
    this.communityData = [];
    this.h5Area = '';
  }

  initData (resData) {
    super.initData(resData);
    this.providerName = resData.providerName || resData.serviceProviderName;
    this.h5Area = (resData.provinceName || '') + (resData.cityName || '') + (resData.districtName || '');
    this.h5StatusSwitch = !!this.status;
    return this;
  }

  static createFakeDataList (len = 10) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: BaseModel.getFakeNumber(99999999, 0),
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        communityName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20)),
        propertyCompanyName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20)),
        provinceName: BaseModel.getFakeTitle(2) + '省',
        cityName: BaseModel.getFakeTitle(3) + '市',
        districtName: BaseModel.getFakeTitle(6) + '区',
        deviceCount: BaseModel.getFakeNumber(10, 0),
        createTime: '2023-12-12 12:12:12',
        status: i % 2,
        manager: '王丽莎',
        serviceCall: '13797400225'
      };
      list.push(dic);
    }
    return list;
  }

  static createInfoFakeData () {
    const dic = {
      id: BaseModel.getFakeNumber(99999999, 0),
      name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
      communityName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20)),
      propertyCompanyName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 20)),
      provinceName: BaseModel.getFakeTitle(2) + '省',
      cityName: BaseModel.getFakeTitle(3) + '市',
      districtName: BaseModel.getFakeTitle(6) + '区',
      deviceCount: BaseModel.getFakeNumber(10, 0),
      createTime: '2023-12-12 12:12:12',
      status: 1,
      manager: '王丽莎',
      serviceCall: '13797400225',
      address: '网点详细地址',
      lng: '545,54535',
      lat: '45435,545353'
    };
    return dic;
  }

  /**
   * 数据具体校验-新增点位
   * @returns {*}
   */
  checkValidAddition () {
    // 专项校验
    if (!this.communityData.length || this.communityData.length < 4) {
      return BaseModel.getValidRes(false, '请选择所属物业和小区');
    } else if (this.name.length < 6 || this.name.length > 40) {
      return BaseModel.getValidRes(false, '请输入长度为6-40位的点位名称');
    } else if (!isValidString1(this.name)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    } else if (this.address.length < 6 || this.address.length > 40) {
      return BaseModel.getValidRes(false, '请输入长度为6-40位的详细地址');
    } else if (!isValidString1(this.address)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    return BaseModel.getValidRes(true, '', {
      propertyCompanyId: this.communityData[0],
      name: this.name,
      communityId: this.communityData[3],
      address: this.address,
      lng: this.lng,
      lat: this.lat
    });
  }

  /**
   * 数据具体校验-编辑点位
   * @returns {*}
   */
  checkValidInfoSubmit () {
    // 专项校验
    if (this.name.length < 6 || this.name.length > 40) {
      return BaseModel.getValidRes(false, '请输入长度为6-40位的点位名称');
    } else if (!isValidString1(this.name)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    } else if (this.address.length < 6 || this.address.length > 40) {
      return BaseModel.getValidRes(false, '请输入长度为6-40位的详细地址');
    } else if (!isValidString1(this.address)) {
      return BaseModel.getValidRes(false, '请输入有效字符');
    }

    return BaseModel.getValidRes(true, '', {
      id: this.id,
      name: this.name,
      address: this.address,
      lng: this.lng,
      lat: this.lat
    });
  }

  /**
   * 数据具体校验-编辑运维人员
   * @returns {*}
   */
  checkValidConcatSubmit () {
    // 专项校验
    if (!isPhone(this.serviceCall)) {
      return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
    }
    return BaseModel.getValidRes(true, '', {
      siteId: this.id,
      manager: this.manager,
      serviceCall: this.serviceCall
    });
  }

  static createAdditionModel () {
    return new this();
  }
}
