<!--
    Created by 程雨喵'mac on 2024/10/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：端口功率设置
-->
<style lang="less">
.dc-port-parameter-submit {
  .submit-table {
    position: relative;
    border-top: 1px solid @lineColorNormal;
    border-radius: @borderRadiusMin;
    &+.submit-table {
      margin-top: @containerGap;
    }
    .submit-table-row {
      display: flex;
      //align-items: flex-start;
      border: 1px solid @lineColorNormal;
      border-top: 0;
      min-height: 40px;
      line-height: @formHeight;
      font-size: 12px;
      .ivu-icon {
        cursor: help;
        color: @textColorLight;
        margin-right: 5px;
      }
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
      }
      .table-col {
        position: relative;
        padding: 5px;
        .display-flex();
        box-sizing: border-box;
        &.style-merge {
          background: @backColorStrong;
          position: absolute;
          width: 100%;
          top: 41px;
          bottom: 0;
          .display-flex();
          border-bottom: 1px solid @lineColorNormal;
          z-index: 1;
        }
        .table-col-inner {
          width: 100%;
          //.display-flex();
          //white-space: nowrap;
          .ivu-input-number {
            flex: 1;
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(120px);
          color: @textColorLight;
        }
        &.col-t-1 {
          .flex-grow(80px);
        }
        &.col-t-2 {
          flex: 1;
        }
      }
    }
  }
}
</style>

<template>
  <div class="dc-port-parameter-submit">
    <div
      v-for="(item, i) in tableRows"
      :key="i"
      class="submit-table"
    >
      <div class="submit-table-row is-header">
        <div
          v-for="(tableHeadersItem, tableHeadersIndex) in tableHeaders"
          :key="tableHeadersIndex"
          class="table-col"
          :class="'col-t-' + tableHeadersIndex"
        >
          {{ tableHeadersItem }}
        </div>
      </div>
      <div
        v-for="(re, ind) in item.items"
        :key="'ind-' + ind"
        class="submit-table-row"
      >
        <div
          v-if="ind === 0"
          class="table-col col-t-0 style-merge"
        >
          {{ re.submitTypeTitle }}
        </div>
        <div class="table-col col-t-0">
          {{ re.submitTypeTitle }}
        </div>
        <div
          class="table-col col-t-1"
        >
          {{ re.title }}
        </div>
        <!--编辑时，编辑时，编辑时-->
        <div
          v-if="isEdit"
          class="table-col col-t-2"
        >
          <div class="table-col-inner">
            <RadioGroup
              v-if="re.valueTag === 'Radio'"
              v-model="model[re.key]"
              :value="'1'"
              :disabled="re.disabled"
            >
              <Radio
                v-for="(sub, r) in powerDic[re.optionKey]"
                :key="'r-' + r"
                :disabled="re.disabled"
                :label="parseInt(sub)"
              >
                {{ sub }}
              </Radio>
            </RadioGroup>
          </div>
        </div>
        <!--展示时，展示时，展示时-->
        <div
          v-else
          class="table-col col-t-2"
        >
          <span>{{ model[re.key] || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      // 功率列表
      powerDic: {
        // 超翔- manufacturers 20
        maxPortPowerCX: [],
        // 友电- manufacturers 40
        maxPortPowerYD: [],
        // 尚亿- manufacturers 50
        maxPortPowerSY: []
      },
      tableHeaders: ['配置类型', '相关设备', '相关值(单位：瓦)'],
      tableRows: [
        {
          title: '端口最大功率',
          items: [
            {
              submitTypeTitle: '端口最大功率',
              title: '超翔',
              key: 'portPowerCX',
              valueTag: 'Radio',
              manuFacturers: '10',
              // powerDic对象的key
              optionKey: 'maxPortPowerCX',
              disabled: false
            },
            {
              title: '友电',
              key: 'portPowerYD',
              valueTag: 'Radio',
              manuFacturers: '40',
              // powerDic对象的key
              optionKey: 'maxPortPowerYD',
              disabled: false
            },
            {
              title: '尚亿',
              key: 'portPowerSY',
              valueTag: 'Radio',
              manuFacturers: '50',
              // powerDic对象的key
              optionKey: 'maxPortPowerSY',
              disabled: false
            }
          ]
        }
      ]
    };
  },
  created () {
    // 如果有值，需要把别的置为不可用（设备列表点进来会有值，模板点进来没有值）
    if (this.model.manufacturers) {
      const list = [];
      this.tableRows[0].items.forEach(v => {
        if (v.manuFacturers === this.model.manufacturers) {
          v.submitTypeTitle = '端口最大功率';
          list.push(v);
        }
        this.tableRows[0].items = list;
      });
    }
    this.requestData();
  },
  methods: {
    $checkSubmit () {
      const item = this.tableRows[0].items.find(v => !this.model[v.key]);
      if (item) {
        return this.$Message.warning(item.title + '端口功率暂未设置');
      }
      return { success: true };
    },
    // 请求-所有功率列表
    requestData () {
      const api = DeviceApiSet.maxPortPowerOption;
      api.params = {};
      this.$http(api).then(res => {
        this.powerDic = res.data;
      });
    }
  }
};
</script>
