/**
 * Created by 程雨喵'mac on 2023/12/1.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-permission, value: data-value="['user:list']"
 * 功能：按钮权限
 */

function inserted (el, binding, vnode) {
  // 1.查看功能是否开启
  if (!binding.value) return;
  let realValue = null;
  if (Array.isArray(binding.value)) {
    realValue = binding.value;
  } else {
    realValue = [binding.value];
  }
  // 当前登录账号
  const permission = vnode.context.$store.state.user.permission;
  // 全部必须都在权限中
  let flag = true;
  for (let i = 0; i < realValue.length; i++) {
    const key = realValue[i];
    flag = permission.includes(key);
    if (!flag) {
      // 判断是否有权限，如果没有权限，则删除该节点
      el.parentNode && el.parentNode.removeChild(el);
      break;
    }
  }
}

export default {
  install (Vue) {
    Vue.directive('permission', {
      inserted
    });
  }
};
