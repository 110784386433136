<!--
    Created by 王丽莎 on 2024/05/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：交易管理-充电币交易流水
-->
<style lang="less">
#charging-coin-transaction-record {
  .col-text-strong {
    .display-flex();
    color: @adornColor;
    font-weight: 600;
  }
  .ivu-poptip-rel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-text-light {
    color: @textColorLight;
  }
  .pay-amount-icon {
    color: @textColorLight;
  }
}
</style>

<template>
  <div id="charging-coin-transaction-record">
    <!-- 查询条件 -->
    <div
      v-permission="'system:chargeCoin:getTransRecord:list'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:chargeCoin:getTransRecord:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-row-expand="mixinTableOnRowExpand"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
    <!--弹窗：扣减充电币-->
    <v-submit
      v-if="dialog.deductedSubmitModel"
      :model="dialog.deductedSubmitModel"
      @on-close="dialog.deductedSubmitModel = null"
      @on-sure="_clickSubmit"
    />
    <!--弹窗：扣减详情-->
    <v-detail
      v-if="dialog.deductedDetailModel"
      :model="dialog.deductedDetailModel"
      @on-close="dialog.deductedDetailModel = null"
    />
  </div>
</template>

<script>
import { ChargingCoinTransactionRecordDataModel } from './model/ChargingCoinTransactionRecordDataModel';
import { DeductedDataModel } from './model/DeductedDataModel';
import ColumnMixin from './mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import OrderApiSet from '@/request/api/OrderApiSet';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';
import vSubmit from './view/submit.vue';
import vDetail from './view/detail.vue';

export default {
  name: 'ChargingCoinTransactionRecord',
  components: { vSubmit, vDetail },
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      searchOption: {
        transClassify: BaseSearchModel.initData('业务订单类型', 'transClassify', 'Select').setData([
          { label: '购买充电币 ', value: '1' },
          { label: '赠送充电币', value: '2' },
          { label: '迁入余额', value: '3' },
          { label: '申请退币', value: '4' },
          { label: '充电退款', value: '5' },
          { label: '充电付款', value: '6' },
          { label: '余额调账', value: '7' },
          { label: '人工退款', value: '8' }
        ]),
        transType: BaseSearchModel.initData('动账类型', 'transType', 'Select').setData([
          { label: '入账', value: '1' },
          { label: '出账', value: '2' },
          { label: '冻结', value: '3' }
        ]),
        userTel: BaseSearchModel.initData('用户手机号', 'userTel', 'Input'),
        transSerialNo: BaseSearchModel.initData('关联业务单号', 'transSerialNo', 'Input'),
        dealTime: BaseSearchModel.initData('创建时间', 'dealTime', 'DatePickerRange').setDatePickerElevator([])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(24).setPermission('system:chargeCoin:getTransRecord:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null,
        // 扣减充电币
        deductedSubmitModel: null,
        // 扣减详情
        deductedDetailModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'export':
        this.$Message.info('点击导出');
        break;
      }
    },
    // 确定扣减充电币
    _clickSubmit (params) {
      console.log(params);
      this.$Modal.confirm({
        title: '操作提示',
        content: '是否确认提交，提交后请等待处理结果',
        onOk: () => {
          console.log('调用提交接口');
          this.requestSubmit(params);
        }
      });
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const s = this.searchOption;
      const api = OrderApiSet.getTransRecordList;
      api.params = {
        transClassify: s.transClassify.value,
        transType: s.transType.value,
        tel: s.userTel.value,
        transSerialNo: s.transSerialNo.value,
        createTimeBegin: s.dealTime.value[0],
        createTimeEnd: s.dealTime.value[1],
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.settingExportCondition = api.params;
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = ChargingCoinTransactionRecordDataModel.initListWithDataList(resData.rows);
        ChargingCoinTransactionRecordDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
      // const resList = ChargingCoinTransactionRecordDataModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = ChargingCoinTransactionRecordDataModel.initListWithDataList(resList);
      // ChargingCoinTransactionRecordDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
    },
    // 请求扣减充电币
    requestSubmit (params) {
      const api = OrderApiSet.coinAdjustSubmit;
      api.params = params;
      this.$http(api).then(res => {
        setTimeout(() => {
          this.$Modal.confirm({
            title: '操作提示',
            content: '提交成功，请稍后查看处理结果。',
            okText: '查看结果',
            cancelText: '关闭提示',
            onOk: () => {
              this.dialog.deductedSubmitModel = null;
              this.requestData();
              this.requestDetail(params.moneyRecordId);
            },
            onCancel: () => {
              this.dialog.deductedSubmitModel = null;
              this.requestData();
            }
          });
        }, 300);
      });
    },
    // 请求扣减记录
    requestDetail (moneyRecordId) {
      const api = OrderApiSet.getCoinAdjust;
      api.params = {
        moneyRecordId: moneyRecordId
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.dialog.deductedDetailModel = DeductedDataModel.initModelWithData(resData);
        // const resData = DeductedDataModel.createFakeData();
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
