// commonPageDrawerMixin commonPageDrawerMixin commonPageDrawerMixin
export default {
  props: {
    /**
     * 参数类型：
     *  1: 手机号
     *  2: 订单号
     *  3: 设置编码
     *  4: 网点ID数组
     *  5: 充电卡号
     *  6: 交易渠道订单号
     */
    parameterType: {
      type: Number,
      default: () => {
        return null;
      }
    },
    // 参数的key
    parameterKey: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 参数的值
    parameterValue: {
      type: [String, Array],
      default: ''
    },
    // 参数是否是下拉
    parameterIsSelect: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (this.parameterKey.length > 0) {
      if (this.parameterIsSelect) {
        this.searchOption[this.parameterKey[0]].labelSelectValue = this.parameterKey[1];
        this.searchOption[this.parameterKey[0]].value = this.parameterValue;
      } else {
        this.searchOption[this.parameterKey[0]].value = this.parameterValue;
      }
      this.requestData();
    }
  }
};
