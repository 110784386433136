<!--
    Created by 程雨喵'mac on 2024/8/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：设备高级参数
-->
<style lang="less">
.device-params-dialog {
  padding-bottom: @containerGap;
  height: 100%;
  overflow-y: hidden;
  box-sizing: border-box;
}
</style>

<template>
  <yg-drawer
    :width="1100"
    :title="drawerTitle"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="device-params-dialog"
    >
      <v-chao-xiang v-if="model.manufacturers === '10'" />
      <v-you-dian
        v-else-if="model.manufacturers === '40'"
        :model="model"
      />
      <span v-else>敬请期待</span>
    </div>
  </yg-drawer>
</template>

<script>
import vChaoXiang from './chaoxiang.vue';
import vYouDian from './youdian.vue';
export default {
  components: { vChaoXiang, vYouDian },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {};
  },
  computed: {
    drawerTitle () {
      const model = this.model;
      if (model.manufacturers === '40') {
        return '友电设备运行参数';
      } else if (model.manufacturers === '50') {
        return '尚亿设备运行参数';
      } else if (model.manufacturers === '10') {
        return '超翔设备运行参数';
      }
      return '设备运行参数';
    }
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    }
  }
};
</script>
