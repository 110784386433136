<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：分账比例
-->
<style lang="less">
.charging-pie-setting-bill-ratio {
  .bill-ratio-table {
    position: relative;
    //border: 1px solid @lineColorNormal;
    //border-bottom: 0;
    //border-top: 0;
    border-radius: @borderRadiusMin;
    border-top: 1px solid @lineColorNormal;
    &+.bill-ratio-table {
      margin-top: @containerGap;
    }
    .bill-ratio-table-row {
      display: flex;
      align-items: flex-start;
      border: 1px solid @lineColorNormal;
      border-top: 0;
      line-height: @formHeight;
      font-size: 12px;
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
      }
      .table-col {
        padding: 5px;
        .display-flex();
        .table-col-inner {
          width: 100%;
          .display-flex();
          white-space: nowrap;
          .ivu-input-number {
            flex: 1;
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        .is-merge {
          position: absolute;
          .display-flex();
          left: 1px;
          bottom: 1px;
          top: ~"calc(@{formHeight} + 11px)";
          width: 33.333333%;
          background-color: @backColorStrong;
          border-right: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(33.333333%);
          color: @textColorLight;
        }
        &.col-t-1 {
          .flex-grow(33.333333%);
          color: @textColorLight;
        }
        &.col-t-2 {
          .flex-grow(33.333333%);
        }
      }
    }
  }
}
</style>

<template>
  <div class="charging-pie-setting-bill-ratio">
    <div
      v-for="(tb, tIndex) in table"
      :key="'tb-' + tIndex"
      class="bill-ratio-table"
    >
      <div class="bill-ratio-table-row is-header">
        <div
          v-for="(item, i) in tb.headers"
          :key="i"
          class="table-col"
          :class="'col-t-' + i"
        >
          {{ item }}
        </div>
      </div>
      <div
        v-for="(item, i) in tb.rows"
        :key="'r-' + i"
        class="bill-ratio-table-row"
      >
        <div class="table-col col-t-0">
          <span
            v-if="i === 0"
            class="is-merge"
          >{{ tb.title }}</span>
        </div>
        <div class="table-col col-t-1">
          {{ item.title1 }}
        </div>
        <div
          v-if="isEdit"
          class="table-col col-t-2"
        >
          <div class="table-col-inner">
            <InputNumber
              v-model="model[item.key]"
              :min="0"
              :max="100"
              :disabled="item.disabled"
              @input="onInput(item.key)"
            />
            <span style="margin-left: 5px">%</span>
          </div>
        </div>
        <div
          v-else
          class="table-col col-t-1"
        >
          {{ model[item.key] }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      table: [
        {
          title: '充电订单分账设置',
          headers: ['分账类型', '商户类型', '分账比例'],
          rows: [
            { title1: '物业公司', key: 'h5PropertyCompanyRatio', disabled: false },
            { title1: '服务商', key: 'h5ServiceProviderRatio', disabled: false },
            { title1: '代理商', key: 'agentsRatio', disabled: true }
          ]
        },
        {
          title: '充电币购买订单分账设置',
          headers: ['分账类型', '商户类型', '分账比例'],
          rows: [
            { title1: '物业公司', key: 'h5ChargeCoinPCRatio', disabled: false },
            { title1: '服务商', key: 'h5ChargeCoinSPRatio', disabled: false },
            { title1: '代理商', key: 'chargeCoinARatio', disabled: true }
          ]
        }
      ]
    };
  },
  created () {},
  methods: {
    onInput (key) {
      setTimeout(() => {
        const index = key.includes('Coin') ? 1 : 0;
        const item = this.table[index].rows.find(v => v.key !== key && !v.disabled);
        this.model[key] = parseInt(this.model[key] || 0);
        this.model[item.key] = parseInt((100 - this.model[key]));
        this.$Message.info('总和必须等于100，数据已自动校准');
      }, 10);
    },
    $checkSubmit () {
      const { h5PropertyCompanyRatio, h5ServiceProviderRatio, agentsRatio, h5ChargeCoinPCRatio, h5ChargeCoinSPRatio, chargeCoinARatio } = this.model;
      const sum = h5PropertyCompanyRatio + h5ServiceProviderRatio + agentsRatio;
      if (sum === 0) {
        this.$Message.warning('充电订单分账比例总必须等于100');
        return false;
      }
      const sum1 = h5ChargeCoinPCRatio + h5ChargeCoinSPRatio + chargeCoinARatio;
      if (sum1 === 0) {
        this.$Message.warning('充电币购买订单分账比例总必须等于100');
        return false;
      }
      return true;
    }
  }
};
</script>
