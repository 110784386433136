/**
 * Created by 程雨喵'mac on 2023/3/30.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：首页看板中使用的颜色
 */

export const DATA_COLOR = {
  primary: 'rgba(84,110,253,1)',
  adorn: 'rgba(66,193,251,1)',
  light: 'rgba(26,212,181,1)',
  support: 'rgba(121,243,229,1)',
  error: 'rgba(248,83,0,1)',
  pink: 'rgba(255,51,95,1)',
  primaryAlphaLight: 'rgba(84,110,253,0.6)',
  adornAlphaLight: 'rgba(66,193,251,0.6)',
  lightAlphaLight: 'rgba(26,212,181,0.6)',
  supportAlphaLight: 'rgba(121,243,229,0.6)',
  pinkAlphaLight: 'rgba(255,51,95,0.6)',
  primaryAlpha: 'rgba(84,110,253,0.3)',
  adornAlpha: 'rgba(66,193,251,0.3)',
  lightAlpha: 'rgba(26,212,181,0.3)',
  supportAlpha: 'rgba(121,243,229,0.3)',
  pinkAlpha: 'rgba(255,51,95,0.3)',
  primaryAlphaDeep: 'rgba(84,110,253,0.1)',
  adornAlphaDeep: 'rgba(66,193,251,0.1)',
  lightAlphaDeep: 'rgba(26,212,181,0.1)',
  supportAlphaDeep: 'rgba(121,243,229,0.1)',
  pinkAlphaDeep: 'rgba(255,51,95,0.1)'
};
