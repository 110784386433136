<!--
    Created by 程雨喵'mac on 2023/11/15.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.simplify-theme-dark {
  .left-bar-header {
    .img-outer {
      img {
        //filter: hue-rotate(180deg);
        filter: invert(150%) hue-rotate(180deg);
      }
    }
  }
}
.left-bar-header {
  .display-flex();
  font-size: 16px;
  position: relative;
  line-height: @leftBarItemHeight;
  height: @leftBarItemHeight;
  box-sizing: border-box;
  color: @textColorNormal;
  cursor: pointer;
  &.is-current {
    background-color: @leftBarSelectOuterColor;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: @themeColor;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  &.is-open-header, &:hover {
    background-color: @leftBarSelectOuterColor;
  }
  &.is-open-header {
    .arrow-icon {
      transform: rotateZ(180deg);
    }
  }
  .img-outer {
    @outerWidth: ~'calc(@{leftBarIconWidth} + @{containerGap} * 3)';
    .flex-grow(@outerWidth);
    .display-flex();
    img {
      width: @leftBarIconWidth;
      height: @leftBarIconWidth;
    }
  }
  span {
    flex: 1;
    &.has-badge {
      display: flex;
      align-items: center;
      .badge {
        .display-flex();
        margin-left: 5px;
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        font-size: 12px;
        background-color: @adornColor;
        border-radius: 50%;
        color: @textColorFlip;
      }
    }
  }
  .arrow-icon {
    margin-right: 15px;
    font-size: 20px;
    transition: all @transitionTime15;
  }
  &:hover {
    .left-bar-header-hover {
      display: block;
    }
  }
}
.left-bar-header-hover {
  position: absolute;
  left: @leftBarCloseWidth;
  top: -1px;
  width: @leftBarOpenWidth;
  padding-left: 15px;
  display: none;
  .hover-bar-inner {
    padding-bottom: 14px;
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    box-shadow: @shadowMax;
    .hover-bar-header {
      padding-left: 40px;
      box-sizing: border-box;
      background-color: transparent;
      font-size: 14px;
      font-weight: 600;
      cursor: default;
    }
    .hover-bar-li {
      position: relative;
      box-sizing: border-box;
      background-color: transparent;
      line-height: 30px;
      padding-left: 60px;
      font-size: 13px;
      &::before {
        content: '';
        position: absolute;
        left: 42px;
        top: 0;
        bottom: 0;
        width: 2px;
        background-color: #ebebeb;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &.is-current {
        &::after {
          content: '';
          position: absolute;
          left: 42px;
          top: 0;
          bottom: 0;
          width: 2px;
          background-color: @themeColor;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &:hover {
        color: @themeColor;
        font-weight: 600;
      }
    }
  }
}

</style>

<template>
  <div
    class="left-bar-header"
    :class="leftBarHeaderClass"
    @click.stop="_clickItem"
  >
    <div class="img-outer">
      <img
        :src="`/leftBar/${model.meta.icon}.png`"
        :alt="model.meta.title"
        class="outer-img"
      >
    </div>
    <span
      v-if="!isSmallSize"
      :class="model.path === 'download' ? 'has-badge' : ''"
    >
      {{ model.meta.title }}
      <span
        v-if="model.path === 'download' && downloadAmount"
        class="badge"
      >{{ downloadAmount }}</span>
    </span>
    <Icon
      v-if="!isSmallSize"
      type="ios-arrow-down"
      class="arrow-icon"
    />
    <div
      v-if="isSmallSize"
      class="left-bar-header-hover"
    >
      <div class="hover-bar-inner">
        <div class="hover-bar-header">
          {{ model.meta.title }}
        </div>
        <div
          v-for="(item, i) in model.children"
          :key="i"
          class="hover-bar-li"
          :class="(isCurrent && currentSubBarIndex === i) ? 'is-current' : ''"
          @click.stop="_clickPush(item)"
        >
          {{ item.meta.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isSmallSize: {
      type: Boolean,
      default: false
    },
    // 是否是当前选中的头
    isCurrent: {
      type: Boolean,
      default: false
    },
    currentSubBarIndex: {
      type: Number,
      default: -1
    },
    // 是否展开
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  computed: {
    downloadAmount () {
      return this.$store.state.app.downloadAmount;
    },
    leftBarHeaderClass () {
      if (this.isSmallSize) {
        // 小的时候 不看展开的，只看是否是当前的
        if (this.isCurrent) {
          return 'is-current';
        }
      } else {
        // 一定要先看是否是 展开的!!!
        if (this.isOpen) {
          return 'is-open-header';
        } else if (this.isCurrent) {
          return 'is-current';
        }
      }
      return '';
    }
  },
  methods: {
    _clickItem () {
      if (this.isSmallSize) return;
      this.$emit('on-click');
    },
    _clickPush (item) {
      this.$emit('on-click-item', item);
    }
  }
};
</script>
