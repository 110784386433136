
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('cardNumber', '充电卡号', 120).setSpan({ canCopy: true }),
        BaseTableColumn.initData('tel', '用户手机号', 100).setValueButton([], (row, index) => {
          // 用户列表
          this.dialog.parameterType = 1;
          this.dialog.orderListType = 1;
          this.dialog.orderListModel = row;
        }, { isPhoneNumber: true }),
        BaseTableColumn.initData('bindStatus', '绑定状态', 80).setStatus([
          { value: 1, type: 4 },
          { value: 2, type: 1 }
        ], 'bindStatusDesc'),
        BaseTableColumn.initData('', '时间').setWidth(230).setKeyValueList([
          { label: '绑卡时间:', labelWidth: 70, key: 'bindTimeStr' },
          { label: '解绑时间:', labelWidth: 70, key: 'unbindTimeStr' }
        ]),
        BaseTableColumn.initData('setting', '操作').setWidth(120).setAlign('center').setButtons([
          { title: '刷卡消费记录', name: 'cardUseRecord', permission: 'system:chargeOrder:query' },
          { title: '解除绑定', name: 'unbind', permission: 'system:chargeCard:unbind' },
          { title: '绑卡记录', name: 'bindRecord', permission: 'system:chargeCard:getChargeCardBindLog:list' }
        ], (e, row, index) => {
          switch (e.name) {
          case 'cardUseRecord':
            this.dialog.orderListType = 2;
            this.dialog.parameterType = 5;
            this.dialog.orderListModel = row;
            break;
          case 'unbind':
            if (row.bindStatus === 2) {
              return this.$Message.warning('此卡已是解绑状态');
            }
            this.$Modal.confirm({
              title: '操作提示',
              content: '是否确认解绑卡？',
              onOk: () => {
                console.log('调用解绑卡接口');
                this.requestUnbind(row.id);
              }
            });
            break;
          case 'bindRecord':
            this.dialog.bindRecordModel = row;
            break;
          }
        })
      ]
    };
  }
};
