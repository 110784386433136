<!--
    Created by 王丽莎 on 2024/08/08.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：交易管理 - 充电币交易流水 - 扣减充电币
    功能概述：扣减充电币
-->
<style lang="less">
.deducted-submit-content {
  textarea.ivu-input {
    resize: none;
  }
  .ivu-input-word-count {
    background: transparent;
  }
  .tips {
    .display-flex();
    font-size: 12px;
    color: @textColorLight;
    cursor: help;
    .ivu-icon {
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <yg-modal
    width="520"
    title="扣减充电币"
    sure-text="保存"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 240px"
    >
      <div class="deducted-submit-content">
        <Form>
          <FormItem
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :label-width="item.labelWidth"
            :required="item.isRequired"
          >
            <div v-if="item.tagName === 'Div'">
              <yg-attribute-span
                :text="model[item.key]"
                :can-hide-phone="item.canHidePhone"
              />
            </div>
            <Input
              v-if="item.tagName === 'Textarea'"
              v-model="model[item.key]"
              maxlength="60"
              show-word-limit
              :rows="5"
              type="textarea"
              placeholder="请输入调账的事由"
              style="width: 300px"
              @input="checkSubmit"
            />
            <div
              v-if="item.key === 'payMoney'"
              class="tips"
              @click.stop="_clickShowTip"
            >
              <Icon type="md-help-circle" />
            </div>
          </FormItem>
        </Form>
      </div>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          label: '用户手机号',
          labelWidth: 100,
          key: 'tel',
          tagName: 'Div',
          canHidePhone: true
        },
        {
          label: '扣减数量',
          labelWidth: 100,
          key: 'payMoney',
          tagName: 'Div'
        },
        {
          label: '备注',
          labelWidth: 100,
          key: 'remark',
          tagName: 'Textarea',
          disabled: false,
          clearable: true,
          isRequired: true
        }
      ]
    };
  },
  created () {
    console.log(this.model);
    this.checkSubmit();
  },
  methods: {
    onSure () {
      const res = this.model.checkValidSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.$emit('on-sure', res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    // 检查必填项
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    },
    // 扣减数量说明
    _clickShowTip () {
      this.$Modal.info({
        title: '扣减数量说明',
        content: '<p>1、扣款顺序：先扣迁入金额再扣充值金额；</p><p>2、如不可足额扣减，则记为欠款，再有充电币入账后先抵销欠款。</p>',
        okText: '我知道了'
      });
    }
  }
};
</script>
