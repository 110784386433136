<!--
    Created by 程雨喵'mac on 2024/10/17.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：更改信息
-->
<style lang="less">
.dc-other-setting-info {
  padding-top: @containerGap;
  .__g-card {
    &+.__g-card {
      margin-top: @containerGap;
    }
    .dc-other-setting-info-form-item {
      position: relative;
      //padding-top: @containerGap;
      &:focus-within {
        .ivu-form-item-content {
          .org-data {
            opacity: 1;
          }
        }
      }
      .ivu-form-item-content {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        .ivu-input-wrapper {
          flex: 1;
          .ivu-input:focus {
            box-shadow: none;
          }
        }
        .__g-button {
          margin-left: @containerGap;
        }
        .org-data {
          position: absolute;
          padding: 0 @containerGap * 0.5;
          top: -@containerGap * 1.5;
          left: 0;
          opacity: 0;
          .display-flex();
          font-size: 10px;
          line-height: @containerGap * 1.5;
          border: 1px solid @themeColor;
          border-bottom: 0;
          border-radius: @borderRadiusMin @borderRadiusMin 0 0;
          z-index: 1;
          background-color: @themeColor;
          color: white;
        }
      }
    }
  }
}
</style>

<template>
  <div class="dc-other-setting-info">
    <yg-card
      v-for="(item, i) in formItems"
      :key="i"
      :title="item.title"
    >
      <div slot="content">
        <Form>
          <FormItem
            v-for="(sub, ind) in item.items"
            :key="ind"
            :label="'调整为'"
            :label-width="sub.labelWidth"
            :required="sub.isRequired"
            class="dc-other-setting-info-form-item"
          >
            <p
              class="org-data"
            >
              原始值: {{ orgData[sub.key] }}
            </p>
            <Input
              v-if="sub.tagName === 'Input'"
              v-model.trim="model[sub.key]"
              :placeholder="'请输入' + sub.label"
              :disabled="sub.disabled"
            />
            <yg-button
              type="primary"
              :disabled="!model[sub.key] || orgData[sub.key] === model[sub.key]"
              :disabled-text="item.title + '不可为空或与原始值一样'"
              @click="_clickSubmit(sub.key)"
            >
              提交
            </yg-button>
          </FormItem>
        </Form>
      </div>
    </yg-card>
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      orgData: {
        name: '',
        imei: ''
      },
      formItems: [
        {
          title: '设备名称',
          items: [
            {
              label: '设备名称',
              labelWidth: 100,
              key: 'name',
              tagName: 'Input',
              disabled: false,
              isRequired: true
            }
          ]
        },
        {
          title: 'IMEI号',
          items: [
            {
              label: 'IMEI号',
              labelWidth: 100,
              key: 'imei',
              tagName: 'Input',
              disabled: false,
              isRequired: true
            }
          ]
        }
      ]
    };
  },
  created () {
    this.orgData.name = this.model.name;
    this.orgData.imei = this.model.imei;
  },
  methods: {
    _clickSubmit (key) {
      const value = this.model[key];
      if (key === 'name') {
        if (value.length > 12) {
          return this.$Message.warning('设备名称最多可输入12个字');
        }
      }
      this.$Modal.confirm({
        title: '温馨提示',
        content: '是否确认变更?',
        onOk: () => {
          this.requestSubmit(key);
        }
      });
    },
    requestSubmit (key) {
      const api = key === 'name' ? DeviceApiSet.modDeviceName : DeviceApiSet.modImei;
      api.params = {
        deviceId: this.model.id,
        deviceName: this.model[key],
        imei: this.model[key]
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-refresh');
      });
    }
  }
};
</script>
