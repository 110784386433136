/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
export default {
  state: {
    permission: [],
    avatarKey: '',
    globalSetting: {}
  },
  getters: {},
  mutations: {
    setPermission (state, value) {
      state.permission = value;
    },
    setAvatarKey (state, value) {
      state.avatarKey = value;
    },
    setGlobalSetting (state, value) {
      state.globalSetting = value;
    }
  },
  actions: {
    checkPermission ({ state, commit }, permission) {
      console.log(permission);
      let pArr = permission;
      if (typeof pArr === 'string') {
        pArr = [pArr];
      }
      let flag = true;
      for (let i = 0; i < pArr.length; i++) {
        const key = pArr[i];
        flag = state.permission.includes(key);
        if (!flag) {
          break;
        }
      }
      return flag;
    }
  }
};
