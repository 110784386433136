<!--
    Created by 王丽莎 on 2024/05/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：交易管理-充电币购买订单列表
-->
<style lang="less">
#charging-coin-sales-order {
  .col-text-strong {
    .display-flex();
    color: @adornColor;
    font-weight: 600;
  }
  .ivu-poptip-rel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-text-light {
    color: @textColorLight;
  }
  .pay-amount-icon {
    color: @textColorLight;
  }
}
</style>

<template>
  <div id="charging-coin-sales-order">
    <!-- 查询条件 -->
    <div
      v-permission="'system:chargeCoin:getSalesOrder:list'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:chargeCoin:getSalesOrder:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-row-expand="mixinTableOnRowExpand"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
  </div>
</template>

<script>
import { ChargingCoinSalesOrderDataModel } from './model/ChargingCoinSalesOrderDataModel';
import ColumnMixin from './mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import OrderApiSet from '@/request/api/OrderApiSet';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';

export default {
  name: 'ChargingCoinSalesOrder',
  components: {},
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      searchOption: {
        orderNoLabel: BaseSearchModel.initData('充电币购买订单号充电币', 'orderNoLabel', 'Input').setLabelSelect([
          { value: 'salesOrderNo', label: '充电币购买订单号' },
          { value: 'tel', label: '用户手机号' },
          { value: 'gwTradeNo', label: '交易渠道订单号' }
        ]),
        orderStatus: BaseSearchModel.initData('订单状态', 'orderStatus', 'Select').setData([
          { label: '已下单 ', value: '1' },
          { label: '已付款', value: '2' },
          { label: '已关单', value: '3' }
        ]),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        selectTimeLabel: BaseSearchModel.initData('时间', 'selectTimeLabel', 'DatePickerRange').setDatePickerElevator([]).setLabelSelect([
          { value: 'createTimeBegin-createTimeEnd', label: '下单时间' },
          { value: 'payTimeBegin-payTimeEnd', label: '支付时间' }
        ])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(23).setPermission('system:chargeCoin:getSalesOrder:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'export':
        this.$Message.info('点击导出');
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const s = this.searchOption;
      const api = OrderApiSet.getSalesOrderList;
      api.params = {
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        status: s.orderStatus.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      // 筛选中可下拉的label
      api.params[s.orderNoLabel.labelSelectValue] = s.orderNoLabel.value;
      const timeKey = s.selectTimeLabel.labelSelectValue.split('-');
      api.params[timeKey[0]] = s.selectTimeLabel.value[0];
      api.params[timeKey[1]] = s.selectTimeLabel.value[1];
      this.settingExportCondition = api.params;
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = ChargingCoinSalesOrderDataModel.initListWithDataList(resData.rows);
        ChargingCoinSalesOrderDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      // const resList = ChargingCoinSalesOrderDataModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = ChargingCoinSalesOrderDataModel.initListWithDataList(resList);
      // ChargingCoinSalesOrderDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
