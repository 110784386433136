/**
 * Created by 程雨喵'mac on 2021-08-17.
 * Copyright © 2021年 云柜-金星晖. All rights reserved.
 */
import Vue from 'vue';
import Main from './index.vue';
const LoadingConstructor = Vue.extend(Main);

let instance;
let count = 0;

const Loading = function () {
  if (Vue.prototype.$isServer) return;
  instance = new LoadingConstructor({
    data: {}
  });
  instance.vm = instance.$mount();
  instance.vm.visible = false;
  instance.dom = instance.vm.$el;
  return instance.vm;
};

Loading.show = function () {
  count += 1;
  if (!instance) {
    Loading();
  }
  const app = document.getElementById('app');
  app.appendChild(instance.vm.$el);
  instance.vm.visible = true;
};
Loading.close = function () {
  count -= 1;
  if (count < 1) {
    count = 0;
    setTimeout(() => {
      instance.vm.visible = false;
    }, 500);
  }
};

export default Loading;
