<!--
    Created by 程雨喵'mac on 2024/10/17.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：调整点位
-->
<style lang="less">
.dc-other-setting-position {
  padding-top: @containerGap;
  .__g-card {
    &+.__g-card {
      margin-top: @containerGap;
    }
  }
  .item-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .item-header {
      font-size: 13px;
      display: flex;
      align-items: center;
      color: @textColorLight;
      span.dot {
        position: relative;
        margin-right: 10px;
        .display-flex();
        width: 22px;
        height: 22px;
        background-color: transparent;
        border-radius: 50%;
        color: @textColorNormal;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid @lineColorNormal;
      }
      span.tip {
        margin-left: @containerGap * 0.5;
        font-size: 10px;
        color: @textColorLight;
        font-weight: 400;
        span {
          color: @errorColor;
        }
      }
    }
    &.is-active {
      .item-header {
        color: @textColorNormal;
        font-weight: 600;
        span.dot {
          background-color: @themeColor;
          color: white;
        }
      }
    }
    .item-con {
      position: relative;
      padding-top: 20px;
      padding-left: 35px;
      &.has-line::after {
        content: '';
        position: absolute;
        width: 1px;
        background-color: @lineColorNormal;
        top: 10px;
        left: 12px;
        height:100%;
      }
    }
  }
  .total-data-box {
    display: flex;
    overflow: auto;
    //flex-wrap: wrap;
    //overflow: hidden;
    border-radius: @borderRadiusMin;
    border: 1px solid @lineColorNormal;
    border-right: 0;
    .total-data-item {
      flex: 1;
      min-width: 100px;
      border-right: 1px solid @lineColorNormal;
      &:nth-of-type(2n) {
        .total-data-label {
          //background-color: transparent;
        }
      }
      .total-data-label {
        padding: 8px 5px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        white-space: pre-line;
        color: @textColorLight;
        background-color: @backColorNormal;
      }
      .is-same {
        color: @textColorLight;
        font-weight: 300;
        .table-value {
          color: @textColorLight;
          font-weight: 300;
        }
      }
      .is-other {
        color: @errorColor;
        font-weight: 600;
        .table-value {
          color: @errorColor;
          font-weight: 600;
        }
      }
      .position-edit-div {
        .display-flex();
        flex-direction: column;
        height: 55px;
        line-height: 18px;
        text-align: center;
        padding: 7px 5px;
        box-sizing: border-box;
        font-size: 12px;
        .__g-table-key-value {
          .flex-grow(100%);
          line-height: 14px;
        }
        .__g-table-key-value + .__g-table-key-value {
          margin-top: 0;
        }
      }
    }
  }
  .search-b {
    display: flex;
    width: 80%;
    .b-label {
      margin-right: @containerGap * 0.5;
      .flex-grow(120px);
      font-size: 12px;
    }
    .b-value {
      flex: 1;
    }
  }
}
</style>

<template>
  <div class="dc-other-setting-position">
    <yg-card
      title="原点位信息"
    >
      <div slot="content">
        <div class="total-data-box">
          <div
            v-for="(item, i) in totalItems"
            :key="i"
            class="total-data-item"
            :style="'min-width: ' + item.itemWidth + 'px'"
          >
            <div class="total-data-label">
              {{ item.label }}
            </div>
            <div
              v-if="item.isKeyValue"
              class="position-edit-div"
            >
              <yg-attribute-key-value
                v-for="(re, ind) in item.keyValueData"
                :key="ind"
                :label="re.label"
                :label-width="re.labelWidth"
                :can-copy="!!re.canCopy"
                :can-tooltip="!!re.canTooltip"
                :value="orgData[re.value] + ''"
              />
            </div>
            <yg-attribute-span
              v-else
              :text="orgData[item.key] + ''"
              class="position-edit-div"
            />
          </div>
        </div>
      </div>
    </yg-card>
    <yg-card
      title="调整点位信息"
    >
      <div slot="content">
        <div
          v-for="(step, s) in stepOption.items"
          :key="'step-' + s"
          class="item-row"
          :class="stepOption.index === s ? 'is-active' : ''"
        >
          <p class="item-header">
            <span class="dot">{{ s + 1 }}</span>
            {{ step.title }}
            <span
              v-if="s === 1 && stepOption.index > 0"
              class="tip"
            >
              已<span>高亮</span>显示与原点位不同的信息
            </span>
          </p>
          <div
            class="item-con"
            :class="s < 2 ? 'has-line' : ''"
          >
            <!--第一步查询-->
            <div
              v-if="s === 0"
              class="search-b"
            >
              <div class="b-label">
                <Select
                  v-model="search.labelValue"
                  :clearable="false"
                  :disabled="stepOption.index !== s"
                  class="label"
                >
                  <Option
                    v-for="(op, p) in search.labelOption"
                    :key="p + '-opt'"
                    :value="op.value"
                  >
                    {{ op.label }}
                  </Option>
                </Select>
              </div>
              <div class="b-value">
                <Input
                  v-if="search.labelValue === 1"
                  v-model="search.value"
                  placeholder="请输入"
                  :clearable="false"
                  :disabled="stepOption.index !== s"
                  style="width: 100%"
                />
                <yg-site-cascader
                  v-else
                  v-model="search.valueArr"
                  :level="1"
                  :disabled="stepOption.index !== s"
                />
              </div>
            </div>
            <!--第二部展示-->
            <div
              v-else-if="s === 1 && stepOption.index >= s"
              class="total-data-box"
            >
              <div
                v-for="(item, i) in totalItems"
                :key="i"
                class="total-data-item"
                :style="'min-width: ' + item.itemWidth + 'px;'"
              >
                <div class="total-data-label">
                  {{ item.label }}
                </div>
                <div
                  v-if="item.isKeyValue"
                  class="position-edit-div"
                >
                  <yg-attribute-key-value
                    v-for="(re, ind) in item.keyValueData"
                    :key="ind"
                    :label="re.label"
                    :label-width="re.labelWidth"
                    :can-copy="!!re.canCopy"
                    :can-tooltip="!!re.canTooltip"
                    :value="newData[re.value] + ''"
                    :class="newData[re.value] === orgData[re.value] ? 'is-same' : 'is-other'"
                  />
                </div>
                <yg-attribute-span
                  v-else
                  :text="newData[item.key] + ''"
                  class="position-edit-div"
                  :class="newData[item.key] === orgData[item.key] ? 'is-same' : 'is-other'"
                />
              </div>
            </div>
            <div
              v-else-if="s === 2 && stepOption.index >= s"
            >
              <Alert
                type="warning"
                show-icon
              >
                调整更点位须知
                <template slot="desc">
                  <p>1、点位调整后仍保持原计费规则，如需变更请单独调整；</p>
                  <p>2、跨办事处，跨小区时会影响相关数据统计及分账信息；</p>
                </template>
              </Alert>
            </div>
            <div style="display: flex;justify-content: flex-end; margin-top: 15px">
              <yg-button
                v-if="stepOption.index === s && s !== 0"
                size="small"
                style="margin-right: 10px"
                @click="_clickPrev"
              >
                上一步
              </yg-button>
              <yg-button
                :disabled="stepOption.index !== s"
                type="primary"
                size="small"
                @click="_clickNext"
              >
                {{ step.btnTitle }}
              </yg-button>
            </div>
          </div>
        </div>
      </div>
    </yg-card>
  </div>
</template>

<script>
import LocationApiSet from '@/request/api/LocationApiSet';
import { LocationListModel } from '@/pages/040__locationManager/locationList/model/LocationDataModel';
import DeviceApiSet from '@/request/api/DeviceApiSet';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      stepOption: {
        index: 0,
        items: [
          { title: '请输入查询条件', btnTitle: '查询点位' },
          { title: '确认新点位信息', btnTitle: '确认点位' },
          { title: '提交点位信息', btnTitle: '提交点位' }
        ]
      },
      totalItems: [
        {
          key: '',
          label: '安装点位',
          isKeyValue: true,
          itemWidth: 200,
          keyValueData: [
            { labelWidth: 0, label: '', value: 'name', canTooltip: true },
            { labelWidth: 0, label: '', value: 'siteCode', canCopy: true }
          ]
        },
        {
          key: 'communityName',
          label: '小区名称',
          isKeyValue: false,
          itemWidth: 80
        },
        {
          key: '',
          label: '省市区',
          isKeyValue: true,
          itemWidth: 100,
          keyValueData: [
            { labelWidth: 30, label: '省份:', value: 'provinceName', canTooltip: true },
            { labelWidth: 30, label: '城市:', value: 'cityName', canTooltip: true },
            { labelWidth: 30, label: '区县:', value: 'districtName', canTooltip: true }
          ]
        },
        {
          key: 'address',
          label: '具体地址',
          isKeyValue: false,
          itemWidth: 120
        },
        {
          key: 'officeName',
          label: '吾行办事处',
          isKeyValue: false,
          itemWidth: 100
        },
        {
          key: 'propertyCompanyName',
          label: '物业区域公司',
          isKeyValue: false,
          itemWidth: 100
        }
      ],
      search: {
        labelValue: 1,
        labelOption: [
          { value: 1, label: '点位编码输入' },
          { value: 2, label: '点位名称下拉' }
        ],
        value: '',
        valueArr: []
      },
      orgData: {},
      newData: {},
      isSameSite: false
    };
  },
  created () {
    this.requestPosition(this.model.siteCode).then(res => {
      this.orgData = LocationListModel.initModelWithData(res.rows[0]);
      // this.stepOption.index = index;
    });
  },
  methods: {
    _clickPrev () {
      this.stepOption.index -= 1;
    },
    _clickNext () {
      const index = this.stepOption.index + 1;
      if (index === 1) {
        const { labelValue, value, valueArr } = this.search;
        let siteCode = '';
        if (labelValue === 1) {
          siteCode = value;
        } else if (labelValue === 2) {
          if (valueArr.length !== 5) {
            return this.$Message.warning('请选择到点位');
          }
          siteCode = [valueArr[4]];
        }
        if (!siteCode) return this.$Message.warning('无查询内容');
        // 查询
        this.requestPosition(siteCode).then(res => {
          this.newData = LocationListModel.initModelWithData(res.rows[0]);
          this.stepOption.index = index;
          this.isSameSite = this.orgData.id === this.newData.id;
        }).catch(e => {
          this.$Message.warning(e.message);
        });
      } else if (index === 2) {
        if (this.isSameSite) return this.$Message.warning('操作有误，点位一致');
        this.stepOption.index = index;
      } else if (index === 3) {
        // 提交
        this.requestSubmit();
      }
    },
    requestPosition (siteCode) {
      const api = LocationApiSet.querySiteListWithOfficeApi;
      api.params = {
        pageSize: 1,
        pageNo: 1
      };
      if (Array.isArray(siteCode)) {
        api.params.siteCode = '';
        api.params.siteIds = siteCode;
      } else {
        api.params.siteCode = siteCode;
        api.params.siteIds = [];
      }
      return new Promise((resolve, reject) => {
        this.$http(api).then(res => {
          if (res.data.rows.length) {
            resolve(res.data);
          } else {
            reject(new Error('已超出数据范围，请联系总部资产管理人员'));
          }
        });
      });
    },
    requestSubmit () {
      const api = DeviceApiSet.changeSite;
      api.params = {
        deviceId: this.model.id,
        siteId: this.newData.id
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        this.$emit('on-refresh');
      });
    }
  }
};
</script>
