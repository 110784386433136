<!--
   tab-拥有子节点的tab

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-tab-tree {
  @tabHeight: 46px;
  @tabGap: 5px;
  //height: @tabHeight;
  background-color: @backColorStrong;
  font-size: 16px;
  .tab-inner {
    //padding: 0 15px;
    //padding-top: @tabGap;
    .display-flex(flex-start, flex-start);
    .tab-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //height: @tabHeight;
      line-height: @tabHeight;
      border-radius: @tabHeight * 0.5;
      //border: @tabGap solid white;
      min-width: 90px;
      color: #AFBAC9;
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        color: @themeColor;
      }
      .parent-title {
        padding: 0 15px;
        position: relative;
      }
      &.has-children.is-current {
        padding-bottom: 30px;
      }
      &.is-current {
        //background-image: linear-gradient(90deg,#265BE2 50%,#3792F3);
        color: @themeColor !important;
        .parent-title {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 4px;
            background-color: @themeColor;
          }
        }
      }
      .sub-children {
        position: absolute;
        left: 0;
        bottom: 6px;
        padding: 10px 0;
        display: flex;
        //font-size: 12px;
        span {
          padding: 0 8px;
          cursor: pointer;
          font-size: 12px;
          line-height: 12px;
          font-weight: 400;
          border-right: 1px solid @textColorLight;
          color: @textColorNormal;
          &.sub-current {
            color: @textColorPrimary;
            font-weight: 600;
          }
          &:first-child {
            padding-left: 15px;
          }
          &:last-child {
            border-right: 0;
          }
          &:hover {
            color: @textColorPrimary;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="__g-tab-tree"
  >
    <ul class="tab-inner">
      <li
        v-for="(item, i) in items"
        :key="i"
        class="tab-item"
        :class="getCurrentLiClass(item, i)"
        @click.stop="_clickParent(item, i)"
      >
        <div class="parent-title">
          {{ item.label }}
        </div>
        <div
          v-if="item.children && item.children.length && currentIndex[0] === i"
          class="sub-children"
        >
          <span
            v-for="(sub, k) in item.children"
            :key="k"
            :class="currentIndex[1] === k ? 'sub-current' : ''"
            @click.stop="_clickChildren(i, k)"
          >
            {{ sub.label }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'YgTabTree',
  props: {
    // 数组第一个元素为父节点index, 数组第二个元素为子节点index
    index: {
      type: Array,
      default: () => {
        return [0];
      }
    },
    items: {
      type: Array,
      default: () => {
        return [{
          label: '标签一',
          value: '1',
          children: [{ label: '子标签一', value: '1' }]
        }];
      }
    }
  },
  data () {
    return {
      currentIndex: [0]
    };
  },
  watch: {
    index (newValue) {
      this.setCurrentValue(newValue);
    }
  },
  created () {
    this.setCurrentValue(this.index);
  },
  methods: {
    setCurrentValue (value) {
      if (value !== this.currentIndex) {
        this.currentIndex = value;
      }
    },
    getCurrentLiClass (item, index) {
      let className = item.children && item.children.length ? 'has-children' : 'no-children';
      if (this.currentIndex[0] === index) {
        className += ' is-current';
      }
      return className;
    },
    _clickParent (item, i) {
      if (item.children && item.children.length) {
        this.setCurrentValue([i, 0]);
        this.$emit('on-change', [i, 0]);
      } else {
        this.setCurrentValue([i]);
        this.$emit('on-change', [i]);
      }
    },
    _clickChildren (i, k) {
      this.setCurrentValue([i, k]);
      this.$emit('on-change', [i, k]);
    }
  }
};
</script>
