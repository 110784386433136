<!--
    Created by 程雨喵'mac on 2023/11/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：属性文字，可支持tooltip，可支持copy，可支持手机号隐藏
-->
<style lang="less">
.__c-span-text {
  .ivu-poptip {
    display: flex;
  }
  .__c-span-text-content {
    line-height: 18px;
    height: 18px;
    word-break:break-all;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow: hidden;
  }
  &.span-type-one-line {
    display: block;
  }
}
</style>
<template>
  <!--<div style="display: inline-flex;">-->
  <span
    v-if="(canTooltip && isLong) || (canTooltip && tooltipText) || (canTooltip && tooltipSlot)"
    class="__c-span-text"
    :class="className"
  >
    <Poptip
      :content="tooltipText || showText || tooltipSlot"
      :width="tooltipWidth ? tooltipWidth : elWidth * 2"
      :word-wrap="true"
      trigger="hover"
      :transfer="true"
    >
      <span
        v-price-text="canPrice"
        :data-value="showText"
        class="__c-span-text-content"
      >{{ showText }}</span>
      <div
        v-if="tooltipSlot"
        slot="content"
        v-html="tooltipSlot"
      />
    </Poptip>
  </span>
  <span
    v-else
    v-text-unit="canTextUnit"
    v-hide-phone="canHidePhone"
    v-copy-text="canCopy"
    v-price-text="canPrice"
    :data-value="showText"
    class="__c-span-text span-type-one-line"
    :class="className"
  >{{ showText || '-' }}</span>
  <!--</div>-->
</template>

<script>
export default {
  name: 'YgAttributeSpan',
  components: {},
  props: {
    text: {
      type: [String, Number],
      default: ''
    },
    // 是否支持拷贝，默认为不支持
    canCopy: {
      type: Boolean,
      default: false
    },
    // 是否支持文字过长时...，默认为false(如果此值为false，就不支持hide-phone)
    canTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    tooltipSlot: {
      type: String,
      default: ''
    },
    tooltipWidth: {
      type: [String, Number],
      default: ''
    },
    // 是否支持手机号脱敏，默认为不支持
    canHidePhone: {
      type: Boolean,
      default: false
    },
    canPrice: {
      type: Boolean,
      default: false
    },
    canTextUnit: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 过长会展示...  过长通过计算判断。(12号字，宽度为12)
      isLong: false,
      elWidth: 0
    };
  },
  computed: {
    showText () {
      let text = this.text;
      if (typeof this.text === 'number') {
        text = text + '';
      } else if (text === 'null') {
        text = '';
      } else if (text === 'undefined') {
        text = '';
      }
      return text;
    }
  },
  watch: {
    text (oldV, newV) {
      if (oldV !== newV) {
        this.reset();
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.reset();
    });
  },
  methods: {
    reset () {
      this.elWidth = this.$el.clientWidth;
      this.isLong = this.$el.clientWidth < (this.showText + '').length * 12;
    }
  }
};
</script>
