export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('cardNo', '充电卡号').setWidth(100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('tel', '用户手机号').setWidth(100).setRender((h, { row, index }) => {
          return h('yg-button', {
            props: {
              type: 'text',
              phoneNumber: row.tel
            },
            on: {
              click: () => {
                this.dialog.parameterType = 1;
                this.dialog.orderListType = 1;
                this.dialog.orderListModel = row;
              }
            }
          }, row.tel);
        }),
        BaseTableColumn.initData('useTime', '刷卡时间').setWidth(140).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('outTradeNo', '订单编码', 220).setKeyValueList([
          { label: '业务订单:', labelWidth: 100, key: 'bizNo', attr: { canCopy: true } },
          { label: '支付订单:', labelWidth: 100, key: 'payNo', attr: { canCopy: true } },
          { label: '交易渠道订单:', labelWidth: 100, key: 'gwTradeNo', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('', '订单金额', 120).setKeyValueList([
          { label: '支付:', labelWidth: 40, key: 'consumeMoney', attr: { canPrice: true } },
          { label: '实收:', labelWidth: 40, key: 'payMoney', attr: { canPrice: true } }
        ]),
        BaseTableColumn.initData('', '设备', 120).setKeyValueList([
          { label: '设备名称:', labelWidth: 60, key: 'deviceName', attr: { canTooltip: true } },
          { label: '设备编码:', labelWidth: 60, key: 'deviceNumber', attr: { canCopy: true } },
          { label: 'IMEI号:', labelWidth: 60, key: 'imei', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('', '所在区域').setWidth(150).setKeyValueList([
          { label: '省份:', labelWidth: 60, key: 'provinceName', attr: { canTooltip: true } },
          { label: '城市:', labelWidth: 60, key: 'cityName', attr: { canTooltip: true } },
          { label: '小区:', labelWidth: 60, key: 'communityName', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('siteName', '点位名称').setWidth(120).setSpan({ canTooltip: true })
      ]
    };
  }
};
