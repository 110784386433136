<!--
   tab

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-tab-primary {
  overflow: auto;
  &.__g-tab-size-normal {
    @tabHeight: 46px;
    @tabGap: 8px;
    height: @tabHeight;
    font-size: 15px;
    &.__g-tab-primary-type-1 {
      .tab-item {
        line-height: @tabHeight - @tabGap * 2;
      }
    }
    &.__g-tab-primary-type-2 {
      @tabHeight: 40px;
      height: @tabHeight;
      .tab-inner {
        padding: @tabGap * 0.5;
        .tab-item {
          line-height: @tabHeight - @tabGap;
        }
      }
    }
    &.__g-tab-primary-type-3 {
      .tab-item {
        line-height: @tabHeight;
      }
    }
  }
  &.__g-tab-size-small {
    @tabHeight: 36px;
    @tabGap: 4px;
    height: @tabHeight;
    font-size: 12px;
    &.__g-tab-primary-type-1 {
      .tab-item {
        line-height: @tabHeight - @tabGap * 2;
      }
    }
    &.__g-tab-primary-type-2 {
      @tabHeight: 30px;
      height: @tabHeight;
      font-size: 12px;
      .tab-inner {
        padding: @tabGap * 0.5;
        .tab-item {
          padding: 0 @containerGap * 0.5;
          line-height: @tabHeight - @tabGap;
        }
      }
    }
    &.__g-tab-primary-type-3 {
      .tab-item {
        padding: 0 @containerGap * 0.5;
        line-height: @tabHeight;
      }
    }
  }
  &.__g-tab-primary-type-1 {
    // 有下滑线的tab
    background-color: @backColorStrong;
    .tab-inner {
      height: 100%;
      padding: 0 @containerGap;
      .display-flex(center, flex-start);
      .tab-item {
        .display-flex();
        color: #AFBAC9;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          color: @themeColor;
        }
        &.is-current {
          position: relative;
          color: @themeColor;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: @themeColor;
          }
        }
        &+.tab-item {
          margin-left: @containerGap * 1.5;
        }
      }
    }
  }
  &.__g-tab-primary-type-2 {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    .tab-inner {
      height: 100%;
      .display-flex(center, flex-start);
      background-color: @backColorStrong;
      border-radius: @borderRadiusMin @borderRadiusMin 0 0;
      .tab-item {
        padding: 0 @containerGap;
        .display-flex();
        color: #AFBAC9;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
        border-radius: @borderRadiusMin;
        &:hover {
          color: @themeColor;
        }
        &.is-current {
          position: relative;
          color: @textColorFlip;
          background-color: @themeColor;
        }
      }
    }
  }
  &.__g-tab-primary-type-3 {
    background-color: @backColorStrong;
    .tab-inner {
      height: 100%;
      .display-flex(center, flex-start);
      .tab-item {
        padding: 0 @containerGap;
        .display-flex();
        color: #AFBAC9;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;
        transition: all @transitionTime15;
        &:hover {
          color: @themeColor;
        }
        &.is-current {
          position: relative;
          color: @themeColor;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 4px;
            background-color: @themeColor;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="__g-tab-primary"
    :class="getCurrentClass"
  >
    <ul class="tab-inner">
      <li
        v-for="(item, i) in currentItems"
        :key="i"
        v-permission="permissionItems.length ? permissionItems[i] : false"
        class="tab-item"
        :class="i === currentIndex ? 'is-current' : ''"
        @click.stop="_click(i)"
      >
        {{ item.label || item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'YgTabPrimary',
  props: {
    index: {
      type: Number,
      default: 0
    },
    /**
     * 1: 常规的下面有下划线的tab
     * 2: 是有色块，没有下划线的tab
     * 3: 上面有色块的tab
     */
    type: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      default: () => {
        // ['标签1', '标签2', '标签3']
        return [];
      }
    },
    dicItems: {
      type: Array,
      default: () => {
        // [{ value: '1', label: '标签1' }, { value: '2', label: '标签2' }, { value: '3', label: '标签3' }]
        return [];
      }
    },
    /**
     * 大小
     * 默认：normal
     * 小：small
     */
    tabSize: {
      type: String,
      default: 'normal'
    },
    permissionItems: {
      type: Array,
      default: () => {
        // ['user:list', 'user:mod', 'user:del']
        return ['', '', ''];
      }
    }
  },
  data () {
    return {
      currentIndex: 0
    };
  },
  computed: {
    currentItems () {
      if (this.items.length) {
        return this.items;
      }
      return this.dicItems;
    },
    getCurrentClass () {
      return `__g-tab-primary-type-${this.type} __g-tab-size-${this.tabSize}`;
    }
  },
  watch: {
    index (newValue) {
      this.setCurrentValue(newValue);
    }
  },
  created () {
    this.setCurrentValue(this.index);
  },
  methods: {
    setCurrentValue (value) {
      if (value !== this.currentIndex) {
        this.currentIndex = value;
      }
    },
    _click (i) {
      this.setCurrentValue(i);
      this.$emit('on-change', i);
    }
  }
};
</script>
