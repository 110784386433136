/**
 * 监听是否需要休眠
 *
 * @date:2022/09/27
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
// 页面被点击(也就是活跃状态)
// 1. 监听页面活跃状态
// 2. 记录最后活跃的时间(每鼠标点击一次活跃时间将重置)
// 3. 获取最后活跃的时间
// 5. 开始监听
// 6. 重置
class PageActive {
  /**
   * 初始化
   * @param pastTime：过期时间，10分钟，30分钟，60分钟
   */
  constructor (pastTime = '10') {
    // 页面最长待机时间
    this.gap = 1000 * 60 * parseInt(pastTime);
    // 最后一次活跃时间
    this.activeTime = (new Date()).getTime();
    // 是否活跃
    this.isActived = true;
    // 定时器
    this.timer = null;
    this._mouseDownCallBack = () => {
      this.resetObserver();
    };
    // 监听鼠标点击
    window.addEventListener('mousedown', this._mouseDownCallBack);
  }

  // 私有方法-关闭定时器
  _closeTimer () {
    clearInterval(this.timer);
    this.timer = null;
  }

  // 开始监听
  startObserver (callBack) {
    if (this.timer) {
      this._closeTimer();
    }
    this.timer = setInterval(() => {
      const time = (new Date()).getTime();
      this.isActived = (time - this.activeTime) < this.gap;
      callBack(this.isActived);
    }, 1000);
  }

  // 重置活跃时间：登录成功
  resetObserver () {
    this.activeTime = (new Date()).getTime();
    this.isActived = true;
  }

  // 移除
  removeObserver () {
    window.removeEventListener('mousedown', this._mouseDownCallBack);
    this.activeTime = null;
    this._mouseDownCallBack = null;
    this._closeTimer();
  }
}

export default PageActive;
