<!--
    Created by 程雨喵'mac on 2024/2/29.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：展开
-->
<style lang="less">
.__g-table-expand {
  margin: -20px -50px;
  padding-bottom: 10px;
  //border-radius: @borderRadiusMid;
  background-color: @backColorStrong;
  border-bottom: 1px solid @lineColorNormal;
  .expand-content {
    position: relative;
    padding: 0 5px 5px 130px;
    //border-radius: @borderRadiusMid;
    overflow: hidden;
    //box-shadow: @shadowMin;
    //&::before {
    //  content: '';
    //  position: absolute;
    //  left: 24px;
    //  top: -30px;
    //  width: 60px;
    //  bottom: 50%;
    //  border-left: 1px solid @lineColorNormal;
    //  border-bottom: 1px solid @lineColorNormal;
    //  border-radius: 0 0 0 @borderRadiusMid;
    //}
    .expand-tip {
      position: absolute;
      left: 80px;
      top: 0;
      bottom: 5px;
      width: 50px;
      .display-flex();
      flex-direction: column;
      white-space: pre-line;
      font-size: 12px;
      line-height: 12px;
      color: @textColorLight;
      background-color: @backColorNormal;
      border: 1px solid @lineColorNormal;
      border-radius: @borderRadiusMin 0 0 @borderRadiusMin;
      overflow: hidden;
    }
    .expand-content-tb {
      padding: 0;
      display: flex;
      background: @backColorNormal;
      border-top: 1px solid @lineColorNormal;
      font-size: 12px;
      .expand-content-li {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        .expand-li-title {
          padding: 3px 0;
          color: @textColorLight;
          font-weight: 600;
          text-align: center;
          border-bottom: 1px solid @lineColorNormal;
          border-right: 1px solid @lineColorNormal;
        }
        .expand-item {
          display: flex;
          padding: 0 5px 0 0;
          border-bottom: 1px solid @lineColorNormal;
          border-right: 1px solid @lineColorNormal;
          .expand-li-label {
            margin-right: 5px;
            .flex-grow(90px);
            text-align: right;
            color: @textColorLight;
            font-weight: 300;
          }
          .expand-li-value {
            flex: 1;
            &.can-click {
              cursor: pointer;
              color: @buttonColorPrimary;
              text-decoration: underline;
            }
          }
        }
      }
      .expand-col-20 {.flex-grow(20%);}
      .expand-col-25 {.flex-grow(25%);}
      .expand-col-33 {.flex-grow(33.33333%);}
      .expand-col-40 {.flex-grow(40%);}
      .expand-col-50 {.flex-grow(50%);}
      .expand-col-100 {.flex-grow(100%);}
    }
  }
}
</style>

<template>
  <div class="__g-table-expand">
    <div
      class="expand-content"
      :style="`padding-left:${leftGap + 50}px`"
    >
      <div
        class="expand-tip"
        :style="`left:${leftGap}px`"
      >
        全部<div />信息
      </div>
      <div class="expand-content-tb">
        <div
          v-for="(item, i) in rowItems"
          :key="i"
          class="expand-content-li"
          :class="'expand-col-' + item.col"
        >
          <div class="expand-col-100 expand-li-title">
            {{ item.title }}
          </div>
          <div
            v-for="(sub, s) in item.items"
            :key="'s-' + s"
            class="expand-item expand-col-50"
            :class="'expand-col-' + sub.col"
          >
            <div
              class="expand-li-label"
              :style="sub.labelWidth ? `max-width:${sub.labelWidth}px;min-width:${sub.labelWidth}px;` : ''"
            >
              {{ sub.label }}
            </div>
            <div
              v-if="sub.key"
              class="expand-li-value"
              :class="(sub.canClick && model[sub.key]) ? 'can-click' : ''"
              @click="_click(sub)"
            >
              <yg-attribute-span
                :text="model[sub.key]"
                :can-copy="sub.canCopy"
                :can-tooltip="sub.canTooltip"
                :can-hide-phone="sub.canHidePhone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'YgTableExpand',
  components: {},
  props: {
    // 距离左侧的间距，默认为80(如第一列是勾选，第二列是下标时，就是80)
    leftGap: {
      type: Number,
      default: 80
    },
    // 数据模型
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /**
     * rowItems 传值列子
     * {
     *   title: '型号信息',
     *     col: '50',
     *   items: [
     *     { col: '25', labelWidth: 90, label: '设备商名称:', key: 'aaaa', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', labelWidth: 90, label: '设备型号编:', key: 'bbbb', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', labelWidth: 90, label: '设备型号名:', key: 'cccc', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', labelWidth: 90, label: '设备端口类别:', key: 'dddd', canCopy: false, canTooltip: true, canHidePhone: false }
     *   ]
     *   },
     *   {
     *     title: '所在区域',
     *       col: '50',
     *     items: [
     *     { col: '25', label: '所在省份:', key: 'provinceName', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', label: '所在城市:', key: 'cityName', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', label: '所在小区:', key: 'communityName', canCopy: false, canTooltip: true, canHidePhone: false },
     *     { col: '25', label: '', key: '', canCopy: false, canTooltip: true, canHidePhone: false }
     *   ]
     * }
     */
    rowItems: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {};
  },
  created () {},
  methods: {
    /** ******************* 点击事件开始 ******************* **/
    _click (item) {
      if (item.canClick) {
        this.$emit('on-click', item);
      }
    }
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
