import BaseModel from './dataModel/BaseModel';
import BaseSearchModel from './searchModel/BaseSearchModel';
import BaseSettingModel from './settingModel/BaseSettingModel';
import BaseTableColumn from './tableColumn/BaseTableColumn';

if (Window) {
  Window.prototype.BaseModel = BaseModel;
  Window.prototype.BaseSearchModel = BaseSearchModel;
  Window.prototype.BaseSettingModel = BaseSettingModel;
  Window.prototype.BaseTableColumn = BaseTableColumn;
}
