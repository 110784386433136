import BaseModel from '@/libs/base/dataModel/BaseModel';

export class CardUseRecordModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 业务单号
    this.bizNo = '';
    // 支付单号
    this.payNo = '';
    // 交易单号
    this.tradeNo = '';
    // 设备编码
    this.deviceNumber = '';
    // imei号
    this.imei = '';
    // 省份名称
    this.provinceName = '';
    // 城市名称
    this.cityName = '';
    // 小区名称
    this.communityName = '';
    // 点位名称
    this.siteName = '';
    // 设备名称
    this.deviceName = '';
    // 设备型号编码
    this.deviceModel = '';
    // 设备型号名称
    this.deviceModelName = '';
    // 设备商名称
    this.providerName = '';
    // 端口号
    this.port = '';
    // 端口类别
    this.portType = '';
    // 端口类型描述
    this.portTypeDesc = '';
    // 卡号
    this.cardNo = '';
    // 卡类型
    this.cardType = '';
    // 卡类型描述
    this.cardTypeDesc = '';
    // 卡交易金额
    this.consumeMoney = '';
    // 卡交易类型
    this.consumeType = '';
    // 交易前卡余额
    this.cardBeforeBalanceMoney = '';
    // 交易状态
    this.cardConsumeStatus = '';
    // 同步时间
    this.syncTime = '';
    // 数据来源
    this.sourceType = '';
    // 数据来源描述
    this.sourceTypeDesc = '';
    // 手机号
    this.tel = '';
    this.useTime = '';
    this.gwTradeNo = '';
    this.payMoney = '';

    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    return this;
  }

  static createFakeDataList (len = 10) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        bizNo: BaseModel.getFakeNumber(999999999, 0),
        payNo: BaseModel.getFakeNumber(999999999, 0),
        tradeNo: BaseModel.getFakeNumber(999999999, 0) + 200,
        deviceNumber: BaseModel.getFakeNumber(99999, 0),
        gwTradeNo: BaseModel.getFakeNumber(99999, 0),
        imei: BaseModel.getFakeNumber(50, 0) + 200,
        provinceName: BaseModel.getFakeTitle(3) + '省',
        cityName: BaseModel.getFakeTitle(3) + '市',
        communityName: BaseModel.getFakeTitle(3) + '区',
        siteName: BaseModel.getFakeTitle(8),
        deviceName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        deviceModel: 'HHKHKHKJLJLKJL5849572' + i,
        deviceModelName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        providerName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        port: BaseModel.getFakeNumber(50, 0) + 200,
        portType: '',
        portTypeDesc: '',
        cardNo: BaseModel.getFakeNumber(999999999, 0),
        cardType: '',
        cardTypeDesc: '',
        consumeMoney: BaseModel.getFakeNumber(99990, 0),
        payMoney: BaseModel.getFakeNumber(99990, 0),
        consumeType: '',
        cardBeforeBalanceMoney: BaseModel.getFakeNumber(999),
        cardConsumeStatus: '',
        syncTime: '2023-01-02 12:12:12',
        useTime: '2023-01-02 12:12:12',
        sourceType: '',
        sourceTypeDesc: '',
        tel: '1379740022' + i
      };
      list.push(dic);
    }
    return {
      rows: list,
      total: 30
    };
  }

  static createAdditionModel () {
    return new this();
  }
}
