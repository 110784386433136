import BaseModel from '@/libs/base/dataModel/BaseModel';

export class RefundCoinApplicationDetailDataModel extends BaseModel {
  constructor () {
    super();
    // 用户手机号
    this.applyTel = '';
    // 申请订单号
    this.refundOrderNo = '';
    // 子订单号
    this.childRefundOrderNo = '';
    // 凭证号
    this.payTradeNo = '';
    // 退款金额
    this.realRefundMoney = '';
    // 退款时间
    this.realRefundTime = '';
    // 备注
    this.remark = '';
    // 订单状态：0-待退款 1-退款中 2-退款成功 3-退款失败
    this.status = '';
    // 订单状态描述
    this.statusDesc = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const refundChannelDesc = ['微信', '银行卡'];
    const statusDesc = ['待退款', '退款中', '退款成功', '退款失败'];
    for (let i = 0; i < len; i++) {
      const dic = {
        applyTel: '13904288000',
        refundOrderNo: '000101010' + i,
        refundCoinMoney: parseFloat((Math.random() * 99999).toFixed(2)),
        applyRefundMoney: parseFloat((Math.random() * 30000).toFixed(2)),
        refundChannel: i % 2,
        refundChannelDesc: refundChannelDesc[i % 2],
        receiveTel: '13904288000',
        status: i % 4,
        statusDesc: statusDesc[i % 4],
        applyRefundTime: '2024-05-0' + (i + 1),
        realRefundTime: '2024-05-0' + (i + 1),
        goodsName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        operator: BaseModel.getFakeTitle(3)
      };
      list.push(dic);
    }
    return list;
  }
}
