/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import GLOBAL_CONFIG from '@/app.config';
import Storage from '@/libs/storage/index.js';
import { strReplaceSpaceStartEnd } from '@/libs/stringUtils.js';
import appCode from './code/appCode';

class RequestInterceptor {
  constructor (api) {
    // axios数据请求中用到的字段 =======start==========
    this.method = api.method;
    this.headers = api.headers || {};
    this.url = GLOBAL_CONFIG.baseUrl + api.url;
    const requestParams = this.__checkParams(api.params, api.autoConfig.autoReplaceSpace);
    if (api.isGetMethod && !api.getMethodIsQuery) {
      const v = requestParams[Object.keys(requestParams)[0]] || '';
      this.url = `${this.url}/${v}`;
      this.data = null;
      this.params = null;
    } else {
      this.data = api.isGetMethod ? null : requestParams;
      this.params = api.isGetMethod ? requestParams : null;
    }
    // axios数据请求中用到的字段 =======end==========
    // 是否通过请求拦截
    this.success = true;
    this.code = '';
    if (api.needToken) {
      this.success = this.__checkToken();
    }
    return this;
  }

  // 私有方法-请求参数修正
  /**
   * 私有方法-请求参数修正
   * 1.去掉所有undefined
   * 2.如果有需要，去掉所有空格
   */
  __checkParams (params, autoReplaceSpace) {
    if (!params) {
      return {};
    }
    for (const key in params) {
      const v = params[key];
      // 1.看看是不是对象（数组或者对象）
      if (typeof v === 'object' && v !== null) {
        params[key] = this.__checkParams(v, autoReplaceSpace);
      } else {
        // 2.null与undefined的情况，转为空字符串
        if (!v && v !== 0) {
          params[key] = '';
        }
        // 3.去掉首位空格
        if (typeof v === 'string' && autoReplaceSpace) {
          params[key] = strReplaceSpaceStartEnd(v);
        }
      }
    }
    return params;
  }

  // 私有方法-判断token是否过期
  __checkToken () {
    const storage = new Storage();
    // 没有token就直接返回false
    if (!storage.token) {
      this.code = appCode.tokenPassed;
      return false;
    }
    this.headers.Authorization = storage.token;
    return true;
  }

  // 私有方法-判断接口权限
  __checkPermission () {}

  // 遍历构造器
  static createModel (api) {
    return new RequestInterceptor(api);
  }
}

export default RequestInterceptor;
