<!--
    Created by 程雨喵'mac on 2024/10/22.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：刷卡金额设置
-->
<style lang="less">
.dc-card-parameter-submit {
  .submit-table {
    position: relative;
    border-top: 1px solid @lineColorNormal;
    border-radius: @borderRadiusMin;
    &+.submit-table {
      margin-top: @containerGap;
    }
    .submit-table-row {
      display: flex;
      align-items: flex-start;
      border: 1px solid @lineColorNormal;
      border-top: 0;
      height: 40px;
      line-height: @formHeight;
      font-size: 12px;
      .ivu-icon {
        cursor: help;
        color: @textColorLight;
        margin-right: 5px;
      }
      &.is-header {
        background-color: @backColorNormal;
        color: @textColorLight;
        font-weight: 600;
        font-size: 13px;
      }
      .table-col {
        position: relative;
        padding: 5px;
        .display-flex();
        box-sizing: border-box;
        &.style-merge {
          background: @backColorStrong;
          position: absolute;
          width: 100%;
          top: 40px;
          bottom: 0;
          .display-flex();
          border-bottom: 1px solid @lineColorNormal;
          z-index: 1;
        }
        .table-col-inner {
          width: 100%;
          .display-flex();
          white-space: nowrap;
          .ivu-input-number {
            flex: 1;
          }
        }
        &+.table-col {
          border-left: 1px solid @lineColorNormal;
        }
        &.col-t-0 {
          .flex-grow(140px);
          color: @textColorLight;
        }
        &.col-t-1 {
          .flex-grow(160px);
        }
        &.col-t-2 {
          flex: 1;
        }
        &.col-t-3 {
          .flex-grow(100px);
        }
      }
    }
  }
}
</style>

<template>
  <div class="dc-card-parameter-submit">
    <div
      v-for="(item, i) in tableRows"
      :key="i"
      class="submit-table"
    >
      <div class="submit-table-row is-header">
        <div
          v-for="(tableHeadersItem, tableHeadersIndex) in tableHeaders"
          :key="tableHeadersIndex"
          class="table-col"
          :class="'col-t-' + tableHeadersIndex"
        >
          {{ tableHeadersItem }}
        </div>
      </div>
      <div
        v-for="(re, ind) in item.items"
        :key="'ind-' + ind"
        class="submit-table-row"
      >
        <div
          v-if="ind === 0"
          class="table-col col-t-0 style-merge"
        >
          {{ re.submitTypeTitle }}
        </div>
        <div class="table-col col-t-0">
          {{ re.submitTypeTitle }}
        </div>
        <div
          class="table-col col-t-1"
        >
          {{ re.title }}
        </div>
        <!--编辑时，编辑时，编辑时-->
        <div
          v-if="isEdit"
          class="table-col col-t-2"
        >
          <div class="table-col-inner">
            <InputNumber
              v-if="re.valueTag === 'InputNumber'"
              v-model="model[re.key]"
              :max="re.max"
              :min="re.min"
              :placeholder="re.placeholder"
              :disabled="re.disabled"
              type="number"
              class="display-flex-1"
            />
          </div>
        </div>
        <!--展示时，展示时，展示时-->
        <div
          v-else
          class="table-col col-t-2"
        >
          <span>{{ model[re.key] || '-' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tableHeaders: ['配置类型', '相关设备', '相关值(单位：元)'],
      tableRows: [
        {
          title: '刷卡扣充电币(数量)',
          items: [
            {
              submitTypeTitle: '刷卡扣充电币(数量)',
              title: '超翔',
              valueTag: 'InputNumber',
              key: 'swipeCardCX',
              manuFacturers: '10',
              disabled: false,
              min: 1,
              max: 30
            },
            {
              title: '友电',
              valueTag: 'InputNumber',
              key: 'swipeCardYD',
              manuFacturers: '40',
              disabled: false,
              min: 1,
              max: 30
            },
            {
              title: '尚亿',
              valueTag: 'InputNumber',
              key: 'swipeCardSY',
              manuFacturers: '50',
              disabled: false,
              min: 1,
              max: 30
            }
          ]
        }
      ]
    };
  },
  created () {
    if (this.model.manufacturers) {
      const list = [];
      this.tableRows[0].items.forEach(v => {
        if (v.manuFacturers === this.model.manufacturers) {
          v.submitTypeTitle = '刷卡扣充电币(数量)';
          list.push(v);
        }
        this.tableRows[0].items = list;
      });
    }
  },
  methods: {
    $checkSubmit () {
      const item = this.tableRows[0].items.find(v => !this.model[v.key]);
      if (item) {
        return this.$Message.warning(item.title + '刷卡扣充电币暂未设置');
      }
      return { success: true };
    }
  }
};
</script>
