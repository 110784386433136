<!--
    Created by 程雨喵'mac on 2023/12/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：用户管理-充电卡列表-绑定记录
-->
<style lang="less">
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="800"
    :back-type="1"
    title="绑定记录"
    @on-close="_close"
  >
    <div
      slot="content"
      class="bind-record-list"
    >
      <div class="margin-primary-bottom">
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          @on-click="_clickSetting"
        />
      </div>
      <div v-permission="'system:chargeCard:getChargeCardBindLog:list'">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import CustomerApiSet from '@/request/api/CustomerApiSet';
export default {
  components: {},
  mixins: [],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('刷新列表', 'refresh', 'md-refresh').setPermission('system:chargeCard:getChargeCardBindLog:list')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('bindTypeDesc', '操作类型').setSpan({}),
        BaseTableColumn.initData('tel', '用户手机号', 100).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('createBy', '操作人', 120).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('createTimeStr', '操作时间').setSpan({})
      ]
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    _clickSetting (key) {
      console.log(key);
      if (key === 'refresh') {
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/
    /** ******************* 点击事件开始 ******************* **/
    requestData () {
      // this.tableData = this.createFakeDataList();
      this.tableData = [];
      this.page.total = 0;
      const api = CustomerApiSet.getChargeCardBindLogApi;
      api.params = {
        userCardNumberId: this.model.id
      };
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = resData.rows;
      });
    },
    // 假数据
    createFakeDataList (len = 30) {
      const list = [];
      const bindTypeDesc = ['本人绑卡', '代绑卡', '本人解绑', '代解绑'];
      for (let i = 0; i < len; i++) {
        const dic = {
          id: '000101010' + i,
          cardNumber: '000101010' + i,
          tel: '13904288000',
          bindType: i % 4,
          bindTypeDesc: bindTypeDesc[i % 4],
          createTimeStr: '2024-05-0' + (i + 1),
          createBy: '王丽莎' + i
        };
        list.push(dic);
      }
      return list;
    }
  }
};
</script>
