<!--
    Created by 王丽莎 on 2024/08/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：充电管理 - 充电业务订单列表 - 操作退款页面
    功能概述：操作退款
-->
<style lang="less">
.refund-submit-content {
  .refund-content {
    .display-flex(center, flex-start);
    flex-wrap: wrap;
    width: 100%;
    .cell-item {
      .flex-grow(50%);
      box-sizing: border-box;
      padding: 5px 0;
      .table-value {
        padding: 0 7px;
      }
    }
    textarea.ivu-input {
      resize: none;
    }
    .ivu-input-word-count {
      background: transparent;
    }
  }
  .text-strong {
    color: @strongColor !important;
    font-weight: 500;
  }
}
</style>

<template>
  <yg-modal
    width="640"
    title="操作退款"
    sure-text="保存"
    :back-type="2"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 300px"
    >
      <div class="refund-submit-content">
        <div
          v-for="(item, index) in formItems"
          :key="index"
        >
          <yg-card
            :title="item.title"
            style="margin-bottom: 15px"
          >
            <div
              slot="content"
              class="refund-content"
            >
              <div
                v-for="(re, ind) in item.items"
                :key="ind"
                :class="re.isKeyValue ? 'cell-item' : ''"
              >
                <yg-attribute-key-value
                  v-if="re.isKeyValue"
                  :label="re.label"
                  :label-width="76"
                  :value="model[re.key] + ''"
                  :can-hide-phone="re.isHidePhone"
                  :can-copy="re.isCopy"
                  :can-price="re.isPrice && (model.payChannel === 2 || model.payChannel === 3)"
                  class="key-value-style"
                  :value-class-name="re.textStrong ? 'text-strong' : ''"
                />
                <Form v-else>
                  <FormItem
                    :label="re.label"
                    :label-width="re.labelWidth"
                    :required="re.isRequired"
                  >
                    <Input
                      v-if="re.tagName === 'Textarea'"
                      v-model="model[re.key]"
                      maxlength="60"
                      show-word-limit
                      :rows="5"
                      type="textarea"
                      placeholder="请输入退款的事由"
                      style="width: 300px"
                      @input="checkSubmit"
                    />
                  </FormItem>
                </Form>
              </div>
            </div>
          </yg-card>
        </div>
      </div>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      // 表单数据
      formItems: [
        {
          title: '交易及退款信息',
          items: [
            {
              label: '用户手机号:',
              key: 'userTel',
              isKeyValue: true,
              isHidePhone: true
            },
            {
              label: '支付订单:',
              key: 'payTradeNo',
              isKeyValue: true,
              isCopy: true
            },
            {
              label: '支付金额:',
              key: 'payMoney',
              isKeyValue: true
            },
            {
              label: '支付渠道:',
              key: 'payChannelName',
              isKeyValue: true,
              isPrice: false
            },
            {
              label: '已退款金额:',
              key: 'refundMoney',
              isKeyValue: true
            }
          ]
        },
        {
          title: '退款信息',
          items: [
            {
              label: '退款金额:',
              key: 'canRefundMoney',
              isKeyValue: true,
              isPrice: true,
              textStrong: true
            },
            {
              label: '备注:',
              labelWidth: 88,
              key: 'notes',
              tagName: 'Textarea',
              disabled: false,
              clearable: true,
              isRequired: true,
              isKeyValue: false,
              isPrice: false
            }
          ]
        }
      ]
    };
  },
  created () {
    console.log(this.model);
    this.checkSubmit();
  },
  methods: {
    onSure () {
      const res = this.model.checkValidSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.$emit('on-sure', res.params);
      }
    },
    onClose () {
      this.$emit('on-close');
    },
    // 检查必填项
    checkSubmit () {
      this.model.notes = this.model.notes ? this.model.notes.trim() : '';
      this.buttonDisabled = !this.model.notes;
      this.buttonDisabledMsg = this.model.notes ? '' : '备注不可为空';
    }
  }
};
</script>
