<!--
   整体布局

   @date:2022/11/21
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
#main {
  position: relative;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  padding: ~"calc(@{topBarHeight} + 10px) @{containerGap} @{containerGap} @{containerGap}";
  background-color: @containerBackground;
}
</style>

<template>
  <div
    v-if="hasPermission"
    id="main"
  >
    <v-left-bar
      v-if="currentRoute"
      :current-route="currentRoute"
    />
    <v-top-bar
      v-if="currentRoute"
      :current-route="currentRoute"
      @on-click-dropdown="_clickDropdown"
    />
    <v-container
      v-if="currentRoute"
      :current-route="currentRoute"
    >
      <router-view />
    </v-container>
    <v-monitor
      v-if="showMonitor"
    />
  </div>
</template>

<script>
import vLeftBar from './view/leftBar/index.vue';
import vTopBar from './view/topBar/index.vue';
import vContainer from './view/container.vue';
import PageActive from './utils/PageActive';
import CheckLoginAuth from './utils/CheckLoginAuth';
import Storage from '@/libs/storage/index.js';
import vMonitor from './view/_host/requestMonitor.vue';
import UserApiSet from '@/request/api/UserApiSet';
export default {
  components: { vLeftBar, vTopBar, vContainer, vMonitor },
  data () {
    return {
      currentRoute: null,
      screenActive: null
    };
  },
  computed: {
    showMonitor () {
      return this.$store.state.app.showReqMonitor;
    },
    hasPermission () {
      return this.$store.state.user.permission && this.$store.state.user.permission.length > 0;
    }
  },
  watch: {
    $route (newVal, oldVal) {
      this.currentRoute = {
        name: newVal.name,
        meta: newVal.meta,
        path: newVal.path
      };
      this.saveRouterHistory();
    }
  },
  created () {
    const route = this.$route;
    this.currentRoute = {
      name: route.name,
      meta: route.meta,
      path: route.path
    };
    this.requestPermission();
    this.createScreenActive();
    this.createCheckLoginAuth();
    this.saveRouterHistory();
  },
  destroyed () {
    if (this.screenActive) {
      console.log('screenActive remove =========>');
      this.screenActive.removeObserver();
      this.screenActive = null;
    }
    if (this.checkLoginAuth) {
      console.log('checkLoginAuth remove =========>');
      this.checkLoginAuth.removeObserver();
      this.checkLoginAuth = null;
    }
  },
  methods: {
    _clickDropdown (item) {
      switch (item.name) {
      case 'password':
        this.$Message.info('敬请期待');
        break;
      case 'logOut':
        this.$Modal.confirm({
          title: '温馨提示',
          content: '是否确定退出登录？',
          onOk: () => {
            this.$storage.token = null;
            this.$router.push({ name: 'login' });
          }
        });
        break;
      }
    },
    // 保存路由记录
    saveRouterHistory () {
      const route = this.$route;
      if (route.meta && route.meta.saveInHistory) {
        this.$store.commit('setRouterHistory', route);
      }
    },
    createScreenActive () {
      if (this.screenActive) {
        this.screenActive.removeObserver();
        this.screenActive = null;
      }
      this.screenActive = new PageActive('60');
      this.screenActive.startObserver(status => {
        if (!status) {
          console.log('ScreenActive  reload =============>');
          // 一个小时没动之后，刷新一下界面
          window.location.reload();
        }
      });
    },
    createCheckLoginAuth () {
      if (this.checkLoginAuth) {
        this.checkLoginAuth.removeObserver();
        this.checkLoginAuth = null;
      }
      this.checkLoginAuth = new CheckLoginAuth(this.$storage.token);
      this.checkLoginAuth.startObserver(orgToken => {
        const newToken = (new Storage()).token;
        if (orgToken !== newToken) {
          console.log('登录态已变更=======================>');
          // 登录态变更
          this.$Modal.confirm({
            title: '登录态已变动',
            content: '建议您刷新页面，保持最近登录的账号状态',
            okText: '立即刷新',
            cancelText: '暂不刷新',
            onOk: () => {
              // 刷新页面
              window.location.reload();
            },
            onCancel: () => {
              this.$storage.token = newToken;
              this.$router.replace('/error/404');
            }
          });
        }
      });
    },
    requestPermission () {
      const api = UserApiSet.buttonAuth;
      this.$http(api).then(res => {
        const resData = res.data;
        const permission = [];
        if (Array.isArray(resData.rows)) {
          resData.rows.forEach(v => {
            permission.push(v.authParam);
          });
        }
        if (!permission.length) {
          this.$Message.warning('无权限登录，即将跳转到登录页');
          setTimeout(() => {
            this.$router.replace('/login');
          }, 2000);
        }
        this.$store.commit('setPermission', permission);
      }).catch(e => {
        this.$Message.warning('无权限登录，即将跳转到登录页');
        setTimeout(() => {
          this.$router.replace('/login');
        }, 2000);
      });
    }
  }
};
</script>
