// 设备计费数据模型
import BaseModel from '@/libs/base/dataModel/BaseModel';

export class YouDianParamsModel1 extends BaseModel {
  constructor () {
    super();
    this.maxChargeTime = ''; // 最大充电时长，单位为分钟
    this.maxOverloadPower = ''; // 过载功率，单位为瓦
    this._numChangeToString = true;

    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '最长充电时间', key: 'maxChargeTime' },
      { title: '过载功率', key: 'maxOverloadPower' }
    ];
    const res = checkData.find(v => !data[v.key] || data[v.key] <= 0);
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确输入' + res.title);
    }
    // 专项校验
    if (Object.keys(data).length <= 1) {
      return BaseModel.getValidRes(false, '请输入参数再提交');
    }
    return BaseModel.getValidRes(true, '', {
      maxChargeTime: data.maxChargeTime || '',
      maxOverloadPower: data.maxOverloadPower || ''
    });
  }
}

export class YouDianParamsModel2 extends BaseModel {
  constructor () {
    super();
    this.disconnectPower = ''; // 拔出功率，单位为w
    this.disconnectRecoTime = ''; // 拔出功率识别时间，单位为秒
    this.floatingPercentage = ''; // 浮充百分比
    this.floatingRecoTime = ''; // 浮充状态识别时间，单位为分钟
    this.floatingTime = ''; // 浮充时间，单位为分钟
    this.heartbeat = ''; // 心跳包上报间隔时间，单位为分钟
    this._numChangeToString = true;

    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '拔出功率', key: 'disconnectPower' },
      { title: '拔出功率识别时间', key: 'disconnectRecoTime' },
      { title: '浮充功率识别百分比', key: 'floatingPercentage' },
      { title: '浮充状态识别时间', key: 'floatingRecoTime' },
      { title: '浮充充电时间', key: 'floatingTime' },
      { title: '心跳包上报间隔时间', key: 'heartbeat' }
    ];
    const res = checkData.find(v => !data[v.key] || data[v.key] <= 0);
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确输入' + res.title);
    }
    // 专项校验
    if (Object.keys(data).length <= 1) {
      return BaseModel.getValidRes(false, '请输入参数再提交');
    }
    return BaseModel.getValidRes(true, '', {
      disconnectPower: data.disconnectPower || '',
      disconnectRecoTime: data.disconnectRecoTime || '',
      floatingPercentage: data.floatingPercentage || '',
      floatingRecoTime: data.floatingRecoTime || '',
      floatingTime: data.floatingTime || '',
      heartbeat: data.heartbeat || ''
    });
  }
}

export class YouDianParamsModel3 extends BaseModel {
  constructor () {
    super();
    this.dynamicOverloadPower = ''; // 动态过载功率，单位为瓦
    this.dynamicOverloadRecoTime = ''; // 动态过载识别时间，单位为分钟
    this.dynamicOverloadStartTime = ''; // 动态过载开始时间，单位为分钟
    this.disInterferencePower = ''; // 拔出干扰功率，单位为瓦
    this.disDetectTime = ''; // 拔出干扰功率判断时间，单位为分钟
    this.floatingRec2DueTime = ''; // 浮充识别第二次时间点，单位为分钟

    this.floatingRec2Time = ''; // 浮充状态第二次识别时间，单位为分钟
    this.minPower = ''; // 最小功率，单位为瓦
    this.detectMinPowerDueTime = ''; // 判断最小功率时间点，单位为分钟
    this.max2PowerDueTime = ''; // 第二最大功率时间点，单位为分钟
    this.alertTemperature = ''; // 环境报警温度，单位为摄氏度
    this.portTemperature = ''; // 端口报警温度，单位为摄氏度
    this.ocDetectDisconnect = ''; // 打开/关闭判断用户拔出(光耦) 0打开 其他关闭
    this.qrLight = ''; // 二维码灯 0打开 1关闭

    this._numChangeToString = true;
    this.h5OcDetectDisconnect = false;
    this.h5QrLight = false;
    // 标记是否为缓存中的数据
    this.h5IsStorageData = false;
    this.h5StorageTime = '';
  }

  initData (resData) {
    super.initData(resData);
    this.h5OcDetectDisconnect = this.ocDetectDisconnect === '0';
    this.h5QrLight = this.qrLight === '0';
    return this;
  }

  static checkValidSubmit (data) {
    const checkData = [
      { title: '第二最大功率时间点', key: 'max2PowerDueTime' },
      { title: '浮充识别第二次时间点', key: 'floatingRec2DueTime' },
      { title: '浮充状态识别第二次识别时间', key: 'floatingRec2Time' },
      { title: '动态过载功率', key: 'dynamicOverloadPower' },
      { title: '动态过载识别时间', key: 'dynamicOverloadRecoTime' },
      { title: '动态过载开始时间', key: 'dynamicOverloadStartTime' },
      { title: '拔出干扰功率', key: 'disInterferencePower' },
      { title: '拔出干扰功率判断时间', key: 'disDetectTime' },
      { title: '判断最小功率时间点', key: 'detectMinPowerDueTime' },
      { title: '最小功率', key: 'minPower' }
    ];
    const res = checkData.find(v => !data[v.key] || data[v.key] <= 0);
    if (res && res.key) {
      return BaseModel.getValidRes(false, '请正确输入' + res.title);
    }
    // 专项校验
    if (Object.keys(data).length <= 1) {
      return BaseModel.getValidRes(false, '请输入参数再提交');
    }
    return BaseModel.getValidRes(true, '', {
      dynamicOverloadPower: data.dynamicOverloadPower || '',
      dynamicOverloadRecoTime: data.dynamicOverloadRecoTime || '',
      dynamicOverloadStartTime: data.dynamicOverloadStartTime || '',
      disInterferencePower: data.disInterferencePower || '',
      disDetectTime: data.disDetectTime || '',
      floatingRec2DueTime: data.floatingRec2DueTime || '',
      floatingRec2Time: data.floatingRec2Time || '',
      minPower: data.minPower || '',
      detectMinPowerDueTime: data.detectMinPowerDueTime || '',
      max2PowerDueTime: data.max2PowerDueTime || '',
      // alertTemperature: data.alertTemperature || '',
      // portTemperature: data.portTemperature || '',
      ocDetectDisconnect: data.h5OcDetectDisconnect ? '0' : '1',
      qrLight: data.h5QrLight ? '0' : '1'
    });
  }
}
