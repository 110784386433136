/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

// 前端定义的code
export default {
  tokenPassed: 'front_6344', // 请求无token，或token过期
  networkError: 'font_6345' // 请求异常 403, 404, 405, 500等等
};
