<!--
    Created by 程雨喵'mac on 2024/7/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：订单详情
-->
<style lang="less">
.simplify-theme.simplify-theme-dark {
  .order-detail-box {
    .order-detail-box-content {
      background: @backColorStrong;
      .top-img {
        img.top-bg-img {
          opacity: 0;
        }
        .top-info {
          text-shadow: 0 0 0;
          color: @textColorStrong;
          p {
            .eye-outer {
              img.eye {
                filter: brightness(300%);
              }
            }
          }
        }
      }
    }
  }
}
.order-detail-box {
  overflow: auto;
  height: 100%;
  padding-bottom: @containerGap;
  box-sizing: border-box;
  transition: all @transitionTime25;
  .order-detail-box-content {
    position: relative;
    //background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    overflow: hidden;
    background: @backColorStrong url("../../../../assets/image/orderBg.png") repeat;
    .top-img {
      position: relative;
      display: flex;
      img.top-bg-img {
        filter: brightness(99%);
        width: 100%;
        height: 100px;
      }
      .top-info {
        position: absolute;
        top: @containerGap;
        bottom: @containerGap;
        left: @containerGap;
        right: @containerGap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //color: white;
        text-shadow: 1px 1px 2px @textColorFlip;
        .top-info-title {
          font-size: 26px;
          line-height: 26px;
          font-weight: 600;
        }
        p {
          display: flex;
          align-items: flex-end;
          margin-top: 10px;
          font-size: 12px;
          line-height: 12px;
          .eye-outer {
            margin-left: 5px;
            display: flex;
            align-items: center;
            text-shadow: 0 0 0;
            font-weight: 300;
            cursor: pointer;
            img.eye {
              margin-left: 3px;
              width: 12px;
              height: auto;
            }
          }
        }
      }
    }
    .charts-get-box {
      padding-top: @containerGap * 0.5;
      padding-left: @containerGap;
      .get-btn {
        margin: 0 auto;
        padding: @containerGap * 2 0 @containerGap;
        .display-flex();
        flex-direction: column;
        background-color: @backColorLight;
        width: 200px;
        border-radius: @borderRadiusMid;
        img {
          width: 120px;
        }
      }
    }
  }
  .fixed-button {
    position: fixed;
    right: 45px;
    bottom: @containerGap;
    height: 50px;
    transition: all @transitionTime15;
    background-color: rgba(0,0,0,0.5);
    border-radius: 25px;
    &:hover {
      padding-bottom: @containerGap;
      height: auto;
      background-color: rgba(0,0,0,0.9);
      .circle-box {
        transform: rotateZ(720deg);
        .ivu-icon-md-add {
          display: none;
        }
        .ivu-icon-md-remove {
          display: block;
        }
      }
      .scroll-box {
        height: auto;
        .scroll-item {
          transition-delay: @transitionTime15;
          transform: scale(1);
        }
      }
    }
    .circle-box {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      .display-flex();
      font-size: 30px;
      color: white;
      transition: all @transitionTime25;
      transform: rotateZ(0);
      overflow: hidden;
      &:hover {
        height: 0;
        opacity: 0;
      }
      .ivu-icon-md-remove {
        display: none;
      }
    }
    .scroll-box {
      height: 0;
      .scroll-item {
        .display-flex();
        flex-direction: column;
        height: 50px;
        font-size: 10px;
        color: white;
        transform: scale(0);
        transition: all @transitionTime15;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    width="1200px"
    title="订单详情"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      ref="orderDetailBox"
      class="order-detail-box"
    >
      <div class="order-detail-box-content">
        <div class="top-img">
          <img
            src="../../../../assets/image/orderTop.png"
            alt=""
            class="top-bg-img"
          >
          <div class="top-info">
            <div class="top-info-title">
              <span>充电业务订单详情</span>
            </div>
            <p>
              业务订单编码:{{ model.orderInfo.orderNo }}
              <span
                class="eye-outer"
                @click.stop="showPrice = !showPrice"
              >(金额<img
                :src="showPrice ? '/icon/icon_eye_open.png' : '/icon/icon_eye_close.png'"
                alt=""
                class="eye"
              >)</span>
            </p>
          </div>
        </div>
        <v-info
          :model="model"
          :show-price="showPrice"
        />
        <template
          v-if="showCharts && chartsData"
        >
          <div id="chart-outer">
            <v-charts
              :model="chartsData"
              @on-refresh="requestCharts"
            />
          </div>
        </template>
        <div
          v-else
          class="charts-get-box"
        >
          <p class="h3 margin-primary-bottom">
            订单功率图
          </p>
          <div class="get-btn">
            <img
              src="/bg/requestLogImg.png"
              alt=""
            >
            <yg-button
              type="text"
              @click="_clickShowCharts"
            >
              点击拉取图表
            </yg-button>
          </div>
        </div>
        <div id="log-outer">
          <v-log
            :model="model"
          />
        </div>
        <yg-status-mark
          :text="model.orderInfo.chargeStatusDesc"
          :value="model.orderInfo.chargeStatus"
          info-text="充电状态"
          :type-map-items="markStatus"
          style="right: 15px; top: 15px"
        />
      </div>
      <div class="fixed-button">
        <div class="circle-box">
          <Icon type="md-add" />
          <Icon type="md-remove" />
        </div>
        <div class="scroll-box">
          <div
            v-for="(item, i) in fixedItems"
            :key="i"
            class="scroll-item"
            @click.stop="_clickFixed(item)"
          >
            <img
              v-if="item.key === 'info'"
              src="../../../../assets/icon/fixed_info.png"
              alt=""
            >
            <img
              v-else-if="item.key === 'chart'"
              src="../../../../assets/icon/fixed_chart.png"
              alt=""
            >
            <img
              v-else
              src="../../../../assets/icon/fixed_log.png"
              alt=""
            >
            <p>{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import vInfo from './detail-subView/info.vue';
import vCharts from './detail-subView/charts.vue';
import vLog from './detail-subView/log.vue';
import OrderApiSet from '@/request/api/OrderApiSet';
import { getElementTop } from '@/libs/utils';
import { ChargingBusinessOrderChartsModel } from '../model/ChargingBusinessOrderDataModel';

export default {
  components: { vInfo, vCharts, vLog },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      showPrice: true,
      markStatus: [
        { value: '0', type: 2 },
        { value: '1', type: 4 },
        { value: '2', type: 1 },
        { value: '3', type: 1 },
        { value: '4', type: 1 }
      ],
      fixedItems: [
        { key: 'info', label: '看信息' },
        { key: 'chart', label: '看图表' },
        { key: 'log', label: '看指令' }
      ],
      showCharts: false,
      chartsData: null
    };
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    },
    _clickShowCharts () {
      this.requestCharts();
    },
    _clickFixed (item) {
      if (item.key === 'info') {
        this.$refs.orderDetailBox.scrollTo(0, 0);
      } else {
        const ele = document.getElementById(item.key + '-outer');
        this.$refs.orderDetailBox.scrollTo(0, getElementTop(ele) - 75);
      }
    },
    requestCharts () {
      this.showCharts = false;
      this.chartsData = null;
      const api = OrderApiSet.orderDetailPowerElec;
      api.params = {
        orderNo: this.model.orderInfo.orderNo
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.chartsData = ChargingBusinessOrderChartsModel.initModelWithData(resData);
        this.showCharts = true;
      });
    }
  }
};
</script>
