<!--
   搜索区域

   @date:2022/09/24
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-search-area {
  //margin-right: -@containerGap;
  position: relative;
  //background-color: @text-white;
  //box-shadow: @containerBoxShadow;
  border-radius: @borderRadiusMid;
  font-size: 12px;
  //padding-top: @containerGap  + 5px;
  //padding-left: @containerGap;
  transition: all @transitionTime15;
  padding-bottom: @containerGap * 0.5;
  .search-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .search-item {
      position: relative;
      .display-flex();
      margin-bottom: @containerGap * 0.5;
      padding-right: @containerGap * 0.5;
      flex: 1;
      &.has-show-more-btn {
        .label {
          font-weight: 600;
        }
      }
      &.is-show-in-more {
        .label {
          color: @textColorLight;
          font-weight: 400 !important;
        }
      }
      .ivu-input, .ivu-select, .ivu-select-selected-value {
        font-size: 12px;
      }
      .ivu-date-picker {
        .ivu-input-suffix {
          display: none;
        }
        input.ivu-input-with-suffix {
          padding-right: 5px;
        }
      }
      .ivu-select-item {
        font-size: 12px !important;
      }
      .label {
        margin-right: 5px;
        text-align: right;
        //flex: 1;
        white-space: nowrap;
        &+* {
          flex: 1;
          max-width: 100%;
          overflow: hidden;
        }
        .ivu-select-selected-value {
          padding-left: 3px;
          padding-right: 17px;
          //font-size: 10px;
        }
        .ivu-icon {
          right: 5px;
          font-size: 12px;
        }
      }
      @media screen and (min-width: 3200px) {.flex-grow(10%)}
      @media screen and (max-width: 3000px) {.flex-grow(11.11111%)}
      @media screen and (max-width: 2700px) {.flex-grow(12.5%);}
      @media screen and (max-width: 2400px) {.flex-grow(14.25%)}
      @media screen and (max-width: 2200px) {.flex-grow(16.5%);}
      @media screen and (max-width: 1900px) {.flex-grow(20%)}
      @media screen and (max-width: 1600px) {.flex-grow(25%);}
      @media screen and (max-width: 1300px) {.flex-grow(33.3333333%);}
      @media screen and (max-width: 1000px) {.flex-grow(50%);}
      @media screen and (max-width: 700px) {.flex-grow(100%);}
      .show-more-btn {
        font-size: 12px;
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          color: @themeColor;
        }
        .open-icon {
          .ivu-icon {
            transition: all @transitionTime15;
            transform: rotateZ(0);
          }
          &.is-open {
            .ivu-icon {
              transform: rotateZ(180deg);
            }
          }
        }
      }
      .value-area {
        .range-input-box {
          .display-flex();
          span {
            margin: 0 5px;
          }
        }
      }
      .value-unit {
        margin-left: @containerGap * 0.3;
      }
    }
  }
}
</style>

<template>
  <div
    class="__g-search-area"
  >
    <div
      v-if="searchOption"
      class="search-box"
    >
      <ul
        class="search-inner"
      >
        <li
          v-for="(item, key) in searchOption"
          v-show="(!isOpen && !item.showInMore) || isOpen"
          :key="key"
          class="search-item"
          :class="(item.showInMore ? 'is-show-in-more ' : '') + (showMoreBtn ? 'has-show-more-btn' : '')"
        >
          <!--左侧label-->
          <p
            v-if="!item.labelIsSelect"
            class="label"
            :style="labelWidth"
          >
            <span>{{ item.label }}:</span>
          </p>
          <!--:style="item._labelSelectWidthStyle"-->
          <!--@on-change="getSelectLabelWidth(item)"-->
          <Select
            v-else-if="item.labelIsSelect"
            v-model="item.labelSelectValue"
            :clearable="false"
            class="label"
            :style="labelWidth"
            @on-change="getSelectLabelWidth"
          >
            <Option
              v-for="(op, p) in item.labelSelectOption"
              :key="p + '-opt'"
              :value="op.value"
            >
              <!--{{ item.labelSelectValue }}-->
              {{ op.label }}
            </Option>
          </Select>
          <!--可下拉的左侧列表-->
          <!--右侧输入框等等-->
          <div class="value-area">
            <Select
              v-if="item.tagName === 'Select'"
              v-model="item.value"
              :clearable="item.clearable"
              :disabled="item.disabled"
              :multiple="item.multiple"
              :max-tag-count="1"
              style="width: 100%"
              @on-change="exclusionInputed(key, item)"
            >
              <Option
                v-for="(op, p) in item.data"
                :key="p + '-op'"
                :value="op.value"
              >
                {{ op.label }}
              </Option>
            </Select>
            <Cascader
              v-else-if="item.tagName === 'Cascader'"
              v-model="item.value"
              :clearable="item.clearable"
              :disabled="item.disabled"
              style="width: 100%"
              :data="item.data"
              @on-change="exclusionInputed(key, item)"
            />
            <DatePicker
              v-else-if="item.tagName === 'DatePickerRange'"
              v-model="item.value"
              type="daterange"
              placeholder="请选择"
              transfer
              format="yyyy.MM.dd"
              separator="~"
              :options="item.data"
              :clearable="item.clearable"
              :disabled="item.disabled"
              style="width: 100%"
            />
            <Input
              v-else-if="item.tagName === 'Input'"
              v-model="item.value"
              placeholder="请输入"
              :clearable="item.clearable"
              :disabled="item.disabled"
              style="width: 100%"
              @input="exclusionInputed(key, item)"
            />
            <yg-global-select
              v-else-if="item.tagName === 'YgGlobalSelect'"
              v-model="item.value"
              :select-type="item.data"
              :clearable="item.clearable"
              :disabled="item.disabled"
              :multiple="item.multiple"
              @on-change="exclusionInputed(key, item)"
            />
            <!--省，市，区，街道-->
            <yg-region-cascader
              v-else-if="item.tagName === 'YgRegionCascader'"
              v-model="item.value"
              :level="item.data"
              :disabled="item.disabled"
              @on-change="exclusionInputed(key, item)"
            />
            <!--物业公司，省份，城市，小区，点位-->
            <yg-site-cascader
              v-else-if="item.tagName === 'YgSiteCascader'"
              v-model="item.value"
              :level="item.data"
              :disabled="item.disabled"
              @on-change="exclusionInputed(key, item)"
            />
            <!--输入框Range-->
            <div
              v-if="item.tagName === 'YgRangeInput'"
              class="range-input-box"
            >
              <Input
                v-model="item.value[0]"
                :disabled="item.disabled"
                :clearable="item.clearable"
                placeholder="开始"
                @input="exclusionInputed(key, item)"
              />
              <span>~</span>
              <Input
                v-model="item.value[1]"
                :disabled="item.disabled"
                :clearable="item.clearable"
                placeholder="结束"
                @input="exclusionInputed(key, item)"
              />
            </div>
          </div>
          <!--单位-->
          <div
            v-if="item.unit"
            class="value-unit"
          >
            {{ item.unit }}
          </div>
        </li>
        <li
          class="search-item"
          style="justify-content: flex-start"
        >
          <yg-button
            type="primary"
            style="margin-right: 15px"
            @click="_clickSearch"
          >
            {{ searchText }}
          </yg-button>
          <yg-button
            v-if="showRest && hasSearchValue"
            type="text"
            style="margin-right: 15px"
            @click="_clickReset"
          >
            重置
          </yg-button>
          <span
            v-if="showMoreBtn"
            class="show-more-btn"
            @click.stop="_clickBigScreenOpen"
          >
            <span
              v-if="!isOpen"
            >更多条件</span>
            <span
              v-else
              @click.stop="_clickBigScreenOpen"
            >收起条件</span>
            <span :class="isOpen ? 'open-icon is-open' : 'open-icon'">
              <Icon type="ios-arrow-down" />
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDate } from '@/libs/utils.js';
import { isNumber } from '@/libs/regularUtils';
export default {
  name: 'YgSearchArea',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          userId: {
            // 左侧标题
            label: '用户ID',
            // 字段的key
            key: 'userId',
            // 是否展示清除按钮
            clearable: true,
            // 输入或选择的值
            value: '',
            // 标签名称 Input, Cacader, Select, DatePickerRange, YgRegionCascader, YgSiteCascader, YgGlobalSelect...
            tagName: 'Input',
            // 级联动和选择框时使用的
            data: [],
            // 重置后还会选择此值
            default: null,
            // 条件互斥时使用此字段，比如说输入用户ID时手机号和名称不可输入，那么此时里面的值应该是tel,name
            exclusions: [],
            // 时候只有在查看更多时展示
            showInMore: false
          }
        };
      }
    },
    total: {
      type: Number,
      default: null
    },
    searchText: {
      type: String,
      default: '查询'
    },
    showRest: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // 将传进来的value重组一下
      searchOption: null,
      // 是否展示查看更多按钮, 取决于数据中是否存在 showInMore === true
      showMoreBtn: false,
      isOpen: false,
      // 为了UI展示整齐，左侧的label要统一宽度，需要取字数最多的计算一下
      labelWidth: ''
    };
  },
  computed: {
    // 有筛选项
    hasSearchValue () {
      if (this.value) {
        let flag = false;
        for (const key in this.value) {
          const dic = this.value[key];
          const value = dic.value;
          if (!flag) {
            if (Array.isArray(value)) {
              flag = !!value.join('');
            } else {
              flag = !!value || value === 0;
            }
          }
        }
        return flag;
      }
      return false;
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.setSearchOption();
      }
    }
  },
  mounted () {
    this.setSearchOption();
  },
  methods: {
    ...mapActions(['getCompanyList']),
    // 外部调用-刷新数据(Select等data传进来之后需要刷新下拉项时调用)
    $refresh () {
      this.setSearchOption();
    },
    getSelectLabelWidth (e) {
      const valueOption = this.value;
      let labelWidth = 0;
      for (const key in valueOption) {
        const dic = valueOption[key];
        // 计算字的宽度，因为用的是12号字体，因此一个字的宽度为12，且预留5的宽度做误差处理
        let width = 0;
        if (dic.label) {
          if (dic.labelIsSelect) {
            const labelSelectValue = dic.labelSelectValue;
            const currentLabel = dic.labelSelectOption.find(v => v.value === labelSelectValue);
            console.log(currentLabel);
            // 下拉要特殊处理下(左右padding+border，要多给25)
            if (currentLabel) {
              width = currentLabel.label.length * 12 + 25;
            }
          } else {
            width = dic.label.length * 12 + 5;
          }
          if (dic.label.includes(':')) {
            width = width + 10;
          }
        }
        // 展示在更多里的且是未展开时就不参与计算
        if (dic.showInMore && !this.isOpen) {
          width = 0;
        }
        if (width > labelWidth) {
          labelWidth = width;
        }
      }
      this.labelWidth = `max-width:${labelWidth}px; min-width:${labelWidth}px;`;
    },
    setSearchOption (reset = false) {
      const valueOption = this.value;
      let showMoreBtn = this.showMoreBtn;
      const searchOption = {};
      for (const key in valueOption) {
        const dic = valueOption[key];
        if (!showMoreBtn) {
          showMoreBtn = dic.showInMore;
        }
        if (reset) {
          if (Array.isArray(dic.value)) {
            dic.value = dic.default || [];
          } else {
            dic.value = dic.default || '';
          }
          dic.disabled = false;
        }
        searchOption[key] = BaseSearchModel.deepCopy(dic);
        if (dic.tagName === 'DatePickerRange' || dic.tagName === 'YgRangeInput') {
          // 选择日期的时候要特殊处理一下，因为这个过滤器中会传function，但function被序列化之后就会出错，所以得重新赋值
          searchOption[key].data = dic.data;
        }
      }
      this.getSelectLabelWidth();
      this.searchOption = searchOption;
      this.showMoreBtn = showMoreBtn;
    },
    exclusionInputed (key, item) {
      if (item.exclusions && item.exclusions.length) {
        const searchOption = this.searchOption;
        item.exclusions.forEach(v => {
          if (Array.isArray(searchOption[v].value)) {
            searchOption[v].value = [];
          } else {
            searchOption[v].value = '';
          }
          if (Array.isArray(item.value)) {
            searchOption[v].disabled = !!item.value.some(v => !!v);
          } else {
            searchOption[v].disabled = !!item.value;
          }
        });
      }
    },
    getInfoValue (key) {
      const dic = this.value[key];
      const value = dic.value;
      let str = '';
      if (Array.isArray(value)) {
        if (value.length) {
          value.forEach(v => {
            if (dic.tagName === 'DatePickerRange' && v) {
              str = str + '-' + formatDate(new Date(v));
            } else {
              str = str + '-' + v;
            }
          });
          str = str.slice(1, str.length);
          if (str === '-') {
            str = '全部';
          }
        } else {
          str = '全部';
        }
      } else {
        if (dic.tagName === 'Select') {
          const currentOption = dic.data.find(v => v.value === value);
          str = currentOption ? currentOption.label : '全部';
        } else {
          str = value || '全部';
        }
      }
      return str;
    },
    _clickSearch () {
      for (const key in this.searchOption) {
        const dic = this.searchOption[key];
        // 充电时长区间校验
        if (dic.isNumberRange) {
          if (dic.value[0] || dic.value[1]) {
            const numberRange = dic.numberRange;
            if (dic.value[0] && !dic.value[1]) {
              dic.value[1] = numberRange[1] + '';
            } else if (!dic.value[0] && dic.value[1]) {
              dic.value[0] = numberRange[0] + '';
            }
            let v0 = dic.value[0];
            let v1 = dic.value[1];
            // 校验正整数
            if (!isNumber(v0) || !isNumber(v1)) {
              return this.$Message.warning(dic.label + '可输入正整数或0');
            }
            v0 = parseInt(v0);
            v1 = parseInt(v1);
            // 检验第1个值不大于第二个值
            if (v0 > v1) {
              return this.$Message.warning(dic.label + '取值范围有误');
            }
            // 校验范围
            if (v0 < numberRange[0] || v1 > numberRange[1]) {
              return this.$Message.warning(`${dic.label}取值范围有误：可取范围[${numberRange[0]}~${numberRange[1]}]`);
            }
          }
        }
        if (dic.tagName === 'DatePickerRange') {
          if (dic.value[0] && dic.value[1]) {
            dic.value = [`${formatDate(new Date(dic.value[0]))} 00:00:00`, `${formatDate(new Date(dic.value[1]))} 23:59:59`];
          }
        }
      }
      this.$emit('on-search', { ...this.searchOption });
    },
    _clickBigScreenOpen () {
      this.isOpen = !this.isOpen;
      this.getSelectLabelWidth();
      this.$store.commit('setControlTableResizeCount');
    },
    _clickReset () {
      this.setSearchOption(true);
      this._clickSearch();
    }
  }
};
</script>
