<!--
    Created by 程雨喵'mac on 2023/11/15.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.left-bar-li {
  position: relative;
  padding-left: ~'calc(@{leftBarIconWidth} + @{containerGap} * 3)';
  line-height: @leftBarItemHeight;
  font-size: 14px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  &:hover, &.is-current {
    background-color: @leftBarSelectInnerColor;
    color: @textColorPrimary;
    font-weight: 600;
  }
  &.is-current:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: @themeColor;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>

<template>
  <div
    class="left-bar-li"
    :class="isCurrent ? 'is-current' : ''"
    @click.stop="_clickPush"
  >
    {{ model.meta ? model.meta.title : '' }}
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否是当前选中的头
    isCurrent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  methods: {
    _clickPush () {
      this.$emit('on-click', this.model);
    }
  }
};
</script>
