/**
 * 常用的一些key
 * 外部使用：最好不要直接使用，已挂载到Vue，使用Vue.$appKey使用
 * @date:2022/11/28
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

const GLOBAL_KEY = {
  PERMISSION_EDIT: 'edit', // 操作按钮权限-编辑，与后端需要对应
  PERMISSION_ADD: 'add', // 操作按钮权限-新增，与后端需要对应
  PERMISSION_DETAIL: 'detail', // 操作按钮权限-详情，与后端需要对应
  PERMISSION_SAVE: 'save', // 操作按钮权限-保存，与后端需要对应
  PERMISSION_LIST: 'list', // 操作按钮权限-列表，与后端需要对应
  PERMISSION_UPLOAD: 'upload', // 操作按钮权限-上传，与后端需要对应
  PERMISSION_DOWNLOAD: 'download', // 操作按钮权限-下载，与后端需要对应
  PERMISSION_DEL: 'delete' // 操作按钮权限-删除，与后端需要对应
};

export default GLOBAL_KEY;
