/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 财务管理
 */

import BaseApi from '@/request/base/BaseApi';

class FinanceApiSet {
  constructor () {
    this.subAccountSummary = BaseApi.createModel('分账账单管理-数据汇总', '/opmp/admin/financeManage/subAccountSummary').setMethod(1);
    this.subAccountDetail = BaseApi.createModel('分账账单管理-数据列表', '/opmp/admin/financeManage/subAccountDetail').setMethod(1);
    this.orderSubAccountDetail = BaseApi.createModel('订单分账明细', '/opmp/admin/financeManage/orderSubAccountDetail').setMethod(1);
    this.getOrgRefundList = BaseApi.createModel('退币申请订单详情列表', '/opmp/admin/chargeCoin/getOrgRefund').setMethod(1);
    this.confirmRefund = BaseApi.createModel('确认退款', '/opmp/admin/chargeCoin/confirmRefund').setMethod(1);
    this.returnAccountSummary = BaseApi.createModel('分账订单扣回汇总', '/opmp/admin/financeManage/returnAccountSummary').setMethod(1);
    this.refundAccountDetail = BaseApi.createModel('分账订单扣回-数据明细', '/opmp/admin/financeManage/refundAccountDetail').setMethod(1);
  }
}

export default new FinanceApiSet();
