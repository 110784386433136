/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import apiCode from './code/apiCode';
import appCode from './code/appCode';
import GLOBAL_CONFIG from '@/app.config';
import router from '../../router';

class ResponseObject {
  constructor (api) {
    // BaseApi子类对象
    this.api = api;
    // 错误码
    this.code = '';
    // 错误信息
    this.msg = '请求异常';
    // 返回参数
    this.data = null;
    // 后端实际返回的参数
    this.orgData = null;
    // 是否请求成功，仅用此字段判断
    this.success = false;
  }

  // 私有方法-写入monitor
  __saveInMonitor (monitor) {
    if (!monitor) return;
    if (!GLOBAL_CONFIG.isProd) {
      if (this.api.autoConfig.autoSaveMonitor) {
        monitor.addMonitor(this);
      }
    }
  }

  initWithResponseData (httpResponse, monitor) {
    this.orgData = httpResponse;
    this.code = httpResponse.code;
    this.success = httpResponse.code === apiCode.success;
    this.data = httpResponse.data || httpResponse;
    this.msg = httpResponse.msg || httpResponse.message || '';
    if (!this.success) {
      switch (this.code) {
      case apiCode.tokenPassed: // 无效token
        router.replace({ name: 'login' });
        break;
      case apiCode.noPermission: // 无权限
        // router.replace({ name: 'error_401' });
        break;
      }
    }
    this.__saveInMonitor(monitor);
    return this;
  }

  initWithErrorMessage (appCodeValue, monitor) {
    this.code = appCodeValue;
    this.success = false;
    this.data = {};
    switch (appCodeValue) {
    case appCode.tokenPassed: // 无效token
    case appCode.tokenEmpty:
    case apiCode.tokenExpired: // token状态已过期
      this.msg = '无效token';
      router.replace({ name: 'login' });
      break;
    case apiCode.networkError: // 服务异常
      this.msg = '服务异常';
      break;
    }
    this.__saveInMonitor(monitor);
    return this;
  }

  initWithJSError (msg, monitor) {
    this.code = '-9999';
    this.success = false;
    this.data = {};
    this.msg = msg;
    this.__saveInMonitor(monitor);
    return this;
  }

  // 遍历构造器
  static createModel (api) {
    return new ResponseObject(api);
  }
}

export default ResponseObject;
