/**
 * Created by 程雨喵'mac on 2022/3/7.
 * Copyright © 2022年 云柜-金星晖. All rights reserved.
 * 功能：table中使用，table-colunm基类
 * 外部使用：'@/components/common/table/model/BaseTableColumn.js'
 */

const STR_INDEX = 'index';
const STR_LEFT = 'left';
const STR_CENTER = 'center';

class BaseTableColumn {
  /**
   * 初始化
   * @param dataKey：字段
   * @param title：列表头
   * @param minWidth：最小宽度（默认为80）
   * @param slot：是否是slot(不多解释，看iview的api)
   */
  constructor (dataKey, title, minWidth = 80, slot = false) {
    // table-header中的标题
    this.title = title;
    // 数据中对应的key
    this.key = dataKey;
    // 最小宽度对应的是 iview的table中的minWidth
    this.minWidth = minWidth;
    // 下面是不通过构造函数赋值的
    this.width = null; // iview的table中的width
    this.fixed = null; // iview的table中的fixed
    this.align = 'center'; // iview的table中的align
    this.tooltip = null; // iview的table中的tooltip
    this.render = null; // render函数
    this.slot = null;
    this.className = '';
    this.permission = '';
    if (slot) {
      // 默认为key，也可以自行设置
      this.slot = dataKey;
    }
    if (dataKey === 'index') {
      this.title = '序号';
      this.type = STR_INDEX;
      this.width = 50;
      this.align = STR_CENTER;
    }
  }

  setSlot (slotName) {
    this.slot = slotName;
    return this;
  }

  setSelection () {
    this.type = 'selection';
    return this;
  }

  setWidth (w) {
    this.width = w;
    return this;
  }

  setAlign (align) {
    this.align = align;
    return this;
  }

  // 设置列为固定
  setFixed (fixed = true) { // 这个最好别用，很影响渲染性能
    this.fixed = fixed ? STR_LEFT : null;
    return this;
  }

  setTooltip () { // 这个最好别用，很影响渲染性能
    this.tooltip = true;
    return this;
  }

  setRender (render) {
    this.render = render;
    return this;
  }

  setClassName (className) {
    this.className = className;
    return this;
  }

  /**
   * 展开
   * @param element 组件(import vOpenRow from './view/open-row'), 放到页面中的components: { vOpenRow }，然后再把这个值传进来
   * @returns {BaseTableColumn}
   */
  setExpand (element) {
    this.type = 'expand';
    this.width = 50;
    if (element) {
      this.render = (h, params) => {
        return h(element, {
          props: {
            model: params.row
          }
        });
      };
    }
    return this;
  }

  // 带属性的文字
  setSpan ({ canCopy, canTooltip, canHidePhone, canPrice, canTextUnit, className, tooltipText, tooltipSlot, tooltipWidth }) {
    this.render = (h, { row, index }) => {
      return h('yg-attribute-span', {
        attrs: {
          text: row[this.key],
          canCopy: !!canCopy,
          canTooltip: !!canTooltip,
          canHidePhone: !!canHidePhone,
          canPrice: !!canPrice,
          canTextUnit: canTextUnit || '',
          tooltipText: tooltipText,
          tooltipSlot: tooltipSlot,
          tooltipWidth: tooltipWidth,
          className: className
        }
      }, row[this.key]);
    };
    return this;
  }

  /**
   * [
   *    {label: '下单时间：', labelWidth: 80, key: 'createTime', attr: {canCopy, canTooltip, canHidePhone, canPrice, className...}},
   *    {label: '支付时间：', labelWidth: 80, key: 'payTime', attr: {canCopy, canTooltip, canHidePhone, canPrice, className...}}
   * ]
   * @param list
   */
  setKeyValueList (list, clickCallBack = null) {
    this.render = (h, { row, index }) => {
      const items = [];
      list.forEach(v => {
        const attr = v.attr || {};
        items.push(h('yg-attribute-key-value', {
          props: {
            label: v.label,
            labelWidth: v.labelWidth,
            value: row[v.key],
            canCopy: typeof attr.canCopy === 'function' ? attr.canCopy(row, index, v.key) : !!attr.canCopy,
            canTooltip: typeof attr.canTooltip === 'function' ? attr.canTooltip(row, index, v.key) : !!attr.canTooltip,
            canHidePhone: typeof attr.canHidePhone === 'function' ? attr.canHidePhone(row, index, v.key) : !!attr.canHidePhone,
            canPrice: typeof attr.canPrice === 'function' ? attr.canPrice(row, index, v.key) : !!attr.canPrice,
            canClick: typeof attr.canClick === 'function' ? attr.canClick(row, index, v.key) : !!attr.canClick,
            canTextUnit: typeof attr.canTextUnit === 'function' ? attr.canTextUnit(row, index, v.key) : (attr.canTextUnit || ''),
            tooltipText: attr.tooltipText,
            tooltipSlot: attr.tooltipSlot,
            tooltipWidth: attr.tooltipWidth,
            valueClassName: typeof attr.className === 'function' ? attr.className(row, index, v.key) : (attr.className || '')
          },
          on: {
            'on-click': () => {
              if (clickCallBack && (typeof clickCallBack === 'function')) {
                clickCallBack(row, index, v.key);
              }
            }
          }
        }));
      });
      return items;
    };
    return this;
  }

  /**
   * 列设置为按钮集(可以是一个，可以是多个按钮，入参为数组)
   * @param buttonOptions 是一个数组，格式如下
   * [
   *  // title: 按钮标题, name: 按钮的key，一定要用应为
   *  {title: '删除', name: 'del'}
   * ]
   * @param clickFunction 点击事件
   */
  setButtons (buttonOptions, clickFunction) {
    this.render = (h, { row, index }) => {
      return h('yg-table-buttons', {
        props: {
          items: buttonOptions
        },
        on: {
          'on-click': (e) => {
            // e为buttonOptions数组中的元素
            if (clickFunction && typeof clickFunction === 'function') {
              clickFunction(e, row, index);
            }
          }
        }
      });
    };
    return this;
  }

  /**
   * 可点击的值(非固定按钮)，如果无值就不可点击，如果有值就可以点击，并且会展示下划线。
   * @param filterArr 比如 0会判定为无值，此时就可以把0放进来过滤掉，这样的话 0也可以点击
   * @param clickFunction 点击事件
   * @param obj
   * @returns {BaseTableColumn}
   */
  setValueButton (filterArr = [], clickFunction, obj = {}) {
    this.render = (h, { row, index }) => {
      let canClick = !!row[this.key];
      if (filterArr.length && filterArr.includes(row[this.key])) {
        canClick = true;
      }
      return h('yg-button', {
        props: {
          type: 'text',
          disabled: !canClick,
          phoneNumber: obj.isPhoneNumber ? row[this.key] : ''
        },
        on: {
          click: () => {
            if (clickFunction && typeof clickFunction === 'function') {
              clickFunction(row, index);
            }
          }
        }
      }, canClick ? row[this.key] : '-');
    };
    return this;
  }

  /**
   * 列设置为开关
   */
  setSwitch (changeFunction, permission = '') {
    this.render = (h, { row, index }) => {
      return h('yg-table-switch', {
        props: {
          value: row[this.key],
          permission
        },
        on: {
          'on-change': (e) => {
            // e为buttonOptions数组中的元素
            if (changeFunction && typeof changeFunction === 'function') {
              changeFunction(e, row, index);
            }
          }
        }
      });
    };
    return this;
  }

  /**
   * 设置为状态
   * value: 数据中的值
   * type: 颜色类型（1: 弱色， 2：成功色，3：失败色，4：主题色，5：辅助色）
   * @param options [{ value: 1, type: 1 }]
   * @param placeholderKey 如果status 没找到，就直接展示转义的值
   */
  setStatus (options, placeholderKey) {
    this.render = (h, { row, index }) => {
      const item = options.find(v => v.value === row[this.key]);
      return h('yg-tag-span', {
        attrs: {
          text: row[placeholderKey] || '-',
          type: item ? item.type : 1
        }
      });
    };
    return this;
  }

  // 类方法-遍历构造器
  static initData (dataKey, title, minWidth = 80, slot = false) {
    return new this(dataKey, title, minWidth, slot);
  }
}

export default BaseTableColumn;
