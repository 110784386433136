<!--
    Created by 程雨喵'mac on 2022/11/30.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.__g-page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  font-size: 12px;
  @itemHeight: 26px;
  height: 34px;
  .ivu-page.__g-pager {
    .ivu-page-item, .ivu-page-prev, .ivu-page-next {
      margin-right: 10px;
      border-radius: @borderRadiusMin !important;
      border-color: @lineColorNormal;
      line-height: @itemHeight - 2px;
      min-width: @itemHeight;
      height: @itemHeight;
      &.ivu-page-item-active {
        color: @textColorFlip;
        background-color: @themeColor;
        //border-color: @themeColor;
        a {
          color: @textColorFlip !important;
        }
      }
      &:hover {
        color: @themeColor;
        border-color: @themeColor;
        a {
          color: @themeColor;
        }
      }
    }
    .ivu-page-options-elevator {
      input {
        border-radius: @borderRadiusMin !important;
        border-color: @lineColorNormal;
        height: @itemHeight;
        &:hover {
          border-color: @themeColor;
        }
        &:focus {
          border-color: @themeColor;
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="__g-page"
  >
    <Page
      ref="tablePager"
      :current="page.current"
      :total="page.total"
      :page-size="pageSize"
      :show-total="true"
      :show-elevator="true"
      class-name="__g-pager"
      @on-change="_pageChange"
    />
  </div>
</template>

<script>
export default {
  name: 'YgPage',
  components: {},
  props: {
    /**
     * page相关3件套
     */
    page: {
      type: Object,
      default: () => {
        return { current: 1, total: 0 };
      }
    }
  },
  data () {
    return {};
  },
  computed: {
    pageSize () {
      return parseInt(this.$store.state.user.globalSetting.page);
    }
  },
  methods: {
    _pageChange (page) {
      this.$emit('on-page-change', page);
    }
  }
};
</script>
