<!--
   @date:2022/10/19
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>

-->
<style lang="less">

</style>

<template>
  <Cascader
    :value="currentValue"
    :disabled="disabled"
    :load-data="requestChildren"
    placeholder="请选择"
    change-on-select
    :transfer="true"
    :data="dataList"
    @on-change="_change"
  />
</template>

<script>
import RegionApiSet from '@/request/api/RegionApiSet';
import RegionModel from './model/RegionModel';
import { mapActions } from 'vuex';
export default {
  name: 'YgRegionCascader',
  components: {},
  props: {
    level: {
      type: Number,
      /**
       * 1: 可选择省，市，区，街道
       * 2：可选择省，市，区
       * 3：可选择省，市
       * 4：可选择省
       */
      default: 1
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultValue: [],
      currentValue: [],
      dataList: []
    };
  },
  watch: {
    value (val) {
      this._change(val);
    }
  },
  created () {
    if (this.value.length) {
      this.defaultValue = JSON.parse(JSON.stringify(this.value));
    }
    this.requestProvinceList();
  },
  methods: {
    ...mapActions(['getProvinceList', 'getCityList', 'getAreaList', 'getStreetList']),
    _change (value) {
      // if (value[0] === this.currentValue[0] && value[1] === this.currentValue[1] && value[2] === this.currentValue[2]) return;
      // if (value.length !== 2) {
      //   value = [];
      // }
      if (JSON.stringify(this.currentValue) === JSON.stringify(value)) return;
      this.currentValue = value;
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    requestProvinceList () {
      this.getProvinceList().then(res => {
        this.dataList = RegionModel.initListWithDataList(res, 0, this.level !== 4);
        if (this.defaultValue.length) {
          const provinceItem = this.dataList.find(v => v.value === this.defaultValue[0]);
          if (provinceItem) {
            this.requestDefaultRegion(provinceItem);
          }
        }
      });
    },
    requestDefaultRegion (item) {
      let api = null;
      let needChildren = true;
      if (this.level === 1 && item.type === 2) {
        needChildren = false;
      }
      if (this.level === 2 && item.type === 1) {
        needChildren = false;
      }
      if (this.level === 3 && item.type === 0) {
        needChildren = false;
      }
      if (this.level === 4) {
        needChildren = false;
      }
      switch (item.type) {
      case 0:
        api = RegionApiSet.getCityList;
        break;
      case 1:
        api = RegionApiSet.getAreaList;
        break;
      case 2:
        api = RegionApiSet.getStreetList;
        break;
      }
      api.params.parentCode = item.value;
      this.$http(api).then(res => {
        const { data } = res;
        item.children = RegionModel.initListWithDataList(data, item.type + 1, needChildren);
        if (needChildren) {
          const regionItem = item.children.find(v => v.value === this.defaultValue[item.type + 1]);
          if (regionItem) {
            this.requestDefaultRegion(regionItem);
          } else {
            this._change(this.defaultValue);
          }
        } else {
          this._change(this.defaultValue);
        }
      });
    },
    requestChildren (item, callback) {
      if (item.type === 3 || item.children.length) {
        item.loading = false;
        callback();
        return;
      }
      let needChildren = true;
      if (this.level === 1 && item.type === 2) {
        needChildren = false;
      }
      if (this.level === 2 && item.type === 1) {
        needChildren = false;
      }
      if (this.level === 3 && item.type === 0) {
        needChildren = false;
      }
      if (this.level === 4) {
        needChildren = false;
      }
      item.loading = true;
      switch (item.type) {
      case 0:
        this.getCityList(item.value).then(res => {
          item.children = RegionModel.initListWithDataList(res, item.type + 1, needChildren);
          item.loading = false;
          callback();
        });
        break;
      case 1:
        this.getAreaList(item.value).then(res => {
          item.children = RegionModel.initListWithDataList(res, item.type + 1, needChildren);
          item.loading = false;
          callback();
        });
        break;
      case 2:
        this.getStreetList(item.value).then(res => {
          item.children = RegionModel.initListWithDataList(res, item.type + 1, needChildren);
          item.loading = false;
          callback();
        });
        break;
      }
    }
  }
};
</script>
