// 设备计费数据模型
import BaseModel from '@/libs/base/dataModel/BaseModel';

export default class DeviceRatioModel extends BaseModel {
  constructor () {
    super();
    this.propertyCompanyRatio = 0; // 物业公司分账比例
    this.serviceProviderRatio = 0; // 服务商分账比例
    this.agentsRatio = 0; // 代理商分账比例

    this.chargeCoinPCRatio = 0; // 充电币-物业公司分账比例
    this.chargeCoinSPRatio = 0; // 充电币-服务商分账比例
    this.chargeCoinARatio = 0; // 充电币-代理商分账比例
    // 前端展示-要除以100
    this.h5PropertyCompanyRatio = 0;
    this.h5ServiceProviderRatio = 0;
    this.h5ChargeCoinPCRatio = 0;
    this.h5ChargeCoinSPRatio = 0;
  }

  initData (resData) {
    super.initData(resData);
    // this.h5PropertyCompanyRatio = parseInt(this.propertyCompanyRatio || 0);
    // this.h5ServiceProviderRatio = parseInt(this.serviceProviderRatio || 0);
    this.h5PropertyCompanyRatio = parseInt(this.propertyCompanyRatio / 100);
    this.h5ServiceProviderRatio = parseInt(this.serviceProviderRatio / 100);
    this.h5ChargeCoinPCRatio = parseInt(this.chargeCoinPCRatio / 100);
    this.h5ChargeCoinSPRatio = parseInt(this.chargeCoinSPRatio / 100);
    this.agentsRatio = parseInt(this.agentsRatio / 100);
    this.chargeCoinARatio = parseInt(this.chargeCoinARatio / 100);
    return this;
  }
}
