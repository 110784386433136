<!--
    Created by 王丽莎 on 2024/10/16.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：充电桩管理 - 设备离线次数列表
    功能概述：设备离线记录列表
-->
<style lang="less">
.offline-record-content {
  padding: @containerGap;
  border-radius: @borderRadiusMin;
  height: 100%;
  background-color: @backColorStrong;
  .ivu-table-cell {
    position: relative;
    .offline-start-time {
      position: relative;
      .tag-box {
        position: absolute;
        top: 50%;
        right: 0;
        padding: 0 @containerGap * 0.5;
        height: 14px;
        line-height: 13px;
        border-radius: 0 7px 7px 7px;
        font-size: 10px;
        background: @errorColor;
        color: white;
        font-weight: 600;
        transform: translateY(-50%);
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    width="800"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <p slot="title">
      <span>离线记录</span>
    </p>
    <div
      slot="content"
      class="offline-record-content"
    >
      <div class="padding-primary-top">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import { DeviceOfflineRecordModel } from '@/pages/050__deviceManager/chargingPileList/model/DeviceOfflineRecordModel';
export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('', '离线开始时间').setRender((h, { row, index }) => {
          const div = h('div', {
            attrs: {
              class: 'offline-start-time'
            }
          }, [
            h('div', {
              attrs: {
                class: 'tag-time'
              }
            }, row.createTime),
            row.today ? h('div', {
              attrs: {
                class: 'tag-box'
              }
            }, '今日') : null
          ]);
          return div;
        }).setWidth(220),
        BaseTableColumn.initData('offlineEndTime', '离线结束时间').setSpan({}),
        BaseTableColumn.initData('offlineDuration', '离线时长(分钟)').setSpan({})
      ]
    };
  },
  created () {
    console.log(this.model);
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    onClose () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求离线记录
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DeviceApiSet.offlineList;
      api.params = {
        deviceNumber: this.model.deviceNumber,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      console.log(api);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = DeviceOfflineRecordModel.initListWithDataList(resData.rows);
        // const list = DeviceOfflineRecordModel.createFakeDataList();
        // this.tableData = DeviceOfflineRecordModel.initListWithDataList(list);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
