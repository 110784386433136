/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 小区相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class CommunityApiSet {
  constructor () {
    this.communityList = BaseApi.createModel('获取小区列表', '/opmp/community/list').setMethod(0);
    this.communityChangeStatus = BaseApi.createModel('启用禁用小区', '/opmp/community/changeStatus').setMethod(2);
    // 新增不传小区id，编辑传小区id
    this.communityAdd = BaseApi.createModel('新增/编辑小区信息', '/opmp/community/add');
    this.communityConcatSet = BaseApi.createModel('设置小区-联系人', '/opmp/community/contact/set');
    this.communityAddressSet = BaseApi.createModel('设置小区-地址', '/opmp/community/address/set');
    this.communityInfo = BaseApi.createModel('获取小区信息', '/opmp/community/info').setMethod(0, false);
    this.communityOrg = BaseApi.createModel('获取小区-业组织信息', '/opmp/community/org').setMethod(0, false);
    this.communityBindList = BaseApi.createModel('全量获取小区', '/opmp/community/bind/list').setMethod(0);
    this.getCommunityDetail = BaseApi.createModel('获取小区物业组织信息', '/opmp/community/org').setMethod(0, false);
    this.fictitiousPowerSet = BaseApi.createModel('设置小区-结算功率虚位设置', '/opmp/community/charge/param/set').setMethod(1);
    this.swipeCardNumSet = BaseApi.createModel('设置小区-刷卡扣充电币设置', '/opmp/community/device/param/set').setMethod(1);
    this.getOperationConfig = BaseApi.createModel('获取小区设备配置信息', '/opmp/community/operationConfig').setMethod(0, false);
    this.settleInfoSet = BaseApi.createModel('设置结算信息', '/opmp/community/settleInfo/set');
  }
}

export default new CommunityApiSet();
