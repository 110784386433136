<!--
    Created by 程雨喵'mac on 2023/11/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：属性文字，可支持tooltip，可支持copy，可支持手机号隐藏
-->
<style lang="less">
.__c-span-tag {
  .display-flex();
  .__c-span-tag-status {
    min-width: 70px;
    .display-flex();
    //padding: 0 16px;
    padding: 0 10px;
    height: 24px;
    //height: @formHeight;
    border-radius: @borderRadiusMin;
    color: @textColorFlip;
    font-size: 12px;
    line-height: 12px;
    background-color: @buttonColorDisabled;
    font-weight: 600;
    cursor: default;
    &-1 { background-color: @buttonColorDisabled; }
    &-2 { background-color: @finishColor; }
    &-3 { background-color: @errorColor; }
    &-4 { background-color: @themeColor; }
    &-5 { background-color: @adornColor; }
  }
}
</style>
<template>
  <div
    class="__c-span-tag "
  >
    <span
      class="__c-span-tag-status"
      :class="tagStatusClass"
    >{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'YgTagSpan',
  components: {},
  props: {
    text: {
      type: [String, Number],
      default: ''
    },
    /**
     * 1: 弱色
     * 2：成功色
     * 3：失败色
     * 4：主题色
     * 5：辅助色
     */
    type: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {};
  },
  computed: {
    tagStatusClass () {
      return `__c-span-tag-status-${this.type}`;
    }
  }
};
</script>
