/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 省市区接口
 */

import BaseApi from '@/request/base/BaseApi';

class RegionApiSet {
  constructor () {
    this.getProvinceList = BaseApi.createModel('省列表查询', '/opmp/region/getChildRegion', true);
    this.getCityList = BaseApi.createModel('市列表查询', '/opmp/region/getChildRegion', true);
    this.getAreaList = BaseApi.createModel('区列表查询', '/opmp/region/getChildRegion', true);
    this.getStreetList = BaseApi.createModel('街道列表查询', '/opmp/region/getChildRegion', true);
    // 省市区全量
    this.getAllRegion = BaseApi.createModel('省市区', '/opmp/region/getAllRegion', true);
    this.getAllRegion.autoConfig.autoSaveMonitor = false;
  }
}

export default new RegionApiSet();
