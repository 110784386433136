/**
 * Created by 程雨喵'mac on 2023/12/13.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-price-text, value: data-value="金额"
 * 功能：将指定数字转为小数点一下展示2位 + 前面￥
 */
import GLOBAL_CONFIG from '@/app.config';
function inserted (el, binding, vnode) {
  // 1.查看功能是否开启
  if (!binding.value) return;
  // 2.查看复制的内容
  let text = el.dataset.value;
  if (text) {
    if (typeof text === 'string') {
      text = Number(text);
    }
    if (Number.isNaN(text)) {
      text = '-';
    } else {
      if (GLOBAL_CONFIG.moneyUnit === '元') {
        text = `${text.toFixed(2)}${GLOBAL_CONFIG.moneyUnit}`;
      } else {
        text = `${GLOBAL_CONFIG.moneyUnit}${text.toFixed(2)}`;
      }
    }
  } else {
    text = '-';
  }
  el.innerText = text;
}
function update (el, binding, vnode) {
  inserted(el, binding, vnode);
}
export default {
  install (Vue) {
    Vue.directive('price-text', {
      inserted,
      update
    });
  }
};
