import Vue from 'vue';
import copyText from './package/copyText';
import hidePhone from './package/hidePhone';
import price from './package/price';
import permission from './package/permission';
import transferDom from './package/transferDom';
import textUnit from './package/textUnit';

Vue.use(copyText);
Vue.use(price);
Vue.use(hidePhone);
Vue.use(permission);
Vue.use(transferDom);
Vue.use(textUnit);
