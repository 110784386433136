import BaseModel from '@/libs/base/dataModel/BaseModel';
import { formatDateTime, sortBy } from '@/libs/utils';

// 充电订单列表
export class ChargingBusinessOrderListModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 充电订单编码
    this.orderNo = '';
    // 充电状态:0->待充电、1->充电中、2->已结束、3->已取消、4->充电失败
    this.chargeStatus = '';
    // 充电状态描述
    this.chargeStatusDesc = '';
    // 充电桩端口号
    this.displayCode = '';
    // 结算功率
    this.power = '';
    // 充电类型:0->默认状态、1->小程序充电、2->刷卡充电 3->小橙充电 4->小橙刷卡
    this.chargeType = '';
    // 充电业务类型描述 启动方式
    this.chargeTypeDesc = '';
    // 联网充电卡号码
    this.chargeNumber = '';
    // 订单支付金额
    this.payMoney = '';
    // 订单实收金额
    this.realMoney = '';
    // 消费金额
    this.consumeMoney = '';
    // 退款金额
    this.refundMoney = '';
    // 预计充电时长
    this.expectedChargeDuration = '';
    // 实际充电时长
    this.realChargeDuration = '';
    // 开始时间
    this.startTime = '';
    this.startTimeStr = '';
    // 预计结束时间
    this.expectedEndTime = '';
    this.expectedEndTimeStr = '';
    // 实际结束时间
    this.realEndTime = '';
    this.realEndTimeStr = '';
    // 取消时间
    this.cancelTime = '';
    this.cancelTimeStr = '';
    // 设备编码
    this.deviceNumber = '';
    // 点位名称
    this.siteName = '';
    // 小区名称
    this.blockName = '';
    // 所属省市地
    this.addressName = '';
    // 支付订单编码
    this.payTradeNo = '';
    // 用户
    this.userTel = '';
    // 省
    this.provinceName = '';
    // 市
    this.cityName = '';
    // 区
    this.districtName = '';
    // imei号
    this.imei = '';
    // 设备名称
    this.deviceName = '';
    // 设备型号名称
    this.deviceModelName = '';
    // 数据来源0->内部、1->小橙
    this.sourceType = 0;
    // 数据来源1->内部、2->小橙
    this.sourceTypeDesc = '';
    // 支付渠道交易单号
    this.gwTradeNo = '';
    // 支付渠道 (2微信，3支付宝，4余额)
    this.payChannel = '';
    this.payChannelName = '';
    // 人工退款备注
    this.notes = '';
    // 人工退款金额
    this.manualRefundMoney = '';
    // 自动退款金额
    this.autoRefundMoney = '';
    // 可退款金额
    this.canRefundMoney = '';
    // 人工退款是否可退（0：否，1可人工退
    this.canManualRefund = '';
    // 结算电量
    this.damageAfterChargeCapacity = '';
    // 充电电量
    this.chargeCapacity = '';
    // 计费类别（1：功率 2：电量 3: 时长）
    this.category = '';
    // 订单计费类型描述
    this.categoryDesc = '';
    // 结算时长/结算功率/结算电量
    this.settleInfo = '';
    // 结束原因编码
    this.endReasonCode = '';
    // 结束原因描述
    this.endReasonDesc = '';
    // 前端专用 ==========>
    // 退款金额总和
    this.h5RefundTotal = '';
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    this.power = this.power < 0 ? '空载' : this.power;
    this.manualRefundMoney = this.manualRefundMoney - 0;
    this.autoRefundMoney = this.autoRefundMoney - 0;
    this.canRefundMoney = this.canRefundMoney - 0;
    this.h5RefundTotal = this.manualRefundMoney + this.autoRefundMoney;
    this.consumeMoney = this.consumeMoney + '';
    this.power = this.power + '';
    return this;
  }

  /**
   * 数据具体校验-操作退款
   * @returns {*}
   */
  checkValidSubmit () {
    // 专项校验
    if (!this.notes) {
      return BaseModel.getValidRes(false, '请输入备注');
    }
    return BaseModel.getValidRes(true, '', {
      orderNo: this.orderNo,
      notes: this.notes
    });
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }
}

// 用户信息
class OrderUserInfo extends BaseModel {
  constructor () {
    super();
    this.userId = null; // 用户id
    this.userTel = null; // 用户手机号
    this.startupType = null; // 启动方式
    this.chargeNumber = ''; // 充电卡号
  }

  static createFakeData () {
    return {
      userId: BaseModel.getFakeNumber(99999, 0),
      userTel: 13900002222,
      startupType: '刷卡',
      chargeNumber: BaseModel.getFakeNumber(9999999, 0) + ''
    };
  }
}

// 充电订单信息
class OrderInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.orderNo = null; // 充电订单编码
    this.chargeStatus = null; // 充电状态
    this.chargeStatusDesc = ''; // 充电状态描述
    this.endReason = ''; // 结束原因
    this.displayCode = ''; // 端口
    this.power = ''; // 结算功率
    this.road = ''; // 用电量
    this.payMoney = ''; // 订单支付金额
    this.realMoney = ''; // 订单实收金额
    this.canAllocateMoney = ''; // 可分账金额
    this.orderCostMoney = ''; // 订单消费金额
    this.totalRefundMoney = ''; // 累计退款金额
    this.category = ''; // 计费类别（1：功率 2：电量 3；时长）
    this.categoryDesc = ''; // 订单计费类型描述
    this.chargePrice = ''; // 电费（电量充电订单才有）
    this.servicePrice = ''; // 服务费（电量充电订单才有）
    this.h5ConsumptionDetail = ''; // 消费明细
  }

  initData (resData) {
    super.initData(resData);
    if (this.category === '2') {
      this.h5ConsumptionDetail = `${this.chargePrice ? '电费' + this.chargePrice : ''}${this.servicePrice ? '+服务费' + this.servicePrice : ''}`;
    }
    return this;
  }

  static createFakeData () {
    return {
      orderNo: BaseModel.getFakeNumber(99999, 0),
      chargeStatus: 1,
      chargeStatusDesc: '充电结束',
      endReason: BaseModel.getFakeTitle(100),
      displayCode: '2',
      power: '6',
      road: '0.8',
      payMoney: BaseModel.getFakeNumber(10),
      realMoney: BaseModel.getFakeNumber(10),
      canAllocateMoney: BaseModel.getFakeNumber(10),
      orderCostMoney: BaseModel.getFakeNumber(10)
    };
  }
}

// 电量信息
class OrderCapacityInfo extends BaseModel {
  constructor () {
    super();
    // 结算总电量
    this.damageAfterChargeCapacity = '';
    // 设备总电量
    this.road = '';
    // 计算总电量
    this.chargeCapacity = '';
  }
}

// 充电时间
class OrderTimeInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.chargedTime = ''; // 已充电时长
    this.remainTime = ''; // 剩余充电时长
    this.startTime = ''; // 开始时间
    this.expectedEndTime = ''; // 预计结束时间
    this.realEndTime = ''; // 实际结束时间
  }

  static createFakeData () {
    return {
      chargedTime: BaseModel.getFakeNumber(1000, 0),
      remainTime: '2',
      startTime: '2024-07-15 10:00:00',
      expectedEndTime: '2024-07-15 10:00:00',
      realEndTime: '2024-07-15 10:00:00'
    };
  }
}

// 付款信息
class OrderPayInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.amount = ''; // 支付金额
    this.payStatus = ''; // 支付状态
    this.payTradeNo = ''; // 支付订单编码
    this.channelName = ''; // 支付渠道
    this.channelId = null; // 支付渠道数字
    this.orderTime = ''; // 支付时间
    this.gwTradeNo = ''; // 交易渠道订
    this.payServiceRate = ''; // 渠道服务费
  }

  static createFakeData () {
    return {
      amount: BaseModel.getFakeNumber(5),
      payStatus: '1',
      payTradeNo: BaseModel.getFakeNumber(99999999, 0) + '',
      channelName: '微信支付',
      channelId: 2,
      orderTime: '2024-07-15 10:00:00',
      gwTradeNo: BaseModel.getFakeNumber(99999999, 0) + '',
      payServiceRate: '0.6'
    };
  }
}

// 退款信息
class OrderRefundInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.refundMoney = ''; // 退款金额
    this.payStatus = ''; // 退款状态
    this.outTradeNo = ''; // 退款订单编码
    this.refundType = ''; // 退款交易类型
    this.channelName = ''; // 退款渠道
    this.orderTime = ''; // 退款时间
    this.gwTradeNo = ''; // 凭证号
    this.sourceResidueAmount = ''; // 源支付订单退款后余额
    this.refundBizType = ''; // 退款业务类型
    this.refundOperator = ''; // 退款操作人
    this.manualRefundMoney = ''; // 人工退款金额
    this.manualPayStatus = ''; // 人工退款状态
    this.manualOutTradeNo = ''; // 人工退款订单编码
    this.manualRefundType = ''; // 人工退款交易类型
    this.manualChannelName = ''; // 人工退款渠道
    this.manualOrderTime = ''; // 人工退款时间
    this.manualSourceResidueAmount = ''; // 人工源支付订单退款后余额
    this.manualRefundBizType = ''; // 人工退款业务类型
    this.manualOperator = ''; // 人工操作人信息
    this.manualRemark = ''; // 人工退款备注
  }

  static createFakeData () {
    return {
      refundMoney: BaseModel.getFakeNumber(5),
      payStatus: '1',
      outTradeNo: BaseModel.getFakeNumber(99999999, 0) + '',
      refundType: '1',
      channelName: '微信支付',
      orderTime: '2024-07-15 10:00:00',
      gwTradeNo: BaseModel.getFakeNumber(99999999, 0) + '',
      sourceResidueAmount: '0.6'
    };
  }
}

// 分账信息
class OrderAllocateInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.spAllocateMoney = null; // 服务商分账金额
    this.pmAllocateMoney = null; // 物业公司分账金额
    this.agentAllocateMoney = null; // 代理商分账金额
  }

  static createFakeData () {
    return {
      spAllocateMoney: BaseModel.getFakeNumber(5),
      pmAllocateMoney: BaseModel.getFakeNumber(5),
      agentAllocateMoney: BaseModel.getFakeNumber(5)
    };
  }
}

// 设备信息
class OrderDeviceInfo extends BaseModel {
  constructor () {
    super();
    this._numChangeToString = true;
    this.deviceNumber = ''; // 设备编码
    this.name = ''; // 设备名称
    this.imei = '';
    this.addressName = ''; // 省市区
    this.communityName = ''; // 小区名称
    this.siteName = ''; // 点位名称
    this.deviceModel = ''; // 设备型号名称
    this.deviceProviderName = ''; // 设备商名称
    this.portMaxPower = ''; // 端口最大功率
    this.emptyLoadRefundTime = ''; // 空载退款时间
    this.cardReduceChargeCoinNum = ''; // 刷卡扣充电币
    this.operationType = ''; // 运营类型
    this.contactName = ''; // 运维服务联系人
    this.contactMobile = ''; // 运维服务手机号
    this.officeName = ''; // 办事处名称
    this.directorName = ''; // 办事处总监姓名
    this.directorTel = ''; // 办事处总监手机
    this.freeConfig = ''; // 6分钟内免费充电:0->关闭、1->开启
    this.fictitiousPower = ''; // 结算功率虚位(瓦)
    this.maxChargingRatio = ''; // 最大充电比例
    this.lineLossRatio = ''; // 线损比例
    this.minCostCapacity = ''; // 最低消费电量(度)
    // 前端自用
    this.h5FreeConfig = ''; // 6分钟内免费充电:0->关闭、1->开启
    this.h5LineLossRatio = ''; // 线损比例
    this.h5MaxChargingRatio = ''; // 最大充电比例
    this.h5FictitiousPower = ''; // 结算功率虚位(瓦)
  }

  initData (resData) {
    super.initData(resData);
    this.h5FreeConfig = this.freeConfig === '0' ? '关闭' : '开启';
    this.h5LineLossRatio = this.lineLossRatio ? '已开启(' + this.lineLossRatio + '%)' : '关闭';
    this.h5MaxChargingRatio = this.maxChargingRatio ? '已开启(' + this.maxChargingRatio + '%)' : '关闭';
    this.h5FictitiousPower = this.fictitiousPower || this.fictitiousPower === 0 ? '各档结束功率加' + this.fictitiousPower + '瓦' : this.fictitiousPower;
    return this;
  }

  static createFakeData () {
    return {
      deviceNumber: '900001',
      name: BaseModel.getFakeTitle(5),
      imei: BaseModel.getFakeNumber(999999, 0) + '',
      addressName: BaseModel.getFakeTitle(5),
      communityName: BaseModel.getFakeTitle(5),
      siteName: BaseModel.getFakeTitle(5),
      deviceModel: BaseModel.getFakeTitle(5),
      deviceProviderName: BaseModel.getFakeTitle(5),
      portMaxPower: BaseModel.getFakeNumber(999, 0) + '',
      emptyLoadRefundTime: BaseModel.getFakeNumber(999, 0) + '',
      cardReduceChargeCoinNum: BaseModel.getFakeNumber(5, 0) + '',
      operationType: '自营',
      contactName: BaseModel.getFakeTitle(5),
      contactMobile: '13900000002',
      officeName: BaseModel.getFakeTitle(5),
      directorName: BaseModel.getFakeTitle(5),
      directorTel: '13900000002'
    };
  }
}

// 订单功率列表
class OrderPowerInfo extends BaseModel {
  constructor () {
    super();
    // x轴
    this.xList = [];
    // y轴
    this.yList = [];
    this.orgList = [];
  }

  static initListWithData (dataList) {
    const model = new this();
    model.orgList = dataList;
    const len = dataList.length;
    // 最后上一次的power
    let lastPower = '0';
    for (let i = 0; i < len; i++) {
      const data = dataList[i];
      const tArr = data.time.split(' ')[1];
      const t1Arr = tArr.split(':');
      model.xList.push(t1Arr[0] + ':' + t1Arr[1]);
      if (data.isPower) {
        lastPower = data.power;
      }
      if (data.isElectric) {
        data.power = lastPower;
      }
      model.yList.push(data.power);
    }
    return model;
  }

  static createFakeData () {
    const list = [];
    const nowDate = (new Date()).getTime();
    const min3 = 60 * 3 * 1000;
    for (let i = 0; i < 200; i++) {
      const dic = {
        time: formatDateTime(new Date(nowDate + min3 * i)),
        differenceOfTime: i * 3,
        power: (500 - (i < 150 ? (i < 100 ? 0 : i) : i * 2.5))
      };
      list.push(dic);
    }
    return list;
  }
}

// 订单时段电量列表（电量计费模式下有）
class OrderElectricityInfo extends BaseModel {
  constructor () {
    super();
    // x轴
    this.xList = [];
    // y轴(electricity：电量，periodCapacity：时段电量)
    this.yList = [];
    this.yList2 = [];
    this.orgList = [];
  }

  static initListWithData (dataList) {
    const model = new this();
    const len = dataList.length;
    // 最后上一次的时间
    let lastTime = '';
    for (let i = 0; i < len; i++) {
      const data = dataList[i];
      const tArr = data.time.split(' ')[1];
      const t1Arr = tArr.split(':');
      const xTime = t1Arr[0] + ':' + t1Arr[1];
      if (!lastTime) {
        lastTime = xTime;
      }
      model.xList.push(xTime);
      if (data.isPower) {
        data.electricity = 0;
        data.periodCapacity = 0;
      }
      if (data.isElectric) {
        data.timeRange = lastTime + '~' + xTime;
        lastTime = xTime;
      }
      model.yList.push(data.electricity);
      model.yList2.push(data.periodCapacity);
    }
    model.orgList = dataList;
    return model;
  }

  static createFakeData () {
    const list = [];
    const nowDate = (new Date()).getTime();
    const min3 = 60 * 3 * 1000;
    for (let i = 0; i < 200; i++) {
      const dic = {
        time: formatDateTime(new Date(nowDate + min3 * i)),
        differenceOfTime: i * 3,
        power: (500 - (i < 150 ? (i < 100 ? 0 : i) : i * 2.5))
      };
      list.push(dic);
    }
    return list;
  }
}

// 订单收费规则
class OrderDeviceRuleInfos extends BaseModel {
  constructor () {
    super();
    this.level = 0; // 计费档
    this.category = ''; // 计费类别（1：功率 2：时段）
    this.roadRange = ''; // 计费范围（-号分割范围）
    this.chargeDuration = 0; // 充电时长
    this.price = 0; // 计费单价
    this.chargePrice = 0; // 电费单价,单位：元
    this.servicePrice = 0; // 服务费单价,单位：元
    // 前端独有
    this.h5RangeMin = null;
    this.h5RangeMax = null;
  }

  initData (resData) {
    super.initData(resData);
    if (this.roadRange) {
      const rArr = this.roadRange.split(OrderDeviceRuleInfos.rangeSplitUnit);
      // console.log(typeof this.category);
      if (this.category === 1) {
        // 按功率
        this.h5RangeMin = parseInt(rArr[0]);
        this.h5RangeMax = rArr[1] ? parseInt(rArr[1]) : 0;
      } else {
        // 按电量
        this.h5RangeMin = rArr[0] || '';
        this.h5RangeMax = rArr[1] || '';
      }
    }
    return this;
  }

  static rangeSplitUnit = '-';
}

// 订单结算配置
class OrderChargeConfigInfo extends BaseModel {
  constructor () {
    super();
    this.fictitiousPower = ''; // 结算功率虚位(瓦)
    this.freeConfig = ''; // 6分钟内免费充电:0->关闭、1->开启
    this.maxChargingRatio = ''; // 最大充电比例
    this.lineLossRatio = ''; // 线损比例
    this.minCostCapacity = ''; // 最低消费电量(度)
    this.orderMaxPower = ''; // 订单最大功率(瓦)
    // 前端自用
    this.h5FreeConfig = ''; // 6分钟内免费充电:0->关闭、1->开启
    this.h5LineLossRatio = ''; // 线损比例
    this.h5MaxChargingRatio = ''; // 最大充电比例
    this.h5FictitiousPower = ''; // 结算功率虚位(瓦)
    this.h5OrderMaxPower = ''; // 订单最大功率(瓦)
  }

  initData (resData) {
    super.initData(resData);
    this.h5FreeConfig = this.freeConfig === 0 ? '关闭' : '开启';
    this.h5LineLossRatio = this.lineLossRatio ? '已开启(' + this.lineLossRatio + '%)' : '关闭';
    this.h5MaxChargingRatio = this.maxChargingRatio ? '已开启(' + this.maxChargingRatio + '%)' : '关闭';
    this.h5FictitiousPower = this.fictitiousPower || this.fictitiousPower === 0 ? '各档结束功率加' + this.fictitiousPower + '瓦' : this.fictitiousPower;
    this.h5OrderMaxPower = this.orderMaxPower || this.orderMaxPower === 0 ? '已开启(' + this.orderMaxPower + '瓦)' : '关闭';
    return this;
  }
}

// 订单结算信息
class OrderSettleInfo extends BaseModel {
  constructor () {
    super();
    this.category = ''; // 计费类别（1：功率 2：电量 3: 时长）
    this.categoryDesc = ''; // 计费类别（1：功率 2：电量 3: 时长）
    this.power = ''; // 结算功率（category = 1 时有值）
    this.damageAfterChargeCapacity = ''; // 结算总电量（category = 2 时有值）
    this.settleTime = ''; // 结算时长（category = 3 时有值）
  }
}

// 充电订单详情
export class ChargingBusinessOrderDetailModel extends BaseModel {
  constructor () {
    super();
    this.userInfo = null; // 用户信息(OrderUserInfo)
    this.orderInfo = null; // 充电订单信息(OrderInfo)
    this.orderTimeInfo = null; // 充电时间
    this.payInfo = null; // 付款信息
    this.refundInfo = null; // 退款信息
    this.allocateInfo = null; // 分账信息
    this.deviceInfo = null; // 设备信息
    this.deviceRuleInfos = []; // 计费规则
    this.powerInfos = {}; // 订单功率列表
    this.capacityInfo = null; // 订单电量信息
    this.electricityInfos = {}; // 订单时段电量列表（电量计费模式下有）
    this.orderChargeConfigInfo = null; // 订单结算配置
    this.orderSettleInfo = null; // 结算信息
  }

  initData (resData) {
    this.userInfo = OrderUserInfo.initModelWithData(resData.userInfo);
    this.orderInfo = OrderInfo.initModelWithData(resData.orderInfo);
    this.orderTimeInfo = OrderTimeInfo.initModelWithData(resData.orderTimeInfo);
    this.payInfo = OrderPayInfo.initModelWithData(resData.payInfo);
    this.refundInfo = OrderRefundInfo.initModelWithData(resData.refundInfo);
    this.allocateInfo = OrderAllocateInfo.initModelWithData(resData.allocateInfo);
    this.deviceInfo = OrderDeviceInfo.initModelWithData(resData.deviceInfo);
    this.deviceRuleInfos = OrderDeviceRuleInfos.initListWithDataList(resData.deviceRuleInfos);
    this.capacityInfo = OrderCapacityInfo.initModelWithData(resData.capacityInfo);
    this.orderChargeConfigInfo = OrderChargeConfigInfo.initModelWithData(resData.orderChargeConfigInfo);
    this.orderSettleInfo = OrderSettleInfo.initModelWithData(resData.settleInfo);
    return this;
  }

  static createFakeData () {
    return {
      userInfo: OrderUserInfo.createFakeData(),
      orderInfo: OrderInfo.createFakeData(),
      orderTimeInfo: OrderTimeInfo.createFakeData(),
      payInfo: OrderPayInfo.createFakeData(),
      refundInfo: OrderRefundInfo.createFakeData(),
      allocateInfo: OrderAllocateInfo.createFakeData(),
      deviceInfo: OrderDeviceInfo.createFakeData(),
      capacityInfo: OrderCapacityInfo.createFakeData(),
      orderChargeConfigInfo: OrderChargeConfigInfo.createFakeData()
    };
  }
}

export class ChargingBusinessOrderChartsModel extends BaseModel {
  constructor () {
    super();
    this.orderInfo = null; // 充电订单信息(OrderInfo)
    this.powerInfos = {}; // 订单功率列表
    this.electricityInfos = null; // 订单时段电量列表（电量计费模式下有）
  }

  initData (resData) {
    // 功率图与电量图X轴合并
    const eleList = resData.electricityInfo;
    let hasEletric = false;
    const powerList = resData.powerInfos;
    let mergeList = [];
    if (Array.isArray(powerList) && powerList.length) {
      powerList.forEach(v => {
        v.isPower = true;
        mergeList.push(v);
      });
    }
    if (Array.isArray(eleList) && eleList.length) {
      hasEletric = true;
      eleList.forEach(v => {
        v.isElectric = true;
        mergeList.push(v);
      });
    }
    // 按时间排序
    mergeList = mergeList.sort(sortBy('time', 1));
    // 功率图
    this.powerInfos = OrderPowerInfo.initListWithData(JSON.parse(JSON.stringify(mergeList)));
    // 电量图
    if (hasEletric) {
      this.electricityInfos = OrderElectricityInfo.initListWithData(mergeList);
    }
    return this;
  }
}
