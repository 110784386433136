import BaseModel from '@/libs/base/dataModel/BaseModel';

export class DeductedDataModel extends BaseModel {
  constructor () {
    super();
    // 用户手机号
    this.tel = '';
    // 金额
    this.payMoney = '';
    // 用户金额记录表ID
    this.moneyRecordId = '';
    // 展示扣减充电币按钮（0-不展示 1-展示）
    this.showCoinReduce = '';
    // 展示扣减记录按钮（0-不展示 1-展示）
    this.showCoinRecord = '';
    // 备注
    this.remark = '';
    // 扣减数量
    this.adjustValue = '';
    // 操作时间
    this.createTime = '';
    // 操作人
    this.createBy = '';
    // 交易流水号
    this.transSerialNo = '';
    // 前端专用 ==========>
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  /**
   * 数据具体校验-扣减充电币
   * @returns {*}
   */
  checkValidSubmit () {
    // 专项校验
    if (!this.remark) {
      return BaseModel.getValidRes(false, '请输入备注');
    }
    return BaseModel.getValidRes(true, '', {
      moneyRecordId: this.moneyRecordId,
      transSerialNo: this.transSerialNo,
      tel: this.tel,
      remark: this.remark
    });
  }

  static createAdditionModel (data) {
    const model = new this();
    model.tel = data.tel;
    model.payMoney = data.payMoney;
    model.moneyRecordId = data.moneyRecordId;
    model.transSerialNo = data.transSerialNo;
    return model;
  }

  static createFakeData () {
    const dic = {
      createBy: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
      tel: '13904288001',
      createTime: '2024-05-04',
      payMoney: parseFloat((Math.random() * 99999).toFixed(2)),
      adjustValue: parseFloat((Math.random() * 30000).toFixed(2)),
      remark: BaseModel.getFakeTitle(Math.ceil(Math.random() * 60))
    };
    return dic;
  }
}
