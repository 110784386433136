<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：设备管理-充电桩管理
-->
<style lang="less">
</style>

<template>
  <section
    id="charging-pile-page"
  >
    <!-- 查询条件 -->
    <div v-permission="'system:device:query'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
      />
    </div>
    <div
      v-permission="'system:device:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
        @on-row-expand="mixinTableOnRowExpand"
      />
    </div>
    <!--弹窗：提交-->
    <v-submit-setting
      v-if="!!dialog.settingModel"
      :model="dialog.settingModel"
      :index="dialog.settingIndex"
      @on-close="onCloseSubmit"
    />
    <!--弹窗：端口-->
    <v-port
      v-if="dialog.portModel"
      :model="dialog.portModel"
      @on-close="dialog.portModel = null"
    />
    <!--弹窗：二维码-->
    <v-qrcode
      v-if="dialog.qrCodeModel"
      :model="dialog.qrCodeModel"
      @on-close="dialog.qrCodeModel = null"
    />
    <!--高级参数-->
    <v-advanced-params
      v-if="dialog.advancedModel"
      :model="dialog.advancedModel"
      @on-close="dialog.advancedModel = null"
    />
    <!--离线记录-->
    <v-offline-record
      v-if="dialog.deviceOfflineModel"
      :model="dialog.deviceOfflineModel"
      @on-close="dialog.deviceOfflineModel = null"
    />
    <!--修改更多信息-->
    <v-other-setting
      v-if="dialog.otherSettingModel"
      :model="dialog.otherSettingModel"
      :index="dialog.otherSettingIndex"
      @on-close="onCloseOtherEdit"
    />
  </section>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import { DeviceListModel, DeviceDetailModel } from './model/DeviceDataModel';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import ColumnMixin from './mixin/tableColumn';
import vSubmitSetting from './view/setting/index.vue';
import vPort from './view/port.vue';
import vQrcode from './view/qrcode.vue';
import vAdvancedParams from './view/advancedParams/index.vue';
import vOfflineRecord from './view/offlineRecord.vue';
import vOtherSetting from './view/otherSetting/index.vue';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';

export default {
  components: { vSubmitSetting, vPort, vQrcode, vAdvancedParams, vOfflineRecord, vOtherSetting },
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      test: {
        value: '',
        type: 'deviceType'
      },
      searchOption: {
        deviceNoLabel: BaseSearchModel.initData('设备型号名称设备型', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'name', label: '设备名称' },
          { value: 'deviceModelName', label: '设备型号名称' },
          { value: 'imei', label: 'IMEI号' }
        ]),
        site: BaseSearchModel.initData('物业/点位', 'site', 'YgSiteCascader').setData(1).setShowInMore(true),
        chargingPileType: BaseSearchModel.initData('端口类别', 'chargingPileType', 'YgGlobalSelect').setData('chargingPileType').setShowInMore(true),
        // unusualPortCount: BaseSearchModel.initData('端口数量异常', 'unusualPortCount', 'Select').setData([
        //   { value: 1, label: '是' },
        //   { value: 0, label: '否' }
        // ]),
        deviceSource: BaseSearchModel.initData('设备来源', 'deviceSource', 'YgGlobalSelect').setData('deviceDataSourceType').setShowInMore(true),
        status: BaseSearchModel.initData('启用状态', 'status', 'Select').setData([
          { value: 1, label: '已启用' },
          { value: 0, label: '已停用' }
        ]),
        belongType: BaseSearchModel.initData('主从类型', 'belongType', 'Select').setData([
          { value: 1, label: '主机' },
          { value: 2, label: '从机' }
        ]).setShowInMore(true),
        belongDeviceNumber: BaseSearchModel.initData('所属主机', 'belongDeviceNumber', 'Input').setShowInMore(true),
        operationType: BaseSearchModel.initData('运营类型', 'operationType', 'Select').setMultiple(true).setData([
          { label: '自有', value: '0' },
          { label: '共建', value: '1' },
          { label: '外拓', value: '2' }
        ]).setShowInMore(true),
        deviceProviderId: BaseSearchModel.initData('设备商名称', 'deviceProviderId', 'YgGlobalSelect').setData('deviceProvider').setShowInMore(true),
        isOnline: BaseSearchModel.initData('在线状态', 'isOnline', 'Select').setData([
          { label: '离线', value: '0' },
          { label: '在线', value: '1' }
        ]),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']).setShowInMore(true),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        chargeCategory: BaseSearchModel.initData('计费类型', 'chargeCategory', 'Select').setData([
          { label: '按功率计费', value: 1 },
          { label: '按电量计费', value: 2 },
          { label: '按时间计费', value: 3 }
        ])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出设备列表', 'exportList', '').setExport().setExportReportType(3).setPermission('system:device:export'),
        BaseSettingModel.initData('导出设备二维码', 'exportQrcode', '').setExport().setExportReportType(6).setPermission('system:device:qrcode:export'),
        BaseSettingModel.initData('导出计费规则', 'exportBillingRules', '').setExport().setExportReportType(30).setPermission('system:device:exportComputeFee'),
        BaseSettingModel.initData('导出分账规则', 'exportAccountingRules', '').setExport().setExportReportType(32).setPermission('system:device:exportAllocateTemplate'),
        BaseSettingModel.initData('导出运营规则', 'exportOperateRules', '').setExport().setExportReportType(36).setPermission('system:device:exportOperationTemplate')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        settingModel: null,
        settingIndex: 0,
        portModel: null,
        qrCodeModel: null,
        advancedModel: null,
        deviceOfflineModel: null,
        otherSettingModel: null,
        otherSettingIndex: 0
      },
      tableMoreDataControl: true,
      // 记录展开的行的下标
      expandedRowArr: [],
      // table的默认展开状态
      tableExpandDefault: true
    };
  },
  created () {
    // 配置展开项内容
    this.mixinTableExpandSetColumnOperation(2, [
      {
        title: '型号信息',
        col: '30',
        items: [
          { col: '50', label: '设备商名称:', key: 'deviceProviderName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '设备型号编码:', key: 'deviceModel', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '设备型号名称:', key: 'deviceModelName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '端口类别:', key: 'chargingPileType', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '其他信息',
        col: '30',
        items: [
          { col: '50', label: '运营类型:', labelWidth: 60, key: 'operationTypeDesc', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: '数据来源:', labelWidth: 60, key: 'deviceSourceDesc', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '100', label: '设备备注:', labelWidth: 60, key: 'remark', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '序列号',
        col: '30',
        items: [
          { col: '50', label: 'IMEI号:', labelWidth: 60, key: 'imei', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '50', label: 'SIM卡ID:', labelWidth: 60, key: 'iccid', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '100', label: 'UID:', labelWidth: 60, key: 'deviceUid', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '运维服务',
        col: '10',
        items: [
          { col: '100', label: '联系人:', labelWidth: 50, key: 'manager', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '100', label: '手机号:', labelWidth: 50, key: 'serviceCall', canCopy: false, canTooltip: true, canHidePhone: true }
        ]
      }
    ]);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    onCloseSubmit (needRefresh) {
      this.dialog.settingModel = null;
      if (needRefresh) {
        this.requestData();
      }
    },
    onCloseOtherEdit (needRefresh) {
      this.dialog.otherSettingModel = null;
      if (needRefresh) {
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DeviceApiSet.deviceList;
      const s = this.searchOption;
      api.params = {
        chargingPileType: s.chargingPileType.value,
        belongType: s.belongType.value,
        belongDeviceNumber: s.belongDeviceNumber.value,
        propertyCompanyId: s.site.value[0] || '',
        communityId: s.site.value[3] || '',
        siteId: s.site.value[4] || '',
        // unusualPortCount: s.unusualPortCount.value,
        deviceSource: s.deviceSource.value,
        status: s.status.value,
        deviceProviderId: s.deviceProviderId.value,
        isOnline: s.isOnline.value,
        operationType: s.operationType.value || [],
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        orgCode: s.regionCompany.value,
        officeCode: s.officeCode.value,
        chargeCategory: s.chargeCategory.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      // 筛选中可下拉的label
      api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total; // tableMoreDataControl
        const list = DeviceListModel.initListWithDataList(resData.rows);
        this.tableData = this.mixinTableExpandDataSetExpand(list);
        DeviceListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
      // const resList = DeviceListModel.createFakeDataList(130);
      // this.page.total = resList.length;
      // this.tableData = DeviceListModel.initListWithDataList(resList.slice((this.page.current - 1) * 10, this.page.current * 10));
      // DeviceListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
    },
    requestDetail () {
      const resData = DeviceDetailModel.createFakeData();
      this.dialog.detailModel = DeviceDetailModel.initModelWithData(resData);
    },
    requestChangeStatus (row, status) {
      const api = DeviceApiSet.deviceChangeStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        row.status = status ? 1 : 0;
        row.h5StatusSwitch = status;
      }).catch(e => {
        row.status = status ? 0 : 1;
        row.h5StatusSwitch = !status;
      });
    },
    requestSetting (name, row) {
      console.log(row);
      let api = null;
      let desc = '';
      if (name === 'del') {
        if (row.h5StatusSwitch) return this.$Message.warning('只有停用的充电桩才可以删除');
        api = DeviceApiSet.deviceDel;
        desc = '是否确认删除设备？';
      } else if (name === 'reset') {
        api = DeviceApiSet.deviceReset;
        desc = '是否确认重启？';
      } else if (name === 'refresh') {
        api = DeviceApiSet.devicePortRefresh;
        desc = '是否确认刷新端口？';
      } else if (name === 'clearCache') {
        if (row.isOnline === 0) {
          this.$Modal.confirm({
            title: '温馨提示',
            content: '设备不在线，请上线后再操作！'
          });
          return;
        }
        api = DeviceApiSet.deviceClear;
        desc = '是否确定操作清除设备缓存？';
      }
      api.params = { id: row.id };
      this.$Modal.confirm({
        title: '温馨提示',
        content: desc,
        onOk: () => {
          this.$http(api).then(res => {
            if (name === 'clearCache') {
              this.$Message.success('操作成功，请稍后关注设备状况！');
            } else {
              this.$Message.success('执行成功！');
            }
            this.requestData();
          });
        }
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
