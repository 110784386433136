import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import iView from 'view-design';
import JsonViewer from 'vue-json-viewer';
import YunGuiUI from '@/components/index';
import Storage from '@/libs/storage/index.js';
// import AnalysisIndexDB from '@/libs/indexDB/AnalysisIndexDB';
import YunGuiLoading from '@/components/alert/loading';
import GLOBAL_CONFIG from './app.config.js';
import GLOBAL_KEY from './app.key.js';
import { YunGuiHttpClient, YunGuiFormDataHttpClient } from './request/axios/HttpClient';

require('./libs/directive/index'); // 注册html钩子

if ((new Storage()).theme === '1') {
  // require('./assets/css/iview-dark.less');
  require('./assets/css/iview-light.less');
} else {
  require('./assets/css/iview-dark.less');
}
require('@/libs/base/index');

// 一些全局参数配置
const ygStorage = new Storage();
GLOBAL_CONFIG.pageSize = parseInt(ygStorage.globalSetting.page);
GLOBAL_CONFIG.moneyUnit = ygStorage.globalSetting.money;
// Window.prototype.$analysisIndexDB = AnalysisIndexDB;
// 全局配置config挂载
Vue.prototype.$config = GLOBAL_CONFIG;
Vue.prototype.$appKey = GLOBAL_KEY;
Vue.prototype.$ygLoading = YunGuiLoading;
Vue.prototype.$storage = ygStorage;
Vue.prototype.$http = YunGuiHttpClient;
Vue.prototype.$httpFormData = YunGuiFormDataHttpClient;

// UI相关 ------- 开始 -------
iView.LoadingBar.config({
  color: '#546EFD',
  failedColor: '#FF335F',
  height: 4
});
Vue.use(iView);
Vue.use(YunGuiUI);
// UI相关 ------- 结束 -------

// JsonViewer相关 ------- 开始 -------
Vue.use(JsonViewer);
// JsonViewer相关 ------- 结束 -------

Vue.config.productionTip = false;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
});
