<!--
    Created by 王丽莎 on 2024/05/13.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：商户管理-退币申请订单
-->
<style lang="less">
#refund-coin-application-order {
  min-width: 700px;
  .card-outer {
    margin: 0 -15px;
    .total-data-box {
      display: flex;
      flex-wrap: wrap;
      border-radius: @borderRadiusMin;
      overflow: hidden;
      border: 1px solid @lineColorNormal;
      border-right: 0;
      .total-data-item {
        flex: 1;
        min-width: 100px;
        border-right: 1px solid @lineColorNormal;
        &:nth-of-type(2n) {
          .total-data-label {
            //background-color: transparent;
          }
        }
        .total-data-label {
          padding: 8px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          white-space: pre-line;
          color: @textColorLight;
          background-color: @backColorNormal;
        }
        .total-data-value {
          .display-flex();
          line-height: 40px;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }
  .color-text-strong { color: @adornColor; font-weight: 600 }
  .color-text-finish { color: @finishColor; }
  .color-text-primary { color: @textColorNormal; }
  .col-text-strong {
    .display-flex();
    color: @adornColor;
    font-weight: 600;
  }
  .ivu-poptip-rel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-text-light {
    color: @textColorLight;
  }
  .pay-amount-icon {
    color: @textColorLight;
  }
}
</style>

<template>
  <div id="refund-coin-application-order">
    <!-- 查询条件 -->
    <div v-permission="'system:chargeCoin:getRefundOrder:list'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div
      v-permission="'system:chargeCoin:getRefundOrderSum'"
      class="card-outer"
    >
      <yg-card
        title="数据汇总"
      >
        <div slot="content">
          <div class="total-data-box">
            <div
              v-for="(item, i) in totalItems"
              :key="i"
              class="total-data-item"
            >
              <div class="total-data-label">
                {{ item.label }}
              </div>
              <div
                v-price-text="item.priceText"
                :data-value="pageData[item.key]"
                class="total-data-value"
                :class="'color-text-' + item.textColor"
              >
                {{ pageData[item.key] || '-' }}
              </div>
            </div>
          </div>
        </div>
      </yg-card>
    </div>
    <div
      v-permission="'system:chargeCoin:getRefundOrder:list'"
      class="card-outer"
      style="margin-top: -5px"
    >
      <yg-card
        title="数据明细"
        style="padding-bottom: 0"
      >
        <div slot="content">
          <div class="margin-primary-bottom">
            <yg-setting-area
              :items="settingItems"
              :total="page.total"
              :export-condition="settingExportCondition"
              @on-click="_clickSetting"
            />
          </div>
          <yg-table
            :columns="tableColumns"
            :data="tableData"
            :max-height="9999"
            :page="page"
            :row-class-name="mixinTableRowClassName"
            @on-row-expand="mixinTableOnRowExpand"
            @on-page-change="onChangePage"
            @on-selection="onChangeSelection"
          />
        </div>
      </yg-card>
      <!--详情弹窗-->
      <v-detail
        v-if="dialog.detailModel"
        :model="dialog.detailModel"
        @on-close="dialog.detailModel = null"
        @on-sure="dialog.detailModel = null"
      />
    </div>
  </div>
</template>

<script>
import { RefundCoinApplicationOrderTotalModel, RefundCoinApplicationOrderListModel } from './model/RefundCoinApplicationOrderDataModel';
import OrderApiSet from '@/request/api/OrderApiSet';
import FinanceApiSet from '@/request/api/FinanceApiSet';
import ColumnMixin from './mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';
import vDetail from './view/detail.vue';

export default {
  components: { vDetail },
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  data () {
    return {
      totalItems: [
        { key: 'waitToRefundOrderNum', label: '待退款订单数量', textColor: 'primary', priceText: false },
        { key: 'waitToRefundMoneySum', label: '待退款金额', textColor: 'primary', priceText: true },
        { key: 'refundFailMoneySum', label: '退款失败金额', textColor: 'strong', priceText: true },
        { key: 'refundSuccessMoneySum', label: '退款成功金额', textColor: 'finish', priceText: true }
      ],
      searchOption: {
        orderNoLabel: BaseSearchModel.initData('用户手机号用户手', 'orderNoLabel', 'Input').setLabelSelect([
          { value: 'tel', label: '用户手机号' },
          { value: 'refundOrderNo', label: '申请订单号' }
        ]),
        orderStatus: BaseSearchModel.initData('订单状态', 'orderStatus', 'Select').setData([
          { label: '待退款 ', value: '0' },
          { label: '部分退款', value: '1' },
          { label: '退款成功', value: '2' },
          { label: '退款失败', value: '3' }
        ]),
        refundChannel: BaseSearchModel.initData('退款渠道', 'refundChannel', 'Select').setData([
          { label: '微信', value: '1' },
          { label: '银行卡', value: '2' }
        ]),
        time: BaseSearchModel.initData('申请时间', 'time', 'DatePickerRange').setDatePickerElevator([])
      },
      settingItems: [
        BaseSettingModel.initData('批量退款(微信)', 'bulkRefund', '').setPermission('system:chargeCoin:confirmRefund'),
        BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(25).setPermission('system:chargeCoin:getRefundOrder:export')
      ],
      settingExportCondition: {},
      settingExportIdList: [],
      page: { current: 1, total: 0 },
      tableData: [],
      pageData: {},
      dialog: {
        detailModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    getRequestParams () {
      const s = this.searchOption;
      const params = {
        status: s.orderStatus.value,
        refundChannelDesc: s.refundChannel.value,
        applyRefundTimeBegin: s.time.value[0] || '',
        applyRefundTimeEnd: s.time.value[1] || ''
      };
      params[s.orderNoLabel.labelSelectValue] = s.orderNoLabel.value;
      return params;
    },
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'showSubRefundList':
        this.dialog.detailModel = {};
        break;
      case 'bulkRefund':
        if (!this.tableData.length) {
          return this.$Modal.warning({
            title: '无数据可操作',
            content: '请先查询到结果后，再进行批量退款操作',
            okText: '我知道了'
          });
        }
        if (!this.settingExportIdList.length) {
          return this.$Modal.warning({
            title: '无数据可操作',
            content: '请先选择待退款订单后，再进行批量退款操作',
            okText: '我知道了'
          });
        }
        this.$Modal.confirm({
          title: '操作提示',
          content: '确认后将调支付渠道接口进行退款，是否确认操作？',
          onOk: () => {
            console.log('调用批量退款接口');
            console.log(this.settingExportIdList);
            this.requestBulkRefund(0);
          }
        });
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 请求汇总数据
    requestTotalData () {
      const api = OrderApiSet.getRefundOrderSum;
      api.params = this.getRequestParams();
      this.$http(api).then(res => {
        this.pageData = RefundCoinApplicationOrderTotalModel.initModelWithData(res.data);
      });
      // const resData = RefundCoinApplicationOrderTotalModel.createFakeData();
      // this.pageData = RefundCoinApplicationOrderTotalModel.initModelWithData(resData);
    },
    // 请求列表
    requestData () {
      this.settingExportIdList = [];
      this.tableData = [];
      this.page.total = 0;
      const api = OrderApiSet.getRefundOrderList;
      api.params = this.getRequestParams();
      api.params.pageNo = this.page.current;
      api.params.pageSize = this.$config.pageSize;
      if (this.page.current === 1) {
        this.requestTotalData();
      }
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = RefundCoinApplicationOrderListModel.initListWithDataList(resData.rows);
        RefundCoinApplicationOrderListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
      // const resList = RefundCoinApplicationOrderListModel.createFakeDataList(10);
      // this.page.total = resList.length;
      // this.tableData = RefundCoinApplicationOrderListModel.initListWithDataList(resList);
      // RefundCoinApplicationOrderListModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
    },
    // 请求单个退款
    requestRefund (refundOrderNo) {
      const api = FinanceApiSet.confirmRefund;
      api.params = {
        refundOrderNo: refundOrderNo
      };
      this.$http(api).then(res => {
        console.log(res);
        this.$Message.success('操作成功，请关注处理状态更新');
        this.requestData();
      });
    },
    // 请求批量退款
    requestBulkRefund (index) {
      const item = this.settingExportIdList[index];
      if (item && item.refundOrderNo) {
        const api = FinanceApiSet.confirmRefund;
        api.params = { refundOrderNo: item.refundOrderNo };
        this.$http(api).then(res => {
          if ((this.settingExportIdList.length - 1) > index) {
            this.requestBulkRefund(index + 1);
          } else {
            // 结束了
            this.$Message.success('批量操作成功，请关注处理状态更新');
            this.requestData();
          }
        });
      } else {
        if (index === 0) {
          this.$Message.warning('批量申请失败');
          return;
        }
        this.$Message.warning('部分申请操作成功，请关注处理状态更新');
        this.requestData();
      }
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
