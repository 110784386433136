/**
 * @date:2022/10/19
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import RegionApiSet from '@/request/api/RegionApiSet';
import { YunGuiHttpClient } from '@/request/axios/HttpClient';

export default {
  state: {
    // 省列表
    provinceList: [],
    // 市列表
    cityDic: {},
    // 区列表
    areaDic: {},
    // 街道列表
    streetDic: {},
    // 省市区全量数据
    regionAllList: []
  },
  mutations: {
    setProvinceList (state, value) {
      state.provinceList = value;
    },
    setCityDic (state, value) {
      state.cityDic = value;
    },
    setAreaDic (state, value) {
      state.areaDic = value;
    },
    setStreetDic (state, value) {
      state.streetDic = value;
    },
    setRegionAllList (state, value) {
      state.regionAllList = value;
    }
  },
  actions: {
    // 初始化所有数据
    resetRegionStoreData ({ state }) {
      state.provinceList = [];
      state.cityDic = {};
      state.areaDic = {};
      state.streetDic = {};
    },
    // 这里预留为了以后动态渲染左侧导航用(后期需要跟权限绑定)
    getProvinceList ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.provinceList.length) {
          resolve(state.provinceList);
        } else {
          const api = RegionApiSet.getProvinceList;
          api.params = { parentCode: '0' };
          YunGuiHttpClient(api).then(res => {
            commit('setProvinceList', res.data);
            resolve(res.data);
          });
        }
      });
    },
    getCityList ({ state, commit }, provinceId) {
      return new Promise((resolve, reject) => {
        const cityDic = state.cityDic;
        const cityList = cityDic[provinceId];
        if (cityList && cityList.length) {
          resolve(JSON.parse(JSON.stringify(cityList)));
        } else {
          const api = RegionApiSet.getCityList;
          api.params = { parentCode: provinceId };
          YunGuiHttpClient(api).then(res => {
            cityDic[provinceId] = JSON.parse(JSON.stringify(res.data));
            commit('setAreaDic', cityDic);
            resolve(res.data);
          });
        }
      });
    },
    getAreaList ({ state, commit }, cityId) {
      return new Promise((resolve, reject) => {
        const areaDic = state.areaDic;
        const areaList = areaDic[cityId];
        if (areaList && areaList.length) {
          resolve(JSON.parse(JSON.stringify(areaList)));
        } else {
          const api = RegionApiSet.getAreaList;
          api.params = { parentCode: cityId };
          YunGuiHttpClient(api).then(res => {
            areaDic[cityId] = JSON.parse(JSON.stringify(res.data));
            commit('setCityDic', areaDic);
            resolve(res.data);
          });
        }
      });
    },
    getStreetList ({ state, commit }, areaId) {
      return new Promise((resolve, reject) => {
        const streetDic = state.areaDic;
        const streetList = streetDic[areaId];
        if (streetList && streetList.length) {
          resolve(JSON.parse(JSON.stringify(streetList)));
        } else {
          const api = RegionApiSet.getStreetList;
          api.params = { parentCode: areaId };
          YunGuiHttpClient(api).then(res => {
            streetDic[areaId] = JSON.parse(JSON.stringify(res.data));
            commit('setStreetDic', streetDic);
            resolve(res.data);
          });
        }
      });
    },
    getRegionAllList ({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.regionAllList.length) {
          resolve(state.regionAllList);
        } else {
          const api = RegionApiSet.getAllRegion;
          api.params = {};
          YunGuiHttpClient(api).then(res => {
            commit('setRegionAllList', res.data);
            resolve(res.data);
          });
        }
      });
    }
  }
};
