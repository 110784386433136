<!--
   整体布局-左侧导航

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
#left-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: @leftBarOpenWidth;
  background-color: @leftBarBackground;
  z-index: @leftBarIndex;
  box-shadow: @shadowMid;
  user-select: none;
  &.bar-is-open {
    width: @leftBarOpenWidth;
    .left-bar-container {
      .left-bar-box {
        .bar-list-box {
          overflow: auto;
        }
      }
    }
  }
  &.bar-is-close {
    width: @leftBarCloseWidth;
    .left-bar-container {
      .left-bar-box {
        h1 {
          padding: 0;
          .display-flex();
          //font-size: 16px;
          .light {
            display: none;
          }
        }
      }
    }
  }
  &::after {
    position: absolute;
    content: '';
    background-color: @leftBarBackground;
    right: -3px;
    top: 0;
    width: 3px;
    height: @topBarHeight;
  }
  .left-bar-container {
    height: 100%;
    .left-bar-box {
      display: flex;
      flex-direction: column;
      height: 100%;
      h1 {
        padding-left: ~'calc(@{leftBarIconWidth})';
        height: @topBarHeight;
        line-height: @topBarHeight;
        color: @adornColor;
        font-size: 20px;
        .light {
          color: @textColorNormal;
        }
      }
      .bar-list-box {
        flex: 1;
        .bar-group {
          .bar-inner {
            height: 0;
            opacity: 0;
            transition: all @transitionTime15;
          }
          &.group-is-open {
            .bar-inner {
              height: auto;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    id="left-bar"
    :class="leftBarOpen ? 'bar-is-open' : 'bar-is-close'"
  >
    <div class="left-bar-container">
      <div class="left-bar-box">
        <h1>
          <span>智能</span>
          <span class="light">充电管理平台</span>
        </h1>
        <div class="bar-list-box">
          <div
            v-for="(item, i) in barList"
            :key="'bar-' + i"
            class="bar-group"
            :class="i === openBarClickIndex ? 'group-is-open' : ''"
          >
            <v-bar-header
              :model="item"
              :is-current="i === currentBarIndex"
              :is-open="i === openBarClickIndex"
              :is-small-size="!leftBarOpen"
              :current-sub-bar-index="currentSubBarIndex"
              @on-click="_clickOpenBarItem(i)"
              @on-click-item="_clickPush"
            />
            <div
              v-if="leftBarOpen"
              class="bar-inner"
            >
              <v-bar-item
                v-for="(sub, s) in item.children"
                :key="s"
                :model="sub"
                :is-current="currentBarIndex === i && currentSubBarIndex === s"
                @on-click="_clickPush"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vBarHeader from './barHeader.vue';
import vBarItem from './barItem.vue';
export default {
  components: { vBarHeader, vBarItem },
  props: {
    currentRoute: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      barList: [],
      // 导航展开状态时：记录选中的大导航下标
      openBarClickIndex: -1,
      // 当前的 路由下标
      currentBarIndex: -1,
      // 当前的二级路由下标
      currentSubBarIndex: -1,
      hasPermission: true
    };
  },
  computed: {
    leftBarOpen () {
      return this.$store.state.app.leftBarOpen;
    }
  },
  watch: {
    currentRoute () {
      if (this.judgeChrome()) {
        // 查看有没有权限
        this.hasPermission = false;
        this.checkPermission(this.barList);
        if (!this.hasPermission) {
          console.log('没权限');
          this.$router.push({ name: 'error_401' });
          return;
        }
        this.setCurrentBarStatus();
      }
    }
  },
  created () {
    if (this.judgeChrome()) {
      this.currentYear = (new Date()).getFullYear();
      this.$store.dispatch('getLeftBarList').then(res => {
        this.barList = res;
        if (this.currentRoute) {
          setTimeout(() => {
            // 查看有没有权限
            this.hasPermission = false;
            this.checkPermission(this.barList);
            if (!this.hasPermission) {
              console.log('没权限');
              this.$router.push({ name: 'error_401' });
              return;
            }
            this.setCurrentBarStatus();
          }, 500);
        }
      });
    }
  },
  methods: {
    judgeChrome () {
      let result = 0;
      // 判断是否为谷歌
      // 取得浏览器的userAgent字符串
      const arr = navigator.userAgent.split(' ');
      let chromeVersion = '';
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes('Chrome') || arr[i].includes('chrome')) {
          chromeVersion = arr[i];
        }
        if (i === (arr.length - 1)) {
          if (chromeVersion) {
            result = Number(chromeVersion.split('/')[1].split('.')[0]);
          }
        }
      }
      if (result === 0) {
        this.$router.push({ name: 'error_300' });
        return false;
      }
      return true;
    },
    checkPermission (list) {
      if (Array.isArray(list) && list.length) {
        for (let i = 0; i < list.length; i++) {
          const v = list[i];
          if (v.children && v.children.length) {
            this.checkPermission(v.children);
          }
          if (!this.hasPermission && v.name === this.currentRoute.name) {
            this.hasPermission = true;
          }
        }
      }
    },
    setCurrentBarStatus () {
      this.barList.forEach((v, i) => {
        if (v.children.length) {
          v.children.forEach((sub, j) => {
            if (sub.name === this.currentRoute.name) {
              this.currentBarIndex = i;
              this.currentSubBarIndex = j;
            }
          });
        }
      });
      this.openBarClickIndex = this.currentBarIndex;
      // this._clickOpenBarItem(this.currentBarIndex);
    },
    leftBarHeaderClass (i) {
      // 一定要先看是否是 展开的!!!
      if (i === this.openBarClickIndex) {
        return 'is-open-header';
      } else if (i === this.currentBarIndex) {
        return 'is-current';
      }
      return '';
    },
    _clickOpenBarItem (i) {
      if (i === this.openBarClickIndex) {
        // 同样的就折回
        this.openBarClickIndex = -1;
        return;
      }
      this.openBarClickIndex = i;
      // this.$refs.logoImg.className = 'start-anim';
      // const currentBar = this.barList[i];
      // if (currentBar.children.length === 1) {
      //   this._clickPush(currentBar.children[0]);
      // }
      // setTimeout(() => {
      //   this.$refs.logoImg.className = '';
      // }, 250);
    },
    // 实际跳转
    _clickPush (item) {
      if (this.currentRoute.name === item.name) return;
      this.$router.push({ name: item.name });
    }
  }
};
</script>
