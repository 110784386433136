/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

const METHOD_GET = 'get';
const METHOD_POST = 'post';
const METHOD_PUT = 'put';

class BaseApi {
  constructor (title, url, needToken = true) {
    // 接口的url
    this.url = url;
    // 接口标题
    this.title = title;
    // 是否需要token
    this.needToken = needToken;
    this.method = METHOD_POST; // 默认post
    // 仅GET方法时适用：当此值为true时会问号拼接，当此值为false时会/拼接值
    this.getMethodIsQuery = true;
    // 接口的请求参数
    this.params = {};
    // 接口的headers
    this.headers = null;
    // 自动做某些事情...
    this.autoConfig = {
      autoSaveMonitor: true,
      autoLoading: true, // 请求时loading
      autoToast: true, // 请求错误时，自动弹出弹窗
      autoReplaceSpace: true // 自动去掉请求参数中的空格
    };
  }

  /**
   * 设置请求方法
   * type-0：get
   * type-1: post
   * type-2: put
   * getMethodIsQuery: 仅GET方法时适用：当此值为true时会问号拼接，当此值为false时会/拼接值
   */
  setMethod (type = 0, getMethodIsQuery = true) {
    switch (type) {
    case 0:
      this.method = METHOD_GET;
      this.getMethodIsQuery = getMethodIsQuery;
      break;
    case 1:
      this.method = METHOD_POST;
      break;
    case 2:
      this.method = METHOD_PUT;
      break;
    }
    return this;
  }

  get isGetMethod () {
    return this.method === METHOD_GET;
  }

  // 遍历构造器
  static createModel (title, url, needToken = true) {
    return new BaseApi(title, url, needToken);
  }
}

export default BaseApi;
