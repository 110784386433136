<!--
    Created by 程雨喵'mac on 2022/9/29.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.__g-card {
  position: relative;
  background-color: @backColorStrong;
  border-radius: @borderRadiusMid;
  padding: 0 @containerGap @containerGap @containerGap;
  &.__g-card-big {
    .__g-card-inner {
      .__g-card-header {
        padding: @containerGap 0;
        .__g-card-title {
          .__g-card-tit-strong {
            margin-right: 5px;
            font-size: 20px;
            color: @textColorPrimary;
          }
        }
      }
    }
  }
  .__g-card-inner {
    .__g-card-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 16px;
      padding: 10px 0;
      .adorn {
        position: relative;
        margin-right: 5px;
        height: 14px;
        width: 14px;
        .circle {
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          &-1 {
            top: 0;
            left: 3px;
            background-color: @themeColor;
          }
          &-2 {
            top: 6px;
            left: 0;
            background-color: #45C2DE;
            z-index: 1;
          }
          &-3 {
            top: 6px;
            left: 6px;
            background-color: #A7FFEB;
            z-index: 2;
          }
        }
      }
      .__g-button {
        font-size: 14px;
      }
      .__g-card-title {
        flex: 1;
        font-size: 16px;
        font-weight: 600;
        user-select: none;
        line-height: 30px;
      }
      .__g-card-right {
        position: relative;
        display: flex;
        align-items: center;
      }
      //&::after {
      //  content: '';
      //  position: absolute;
      //  bottom: 0;
      //  left: 0;
      //  right: 0;
      //  height: 2px;
      //  background-color: #f5f5f5;
      //}
    }
  }
}
</style>

<template>
  <div
    class="__g-card"
    :class="isBig ? '__g-card-big' : ''"
  >
    <div class="__g-card-inner">
      <!--标题-->
      <div class="__g-card-header">
        <span
          v-if="title"
          class="adorn"
        >
          <span class="circle circle-1" />
          <span class="circle circle-2" />
          <span class="circle circle-3" />
        </span>
        <div
          v-if="title"
          class="__g-card-title"
        >
          <span v-if="isBig">
            <span class="__g-card-tit-strong">{{ titleStrong }}</span>
            <span class="__g-card-tit-normal">{{ titleNormal }}</span>
          </span>
          <span v-else>{{ title }}</span>
        </div>
        <div v-else>
          <slot name="title" />
        </div>
        <div class="__g-card-right">
          <slot name="button" />
        </div>
      </div>
      <!--内容-->
      <div class="__g-card-content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgCard',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    isBig: {
      type: Boolean,
      default: false
    },
    bigSliceIndex: {
      type: Number,
      default: 2
    }
  },
  data () {
    return {};
  },
  computed: {
    titleStrong () {
      return this.title.slice(0, this.bigSliceIndex);
    },
    titleNormal () {
      return this.title.slice(this.bigSliceIndex, this.title.length + 1);
    }
  },
  methods: {
    getTitle (start, end) {
      return this.title.slice(start, end);
    }
  }
};
</script>
