
export default {
  data () {
    return {
      // 下面数据明细-表头
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('applyTel', '用户手机号', 100).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('refundOrderNo', '申请订单号', 140).setSpan({ canCopy: true }).setValueButton([], (row, index) => {
          this.dialog.detailModel = row;
        }),
        BaseTableColumn.initData('status', '订单状态').setWidth(80).setStatus([
          { value: 0, type: 4 },
          { value: 1, type: 1 },
          { value: 2, type: 1 },
          { value: 3, type: 3 },
          { value: 4, type: 2 }
        ], 'statusDesc'),
        BaseTableColumn.initData('', '充电币明细', 140).setKeyValueList([
          { label: '退币数量:', labelWidth: 64, key: 'refundCoinMoney' },
          { label: '收回数量:', labelWidth: 64, key: 'receiveMoney', attr: { canTooltip: true, tooltipText: '此数据为迁入与赠送总和' } }
        ]),
        BaseTableColumn.initData('', '退款金额', 150).setKeyValueList([
          { label: '申请金额:', labelWidth: 64, key: 'applyRefundMoney', attr: { canPrice: true } },
          { label: '实际退款:', labelWidth: 64, key: 'realRefundMoney', attr: { canPrice: true } }
        ]),
        BaseTableColumn.initData('refundChannelDesc', '退款渠道', 70).setSpan({}),
        BaseTableColumn.initData('', '收款信息').setAlign('center').setWidth(240).setRender((h, { row, index }) => {
          let arr = [];
          if (row.refundChannel === 1) {
            arr = [{ label: '收款人:', labelWidth: 84, key: 'applyTel', attr: { canHidePhone: true } }];
          } else if (row.refundChannel === 2) {
            arr = [
              { label: '收款人姓名:', labelWidth: 84, key: 'receiveName', attr: { canCopy: true } },
              { label: '收款银行卡号:', labelWidth: 84, key: 'receiveCard', attr: { canCopy: true } },
              { label: '收款银行名称:', labelWidth: 84, key: 'receiveBank', attr: { canCopy: true } }
            ];
          }
          if (arr.length > 0) {
            const items = [];
            arr.forEach(v => {
              const attr = v.attr || {};
              items.push(h('yg-attribute-key-value', {
                props: {
                  label: v.label,
                  labelWidth: v.labelWidth,
                  value: row[v.key],
                  canCopy: typeof attr.canCopy === 'function' ? attr.canCopy(row, index, v.key) : !!attr.canCopy,
                  canHidePhone: typeof attr.canHidePhone === 'function' ? attr.canHidePhone(row, index, v.key) : !!attr.canHidePhone
                }
              }));
            });
            return items;
          }
          return h('div', '-');
        }),
        BaseTableColumn.initData('', '时间').setWidth(230).setKeyValueList([
          { label: '申请时间:', labelWidth: 80, key: 'applyRefundTime', attr: { canTooltip: true } },
          { label: '确认时间:', labelWidth: 80, key: 'confirmRefundTime', attr: { canTooltip: true } },
          { label: '退款时间:', labelWidth: 80, key: 'realRefundTime', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('operator', '操作人').setWidth(100).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setRender((h, { row, index }) => {
          const buttonOptions = [];
          if (row.refundChannel === 1 && row.status === 0) {
            buttonOptions.push({ title: '确认退款', key: 'confirmRefund', permission: 'system:chargeCoin:confirmRefund' });
          }
          return h('yg-table-buttons', {
            props: {
              items: buttonOptions
            },
            on: {
              'on-click': ({ key }) => {
                switch (key) {
                case 'confirmRefund':
                  this.$Modal.confirm({
                    title: '操作提示',
                    content: '确认后将调支付渠道接口进行退款，是否确认操作？',
                    onOk: () => {
                      console.log('调用退款接口');
                      this.requestRefund(row.refundOrderNo);
                    }
                  });
                  break;
                }
              }
            }
          });
        })
      ]
    };
  }
};
