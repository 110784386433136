/**
 * Created by 程雨喵'mac on 2022/11/28.
 * Copyright © 2022年 云柜-金星晖. All rights reserved.
 * 名称：正则表达式
 * 外部使用：import {xx} from '@/libs/regularUtils.js';
 */

// 数字(整数)
export const isNumber = value => {
  const rex = /^[0-9]*$/;
  return rex.test(value);
};
/**
 * 数字(整数 + 小数),小数是小数点后两位
 * @param value 字符串
 * @param len 默认为2，传就是4
 * @returns {boolean}
 */
export const isFloat = (value, len = 2) => {
  if (len === 2) {
    const rex = /^\d+(\.\d{1,2})?$/;
    return rex.test(value);
  }
  const rex = /^\d+(\.\d{1,4})?$/;
  return rex.test(value);
};
// 手机号正则
export const isPhone = value => {
  const rex = /^1\d{10}$/; // /^1[3-9]+\d{9}$/;
  return rex.test(value);
};
// 邮箱
export const isEmail = value => {
  const rex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
  return rex.test(value);
};
// 无符号的密码
export const isPassword = value => {
  const rex = /^[a-zA-Z0-9]{6,8}$/;
  return rex.test(value);
};
// 数字、英文
export const isNumberAndEng = value => {
  const rex = /^[A-Za-z0-9]+$/;
  return rex.test(value);
};
// 中文，英文，数字
export const isNoSymbol = value => {
  const rex = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
  return rex.test(value);
};
// 中文，英文，数字、空格
export const isNoSymbols = value => {
  const rex = /^[\u4E00-\u9FA5A-Za-z0-9\s/g]+$/;
  return rex.test(value);
};
// 仅限数字支持小数点后2位
export const isNumberDecimals = value => {
  const rex = /^\d+(\.\d{1,2})?$/;
  return rex.test(value);
};

// 中文，英文，数字，符号(仅支持：_ - () （）) ;
export const isValidString1 = value => {
  const reg = /^[\u4E00-\u9FA5A-Za-z0-9_（）]+$/;
  const tempArr = ['(', ')', '-', '.'];
  let checkStr = value;
  tempArr.forEach(v => {
    checkStr = checkStr.replaceAll(v, 'g');
  });
  console.log(checkStr);
  return reg.test(checkStr);
};

// 仅限6~8位数字字母组合
export const isAlphanumeric = value => {
  const regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,8}$/;
  return regex.test(value);
};
