<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：步骤条
-->
<style lang="less">
.__g-steps {
  height: 100%;
  .display-flex();
  flex-direction: column;
  .__g-step-header {
    width: 100%;
  }
  .__g-step-content {
    width: 100%;
    flex: 1;
    overflow: auto;
  }
  .__g-step-bottom {
    .display-flex();
    padding: @containerGap 0;
    .__g-button+.__g-button {
      margin-left: @containerGap;
    }
  }
}
</style>

<template>
  <div class="__g-steps">
    <div
      class="__g-step-header"
      :style="`padding: 0 ${stepGap}px`"
    >
      <Steps :current="currentIndex">
        <Step
          v-for="(item, i) in items"
          :key="i"
          :title="item.title"
          :content="item.info"
        />
      </Steps>
    </div>
    <div class="__g-step-content">
      <slot name="step-content" />
    </div>
    <div
      v-if="nextText || prevText"
      class="__g-step-bottom"
    >
      <yg-button
        v-if="prevText"
        @click="_click(0)"
      >
        {{ prevText }}
      </yg-button>
      <yg-button
        v-if="nextText"
        type="primary"
        :disabled="nextButtonDisabled"
        :disabled-text="nextButtonDisabledText"
        @click="_click(1)"
      >
        {{ nextText }}
      </yg-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgSteps',
  components: {},
  props: {
    // 当前下标(外部控制下标)
    currentIndex: {
      type: Number,
      default: 0
    },
    // 顶部步骤的两边间距
    stepGap: {
      type: Number,
      default: 50
    },
    items: {
      type: Array,
      default: () => {
        return [
          { title: '步骤1', info: '步骤1描述' }
        ];
      }
    },
    // 下一步(传空字符串就不展示)
    nextText: {
      type: String,
      default: '下一步'
    },
    nextButtonDisabled: {
      type: Boolean,
      default: false
    },
    nextButtonDisabledText: {
      type: String,
      default: ''
    },
    // 上一步(传空字符串就不展示)
    prevText: {
      type: String,
      default: '上一步'
    }
  },
  data () {
    return {};
  },
  methods: {
    _click (e) {
      // 0: 点击上一步，1：点击下一步
      this.$emit('on-click', e);
    }
  }
};
</script>
