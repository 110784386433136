
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('salesOrderNo', '充电币\n购买订单号', 120).setSpan({ canCopy: true }),
        BaseTableColumn.initData('status', '订单状态').setWidth(80).setStatus([
          { value: 1, type: 2 },
          { value: 2, type: 4 },
          { value: 3, type: 1 }
        ], 'statusDesc'),
        BaseTableColumn.initData('tel', '下单人手机号').setWidth(100).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('createTimeStr', '下单时间').setWidth(150).setSpan({}),
        BaseTableColumn.initData('goodsName', '商品名称').setWidth(120).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('price', '商品售价', 100).setSpan({ canPrice: true }),
        BaseTableColumn.initData('totalMoney', '商品明细\n充电币总数量', 100).setSpan({}),
        BaseTableColumn.initData('payMoney', '支付金额', 100).setSpan({ canPrice: true }),
        BaseTableColumn.initData('', '支付', 270).setKeyValueList([
          { label: '支付渠道:', labelWidth: 100, key: 'payChannelDesc', attr: { canTooltip: true } },
          { label: '交易渠道订单:', labelWidth: 100, key: 'gwTradeNo', attr: { canCopy: true } },
          { label: '支付时间:', labelWidth: 100, key: 'payTimeStr', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('', '发放详情', 250).setKeyValueList([
          // { label: '数量:', labelWidth: 70, key: 'giveNum', attr: { canTooltip: true } },
          { label: '状态:', labelWidth: 70, key: 'giveStatusDesc' },
          { label: '流水号:', labelWidth: 70, key: 'transSerialNo', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('', '订单认领', 160).setKeyValueList([
          { label: '物业:', labelWidth: 70, key: 'propertyCompanyName', attr: { canTooltip: true } },
          { label: '省市区:', labelWidth: 70, key: 'addressName', attr: { canTooltip: true } },
          { label: '小区:', labelWidth: 70, key: 'communityName', attr: { canTooltip: true } }
        ])
      ]
    };
  }
};
