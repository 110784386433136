<!--
    Created by 程雨喵'mac on 2024/2/20.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.__g-file-upload {
  position: relative;
  .up-file {
    .display-flex();
    height: 100%;
  }
  .input-file {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .ivu-icon-md-cloud-upload:before {
    margin-right: 6px;
  }
}
</style>

<template>
  <div class="__g-file-upload">
    <div class="up-file">
      <slot />
    </div>
    <!-- 上传文件 -->
    <input
      ref="uploadFile"
      class="input-file"
      type="file"
      accept="image/gif, image/jpeg,image/png"
      @change="handUpload"
    >
  </div>
</template>

<script>
import CommonApiSet from '@/request/api/CommonApiSet';
export default {
  name: 'YgUpload',
  components: {},
  props: {
    uploadKey: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadFile: null
    };
  },
  methods: {
    // 清空导入文件file
    clearImportFile () {
      this.$refs.uploadFile.files = null;
      this.$refs.uploadFile.value = null;
    },
    // 上传文件
    handUpload (event) {
      console.log(event);
      const files = event.target.files || event.dataTransfer.files;
      this.uploadFile = files[0];
      this.clearImportFile();
      this.requestUpload();
    },
    requestUpload () {
      const api = CommonApiSet.fileUpload;
      const formDt = new FormData();
      formDt.append('file', this.uploadFile);
      api.params = formDt;
      this.$httpFormData(api).then(res => {
        const { data } = res;
        this.$emit('on-finish', {
          key: this.uploadKey,
          url: data.url
        });
      });
    }
  }
};
</script>
