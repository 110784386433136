<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：分账比例
-->
<style lang="less">
.charging-pie-setting-bill-setting {
  .top {
    padding-bottom: @containerGap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .__g-button {
      height: auto;
    }
  }
  .bottom-tip {
    .display-flex();
    font-size: 12px;
    color: @textColorLight;
    cursor: help;
    .ivu-icon {
      margin-right: 5px;
    }
  }
}
</style>

<template>
  <div class="charging-pie-setting-bill-setting">
    <div class="top">
      <p class="h4">
        按比例分账
      </p>
      <div>
        <yg-button
          type="text"
          @click="_clickChangeEdit"
        >
          {{ isEdit ? '退出编辑' : '编辑规则' }}
        </yg-button>
      </div>
    </div>
    <v-bill-ratio
      v-if="currentModel"
      ref="billRatio"
      :model="currentModel"
      :is-edit="isEdit"
    />
    <div
      v-if="isEdit"
      class="padding-primary-top display-flex display-flex-h-between"
    >
      <div
        class="bottom-tip"
        @click.stop="_clickShowTip"
      >
        <Icon type="md-help-circle" />
        <span>比例说明</span>
      </div>
      <yg-button
        type="primary"
        @click="_clickSubmit"
      >
        提交数据
      </yg-button>
    </div>
  </div>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import DeviceRatioModel from '../../model/DeviceRatioModel';
import vBillRatio from './billSetting/billRatio.vue';
export default {
  components: { vBillRatio },
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      orgModel: {},
      currentModel: null,
      isEdit: false
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    _clickChangeEdit () {
      if (this.isEdit) {
        // 关闭编辑-先查看是否有变化,没有变化就直接改状态，有变化就要提示
        const oJson = JSON.stringify(this.orgModel);
        const dJson = JSON.stringify(this.currentModel);
        if (oJson === dJson) {
          this.isEdit = false;
        } else {
          this.$Modal.confirm({
            title: '温馨提示',
            content: '数据暂未提交，是否确认退出？',
            onOk: () => {
              this.currentModel = this.orgModel;
              this.isEdit = false;
            }
          });
        }
      } else {
        this.currentModel = DeviceRatioModel.deepCopy(this.orgModel);
        this.isEdit = true;
      }
    },
    _clickShowTip () {
      const p1 = '<p>1、只可输入0~100；</p>';
      const p2 = '<p>2、所有的分账比例总必须等于100；</p>';
      const p3 = '<p>3、分账比例为0%则视为不参与分账；</p>';
      this.$Modal.info({
        title: '比例说明',
        content: p1 + p2 + p3,
        okText: '我知道了'
      });
    },
    _clickSubmit () {
      if (this.$refs.billRatio.$checkSubmit()) {
        const { h5PropertyCompanyRatio, h5ServiceProviderRatio, agentsRatio, h5ChargeCoinPCRatio, h5ChargeCoinSPRatio, chargeCoinARatio } = this.currentModel;
        const params = {
          deviceId: this.model.id,
          // propertyCompanyRatio: parseInt(h5PropertyCompanyRatio),
          // serviceProviderRatio: parseInt(h5ServiceProviderRatio),
          propertyCompanyRatio: parseInt(h5PropertyCompanyRatio * 100),
          serviceProviderRatio: parseInt(h5ServiceProviderRatio * 100),
          chargeCoinPCRatio: parseInt(h5ChargeCoinPCRatio * 100),
          chargeCoinSPRatio: parseInt(h5ChargeCoinSPRatio * 100),
          agentsRatio,
          chargeCoinARatio
        };
        this.requestSubmit(params);
      }
    },
    requestData () {
      const api = DeviceApiSet.deviceSeparateAccount;
      api.params = {
        deviceId: this.model.id
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.orgModel = new DeviceRatioModel();
        this.orgModel.initData(resData);
        this.currentModel = DeviceRatioModel.deepCopy(this.orgModel);
      });
      // this.orgModel = new DeviceRatioModel();
      // this.currentModel = DeviceRatioModel.deepCopy(this.orgModel);
    },
    requestSubmit (params) {
      const api = DeviceApiSet.deviceSeparateAccountSet;
      api.params = params;
      this.$http(api).then(res => {
        this.isEdit = false;
        this.$Message.success('操作成功');
        this.requestData();
      });
    }
  }
};
</script>
