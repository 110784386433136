<!--
    Created by 王丽莎 on 2024/08/08.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：交易管理 - 充电币交易流水 - 扣减记录
    功能概述：扣减记录
-->
<style lang="less">
.deducted-detail-content {
  .ivu-form-item-content {
    line-height: 30px;
  }
}
</style>

<template>
  <yg-modal
    width="520"
    title="扣减记录"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      slot="content"
      style="min-height: 240px"
    >
      <div class="deducted-detail-content">
        <yg-attribute-key-value
          v-for="(item, index) in formItems"
          :key="index"
          :label="item.label"
          :label-width="100"
          :value="model[item.key] + ''"
          :can-hide-phone="item.isHidePhone"
          class="margin-primary-bottom"
        />
      </div>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      // 表单数据
      formItems: [
        {
          label: '用户手机号:',
          key: 'tel',
          isHidePhone: true
        },
        {
          label: '扣减数量:',
          key: 'adjustValue'
        },
        {
          label: '备注:',
          key: 'remark'
        },
        {
          label: '操作日期:',
          key: 'createTime'
        },
        {
          label: '操作人:',
          key: 'createBy'
        }
      ]
    };
  },
  created () {
    console.log(this.model);
  },
  methods: {
    onClose () {
      this.$emit('on-close');
    }
  }
};
</script>
