import BaseModel from '@/libs/base/dataModel/BaseModel';
import md5 from 'md5';

export const HIGH_LIGHT_TEXT1 = '停止原因';
export const HIGH_LIGHT_TEXT2 = '异常代码';
export const HIGH_LIGHT_TEXT3 = '运行结果';

const HEART_BEAT1 = 'CHARGE_HEART_BEAT';
const HEART_BEAT2 = 'STA';
const HEART_BEAT3 = 'CHARGE_DATA';

export class ChargeLogItemModel extends BaseModel {
  constructor () {
    super();
    this.createTime = ''; // 触发时间
    this.instructionName = ''; // 指令名称
    this.instructionType = ''; // 设备指令
    this.instructionInfo = ''; // 指令内容
    this.h5InstructionTypeMd5 = ''; // 设备指令MD5
    this.h5HighlightText = '';
    this.h5IsHeartBeat = false;
  }

  initData (resData) {
    super.initData(resData);
    if (this.instructionInfo) {
      if (this.instructionInfo.includes(HIGH_LIGHT_TEXT1)) {
        this.instructionInfo = this.instructionInfo.replaceAll(HIGH_LIGHT_TEXT1, `<span>${HIGH_LIGHT_TEXT1}</span>`);
        this.h5HighlightText = HIGH_LIGHT_TEXT1;
      } else if (this.instructionInfo.includes(HIGH_LIGHT_TEXT2)) {
        this.instructionInfo = this.instructionInfo.replaceAll(HIGH_LIGHT_TEXT2, `<span>${HIGH_LIGHT_TEXT2}</span>`);
        this.h5HighlightText = HIGH_LIGHT_TEXT2;
      } else if (this.instructionInfo.includes(HIGH_LIGHT_TEXT3)) {
        this.instructionInfo = this.instructionInfo.replaceAll(HIGH_LIGHT_TEXT3, `<span>${HIGH_LIGHT_TEXT3}</span>`);
        this.h5HighlightText = HIGH_LIGHT_TEXT3;
      }
    }
    if (this.instructionType) {
      this.h5InstructionTypeMd5 = md5(this.instructionType);
      // 判断是否是 心跳指令
      this.h5IsHeartBeat = this.instructionType === (HEART_BEAT1) || this.instructionType === (HEART_BEAT2) || this.instructionType === (HEART_BEAT3);
    }
    return this;
  }
}
