<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：用户管理 - 充电业务订单/充电支付订单
    功能概述：充电业务订单、充电支付订单
-->
<style lang="less">
.order-list-content {
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    //background-color: red;
  }
}
</style>

<template>
  <yg-drawer
    width="100%"
    :title="title[type]"
    :back-type="1"
    :show-bottom="false"
    @on-close="onClose"
  >
    <div
      v-if="showContent"
      slot="content"
      class="order-list-content"
    >
      <div
        v-if="showMask"
        class="mask"
        @click.stop="_click"
      />
      <!--用户列表 userListP-->
      <component
        :is="getDrawerElement.tag"
        :parameter-type="parameterType"
        :parameter-key="getDrawerElement.p.key"
        :parameter-value="getDrawerElement.p.value"
        :parameter-is-select="getDrawerElement.p.isSelect"
      />
    </div>
  </yg-drawer>
</template>

<script>
import vUserList from '@/pages/080__userManager/userList/controller.vue';
import vChargingBusinessOrder from '@/pages/060__rechargeManager/chargingBusinessOrder/controller.vue';
import vChargingPayOrder from '@/pages/070__dealManager/chargingPayOrder/controller.vue';
import vChargingRefundOrder from '@/pages/070__dealManager/chargingRefundOrder/controller.vue';
import vDevicePileList from '@/pages/050__deviceManager/chargingPileList/controller.vue';
import vCardUseRecord from '@/pages/060__rechargeManager/cardUseRecord/controller.vue';
import vChargingCoinTransactionRecord from '@/pages/070__dealManager/chargingCoinTransactionRecord/controller.vue';
import vChargingCardList from '@/pages/080__userManager/chargingCardList/controller.vue';
import vChargingCoinSalesOrder from '@/pages/070__dealManager/chargingCoinSalesOrder/controller.vue';
import vRefundCoinApplicationOrder from '@/pages/090__capitalManager/refundCoinApplicationOrder/controller.vue';

export default {
  name: 'YgOrderList',
  components: {
    vUserList,
    vChargingBusinessOrder,
    vChargingPayOrder,
    vChargingRefundOrder,
    vDevicePileList,
    vCardUseRecord,
    vChargingCoinTransactionRecord,
    vChargingCardList,
    vChargingCoinSalesOrder,
    vRefundCoinApplicationOrder
  },
  props: {
    /**
     * 页面类型
     *  1: 客户查询
     *  2: 充电业务订单
     *  3: 充电支付订单
     *  4: 充电退款订单
     *  5: 充电桩管理
     *  6: 刷卡记录
     *  7：充电币交易流水
     *  8：充电卡列表
     *  9：充电币销售订单
     *  10：退币申请订单
     */
    type: {
      type: Number,
      default: () => {
        return null;
      }
    },
    /**
     * 参数类型：
     *  1: 手机号
     *  2: 订单号
     *  3: 设置编码
     *  4: 网点ID数组
     *  5：充电卡卡号
     *  6: 交易渠道订单号
     */
    parameterType: {
      type: Number,
      default: () => {
        return null;
      }
    },
    // 数据
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      showContent: false,
      timer: null,
      title: {
        1: '客户查询',
        2: '充电业务订单',
        3: '充电支付订单',
        4: '充电退款订单',
        5: '充电桩管理',
        6: '刷卡记录',
        7: '充电币交易流水',
        8: '充电卡列表',
        9: '充电币销售订单',
        10: '退币申请订单'
      }
    };
  },
  computed: {
    getDrawerElement () {
      let str = '';
      let dic = null;
      switch (this.type) {
      case 1:
        str = 'v-user-list';
        dic = this.userListP;
        break;
      case 2:
        str = 'v-charging-business-order';
        dic = this.chargingBusinessOrderP;
        break;
      case 3:
        str = 'v-charging-pay-order';
        dic = this.chargingPayOrderP;
        break;
      case 4:
        str = 'v-charging-refund-order';
        dic = this.chargingRefundOrderP;
        break;
      case 5:
        str = 'v-device-pile-list';
        dic = this.devicePileListP;
        break;
      case 6:
        str = 'v-card-use-record';
        dic = this.cardUseRecordP;
        break;
      case 7:
        str = 'v-charging-coin-transaction-record';
        dic = this.chargingCoinTransactionRecordP;
        break;
      case 8:
        str = 'v-charging-card-list';
        dic = this.chargingCardListP;
        break;
      case 9:
        str = 'v-charging-coin-sales-order';
        dic = this.chargingCoinSalesOrderP;
        break;
      case 10:
        str = 'v-refund-coin-application-order';
        dic = this.refundCoinApplicationOrderP;
        break;
      }
      return {
        tag: str,
        p: dic
      };
    },
    showMask () {
      return this.$store.state.app.orderListDrawerCount > this.$config.orderListDrawerCountLimit;
    },
    /**
     * parameter-key=""
     * parameter-value=""
     * parameter-is-select=""
     * @returns {{}}
     */
    // 用户列表
    userListP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        isSelect = true;
        key = ['tel'];
        value = model.userTel || model.tel;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电业务订单
    chargingBusinessOrderP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        isSelect = true;
        key = ['selectOrderLabel', 'tel'];
        value = model.userTel || model.mobile || model.tel;
      } else if (parameterType === 2) {
        value = model.bizNo;
        key = ['selectOrderLabel', 'orderNo'];
      } else if (parameterType === 5) {
        isSelect = true;
        key = ['selectOrderLabel', 'chargeNumber'];
        value = model.cardNumber;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电支付订单
    chargingPayOrderP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        isSelect = true;
        key = ['selectOrderLabel', 'tel'];
        value = model.mobile || model.userTel || model.tel;
      } else if (parameterType === 2) {
        value = model.orgPayTradeNo || model.payTradeNo || model.bizNo;
        key = ['selectOrderLabel', 'payorderNo'];
        isSelect = true;
      } else if (parameterType === 6) {
        value = model.gwTradeNo;
        key = ['selectOrderLabel', 'gwTradeNo'];
        isSelect = true;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电退款订单
    chargingRefundOrderP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 2) {
        if (model.refundOrderNo) {
          value = model.refundOrderNo;
          key = ['selectOrderLabel', 'refundOrderNo'];
        }
        if (model.bizNo || model.orderNo) {
          value = model.bizNo || model.orderNo;
          key = ['selectOrderLabel', 'bizOrder'];
        }
        isSelect = true;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电桩列表
    devicePileListP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 3) {
        isSelect = true;
        key = ['deviceNoLabel', 'deviceNumber'];
        value = model.deviceNumber;
      } else if (parameterType === 4) {
        value = model.siteIdArr;
        key = ['site'];
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 刷卡记录
    cardUseRecordP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 5) {
        isSelect = true;
        key = ['deviceNoLabel', 'cardNumber'];
        value = model.cardNumber;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电币交易流水
    chargingCoinTransactionRecordP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      const isSelect = false;
      if (parameterType === 1) {
        key = ['userTel'];
        value = model.tel;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电卡列表
    chargingCardListP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        key = ['cardNoLabel', 'tel'];
        value = model.tel;
        isSelect = true;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 充电币销售订单
    chargingCoinSalesOrderP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        key = ['orderNoLabel', 'tel'];
        value = model.tel;
        isSelect = true;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    },
    // 退币申请订单
    refundCoinApplicationOrderP () {
      const parameterType = this.parameterType;
      const model = this.model;
      let value = '';
      let key = null;
      let isSelect = false;
      if (parameterType === 1) {
        key = ['orderNoLabel', 'tel'];
        value = model.tel;
        isSelect = true;
      }
      return {
        key: key,
        value: value,
        isSelect: isSelect
      };
    }
  },
  created () {
    this.$store.commit('setOrderListDrawerCountAdd');
    // console.log(this.model);
    this.timer = setTimeout(() => {
      this.showContent = true;
      clearTimeout(this.timer);
      this.timer = null;
    }, 150);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    onClose () {
      this.$store.commit('setOrderListDrawerCountMinus');
      this.$emit('on-close');
    },
    _click () {
      this.$Message.info('页面嵌套过多，请跳转至相关页面进行操作');
    }
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
