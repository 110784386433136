<!--
    Created by 程雨喵'mac on 2024/1/16.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.dialog-global {
  .dialog-title {
    .big {
      line-height: 16px;
      font-weight: bold;
      color: @textColorNormal;
    }
    .small {
      margin-left: 10px;
      font-size: 14px;
      color: @textColorLight;
      line-height: 14px;
      font-weight: 400;
    }
  }
  .div-content {
    padding-bottom: 30px;
    font-size: 12px;
    color: @textColorNormal;
    .row {
      display: flex;
      align-items: flex-start;
      .label {
        margin-right: 10px;
        padding: @containerGap * 0.5 + 2px 0;
        .flex-grow(120px);
        text-align: right;
        color: @textColorLight;
      }
      .value {
        padding: @containerGap * 0.5 0;
        flex: 1;
        .ivu-radio-wrapper {
          margin-bottom: 8px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>

<template>
  <div
    v-transfer-dom
    :data-transfer="true"
    class="dialog-global"
  >
    <yg-modal
      v-if="visible"
      :back-type="1"
      width="560"
      @on-close="dialogClose"
      @on-sure="dialogSure"
    >
      <p
        slot="title"
        class="dialog-title"
      >
        <span class="big">全局设置</span>
        <span class="small">请按照个人习惯配置</span>
      </p>
      <div
        slot="content"
        class="div-content"
      >
        <div
          v-for="(item, i) in items"
          :key="'item-' + i"
          class="row"
        >
          <span
            class="label"
          >{{ item.label }}</span>
          <div class="value">
            <RadioGroup v-model="item.value">
              <Radio
                v-for="(sub, j) in item.options"
                :key="j"
                :label="sub.value"
              >
                {{ sub.label }}
              </Radio>
            </RadioGroup>
          </div>
        </div>
      </div>
    </yg-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false,
      items: [
        {
          key: 'page',
          label: '列表分页',
          value: '',
          options: [
            { label: '每页10条数据', value: '10' },
            { label: '每页20条数据', value: '20' },
            { label: '每页30条数据', value: '30' }
          ]
        },
        {
          key: 'money',
          label: '金额展示方式',
          value: '',
          options: [
            { label: '使用￥', value: '￥' },
            { label: '使用元', value: '元' }
          ]
        },
        {
          key: 'tableLine',
          label: '列表行间分割线',
          value: '',
          options: [
            { label: '无分割线', value: '0' },
            { label: '有分割线', value: '1' }
          ]
        },
        {
          key: 'tableExpand',
          label: '列表全部信息',
          value: '',
          options: [
            { label: '默认展开', value: '1' },
            { label: '默认收起', value: '2' }
          ]
        },
        {
          key: 'tableButton',
          label: '列表按钮大于2个时',
          value: '',
          options: [
            { label: '展示为更多', value: '1' },
            { label: '不做处理', value: '2' }
          ]
        },
        {
          key: 'escClose',
          label: '按esc关闭弹层',
          value: '',
          options: [
            { label: '询问后关闭', value: '1' },
            { label: '直接关闭', value: '2' }
          ]
        }
      ],
      // 原数据-判断是否变更
      orgData: ''
    };
  },
  watch: {
    value () {
      this.visible = this.value;
    }
  },
  created () {
    const globalSetting = this.$store.state.user.globalSetting;
    let maxLength = 0;
    let orgData = '';
    this.items.forEach(v => {
      if (v.label.length > maxLength) {
        maxLength = v.label.length;
      }
      v.value = globalSetting[v.key] || '1';
      orgData += v.value;
    });
    this.orgData = orgData;
  },
  methods: {
    dialogClose () {
      this.visible = false;
      this.$emit('input', false);
      this.$emit('on-change', false);
    },
    dialogSure () {
      // 判断值是否有变化
      let orgData = '';
      const newData = {};
      this.items.forEach(v => {
        orgData += v.value;
        newData[v.key] = v.value;
      });
      if (orgData === this.orgData) {
        // 一样就直接关闭
        this.dialogClose();
      } else {
        this.$Modal.confirm({
          title: '温馨提示',
          content: '修改全需配置后需要重新加载，是否重新加载？',
          onOk: () => {
            this.$ygLoading.show();
            this.$storage.globalSetting = newData;
            setTimeout(() => {
              this.$router.go(0);
            }, 1000);
          }
        });
      }
    }
  }
};
</script>
