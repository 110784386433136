<!--
    Created by 程雨喵'mac on 2024/2/26.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：修改密码
-->
<style lang="less">

</style>

<template>
  <yg-modal
    title="修改密码"
    width="420"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledText"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="employee-password"
    >
      <Form>
        <FormItem
          v-for="(item, i) in inputItems"
          :key="i"
          :label="item.label + ':'"
          :label-width="item.labelWidth"
          :required="item.isRequired"
        >
          <Input
            v-model.trim="formData[item.key]"
            :placeholder="item.placeholder"
            :disabled="item.disabled"
            :type="item.type"
            class="display-flex-1"
            @input="checkSubmit"
          />
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    username: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputItems: [
        { label: '登录账号', labelWidth: 110, placeholder: '', key: 'username', type: 'text', isRequired: true, disabled: true },
        { label: '原密码', labelWidth: 110, placeholder: '请输入原密码', key: 'oldPassword', type: 'password', isRequired: true, disabled: false },
        { label: '新密码', labelWidth: 110, placeholder: '请输入6~8位密码，必须为数字字母组合', key: 'password', type: 'password', isRequired: true },
        { label: '确认新密码', labelWidth: 110, placeholder: '请输入6~8位密码，必须为数字字母组合', key: 'h5PasswordAgain', type: 'password', isRequired: true }
      ],
      formData: {
        username: '',
        oldPassword: '',
        password: '',
        h5PasswordAgain: ''
      },
      buttonDisabled: true,
      buttonDisabledText: ''
    };
  },
  created () {
    this.formData.username = this.username;
    this.checkSubmit();
  },
  methods: {
    onSure () {
      this.$emit('on-sure', this.formData);
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.inputItems.find(v => !this.formData[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledText = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
