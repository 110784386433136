<!--
   抽屉

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-drawer {
  .position(fixed;0;0;0;0);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: @modalIndex;
  font-size: 14px;
  line-height: 14px;
  padding-left: @leftBarCloseWidth;
  background-color: @modalBackground;
  overflow: hidden;
  transition: all @transitionTime15;
  .__g-drawer-inner {
    height: 100%;
    background-color: @backColorStrong;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: all @transitionTime15;
    border-top-left-radius: @borderRadiusMid;
    border-bottom-left-radius: @borderRadiusMid;
    box-shadow: @shadowMax;
    overflow: hidden;
    .__g-drawer-header {
      padding: @containerGap @containerGap @containerGap * 0.5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //background-color: white;
      &:nth-child(1) {
        font-size: 18px;
        line-height: 16px;
        font-weight: bold;
      }
      .ivu-icon {
        cursor: pointer;
        font-size: 28px;
      }
      .bread-case {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        color: @textColorNormal;
        span {
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          &.span-strong {
            font-weight: 600;
            color: @textColorStrong;
          }
        }
        .ivu-icon.arrow {
          margin: 0 5px;
          font-size: 20px;
        }
      }
    }
    .__g-drawer-content {
      padding: 0 15px;
      flex: 1;
      overflow: auto;
    }
    .__g-drawer-footer {
      box-shadow: @shadowMax;
      .footer-inner {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ebebeb;
      }
    }
  }
  &.__g-drawer-back-1 {
    .__g-drawer-inner {
      background-color: @backColorStrong;
    }
    .__g-drawer-footer {
      background-color: @backColorNormal;
    }
  }
  &.__g-drawer-back-2 {
    .__g-drawer-inner {
      background-color: @backColorNormal;
    }
    .__g-drawer-footer {
      background-color: @backColorStrong;
    }
  }
}
</style>

<template>
  <div
    class="__g-drawer"
    :class="'__g-drawer-back-' + backType"
  >
    <transition
      name="left-in"
      @after-leave="handleAfterLeave"
    >
      <div
        v-show="visible"
        :style="containerWidth"
        class="__g-drawer-inner"
      >
        <!--标题-->
        <div class="__g-drawer-header">
          <div v-if="title">
            <!--  面包屑  -->
            <h3 class="bread-case">
              <span
                v-if="currentRoute.meta.parentTitle"
              >{{ currentRoute.meta.parentTitle }}</span>
              <Icon
                v-if="currentRoute.meta.parentTitle"
                type="ios-arrow-forward"
                class="arrow"
              />
              <span>{{ currentRoute.meta.title }}</span>
              <Icon
                v-if="currentRoute.meta.parentTitle"
                type="ios-arrow-forward"
                class="arrow"
              />
              <span class="span-strong">{{ title }}</span>
            </h3>
          </div>
          <p v-else>
            <slot name="title" />
          </p>
          <span
            v-if="closeable"
            @click.stop="_close"
          >
            <Icon type="md-close" />
          </span>
        </div>
        <!--内容-->
        <div class="__g-drawer-content">
          <slot name="content" />
        </div>
        <!--底部按钮-->
        <div
          v-if="showBottom"
          class="__g-drawer-footer"
        >
          <div class="footer-inner">
            <div>
              <slot name="button" />
            </div>
            <yg-button
              v-if="sureText"
              type="primary"
              :disabled="sureButtonDisabled"
              :disabled-text="sureButtonDisabledText"
              @click="_sure"
            >
              {{ sureText }}
            </yg-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { onHandler, offHandler } from '@/libs/utils.js';
export default {
  name: 'YgDrawer',
  components: {},
  props: {
    width: {
      type: [Number, String],
      // box-sizing:border-box
      default: 300
    },
    title: {
      type: String,
      default: ''
    },
    sureText: {
      type: String,
      default: '确认'
    },
    closeable: {
      type: Boolean,
      default: true
    },
    showBottom: {
      type: Boolean,
      default: true
    },
    sureButtonDisabled: {
      type: Boolean,
      default: false
    },
    sureButtonDisabledText: {
      type: String,
      default: ''
    },
    backType: {
      type: Number,
      // 1: 默认白色，2：底色
      default: 1
    },
    /**
     * true时，点击右上角关闭后会先关闭抽屉，再执行on-close
     * false时，点击右上角关闭，只执行on-close
     */
    autoClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: false,
      currentRoute: {
        meta: {}
      },
      // 判断抽屉打开时左侧导航是否展开的状态，如果是，那么关闭抽屉时要复原
      barIsOpenWhenShow: false
    };
  },
  computed: {
    containerWidth () {
      const temp = this.width + '';
      if (temp.includes('%') || temp.includes('px')) {
        return 'width:' + this.width;
      }
      return 'width:' + this.width + 'px';
    }
  },
  created () {
    onHandler(window, 'keydown', this.addKeyboardObserver);
    const cc = this.$storage.autoConfig;
    const lastShow = cc.lastShowEscTip;
    const t = (new Date()).getTime() - lastShow;
    if (t > 604800000) {
      cc.lastShowEscTip = (new Date()).getTime();
      this.$storage.autoConfig = cc;
      // 一周弹一次
      this.$Notice.info({
        title: '操作提示',
        desc: '按ESC可关闭抽屉，本条提示近一周内不会展示。请手动关闭此提示!',
        duration: 0
      });
    }
  },
  mounted () {
    this.visible = true;
    document.body.style.overflow = 'hidden';
    const route = this.$route;
    this.currentRoute = {
      name: route.name,
      meta: route.meta,
      path: route.path
    };
    this.barIsOpenWhenShow = this.$store.state.app.leftBarOpen;
    this.$store.commit('setLeftBarOpen', false);
  },
  beforeDestroy () {
    offHandler(window, 'keydown', this.addKeyboardObserver);
    document.body.style.overflow = 'auto';
    if (this.barIsOpenWhenShow) {
      this.$store.commit('setLeftBarOpen', true);
    }
  },
  methods: {
    addKeyboardObserver (e) {
      if (e.keyCode === 27) { // 27是esc
        if (this.$store.state.user.globalSetting.escClose === '2') return this._close();
        this.$Modal.confirm({
          title: '温馨提示',
          content: '<p style="font-size: 16px;">是否确定关闭抽屉？</p><p style="color: #a0a0a0;font-size: 12px">*注:可在全局设置中关闭询问</p>',
          onOk: () => {
            this._close();
          }
        });
      }
    },
    handleAfterLeave () {
      this.$emit('on-close');
    },
    _sure () {
      this.$emit('on-sure');
    },
    _close () {
      if (this.autoClose) {
        this.visible = false;
      } else {
        this.$emit('on-close');
      }
    }
  }
};
</script>
