/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 一些全局的接口
 */

import BaseApi from '@/request/base/BaseApi';

class DownloadApiSet {
  constructor () {
    this.downloadCreate = BaseApi.createModel('下载中心-生成', '/opmp/report/download/generate', true).setMethod(1);
    this.downloadList = BaseApi.createModel('下载中心-列表', '/opmp/report/download/list', true).setMethod(1);
    this.downloadGet = BaseApi.createModel('下载中心-下载', '/opmp/report/download/get', true).setMethod(1);
  }
}

export default new DownloadApiSet();
