import BaseModel from '@/libs/base/dataModel/BaseModel';

export class ChargingPayOrderDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 支付订单编码
    this.outTradeNo = '';
    // 支付账号
    this.transAccount = '';
    this.channelId = '';
    // 状态  0：待支付/待退款 1：支付成功/退款成功 2：支付关闭
    this.payStatus = '';
    // 支付状态描述
    this.payStatusDesc = '';
    // 支付金额
    this.amount = '';
    // 支付渠道
    this.channelName = '';
    // 退款时间
    this.refundTime = '';
    // 退款订单编码
    this.refundOrderNo = '';
    // 充电订单编码
    this.bizNo = '';
    // 支付渠道订单编码
    this.gwTradeNo = '';
    // 用户手机号
    this.userTel = '';
    // 充电业务类型 启动方式
    this.chargeType = '';
    // 充电业务类型描述 启动方式
    this.chargeTypeDesc = '';
    // 支付时间
    this.payTime = '';
    // 退款状态
    this.refundStatus = '';
    // 退款状态描述
    this.refundStatusDesc = '';
    // 省
    this.provinceName = '';
    // 市
    this.cityName = '';
    // 小区名称
    this.blockName = '';
    // 网点名称
    this.siteName = '';
    // 设备编码
    this.deviceNumber = '';
    // 设备imei号
    this.imei = '';
    // 设备名称
    this.deviceName = '';
    // 数据来源 0->内部、1->小橙
    this.sourceType = 0;
    // 数据来源描述 0->内部、1->小橙
    this.sourceTypeDesc = '';
    // 设备型号名称
    this.deviceModelName = '';
    // 交易单号
    this.tripartiteTradeNo = '';
    // 商户单号
    this.merchantNo = '';
    // 人工退款金额
    this.manualRefundMoney = '';
    // 人工退款订单编码
    this.manualRefundOrderNo = '';
    // 自动退款金额
    this.autoRefundMoney = '';
    // 自动退款订单号
    this.autoRefundOrderNo = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 所属省市地
    this.h5AddressName = '';
    // 小橙支付用户编码-判断渠道为小橙时取userTel字段
    this.h5XCUserTel = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    if (this.provinceName && this.cityName) {
      this.h5AddressName = this.provinceName + this.cityName;
    }
    if (this.sourceType === 1) {
      this.h5XCUserTel = this.userTel;
      this.userTel = '-';
    }
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }
}
