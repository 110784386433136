<!--
    Created by 程雨喵'mac on 2022/9/29.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.__g-reactive-box {
  display: flex;
  flex-wrap: wrap;
}
</style>

<template>
  <div
    class="__g-reactive-box"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'YgReactiveBox',
  components: {},
  props: {},
  data () {
    return {};
  }
};
</script>
