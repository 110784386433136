<!--
    Created by 程雨喵'mac on 2023/12/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：获取短信验证码按钮
-->
<style lang="less">
.__g-otp-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  white-space: nowrap;
  &.__g-otp-button-type {
    &-1 {
      // 常规按钮
      width: 100px;
      height: @formHeight;
      border-radius: @borderRadiusMin;
      background-color: @buttonColorDisabled;
      color: @textColorFlip;
      cursor: not-allowed;
      &.__g-otp-can-click {
        background-color: @backColorStrong;
        color: @textColorPrimary;
        cursor: pointer;
      }
    }
    &-2 {
      // 文字按钮
      color: @textColorLight;
      cursor: not-allowed;
      &.__g-otp-can-click {
        color: @textColorPrimary;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.__g-otp-modal-content {
  //padding: 40px 0;
  padding-bottom: @containerGap * 2;
  .input-code-img-big {
    position: fixed;
    left: 50%;
    transform: translateX(-30px);
    padding: 10px;
    .display-flex();
    top: 0;
    background-color: rgba(255,255,255,0.8);
    border-radius: @borderRadiusMid;
    img {
      height: 100px;
    }
  }
  .code-form-row {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .code-label {
      margin-right: 5px;
      .flex-grow(70px);
      color: @textColorLight;
      text-align: right;
    }
    .code-input {
      flex: 1;
      height:  ~'calc(@{formHeight} + 8px)';
      line-height:  ~'calc(@{formHeight} + 8px)';
    }
    .img-code {
      position: relative;
      width: 110px;
      padding: 0 5px;
      margin-left: 10px;
      .display-flex();
      height:  ~'calc(@{formHeight} + 8px)';
      background-color: #f5f5f5;
      border-radius: @borderRadiusMin;
      overflow: hidden;
      border: 1px solid @lineColorNormal;
      cursor: pointer;
      color: @textColorLight;
      &:hover {
        border-color: @themeColor;
        .ivu-icon {
          color: @themeColor;
        }
      }
      img {
        .flex-grow(80px);
        height: ~'calc(@{formHeight})';
        border-radius: @borderRadiusMin;
        &.error-img {
          .flex-grow(~'calc(@{formHeight} * 0.6)');
          height: auto;
        }
      }
      .refresh-icon {
        .flex-grow(20px);
        .display-flex();
        .ivu-icon {
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="__g-otp-button"
    :class="optButtonClass"
    @click.stop="_clickPic"
  >
    <span>{{ buttonText }}</span>
    <!--图形验证码小弹窗-->
    <div
      ref="picCodeModal"
      v-transfer-dom
      data-transfer="true"
    >
      <yg-modal
        v-if="showPicCodeDialog"
        :width="360"
        title="请输入图形验证码"
        :back-type="2"
        sure-text="点击获取"
        :sure-button-disabled="!picCode.verifyInputValue"
        sure-button-disabled-text="请输入图形验证码"
        @on-sure="_clickOtp"
        @on-close="_picDialogClose"
      >
        <div
          slot="content"
          class="__g-otp-modal-content"
        >
          <div
            v-if="showBigImgCode"
            class="input-code-img-big"
          >
            <img
              :src="picCode.verifyUrl || '/icon/img_error.png'"
            >
          </div>
          <div class="code-form-row">
            <span class="code-label">验证码:</span>
            <Input
              v-model="picCode.verifyInputValue"
              type="text"
              clearable
              :maxlength="6"
              class="code-input"
              @on-focus="onInputFocus"
              @on-blur="onInputBlur"
            />
            <div
              class="img-code"
              @click.stop="_clickCode"
            >
              <img
                :src="picCode.verifyUrl || '/icon/img_error.png'"
                :class="picCode.verifyUrl ? '' : 'error-img'"
              >
              <div class="refresh-icon">
                <Icon type="md-refresh" />
              </div>
            </div>
          </div>
        </div>
      </yg-modal>
    </div>
  </div>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
import CommonApiSet from '@/request/api/CommonApiSet';
const COUNT_DOWN = 60;
const COUNT_GAP = 1; // 一秒
export default {
  name: 'YgOtpButton',
  components: {},
  props: {
    /**
     * 按钮类型
     * 1: 常规按钮
     * 2：文字+下划线
     */
    buttonType: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledText: {
      type: String,
      default: ''
    },
    // 是否登录后才可以调用
    isUnAuth: {
      type: Boolean,
      default: false
    },
    // 用登录账号获取
    username: {
      type: String,
      default: ''
    },
    /**
     * 需要发送的手机号
     */
    tel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      picCode: {
        verifyInputValue: '',
        verifyUrl: '',
        verifyId: '',
        verifyCodeLength: '4' // 默认是4
      },
      timer: null,
      count: COUNT_DOWN, // 倒计时
      showPicCodeDialog: false,
      showBigImgCode: false
    };
  },
  computed: {
    buttonCanClick () {
      if (this.disabled) return false;
      return this.count >= COUNT_DOWN;
    },
    optButtonClass () {
      let classStr = `__g-otp-button-type-${this.buttonType}`;
      if (this.buttonCanClick) {
        classStr += ' __g-otp-can-click';
      }
      return classStr;
    },
    buttonText () {
      if (this.buttonCanClick) {
        return '获取验证码';
      } else if (this.disabled) {
        return '获取验证码';
      }
      return `${this.count}秒后重新获取`;
    }
  },
  created () { },
  destroyed () {
    this.clearTimer();
    if (this.$refs.picCodeModal) {
      this.$refs.picCodeModal.remove();
    }
  },
  methods: {
    onInputFocus (e) {
      this.showBigImgCode = true;
    },
    onInputBlur () {
      this.showBigImgCode = false;
    },
    startTimer () {
      if (this.timer) return;
      this.count = COUNT_DOWN - COUNT_GAP;
      this.timer = setInterval(() => {
        const c = this.count - COUNT_GAP;
        if (c < 1) {
          this.count = COUNT_DOWN;
          this.clearTimer();
        } else {
          this.count = c;
        }
      }, COUNT_GAP * 1000);
    },
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    _clickPic () {
      if (this.disabled && this.disabledText) {
        return this.$Message.warning(this.disabledText);
      }
      if (!this.buttonCanClick) return;
      this.requestCode();
      this.showPicCodeDialog = true;
    },
    _clickCode () {
      this.requestCode();
    },
    _picDialogClose () {
      this.showPicCodeDialog = false;
    },
    _clickOtp () {
      if (this.picCode.verifyInputValue) {
        this.requestSend();
      }
    },
    requestSend () {
      const api = this.isUnAuth ? CommonApiSet.sendMsgCodeUnauth : CommonApiSet.sendMsgCode;
      api.params = {
        code: this.picCode.verifyInputValue,
        uuid: this.picCode.verifyId
      };
      if (this.isUnAuth) {
        api.params.username = this.username;
      } else {
        api.params.mobile = this.tel;
      }
      this.$http(api).then(res => {
        this.$Message.success('验证码已发送');
        this.startTimer();
        this.showPicCodeDialog = false;
        this.$emit('on-send');
      }).catch(e => {
        // this.$Message.success('验证码已发送，请注意查收');
        // this.startTimer();
        // this.showPicCodeDialog = false;
        // this.$emit('on-send');
        this._clickCode();
      });
    },
    requestCode () {
      const api = UserApiSet.captchaApi;
      this.$http(api).then(res => {
        console.log(res);
        const resData = res.data;
        this.picCode.verifyUrl = 'data:image/png;base64,' + resData.image;
        this.picCode.verifyId = resData.uuid;
        this.picCode.verifyInputValue = '';
      });
    }
  }
};
</script>
