import { DeviceListModel } from '../model/DeviceDataModel';
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('', '设备信息', 160).setKeyValueList([
          { label: '设备编码:', labelWidth: 60, key: 'deviceNumber', attr: { canCopy: this.$config.env === 'prod', canClick: this.$config.env !== 'prod', canTooltip: this.$config.env !== 'prod' } },
          { label: '设备名称:', labelWidth: 60, key: 'name', attr: { canTooltip: true } }
        ], (row) => {
          this.dialog.qrCodeModel = row;
        }),
        BaseTableColumn.initData('devicePortCount', '设备\n端口', 60).setValueButton([], (row, index) => {
          // console.log(row);
          this.dialog.portModel = row;
        }),
        // BaseTableColumn.initData('isOnline', '在线状态').setWidth(90).setStatus([
        //   { value: 0, type: 1 },
        //   { value: 1, type: 2 }
        // ], 'h5IsOnlineName'),
        BaseTableColumn.initData('signal', '信号状态').setWidth(70).setRender((h, { row, index }) => {
          return h('yg-table-signal', {
            attrs: {
              range: [0, 31],
              value: row.signal,
              statusValue: row.isOnline,
              statusDesc: row.h5IsOnlineName
            }
          });
        }),
        BaseTableColumn.initData('deviceOfflineCount', '当日\n离线次数', 70).setValueButton([0], (row, index) => {
          console.log(row.deviceOfflineCount);
          this.dialog.deviceOfflineModel = row;
        }),
        // BaseTableColumn.initData('iccid', 'SIM卡ID').setSpan({ canCopy: true }),
        // BaseTableColumn.initData('communityName', '小区名称').setSpan({}),
        BaseTableColumn.initData('siteName', '省/市/区', 160).setKeyValueList([
          { label: '省份:', labelWidth: 40, key: 'provinceName', attr: { canTooltip: true } },
          { label: '城市:', labelWidth: 40, key: 'cityName', attr: { canTooltip: true } },
          { label: '区县:', labelWidth: 40, key: 'districtName', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('siteName', '小区/点位', 160).setKeyValueList([
          { label: '小区:', labelWidth: 40, key: 'communityName', attr: { canTooltip: true } },
          { label: '点位:', labelWidth: 40, key: 'siteName', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('contact', '主从类型').setWidth(140).setRender((h, { row, index }) => {
          const arr = [h('div', {}, row.belongTypeDesc)];
          if (row.belongDeviceNumber) {
            arr.push(h('yg-attribute-key-value', {
              props: {
                label: '主机:',
                labelWidth: 68,
                value: row.belongDeviceNumber
              }
            }));
          }
          return arr;
        }),
        // BaseTableColumn.initData('operationTypeDesc', '运营类型').setSpan({ canTooltip: true }),
        // BaseTableColumn.initData('remark', '设备备注').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('softVersion', '软件\n版本号', 100).setSpan({ canCopy: true }),
        BaseTableColumn.initData('installTime', '安装时间').setWidth(85).setRender((h, { row, index }) => {
          let items = [];
          if (row.installTime) {
            items = [
              h('div', {}, row.h5InstallDate),
              h('div', {}, row.h5InstallTime)
            ];
          } else {
            items = [
              h('div', {}, '-')
            ];
          }
          return items;
        }),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setWidth(70).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:device:stopAndStart'),
        BaseTableColumn.initData('setting', '设置').setWidth(80).setAlign('center').setButtons([
          { title: '设置计费', name: 0, permission: 'system:device:price:set' },
          { title: '设置分账', name: 1, permission: 'system:device:cost:set' },
          { title: '设置运营', name: 2, permission: 'system:device:operationType:set' },
          { title: '设置高级参数', name: 40, permission: '' }
        ], ({ name }, row, index) => {
          if (name === 40) {
            this.dialog.advancedModel = row;
            return;
          }
          this.dialog.settingIndex = name;
          this.dialog.settingModel = row;
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(80).setAlign('center').setButtons([
          { title: '删除设备', name: 'del', permission: 'system:device:delete' },
          { title: '重新启动', name: 'reset', permission: 'system:device:reboot' },
          { title: '清除缓存', name: 'clearCache', permission: 'system:device:memory:clear' },
          { title: '更改信息', otherSettingIndex: 0, permission: 'system:device:change:deviceInfo' },
          { title: '调整点位', otherSettingIndex: 1, permission: 'system:device:change:site' }
        ], ({ name, otherSettingIndex }, row, index) => {
          if (name) {
            this.requestSetting(name, row);
          } else {
            this.dialog.otherSettingModel = DeviceListModel.deepCopy(row);
            this.dialog.otherSettingIndex = otherSettingIndex;
          }
        })
      ]
    };
  }
};
