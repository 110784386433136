/**
 * @date:2023/11/01
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

// 页面顶部搜索使用的每个item
class BaseSettingModel extends Object {
  constructor () {
    super();
    this.title = '';
    this.key = '';
    this.icon = null;
    this.permission = '';
    /**
     * 报表类型
     * 1: 设备出厂记录导出
     * 2：设备出厂记录-服务商报表
     * 3：设备-充电桩报表
     * 4：设备出厂记录-二维码
     * 5：设备出厂记录-服务商二维码
     * 6：设备-充电桩二维码
     * 7：充电支付订单
     * 8：充电退款订单
     * 9：用户管理列表
     * 10：分账账单列表
     * 11: 充电业务订单
     */
    this.exportReportType = null;
    this.isExport = false;
  }

  // 深拷贝
  static deepCopy (model) {
    // 1.先通过序列化与反序列化，开辟新的内存空间
    const temp = JSON.parse(JSON.stringify(model));
    const resModel = new BaseSettingModel();
    for (const key in temp) {
      resModel[key] = temp[key];
    }
    return resModel;
  }

  setExport () {
    this.isExport = true;
    return this;
  }

  setPermission (permission) {
    this.permission = permission;
    return this;
  }

  setExportReportType (value) {
    /**
     * 报表类型
     * 1: 设备出厂记录导出
     * 2：设备出厂记录-服务商报表
     * 3：设备-充电桩报表
     * 4：设备出厂记录-二维码
     * 5：设备出厂记录-服务商二维码
     * 6：设备-充电桩二维码
     * 7：充电支付订单
     * 8：充电退款订单
     * 9：用户管理列表
     * 10：充电业务订单
     * 11: 分账账单管理-汇总
     * 12: 分账账单管理-明细
     * 13: 订单分账明细
     * 14: 小橙设备数据
     * 15: 经营数据
     * 16: 友电设备数据
     * 17: 导入经营下载成功或失败数据
     * 18: 打款凭证数据
     */
    this.exportReportType = value;
    return this;
  }

  // 类方法-遍历构造器
  static initData (title, key, icon) {
    if (title && key) {
      const model = new BaseSettingModel();
      model.title = title;
      model.key = key;
      model.icon = icon;
      return model;
    }
    throw (new Error('缺少必传参数'));
  }
}

export default BaseSettingModel;
