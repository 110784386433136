<!--
   表格-适配手机端

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-table {
  @keyframes tableShowAnim {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  opacity: 0;
  @tableRowHeight: 42px;
  @tableHeaderHeight: 42px;
  position: relative; // 这个不能去掉，不然下拉按钮会看不见
  animation: .2s tableShowAnim forwards;
  animation-delay: .1s;
  // 重写iview-table的样式
  //padding: @containerGap;
  //background-color: white;
  .vuex-table-control-count {
    position: fixed;
    left: -100vw;
    top: 0;
  }
  &.__g-has-table-line {
    .ivu-table-wrapper {
      .ivu-table {
        td {
          border-color: @lineColorNormal;
        }
      }
    }
  }
  .ivu-table-wrapper {
    //border-top-left-radius: @borderRadiusMid;
    //border-top-right-radius: @borderRadiusMid;
    .ivu-table {
      font-size: 12px;
      color: @textColorNormal;
      background-color: transparent;
      .ivu-table-header, .ivu-table-fixed-header {
        //border-top: 1px solid @lineColorNormal;
        table {
          border-color: transparent;
          color: @textColorNormal;
        }
        th {
          background-color: @backColorStrong;
          border-color: @lineColorNormal;
          height: @tableHeaderHeight;
          padding: 2px 0;
          //background-color: @under-color !important;
          // font-size: 12px;
        }
        td {
          height: @tableHeaderHeight;
        }
      }
      border-top: 1px solid @lineColorNormal;
      td {
        line-height: 18px;
        border-color: transparent;
        height: @tableRowHeight;
      }
      .ivu-table-row {
        min-height: 70px;
        .ivu-table-cell-with-expand {
          .display-flex();
          height: @tableRowHeight;
          line-height: @tableRowHeight;
          .ivu-table-cell-expand-expanded {
            .display-flex();
          }
        }
      }
      .ivu-table-cell {
        max-height: 78px;
        padding: 4px 5px;
        .ivu-checkbox-wrapper {
          margin-right: 0;
        }
      }
      .ivu-table-fixed-header, .ivu-table-header{
        th {
          .ivu-table-cell {
            span {
              //white-space: nowrap;
              display: block;
              //line-height: 26px;
              line-height: 14px;
              white-space: pre-line;
            }
          }
        }
      }
      .ivu-table-cell-tooltip {
        .ivu-table-cell-tooltip-content {
          word-break:break-all;
          display:-webkit-box;
          -webkit-line-clamp:4;
          -webkit-box-orient:vertical;
          overflow:hidden;
          white-space: inherit;
        }
      }
      .ivu-btn.ivu-btn-text[disabled] {
        color: #c5c8ce;
        background-color: transparent;
      }
      .ivu-btn-text {
        color: @themeColor;
        outline: 0;
        box-shadow: none;
        &:hover {
          background-color: transparent;
          opacity: 0.8;
        }
      }
    }
    //间隔颜色
    .ivu-table-stripe {
      .ivu-table-body {
        .ivu-table-row:nth-child(1n) {
          td {
            background-color: @backColorNormal;
          }
        }
        .ivu-table-row:nth-child(2n) {
          td {
            background-color: @backColorStrong;
          }
        }
      }
      .ivu-table-row-hover {
        td {
          background-color: transparent;
        }
      }
    }
  }
  // 重写iview-page的样式
  .table-pager {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    font-size: 12px;
    @itemHeight: 26px;
    .ivu-page.__g-pager {
      .ivu-page-item, .ivu-page-prev, .ivu-page-next {
        margin-right: 10px;
        border-radius: @borderRadiusMin !important;
        border-color: @lineColorNormal;
        line-height: @itemHeight - 2px;
        min-width: @itemHeight;
        height: @itemHeight;
        background-color: @backColorStrong;
        color: @textColorNormal;
        &.ivu-page-item-active {
          color: @textColorFlip;
          background-color: @themeColor;
          //border-color: @themeColor;
          a {
            color: @textColorFlip !important;
          }
        }
        a {
          color: @textColorNormal;
        }
        &:hover {
          color: @themeColor;
          border-color: @themeColor;
          a {
            color: @themeColor;
          }
        }
      }
      .ivu-page-options-elevator {
        input {
          border-radius: @borderRadiusMin !important;
          border-color: @lineColorNormal;
          height: @itemHeight;
          &:hover {
            border-color: @themeColor;
          }
          &:focus {
            border-color: @themeColor;
          }
        }
      }
    }
  }
  // 新增样式
  .ivu-table-wrapper {
    .ivu-table {
      .row-is-not-active {
        opacity: 0.9;
        color: @textColorLight;
        &:hover {
          opacity: 1;
          color: @textColorNormal;
        }
      }
    }
  }
  &.__g-table-pc {
    overflow: hidden;
    .table-pager {
      margin-top: @containerGap;
      height: 34px;
    }
  }
}
</style>
<template>
  <div
    ref="globalTable"
    :class="tableOuterClass"
    class="__g-table"
  >
    <div class="vuex-table-control-count">
      {{ vuexTableControlCount }}
    </div>
    <Table
      ref="currentTable"
      :no-data-text="noDataText"
      :border="false"
      :columns="columns"
      :data="data"
      stripe
      :height="tableHeight"
      :max-height="maxHeight"
      :row-class-name="rowClassName"
      @on-expand="_clickExpand"
      @on-row-click="_clickRow"
      @on-selection-change="_selectionChange"
    />
    <div
      v-if="showPager && page.total > pageSize"
      class="table-pager"
    >
      <Page
        ref="tablePager"
        :current="page.current"
        :total="page.total"
        :page-size="pageSize"
        :show-total="!smallScreen"
        :show-elevator="!smallScreen"
        class-name="__g-pager"
        @on-change="_pageChange"
      />
    </div>
  </div>
</template>

<script>
import { onHandler, offHandler, getElementTop } from '@/libs/utils.js';
export default {
  name: 'YgTable',
  components: {},
  props: {
    // 下面四个属性是iView中table组件的原生属性，不做说明
    rowClassName: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 如果传值了就用这个，否则用下面tableHeight的逻辑
    // -1则展示数据内容高度，无滚动条
    maxHeight: {
      type: Number,
      default: 0
    },
    // 下面是此项目全局table中声明的新的属性
    /**
     * 是否展示分页
     * 默认为展示
     */
    showPager: {
      type: Boolean,
      default: true
    },
    /**
     * page相关3件套
     */
    page: {
      type: Object,
      default: () => {
        return { current: 1, total: 0 };
      }
    },
    // 是否是全量展示
    pageSizeInfinite: {
      type: Boolean,
      default: false
    },
    // 外部控制是否重新对table高度赋值，当此值有变化时就会重新赋值table高度
    controlResize: {
      type: Boolean,
      default: false
    },
    // 默认底部减去的偏移量
    bottomOffset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      pageSize: this.$config.pageSize,
      tableHeight: 0,
      noDataTextStyle: 'width: 100%;height: 320px;display: flex;align-items: center;justify-content: center;flex-direction: column',
      noDataText: '',
      // 小屏幕，小于700设为小屏幕，需要展示手机端样式
      smallScreen: false
    };
  },
  computed: {
    tableOuterClass () {
      let className = this.smallScreen ? '__g-table-h5' : '__g-table-pc';
      if (this.$store.state.user.globalSetting.tableLine === '1') {
        className += ' __g-has-table-line';
      }
      return className;
    },
    vuexTableControlCount () {
      this.handleResize();
      return this.$store.state.app.controlTableResizeCount;
    }
  },
  watch: {
    data () {
      this.handleResize();
    },
    controlResize (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleResize();
      }
    }
  },
  created () {
    if (this.pageSizeInfinite) {
      this.pageSize = 99999;
    }
    this.noDataText = '<div class="__g-no-data"></div>';
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.maxHeight) {
        this.handleResize();
      }
      onHandler(window, 'resize', this.handleResize);
    });
  },
  destroyed () {
    offHandler(window, 'resize', this.handleResize);
  },
  methods: {
    handleResize () {
      if (this.maxHeight) {
        return;
      }
      console.log('table-handleResize======================>');
      // 这里加延迟是应为团餐项目中的search-area高度是延迟赋值的，因此table的顶部y获取不准，别的项目不要加延迟
      setTimeout(() => {
        const currentRowTable = this.$refs.globalTable;
        this.smallScreen = window.innerWidth < 700;
        const top = getElementTop(currentRowTable);
        // 窗口高度 - table的y - 底部page的高度 - 1(减一是因为如果不是整数会四舍五入) - 内容区域距离浏览器底部的间距
        let tableHeight = window.innerHeight - top - 1 - 15 * 2 - this.bottomOffset;
        if (this.showPager && (this.page.total > this.pageSize)) {
          if (this.smallScreen) {
            // 小屏时 page高度为70
            tableHeight = tableHeight - 70;
          } else {
            // 减顶部margin与高度34
            tableHeight = tableHeight - 15 - 34;
          }
        }
        this.tableHeight = tableHeight;
      }, 100);
    },
    _selectionChange (e) {
      // console.log(e);
      this.$emit('on-selection', e);
    },
    _clickRow (row, index) {
      this.$emit('on-row-click', row, index);
    },
    _pageChange (page) {
      this.$emit('on-page-change', page);
    },
    _clickExpand (row, status) {
      this.$emit('on-row-expand', { row, status });
    }
  }
};
</script>
