/**
 * storage-所有Key
 * 外部使用：外部不要使用
 *
 * @date:2021/11/08
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

// 存
export const saveStorage = (key, value, day = 30) => {
  let expire = -1;
  if (day && typeof day === 'number') {
    expire = new Date().getTime() + (day * 24 * 60 * 60 * 1000);
  }
  window.localStorage.setItem(key, JSON.stringify({ data: value, expire: expire }));
};
// 取(取的时候，如果过期了就删掉)
export const loadStorage = (key) => {
  const data = window.localStorage.getItem(key);
  if (!data) return '';
  try {
    const dataObj = JSON.parse(data);
    if (dataObj.expire === -1) return dataObj.data;
    const now = new Date().getTime();
    if (now >= dataObj.expire) {
      window.localStorage.removeItem(key);
      return '';
    }
    return dataObj.data;
  } catch (e) {
    return '';
  }
};
