
// 设备端口数据模型
import BaseModel from '@/libs/base/dataModel/BaseModel';
export class DeviceOfflineRecordModel extends BaseModel {
  constructor () {
    super();
    // 离线开始时间
    this.createTime = '';
    // 离线结束时间
    this.offlineEndTime = '';
    // 离线时长
    this.offlineDuration = '';
    // 是否今日 （false-否 true-是）
    this.today = null;
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        createTime: '2024-10-16 10:28:45',
        offlineEndTime: '2024-01-01 12:34:56',
        offlineDuration: BaseModel.getFakeNumber(10),
        today: true
      };
      list.push(dic);
    }
    return list;
  }
}
