<!--
   抽屉

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.__g-modal-primary {
  .position(fixed;0;0;0;0);
  overflow: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // 所有dialog都要1000
  z-index: @modalIndex;
  font-size: 14px;
  line-height: 14px;
  background-color: @modalBackground;
  padding: @topBarHeight;
  .__g-modal-con {
    max-height: 100%;
    padding: @containerGap 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid @lineColorNormal;
    box-shadow:0 0 12px 4px rgba(0,47,154,0.2);
    border-radius:6px;
    color: @textColorNormal;
    transition: all @transitionTime15;
    //background-color: white;
    &.__g-modal-back-type-1 {background-color: @backColorStrong;}
    &.__g-modal-back-type-2 {background-color: @backColorNormal}
    .__g-modal-header {
      padding: 4px 15px @containerGap;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: @textColorStrong;
      &:nth-child(1) {
        font-size: 18px;
        line-height: 16px;
        font-weight: bold;
      }
      .ivu-icon {
        cursor: pointer;
        font-size: 28px;
      }
    }
    .__g-modal-content {
      padding: 0 15px;
      flex: 1;
      overflow: auto;
    }
    .__g-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: @containerGap;
      background-color: @backColorLight;
    }
  }
}
</style>

<template>
  <div
    role="alert"
    class="__g-modal-primary"
  >
    <transition
      name="top-in"
      @after-leave="handleAfterLeave"
    >
      <div
        v-show="visible"
        :style="containerWidth"
        class="__g-modal-con"
        :class="'__g-modal-back-type-' + backType"
      >
        <!--标题-->
        <div class="__g-modal-header">
          <p v-if="title">
            {{ title }}
          </p>
          <p v-else>
            <slot name="title" />
          </p>
          <span
            v-if="closeable"
            @click.stop="_close"
          >
            <Icon type="md-close" />
          </span>
        </div>
        <!--内容-->
        <div class="__g-modal-content">
          <slot name="content" />
        </div>
        <!--底部按钮-->
        <div
          v-if="showBottom"
          class="__g-modal-footer"
        >
          <div>
            <slot name="button" />
          </div>
          <yg-button
            v-if="sureText"
            type="primary"
            :disabled="sureButtonDisabled"
            :disabled-text="sureButtonDisabledText"
            @click="_sure"
          >
            {{ sureText }}
          </yg-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { onHandler, offHandler } from '@/libs/utils.js';
export default {
  name: 'YgModal',
  components: {},
  props: {
    width: {
      type: [Number, String],
      // box-sizing:border-box
      default: 300
    },
    title: {
      type: String,
      default: ''
    },
    sureText: {
      type: String,
      default: '确认'
    },
    sureButtonDisabled: {
      type: Boolean,
      default: false
    },
    sureButtonDisabledText: {
      type: String,
      default: ''
    },
    closeable: {
      type: Boolean,
      default: true
    },
    showBottom: {
      type: Boolean,
      default: true
    },
    backType: {
      type: Number,
      // 1: 蓝色背景，2：白色背景
      default: 1
    }
  },
  data () {
    return {
      visible: false
    };
  },
  computed: {
    containerWidth () {
      if (this.$store.state.app.smallScreen) {
        return 'width: 100%';
      }
      const temp = this.width + '';
      if (temp.includes('%') || temp.includes('px')) {
        return 'width:' + this.width;
      }
      return 'width:' + this.width + 'px';
    }
  },
  created () {
    onHandler(window, 'keydown', this.addKeyboardObserver);
    const cc = this.$storage.autoConfig;
    const lastShow = cc.lastShowEscTip;
    const t = (new Date()).getTime() - lastShow;
    if (t > 604800000) {
      cc.lastShowEscTip = (new Date()).getTime();
      this.$storage.autoConfig = cc;
      // 一周弹一次
      this.$Notice.info({
        title: '操作提示',
        desc: '按ESC可关闭弹层，本条提示近一周内不会展示。请手动关闭此提示!',
        duration: 0
      });
    }
  },
  mounted () {
    this.visible = true;
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy () {
    document.body.style.overflow = 'auto';
    offHandler(window, 'keydown', this.addKeyboardObserver);
  },
  methods: {
    addKeyboardObserver (e) {
      if (e.keyCode === 27) { // 27是esc
        if (this.$store.state.user.globalSetting.escClose === '2') return this._close();
        this.$Modal.confirm({
          title: '温馨提示',
          content: '<p style="font-size: 16px;">是否确定关闭弹层？</p><p style="color: #a0a0a0;font-size: 12px">*注:可在全局设置中关闭询问</p>',
          onOk: () => {
            this._close();
          }
        });
      }
    },
    handleAfterLeave () {
      this.$emit('on-close');
    },
    _sure () {
      this.$emit('on-sure');
    },
    _close () {
      this.visible = false;
    }
  }
};
</script>
