<!--
    Created by 程雨喵'mac on 2024/10/17.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：修改其他信息
-->
<style lang="less">

</style>

<template>
  <yg-drawer
    :width="tab.index === 0 ? 520 : 1000"
    title="修改信息"
    :show-bottom="false"
    :back-type="2"
    @on-close="onClose"
  >
    <div
      slot="content"
    >
      <yg-tab-primary
        :index="tab.index"
        :items="tab.items"
        :permission-items="tab.permissionItems"
        :type="3"
        @on-change="onTabChanged"
      />
      <v-info
        v-if="tab.index === 0"
        :model="model"
        @on-refresh="needRefreshList = true;onClose()"
      />
      <v-position
        v-else-if="tab.index === 1"
        :model="model"
        @on-refresh="needRefreshList = true;onClose()"
      />
    </div>
  </yg-drawer>
</template>

<script>
import vInfo from './info.vue';
import vPosition from './position.vue';
export default {
  components: { vInfo, vPosition },
  props: {
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      needRefreshList: false,
      tab: {
        index: 0,
        items: ['更改信息', '调整点位'],
        permissionItems: []
      }
    };
  },
  created () {
    this.tab.index = this.index;
  },
  methods: {
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.tab.index = e;
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    }
  }
};
</script>
