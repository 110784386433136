<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.__c-table-switch-text {
  font-size: 12px;
}
</style>

<template>
  <div>
    <i-switch
      v-if="hasPermission"
      :value="value"
      size="large"
      @on-change="onChange"
    >
      <span
        v-if="trueText"
        slot="open"
        class="__c-table-switch-text"
      >{{ trueText }}</span>
      <span
        v-if="falseText"
        slot="close"
        class="__c-table-switch-text"
      >{{ falseText }}</span>
    </i-switch>
    <span v-else>{{ value ? trueText : falseText }}</span>
  </div>
</template>

<script>
export default {
  name: 'YgTableSwitch',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    permission: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 开启时的文案
    trueText: {
      type: String,
      default: '启用'
    },
    // 关闭时的文案
    falseText: {
      type: String,
      default: '停用'
    }
  },
  data () {
    return {};
  },
  computed: {
    hasPermission () {
      if (!this.permission) {
        return true;
      }
      return this.$store.state.user.permission.includes(this.permission);
    }
  },
  methods: {
    onChange (e) {
      this.$emit('on-change', e);
    }
  }
};
</script>
