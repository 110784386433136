/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import axios from 'axios';
import GLOBAL_CONFIG from '@/app.config';
import RequestInterceptor from './RequestInterceptor';
import ResponseObject from './ResponseObject';
import YunGuiLoading from '@/components/alert/loading';
// import appCode from './code/appCode';
import { Message } from 'view-design';
import { MonitorSingle } from '@/libs/monitor/RequestMonitor';

const httpMonitor = MonitorSingle();

const httpRequest = axios.create({
  baseURL: GLOBAL_CONFIG.baseUrl,
  'Content-Type': 'application/json',
  timeout: GLOBAL_CONFIG.requestTimeout
});

const httpFormDataRequest = axios.create({
  baseURL: GLOBAL_CONFIG.baseUrl,
  // headers: { 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' },
  headers: { 'Content-Type': 'multipart/form-data; boundary=BL6dpsdzxPuead3_GSOFXzNdhK6e85Wth_' },
  timeout: GLOBAL_CONFIG.requestTimeout
});

export const YunGuiHttpClient = function (api) {
  // 每一个请求，对应一个ResponseObject对象和RequestObject对象
  return new Promise((resolve, reject) => {
    const { autoLoading, autoToast } = api.autoConfig;
    if (autoLoading) YunGuiLoading.show();
    // 请求拦截
    const reqInterceptor = RequestInterceptor.createModel(api);
    // 响应对象
    const resObj = ResponseObject.createModel(api);
    if (reqInterceptor.success) { // 通过请求拦截
      // const res = ResponseObject.createModel(api);
      httpRequest(reqInterceptor).then(res => {
        if (autoLoading) YunGuiLoading.close();
        resObj.initWithResponseData(res.data, httpMonitor);
        if (resObj.success) {
          resolve(resObj);
        } else {
          if (autoToast) Message.warning(resObj.msg);
          reject(resObj);
        }
      }).catch((e) => {
        if (autoLoading) YunGuiLoading.close();
        resObj.initWithJSError(e + '', httpMonitor);
        if (autoToast) Message.warning(resObj.msg);
        reject(resObj);
      });
    } else { // 没通过请求拦截
      if (autoLoading) YunGuiLoading.close();
      resObj.initWithErrorMessage(reqInterceptor.code, httpMonitor);
      if (autoToast) Message.warning(resObj.msg);
      reject(resObj);
    }
  });
};

export const YunGuiFormDataHttpClient = function (api) {
  // 每一个请求，对应一个ResponseObject对象和RequestObject对象
  return new Promise((resolve, reject) => {
    const { autoLoading, autoToast } = api.autoConfig;
    if (autoLoading) YunGuiLoading.show();
    // 请求拦截
    const reqInterceptor = RequestInterceptor.createModel(api);
    // 响应对象
    const resObj = ResponseObject.createModel(api);
    if (reqInterceptor.success) { // 通过请求拦截
      httpFormDataRequest(reqInterceptor).then(res => {
        if (autoLoading) YunGuiLoading.close();
        resObj.initWithResponseData(res.data, httpMonitor);
        if (resObj.success) {
          resolve(resObj);
        } else {
          if (autoToast) Message.warning(resObj.msg);
          reject(resObj);
        }
      }).catch((e) => {
        if (autoLoading) YunGuiLoading.close();
        resObj.initWithJSError(e + '', httpMonitor);
        if (autoToast) Message.warning(resObj.msg);
        reject(resObj);
      });
    } else { // 没通过请求拦截
      if (autoLoading) YunGuiLoading.close();
      resObj.initWithErrorMessage(reqInterceptor.code, httpMonitor);
      if (autoToast) Message.warning(resObj.msg);
      reject(resObj);
    }
  });
};
