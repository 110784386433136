import Table from './common/table/index.vue';
import TableButtonGroup from './common/table//view/btns.vue';
import TableSwitch from './common/table//view/switch.vue';
import TableSignal from './common/table//view/signal.vue';
import TableExpand from './common/table//view/expand.vue';
import SearchArea from './common/search-area/index.vue';
import SettingArea from './common/setting-area/index.vue';
import TabPrimary from './common/tab/index.vue';
import TabTree from './common/tab/tree.vue';
import Modal from './alert/modal/index.vue';
import Drawer from './alert/drawer/index.vue';
import Button from './common/button/index.vue';
import Card from './common/card/index.vue';
import Page from './common/pagenation/index.vue';
import ReactiveBox from './common/reactive/box.vue';
import ReactiveColumn from './common/reactive/column.vue';
import AttributeSpan from './common/attribute-text/span.vue';
import AttributeKeyValue from './common/attribute-text/key-value.vue';
import TagSpan from './common/attribute-text/tag.vue';
import StatusMark from './common/status-mark/index.vue';
import Otp from './common/otp/index.vue';
import Steps from './common/step/index.vue';
import GlobalSelect from './common/globalSelect/index.vue';
import RegionCascader from './common/region/cascader.vue';
import RegionSelector from './common/region/selector.vue';
import SiteCascader from './common/site/cascader.vue';
import Upload from './common/upload/index.vue';
import OrderList from './common/order-list/index.vue';
import CommunitySelector from './common/community/selector.vue';
import CommunityDetail from './common/community/detail.vue';
import ChooseFileExcel from './common/chooseFile/excel.vue';

const view = {
  install (Vue, options) {
    Vue.component(Table.name, Table);
    Vue.component(TableButtonGroup.name, TableButtonGroup);
    Vue.component(TableSwitch.name, TableSwitch);
    Vue.component(TableSignal.name, TableSignal);
    Vue.component(TableExpand.name, TableExpand);
    Vue.component(SearchArea.name, SearchArea);
    Vue.component(SettingArea.name, SettingArea);
    Vue.component(TabPrimary.name, TabPrimary);
    Vue.component(TabTree.name, TabTree);
    Vue.component(Card.name, Card);
    Vue.component(Page.name, Page);
    Vue.component(Button.name, Button);
    Vue.component(Modal.name, Modal);
    Vue.component(Drawer.name, Drawer);
    Vue.component(ReactiveBox.name, ReactiveBox);
    Vue.component(ReactiveColumn.name, ReactiveColumn);
    Vue.component(AttributeSpan.name, AttributeSpan);
    Vue.component(AttributeKeyValue.name, AttributeKeyValue);
    Vue.component(TagSpan.name, TagSpan);
    Vue.component(StatusMark.name, StatusMark);
    Vue.component(Otp.name, Otp);
    Vue.component(Steps.name, Steps);
    Vue.component(GlobalSelect.name, GlobalSelect);
    Vue.component(RegionCascader.name, RegionCascader);
    Vue.component(RegionSelector.name, RegionSelector);
    Vue.component(SiteCascader.name, SiteCascader);
    Vue.component(Upload.name, Upload);
    Vue.component(OrderList.name, OrderList);
    Vue.component(CommunitySelector.name, CommunitySelector);
    Vue.component(CommunityDetail.name, CommunityDetail);
    Vue.component(ChooseFileExcel.name, ChooseFileExcel);
  }
};
export default view;
