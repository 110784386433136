
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('refundOrderNo', '申请订单号', 140).setSpan({ canCopy: true }),
        BaseTableColumn.initData('childRefundOrderNo', '退款子订单号', 140).setSpan({ canCopy: true }),
        BaseTableColumn.initData('realRefundMoney', '退款金额', 100).setSpan({ canPrice: true }),
        BaseTableColumn.initData('status', '处理状态').setWidth(100).setStatus([
          { value: 0, type: 4 },
          { value: 1, type: 1 },
          { value: 2, type: 1 },
          { value: 3, type: 3 },
          { value: 4, type: 2 }
        ], 'statusDesc'),
        BaseTableColumn.initData('realRefundTime', '退款时间', 150).setSpan({}),
        BaseTableColumn.initData('payTradeNo', '凭证号', 150).setSpan({ canCopy: true }),
        BaseTableColumn.initData('remark', '备注', 100).setSpan({ canTooltip: true })
      ]
    };
  }
};
