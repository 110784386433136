<!--
    Created by 程雨喵'mac on 2022/9/29.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.__g-table-button-group {
  .display-flex();
  .__g-table-button-span {
    .display-flex();
    color: @themeColor;
    cursor: pointer;
  }
  .__g-table-button-double {
    .display-flex();
    .__g-table-button-span {
      line-height: 12px;
      padding-right: 5px;
      &+.__g-table-button-span {
        padding-left: 5px;
        border-left: 1px solid @themeColor;
      }
    }
  }
  .has-under-line {
    text-decoration: underline @themeColor;
  }
}
</style>

<template>
  <div class="__g-table-button-group">
    <div
      v-if="items.length === 1 && hasPermission"
      v-permission="items[0].permission || false"
      class="__g-table-button-span"
      @click.stop="_click(items[0])"
    >
      <span class="has-under-line">
        {{ items[0].title }}
      </span>
    </div>
    <div
      v-else-if="items.length === 2 && hasPermission"
      class="__g-table-button-double"
    >
      <span
        v-for="(item, i) in items"
        :key="i"
        v-permission="item.permission || false"
        class="__g-table-button-span has-under-line"
        @click.stop="_click(item)"
      >
        {{ item.title }}
      </span>
    </div>
    <div
      v-else-if="items.length > 2 && !useDropdown"
      class="__g-table-button-line"
    >
      <span
        v-for="(item, i) in items"
        :key="i"
        v-permission="item.permission || false"
        class="__g-table-button-span has-under-line"
        @click.stop="_click(item)"
      >
        {{ item.title }}
      </span>
    </div>
    <Dropdown
      v-else-if="hasPermission"
      trigger="hover"
      :transfer="true"
    >
      <span class="__g-table-button-span">
        更多
        <Icon type="md-arrow-dropdown" />
      </span>
      <DropdownMenu
        slot="list"
        class="top-bar-dropdown"
      >
        <DropdownItem
          v-for="(item, i) in items"
          :key="i"
          v-permission="item.permission || false"
        >
          <div
            class="top-bar-dropdown-item-li"
            @click="_click(item)"
          >
            {{ item.title }}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: 'YgTableButtons',
  components: {},
  props: {
    /**
     * 以下面default中的数据结构传进来
     * 用name来区分是哪个按钮
     */
    items: {
      type: Array,
      default: () => {
        return [{ title: '', name: '', permission: '' }];
      }
    }
  },
  data () {
    return {
      // 根据全局配置来
      useDropdown: false
    };
  },
  computed: {
    // 是否一个权限都没有
    hasPermission () {
      let flag = false;
      for (let i = 0; i < this.items.length; i++) {
        const dic = this.items[i];
        if (dic.permission) {
          flag = this.$store.state.user.permission.includes(dic.permission);
        } else {
          flag = true;
        }
        if (flag) {
          break;
        }
      }
      return flag;
    }
  },
  created () {
    const globalSetting = this.$storage.globalSetting;
    this.useDropdown = globalSetting.tableButton === '1';
  },
  methods: {
    _click (item) {
      this.$emit('on-click', item);
    }
  }
};
</script>
