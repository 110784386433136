
// 设备端口数据模型
import BaseModel from '@/libs/base/dataModel/BaseModel';
export class DevicePortModel extends BaseModel {
  constructor () {
    super();
    // 设备ID
    this.id = '';
    // 设备展示码
    this.displayCode = '';
    // 使用状态（0：未使用 1：已使用）
    this.useStatus = '';
    // 端口状态（0：禁用 1：启用 2：停用）
    this.status = '';
    // 订单ID
    this.itemId = '';
    // 前端展示
    this.h5StatusSwitch = false;
    this.h5UseStatus = '';
    this.h5ItemId = '';
  }

  initData (resData) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.h5UseStatus = this.useStatus === 0 ? '空闲' : '在用';
    this.h5ItemId = this.itemId !== -1 ? this.itemId : '';
    return this;
  }
}
