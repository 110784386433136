import { DeductedDataModel } from '@/pages/070__dealManager/chargingCoinTransactionRecord/model/DeductedDataModel';

export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('tel', '用户手机号', 100).setSpan({ canHidePhone: true }),
        BaseTableColumn.initData('transSerialNo', '交易流水号', 120).setSpan({ canCopy: true }),
        BaseTableColumn.initData('transTime', '创建时间', 120).setSpan({}),
        BaseTableColumn.initData('transTypeDesc', '动账类型', 80).setSpan({}),
        BaseTableColumn.initData('transClassifyDesc', '业务订单类型', 100).setSpan({}),
        BaseTableColumn.initData('payMoney', '数量', 100).setSpan({}),
        BaseTableColumn.initData('transCourse', '交易科目', 100).setSpan({}),
        BaseTableColumn.initData('transCourseBalance', '交易后\n科目数量', 100).setSpan({}),
        BaseTableColumn.initData('bizOrderNo', '关联\n业务单号', 120).setSpan({ canCopy: true }),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setRender((h, { row, index }) => {
          const buttonOptions = [];
          // 展示扣减充电币按钮（0-不展示 1-展示）
          if (row.showCoinReduce === '1') {
            buttonOptions.push({ title: '扣减充电币', key: 'deductedSubmit', permission: 'system:admin:chargeCoin:coinAdjust' });
          }
          // 展示扣减记录按钮（0-不展示 1-展示）
          if (row.showCoinRecord === '1') {
            buttonOptions.push({ title: '扣减记录', key: 'deductedDetail', permission: 'system:admin:chargeCoin:getCoinAdjust' });
          }
          return h('yg-table-buttons', {
            props: {
              items: buttonOptions
            },
            on: {
              'on-click': ({ key }) => {
                switch (key) {
                case 'deductedSubmit':
                  this.dialog.deductedSubmitModel = DeductedDataModel.createAdditionModel(row);
                  break;
                case 'deductedDetail':
                  this.requestDetail(row.moneyRecordId);
                  break;
                }
              }
            }
          });
        })
      ]
    };
  }
};
