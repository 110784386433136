/**
 * Created by 程雨喵'mac on 2022/5/27.
 * Copyright © 2022年 云柜-金星晖. All rights reserved.
 * 名称：导出（后端返回文件流，前端将文件流导出为excel）
 */

import axios from 'axios';
import Storage from '@/libs/storage/index.js';
import Loading from '@/components/alert/loading';

export const downloadExcel = (url, params, fileName) => {
  Loading.show();
  return new Promise((resolve, reject) => {
    const storage = new Storage();
    axios.request({
      url: process.env.VUE_APP_API_URL + url,
      responseType: 'blob', // 重要
      method: 'post',
      headers: {
        Authorization: storage.token
      },
      data: params
    }).then(res => {
      const reader = new FileReader(); // 创建读取文件对象
      reader.readAsText(res.data, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
      reader.addEventListener('loadend', function () { //
        try {
          const res = JSON.parse(reader.result); // 返回的数据
          if (res.message && res.code !== 200) {
            reject(res);
          }
        } catch (e) {
          const url = window.URL.createObjectURL(res.data);
          const downloadElement = document.createElement('a');
          downloadElement.style.display = 'none';
          downloadElement.href = url;
          downloadElement.download = fileName;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement); // 移除a标签
          window.URL.revokeObjectURL(url); // 释放block
          resolve();
        }
      });
      Loading.close();
    }).catch(err => {
      Loading.close();
      reject(err);
    });
  });
};
