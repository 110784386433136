<!--
    Created by 程雨喵'mac on 2024/10/21.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    功能概述：计费设置-按时间计费
-->
<style lang="less">
.device-price-use-time {
  .is-edit-box {
    .display-flex();
    justify-content: flex-start;
  }
  .is-look-box {
    line-height: @formHeight;
  }
}
</style>

<template>
  <div class="device-price-use-time">
    <div
      v-if="isEdit"
      class="is-edit-box"
    >
      <InputNumber
        v-model="dataList[0].price"
        :min="0"
        :disabled="true"
      />
      <span
        class="txt"
        style="margin: 0 10px"
      >元，充电时长</span>
      <InputNumber
        v-model="dataList[0].chargeDuration"
        :min="0"
      />
      <span
        class="txt"
        style="margin-left: 10px"
      >分钟</span>
    </div>
    <div
      v-else
      class="is-look-box"
    >
      {{ dataList[0].price || '-' }}元，充电时长{{ dataList[0].chargeDuration || '-' }}分钟
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {};
  },
  created () {},
  methods: {
    $checkSubmit () {
      if (this.dataList[0].chargeDuration <= 0) {
        return this.$Message.warning('充电时长填写有误');
      }
      this.errorIndex = -1;
      return { success: true };
    }
  }
};
</script>
