/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

const fakeStrArr = [
  '啊', '吧', '此', '的', '额', '发',
  '个', '好', '就', '看', '了', '吗',
  '你', '哦', '怕', '去', '人', '是',
  '他', '我', '想', '要', '在', '啊',
  '版', '寸', '单', '呃', '方', '告',
  '哈', '件', '看', '了', '妹', '你',
  '哦', '票', '求', '让', '索', '天',
  '问', '息', '样', '这', '歌', '敏'
];

class BaseModel extends Object {
  /* eslint-disable no-useless-constructor */
  constructor () {
    super();
    // 将此值置为true之后数字将转换为字符串(但子类必须是使用initData方法)
    this._numChangeToString = false;
  }

  /**
   * 数据初始化方法
   * @param resData：网络请求中的数据，是每一个对象
   */
  initData (resData) {
    for (const key in resData) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        this[key] = resData[key];
        if (this._numChangeToString && ((typeof this[key]) === 'number')) {
          this[key] = this[key] + '';
        }
      }
    }
    return this;
  }

  // 子类实现-提交时校验数据的方法
  checkSubmitValid () {}

  /** 静态方法-------------------------start **/
  // 遍历构造器：返回单个对象
  static initModelWithData (data) {
    const model = new this();
    model.initData(data);
    return model;
  }

  // 遍历构造器：返回对象数组
  static initListWithDataList (dataList) {
    const list = [];
    if (dataList && Array.isArray(dataList) && dataList.length) {
      const len = dataList.length;
      // 这里用遍历效率最高的for循环
      for (let i = 0; i < len; i++) {
        const data = dataList[i];
        // console.log(this);
        // console.log(this.initModelWithData(data));
        list.push(this.initModelWithData(data));
      }
    }
    return list;
  }

  // 深拷贝
  static deepCopy (model) {
    // 1.先通过序列化与反序列化，开辟新的内存空间
    const temp = JSON.parse(JSON.stringify(model));
    // 2.通过遍历构造器，快速创建对象
    return this.initModelWithData(temp);
  }

  // 判断是否为有长度的数组
  static checkArray (arr) {
    return arr && Array.isArray(arr) && arr.length;
  }

  // 生成提交数据时的 返回参数
  static getValidRes (success = false, msg = '', params = null) {
    return {
      success: success,
      msg: msg,
      params: params
    };
  }

  /**
   * 获取随机汉字
   * @param len 汉字长度
   * @returns {string}
   */
  static getFakeTitle (len) {
    let str = '';
    for (let i = 0; i < len; i++) {
      const index = Math.floor(Math.random() * (fakeStrArr.length - 1));
      str += fakeStrArr[index];
    }
    return str;
  }

  /**
   * 获取随机数
   * @param limit 最大值(默认 99999)
   * @param pointPosition 小数点位数(默认小数点后两位)
   * @returns {number}
   */
  static getFakeNumber (limit = 99999, pointPosition = 2) {
    if (pointPosition) {
      return parseFloat((Math.random() * limit).toFixed(pointPosition));
    }
    return Math.floor((Math.random() * limit));
  }
  /** 静态方法-------------------------end **/
}

export default BaseModel;
