/**
 * Created by 程雨喵'mac on 2024/4/15.
 * Copyright © 2024年 云柜-金星晖. All rights reserved.
 * 功能：页面中的列表可展开
 */
// 次mixin中的所有方法与属性 都要以mixinTableExpand 开头

/**
 * 使用方法-接入mixin后
 * 1.在created里面 调用 mixinTableExpandSetColumnOperation方法，来配置展开项
 * 2.在页面的table中-传值：:row-class-name="mixinTableRowClassName"
 * 3.在页面的table中-绑定事件： @on-row-expand="mixinTableOnRowExpand"
 * 4.在页面的requestData方法中，把tableData = xxx 改成 this.tableData = this.mixinTableExpandDataSetExpand(list);
 */
export default {
  data () {
    return {
      // 记录展开的行的下标
      mixinTableExpandRowArr: [],
      // table的默认展开状态
      mixinTableExpandDefault: this.$storage.globalSetting.tableExpand !== '2'
    };
  },
  created () {},
  methods: {
    // table的expand事件
    mixinTableOnRowExpand ({ row, status }) {
      const index = this.tableData.findIndex(v => v.id === row.id);
      const i = this.mixinTableExpandRowArr.findIndex(v => v === index);
      if (i !== -1) {
        this.mixinTableExpandRowArr.splice(i, 1);
      }
      if (status) {
        this.mixinTableExpandRowArr.push(index);
      }
    },
    // 控制table的row的样式
    mixinTableRowClassName (row, index) {
      return this.mixinTableExpandRowArr.includes(index) ? 'is-row-expanded' : '';
    },
    /**
     * 配置项
     * @param index：插入的下标
     * @param expandOption：展开后展示的内容
     * @param clickFunction：如果有点击事件，就传进来
     */
    mixinTableExpandSetColumnOperation (index, expandOption, clickFunction) {
      const col = BaseTableColumn.initData('expandCol').setExpand().setWidth(55).setClassName('col-expand-class').setRender((h, params) => {
        return h('yg-table-expand', {
          props: {
            model: params.row,
            rowItems: expandOption
          },
          on: {
            'on-click': (item) => {
              if (clickFunction && typeof clickFunction === 'function') {
                clickFunction(params.row, params.index, item);
              }
            }
          }
        });
      });
      this.tableColumns.splice(index, 0, col);
      this.$nextTick(() => {
        this.$el.className += ' __g-page-table-can-expand';
        const qArr = document.querySelectorAll('th.col-expand-class > div > span');
        const expandEle = qArr[qArr.length - 1];
        expandEle.style.display = 'flex';
        expandEle.style.height = '100%';
        expandEle.innerHTML = `
          <div style="display: inline-flex;align-items: center;justify-content:space-between;cursor: pointer;height: 100%">
            <img src="/icon/exchange.png" style="width: 14px; height: 14px;">
            <span style="font-size: 11px; margin-left: 5px" class="expand-txt">${this.mixinTableExpandDefault ? '默认展开' : '默认收起'}</span>
          </div>
        `;
        expandEle.onclick = () => {
          this.mixinTableExpandDefault = !this.mixinTableExpandDefault;
          if (!this.mixinTableExpandDefault) {
            this.$Notice.info({
              title: '友情提示',
              desc: '可在全局设置中一键设置默认收起'
            });
          }
          this.$Message.info(`已经切换为: ${this.mixinTableExpandDefault ? '默认展开' : '默认收起'}`);
          expandEle.querySelector('span.expand-txt').innerText = this.mixinTableExpandDefault ? '默认\n展开' : '默认\n收起';
          if (this.tableData.length) {
            this.onChangePage(this.page.current);
          }
        };
      });
    },
    // 在dataList中添加默认展开项
    mixinTableExpandDataSetExpand (list) {
      const mixinTableExpandRowArr = [];
      this.mixinTableExpandRowArr = [];
      list.forEach((v, index) => {
        if (this.mixinTableExpandDefault) {
          mixinTableExpandRowArr.push(index);
        }
        v._expanded = this.mixinTableExpandDefault;
      });
      this.mixinTableExpandRowArr = mixinTableExpandRowArr;
      return list;
    }
  }
};
