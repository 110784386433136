<!--
    Created by 王丽莎 on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：客户管理-客户列表
-->
<style lang="less">
#customer-list-content {
  .col-text-strong {
    color: @adornColor;
    font-weight: 600;
  }
}
</style>

<template>
  <div id="customer-list-content">
    <!-- 查询条件 -->
    <div
      v-permission="'system:custom:query'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:custom:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
  </div>
</template>

<script>
import { UserDataModel } from './model/UserDataModel';
import ColumnMixin from '@/pages/080__userManager/userList/mixin/tableColumn';
import CustomerApiSet from '@/request/api/CustomerApiSet';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';

export default {
  name: 'UserList',
  components: {},
  mixins: [ColumnMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      searchOption: {
        tel: BaseSearchModel.initData('用户手机号', 'tel', 'Input')
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(21).setPermission('system:custom:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'download':
        this.$Message.info('点击导出');
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const s = this.searchOption;
      console.log(s);
      this.tableData = [];
      this.page.total = 0;
      const api = CustomerApiSet.getUserAccountListApi;
      api.params = {
        tel: s.tel.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.settingExportCondition = api.params;
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = UserDataModel.initListWithDataList(resData.rows);
        UserDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
