import BaseModel from '@/libs/base/dataModel/BaseModel';

export class ChargingCoinSalesOrderDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 充电币购买订单号
    this.salesOrderNo = '';
    // 订单状态
    this.status = '';
    // 订单状态：1-已下单 2-已付款 3-已关单
    this.statusDesc = '';
    // 下单人手机号
    this.tel = '';
    // 下单时间
    this.createTime = '';
    this.createTimeStr = '';
    // 商品名称
    this.goodsName = '';
    // 商品售价
    this.price = '';
    // 充电币总金额
    this.totalMoney = '';
    // 支付金额
    this.payMoney = '';
    // 支付渠道
    this.payChannel = '';
    // 支付渠道（1-微信）
    this.payChannelDesc = '';
    // 支付渠道交易单号
    this.gwTradeNo = '';
    // 支付时间
    this.payTime = '';
    this.payTimeStr = '';
    // 发放充值金额-数量
    this.giveNum = '';
    // 发放充值金额-状态
    this.giveStatus = '';
    this.giveStatusDesc = '';
    // 发放充值金额-流水号
    this.transSerialNo = '';
    // 订单认领-物业
    this.propertyCompanyName = '';
    // 订单认领-小区
    this.communityName = '';
    // 省市区
    this.addressName = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const statusDesc = ['已下单', '已付款', '已关单'];
    const payChannelDesc = ['微信', '银行卡'];
    const giveStatusDesc = ['待发放', '已发放', '已取消'];
    for (let i = 0; i < len; i++) {
      const dic = {
        goodsName: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        salesOrderNo: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2)),
        status: i % 3,
        statusDesc: statusDesc[i % 3],
        createTime: '2024-05-0' + (i + 1),
        price: Math.random() * 10 + i,
        totalMoney: Math.random() * 100 + i,
        payMoney: Math.random() * 10 + i,
        payChannel: i % 2,
        payChannelDesc: payChannelDesc[i % 2],
        gwTradeNo: 'FKLJL00000421' + i,
        payTime: '2024-05-0' + (i + 1),
        giveNum: Math.ceil(Math.random() * 1000),
        giveStatus: giveStatusDesc[i % 2],
        giveStatusDesc: giveStatusDesc[i % 2],
        transSerialNo: 'y45893759' + i,
        propertyCompanyName: '物业公司名称',
        communityName: '小区名称'
      };
      list.push(dic);
    }
    return list;
  }
}
