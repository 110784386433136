<!--
    Created by 程雨喵'mac on 2024/4/11.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    组件名称：选择excel
-->
<style lang="less">
.__g-choose-file-excel {
  line-height: 16px;
  .import-area {
    position: relative;
    .up-file {
      padding: @containerGap * 1.5 @containerGap * 2;
      .display-flex();
      flex-direction: column;
      color: @textColorLight;
      font-size: 13px;
      font-weight: 600;
      background-color: @backColorLight;
      border-radius: 10px;
      border: 2px dashed @themeColor;
      text-align: center;
      img {
        margin-bottom: 10px;
        width: 60px;
      }
      .tip-text {
        white-space: nowrap;
        margin-top: 3px;
        font-size: 12px;
        font-weight: 300;
        &.has-data {
          color: @textColorPrimary;
          font-weight: 400;
        }
      }
    }
    .input-file {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
    .ivu-icon-md-cloud-upload:before {
      margin-right: 6px;
    }
  }
  .file-name {
    color: @textColorLight;
    .del-btn {
      margin-left: @containerGap;
      text-decoration: underline;
      color: @themeColor;
      cursor: pointer;
    }
  }
  .download-btn {
    margin-top: 10px;
    color: @textColorLight;
    font-size: 12px;
    text-align: center;
    .btn {
      text-decoration: underline;
      color: @themeColor;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div
    class="__g-choose-file-excel"
  >
    <div class="import-area">
      <div class="up-file">
        <img src="/bg/excel-icon.png">
        <div>{{ uploadFile && uploadFile.name ? '当前已选' : '请添加文件，可将文件拖动到这里！' }}</div>
        <div
          v-if="uploadFile && uploadFile.name"
          class="tip-text has-data"
        >
          {{ uploadFile.name }}
        </div>
        <div
          v-else
          class="tip-text"
        >
          请上传不大于1MB的 xlsx 或 csv 文件
        </div>
      </div>
      <!-- 上传文件 -->
      <input
        ref="uploadFile"
        class="input-file"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv"
        @change="handUpload"
      >
    </div>
    <div
      v-if="templateUrl"
      class="download-btn"
    >
      仅支持导表上传，请先
      <span
        class="btn"
        @click="_downloadTemplate"
      >下载模版</span>
      ，填写后上传!
    </div>
    <!--<div-->
    <!--  v-if="uploadFile && uploadFile.name"-->
    <!--  class="file-name margin-primary-top"-->
    <!--&gt;-->
    <!--  <span>已添加：{{ uploadFile.name }}</span>-->
    <!--  <span-->
    <!--    class="del-btn"-->
    <!--    @click="_delFile"-->
    <!--  >删除</span>-->
    <!--</div>-->
  </div>
</template>

<script>
import { downloadExcel } from '@/libs/download';

export default {
  name: 'YgChooseExcel',
  components: {},
  props: {
    // 模板下载地址
    templateUrl: {
      type: String,
      default: ''
    },
    templateParams: {
      type: Object,
      default: () => {
        return null;
      }
    },
    // maxSize: {},
    // 模板名称
    templateName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadFile: null
    };
  },
  methods: {
    // 下载模板
    _downloadTemplate () {
      const date = (new Date()).getTime();
      downloadExcel(this.templateUrl, this.templateParams, this.templateName + date + '.xlsx')
        .then(() => {
          this.$Message.success('下载成功');
        })
        .catch(e => {
          this.$Message.warning('下载失败');
        });
    },
    // 上传文件
    handUpload (event) {
      const files = event.target.files || event.dataTransfer.files;
      console.log(files[0]);
      if (!files.length || (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && files[0].type !== 'application/vnd.ms-excel' && files[0].type !== 'text/csv')) {
        this.clearImportFile();
        return this.$Message.warning('请上传xlsx 或 csv 文件');
      } else if (files[0].size > 1024000) {
        // this.clearImportFile();
        return this.$Message.warning('文件不可大于1MB');
      }
      this.uploadFile = files[0];
      this.clearImportFile();
      this.$Message.info('已选择：' + this.uploadFile.name);
      this.$emit('on-change', this.uploadFile);
    },
    // 删除
    _delFile () {
      this.uploadFile = null;
      this.clearImportFile();
    },
    // 清空导入文件file
    clearImportFile () {
      this.$refs.uploadFile.files = null;
      this.$refs.uploadFile.value = null;
    }
  }
};
</script>
