/**
 * @date:2024/02/02
 * @author 王丽莎
 * @copyright 南京云柜<yun-gui.com>
 * 客户管理相关
 */

import BaseApi from '@/request/base/BaseApi';

class CustomerApiSet {
  constructor () {
    this.getUserListApi = BaseApi.createModel('获取客户信息列表', '/opmp/customer/list', true).setMethod(0);
    this.getUserAccountListApi = BaseApi.createModel('获取客户信息列表', '/opmp/admin/chargeCoin/getUserAccount', true).setMethod(1);
    this.getChargeCardListApi = BaseApi.createModel('充电卡列表', '/opmp/admin/chargeCard/getChargeCard', true).setMethod(1);
    this.getChargeCardBindLogApi = BaseApi.createModel('充电卡绑定日志', '/opmp/admin/chargeCard/getChargeCardBindLog', true).setMethod(1);
    this.chargeCardUnbindApi = BaseApi.createModel('充电卡解绑', '/opmp/admin/chargeCard/unbind', true).setMethod(1);
  }
}
export default new CustomerApiSet();
