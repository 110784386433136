<!--
    Created by 王丽莎 on 2024/05/14.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：财务管理-充电币退款（微信）
-->
<style lang="less">
#org-refund-manage {
  .col-text-strong {
    .display-flex();
    color: @adornColor;
    font-weight: 600;
  }
  .ivu-poptip-rel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-text-light {
    color: @textColorLight;
  }
  .pay-amount-icon {
    color: @textColorLight;
  }
}
</style>

<template>
  <yg-drawer
    :show-bottom="false"
    width="1000"
    :back-type="1"
    title="订单明细"
    @on-close="_close"
  >
    <div
      id="org-refund-manage"
      slot="content"
    >
      <div class="margin-primary-bottom">
        <yg-setting-area
          :items="settingItems"
          :total="page.total"
          @on-click="_clickSetting"
        />
      </div>
      <div
        v-permission="'system:chargeCoin:getOrgRefund:list'"
        class="padding-primary-top"
      >
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import { RefundCoinApplicationDetailDataModel } from '../model/RefundCoinApplicationDetailDataModel';
import ColumnMixin from '../mixin/detailTableColumn';
import FinanceApiSet from '@/request/api/FinanceApiSet';

export default {
  name: 'ChargingCoinSalesOrder',
  components: {},
  mixins: [ColumnMixin],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      settingItems: [
        BaseSettingModel.initData('刷新列表', 'refresh', 'md-refresh').setPermission('system:chargeCoin:getOrgRefund:list')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      refundOrderNo: ''
    };
  },
  created () {
    if (this.model.refundOrderNo) {
      // 查儿子
      this.refundOrderNo = this.model.refundOrderNo;
    }
    // 查全部
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    _clickSetting (key) {
      console.log(key);
      if (key === 'refresh') {
        this.requestData();
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _close () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    // 没有分页
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = FinanceApiSet.getOrgRefundList;
      api.params = {
        refundOrderNo: this.refundOrderNo
        // pageSize: this.$config.pageSize,
        // pageNo: this.page.current
      };
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.length;
        this.tableData = RefundCoinApplicationDetailDataModel.initListWithDataList(resData);
        // const resList = RefundCoinApplicationDetailDataModel.createFakeDataList(10);
        // this.page.total = resList.length;
        // this.tableData = RefundCoinApplicationDetailDataModel.initListWithDataList(resList);
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
