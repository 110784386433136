<!--
    Created by 王丽莎 on 2024/05/14.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：用户管理-充电卡列表
-->
<style lang="less">
#charging-card-list {
  .col-text-strong {
    .display-flex();
    color: @adornColor;
    font-weight: 600;
  }
  .ivu-poptip-rel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .col-text-light {
    color: @textColorLight;
  }
  .pay-amount-icon {
    color: @textColorLight;
  }
}
</style>

<template>
  <div id="charging-card-list">
    <!-- 查询条件 -->
    <div
      v-permission="'system:chargeCard:getChargeCard:list'"
    >
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:chargeCard:getChargeCard:list'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-row-expand="mixinTableOnRowExpand"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
      />
    </div>
    <!--弹窗：查看各类型订单-->
    <yg-order-list
      v-if="!!dialog.orderListModel"
      :parameter-type="dialog.parameterType"
      :type="dialog.orderListType"
      :model="dialog.orderListModel"
      @on-close="dialog.orderListModel = null"
      @on-sure="dialog.orderListModel = null"
    />
    <!--弹窗：充电卡刷卡记录-->
    <v-card-use-record
      v-if="!!dialog.cardUseRecordModel"
      :card-number="dialog.cardUseRecordModel.cardNumber"
      @on-close="dialog.cardUseRecordModel = null"
    />
    <!--弹窗：绑定解绑记录表-->
    <v-bind-record
      v-if="!!dialog.bindRecordModel"
      :model="dialog.bindRecordModel"
      @on-close="dialog.bindRecordModel = null"
    />
  </div>
</template>

<script>
import { ChargingCardListDataModel } from './model/ChargingCardListDataModel';
import ColumnMixin from './mixin/tableColumn';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import CustomerApiSet from '@/request/api/CustomerApiSet';
import vCardUseRecord from '@/pages/060__rechargeManager/cardUseRecord/controller.vue';
import vBindRecord from './view/bindRecord.vue';
import commonPageDrawerMixin from '@/components/common/order-list/commonPageDrawerMixin';

export default {
  name: 'ChargingCardList',
  components: { vCardUseRecord, vBindRecord },
  mixins: [ColumnMixin, pageTableExpandMixin, commonPageDrawerMixin],
  props: {},
  data () {
    return {
      searchOption: {
        cardNoLabel: BaseSearchModel.initData('用户手机号用户手', 'cardNoLabel', 'Input').setLabelSelect([
          { value: 'cardNumber', label: '充电卡号' },
          { value: 'tel', label: '用户手机号' }
        ]),
        bindStatus: BaseSearchModel.initData('绑定状态', 'bindStatus', 'Select').setData([
          { label: '已绑定 ', value: '1' },
          { label: '已解绑', value: '2' }
        ]),
        selectTimeLabel: BaseSearchModel.initData('时间', 'selectTimeLabel', 'DatePickerRange').setDatePickerElevator([]).setLabelSelect([
          { value: 'bindTimeBegin-bindTimeEnd', label: '绑卡时间' },
          { value: 'unbindTimeBegin-unbindTimeEnd', label: '解绑时间' }
        ])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(22).setPermission('system:chargeCard:getChargeCard:export')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null,
        bindRecordModel: null,
        cardUseRecordModel: null
      }
    };
  },
  created () {},
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'export':
        this.$Message.info('点击导出');
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const s = this.searchOption;
      const api = CustomerApiSet.getChargeCardListApi;
      api.params = {
        bindStatus: s.bindStatus.value,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      // 筛选中可下拉的label
      api.params[s.cardNoLabel.labelSelectValue] = s.cardNoLabel.value;
      const timeKey = s.selectTimeLabel.labelSelectValue.split('-');
      api.params[timeKey[0]] = s.selectTimeLabel.value[0];
      api.params[timeKey[1]] = s.selectTimeLabel.value[1];
      this.settingExportCondition = api.params;
      console.log(api.params);
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = ChargingCardListDataModel.initListWithDataList(resData.rows);
        ChargingCardListDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
        // const resList = ChargingCardListDataModel.createFakeDataList(10);
        // this.page.total = resList.length;
        // this.tableData = ChargingCardListDataModel.initListWithDataList(resList);
        // ChargingCardListDataModel.checkModelIsSelect(this.tableData, this.settingExportIdList);
      });
    },
    // 请求解绑
    requestUnbind (id) {
      const api = CustomerApiSet.chargeCardUnbindApi;
      api.params = {
        cardId: id
      };
      this.$http(api).then(res => {
        console.log(res);
        this.$Message.success('解绑成功');
        this.requestData();
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
