import BaseModel from '@/libs/base/dataModel/BaseModel';

export class ChargingCardListDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 充电卡号
    this.cardNumber = '';
    // 用户手机号
    this.tel = '';
    // 卡绑定状态：1->已绑定 2->已解绑
    this.bindStatus = '';
    this.bindStatusDesc = '';
    // 绑卡时间
    this.bindTime = '';
    this.bindTimeStr = '';
    // 解绑时间
    this.unbindTime = '';
    this.unbindTimeStr = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const bindStatusDesc = ['已绑定', '已解绑'];
    for (let i = 0; i < len; i++) {
      const dic = {
        id: '000101010' + i,
        cardNumber: '000101010' + i,
        tel: '13904288000',
        bindStatus: i % 2,
        bindStatusDesc: bindStatusDesc[i % 2],
        bindTime: '2024-05-0' + (i + 1),
        unbindTime: '2024-05-0' + (i + 1)
      };
      list.push(dic);
    }
    return list;
  }
}
