/**
 * Created by 程雨喵'mac on 2022/11/28.
 * Copyright © 2022年 云柜-金星晖. All rights reserved.
 * 名称：处理字符串工具
 * 外部使用：import {strReplaceSpace} from '@/libs/stringUtils.js';
 */

// 去掉所有空格
export const strReplaceSpace = str => {
  return str.replace(/\s*/g, '');
};
// 去掉头、尾空格
export const strReplaceSpaceStartEnd = str => {
  return str.replace(/^\s*|\s*$/g, '');
};
