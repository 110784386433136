<!--
    Created by 程雨喵'mac on 2024/4/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
#qrcode {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background: transparent url("/logo/logoSmall.png") no-repeat;
    background-size: 100% 100%;
    transform: translateX(-50%) translateY(-50%);
  }
}
.charging-pie-qrcode-modal {
  position: relative;
  padding-bottom: @containerGap;
  //background-color: @backColorStrong;
  .qrcode-content-left {
    padding: @containerGap;
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    .qrcode-box {
      padding-top: @containerGap;
      display: flex;
      .qrcode-section {
        .flex-grow(240px);
        .qrcode-outer {
          padding: 20px 20px @containerGap;
          border-radius: @borderRadiusMin;
          border: 1px solid @lineColorNormal;
          &.is-setting-mode {
            background-color: @backColorNormal;
          }
          .code-top {
            margin-bottom: @containerGap;
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            line-height: 16px;
            height: 16px;
            overflow: hidden;
          }
          p {
            word-break: break-all;
            margin-top: @containerGap;
            font-weight: 600;
            text-align: center;
            font-size: 18px;
          }
        }
        .qrcode-btn-group {
          margin-top: @containerGap;
          display: flex;
          justify-content: center;
        }
      }
      .qrcode-setting {
        flex: 1;
        margin-left: @containerGap;
        font-size: 12px;
        background-color: @backColorNormal;
        .setting-row {
          .setting-tit {
            padding-bottom: @containerGap * 0.6;
            font-weight: 600;
            background-color: @backColorStrong;
          }
          .setting-value {
            padding-bottom: @containerGap;
            background-color: @backColorStrong;
          }
          .url-value {
            padding: @containerGap * 0.5;
            background-color: @backColorNormal;
            word-break: break-all;
            color: @textColorLight;
          }
        }
      }
    }
  }
  .qrcode-content-right {
    padding: @containerGap;
    margin-top: @containerGap;
    background-color: @backColorStrong;
    border-radius: @borderRadiusMid;
    .info-box-table {
      margin-top: @containerGap;
      padding: 0;
      background: @backColorNormal;
      border-top: 1px solid @lineColorNormal;
      border-left: 1px solid @lineColorNormal;
      font-size: 12px;
      .info-box-li {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        .info-box-li-title {
          padding: 7px 0;
          color: @textColorLight;
          font-weight: 600;
          text-align: center;
          border-bottom: 1px solid @lineColorNormal;
          border-right: 1px solid @lineColorNormal;
        }
        .info-box-li-item {
          display: flex;
          padding: 5px 5px 5px 0;
          border-bottom: 1px solid @lineColorNormal;
          border-right: 1px solid @lineColorNormal;
          background-color: @backColorStrong;
          .info-box-li-label {
            margin-right: 5px;
            .flex-grow(90px);
            text-align: right;
            color: @textColorLight;
            font-weight: 300;
          }
          .info-box-li-value {
            flex: 1;
          }
        }
      }
      .info-box-li-col-25 {.flex-grow(20%);}
      .info-box-li-col-50 {.flex-grow(50%);}
      .info-box-li-col-100 {.flex-grow(100%);}
    }
  }
}
</style>

<template>
  <yg-drawer
    title="查看设备二维码"
    :show-bottom="false"
    :back-type="2"
    width="640"
    @on-close="_close"
  >
    <div
      slot="content"
      class="charging-pie-qrcode-modal"
    >
      <div class="qrcode-content-left">
        <div class="h3">
          二维码
        </div>
        <div class="qrcode-box">
          <div class="qrcode-section">
            <div
              id="qrcodeOuter"
              class="qrcode-outer"
              :class="canSetting ? 'is-setting-mode' : ''"
            >
              <div class="code-top word-row1">
                {{ currentSiteName }}
              </div>
              <div id="qrcode" />
              <p>{{ currentDeviceNumber }}</p>
            </div>
            <div class="qrcode-btn-group">
              <yg-button
                size="small"
                type="primary"
                @click="_clickDownload"
              >
                下载
              </yg-button>
              <yg-button
                size="small"
                style="margin-left: 10px"
                @click="canSetting = true"
              >
                编辑
              </yg-button>
            </div>
          </div>
          <div class="qrcode-setting">
            <div class="setting-row">
              <div class="setting-tit">
                充电二维码域名
              </div>
              <div class="setting-value">
                <RadioGroup
                  v-model="chargeQrcodeDomainValue"
                  @on-change="drawQrcode"
                >
                  <Radio
                    v-for="(item, i) in chargeQrcodeDomainOption"
                    :key="i"
                    :label="item.value"
                    :disabled="!canSetting"
                    style="font-size: 12px"
                  >
                    {{ item.label }}
                  </Radio>
                </RadioGroup>
              </div>
            </div>
            <div class="setting-row">
              <div class="setting-tit">
                前端二维码渠道枚举
              </div>
              <div class="setting-value">
                <RadioGroup
                  v-model="frontQrcodeEnumValue"
                  @on-change="drawQrcode"
                >
                  <Radio
                    v-for="(item, i) in frontQrcodeEnumOption"
                    :key="i"
                    :label="item.value"
                    :disabled="!canSetting"
                    style="font-size: 12px"
                  >
                    {{ item.label }}
                  </Radio>
                </RadioGroup>
              </div>
            </div>
            <div class="setting-row">
              <div class="setting-tit">
                顶部信息
              </div>
              <div class="setting-value">
                <Input
                  v-model="currentSiteName"
                  :disabled="!canSetting"
                />
              </div>
            </div>
            <div class="setting-row">
              <div class="setting-tit">
                设备编码
              </div>
              <div class="setting-value">
                <Input
                  v-model="currentDeviceNumber"
                  :disabled="!canSetting"
                  @input="drawQrcode"
                />
              </div>
            </div>
            <div class="setting-row">
              <div class="setting-tit">
                二维码内容
              </div>
              <div class="setting-value url-value">
                {{ currentQrcodeContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="qrcode-content-right">
        <div class="h3">
          设备信息
        </div>
        <div class="info-box-table">
          <div
            v-for="(item, i) in infoItems"
            :key="i"
            class="info-box-li"
            :class="'info-box-li-col-' + item.col"
          >
            <div class="info-box-li-col-100 info-box-li-title">
              {{ item.title }}
            </div>
            <div
              v-for="(sub, s) in item.items"
              :key="'s-' + s"
              class="info-box-li-item info-box-li-col-50"
            >
              <div class="info-box-li-label">
                {{ sub.label }}
              </div>
              <div
                v-if="sub.key"
                class="info-box-li-value"
              >
                <yg-attribute-span
                  :text="model[sub.key]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import mixinDivCreateImage from '@/libs/mixins/divCreateImage';
import { mapActions } from 'vuex';
import QRCode from 'qrcodejs2';
export default {
  components: {},
  mixins: [mixinDivCreateImage],
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      qrCodeSingle: null,
      canSetting: false,
      infoItems: [
        {
          title: '卡信息',
          items: [
            { label: 'IMEI号:', key: 'imei' },
            { label: 'SIM卡ID:', key: 'iccid' }
          ]
        },
        {
          title: '所在地区',
          items: [
            { label: '所在省份:', key: 'provinceName' },
            { label: '所在城市:', key: 'cityName' },
            { label: '所在小区:', key: 'communityName' },
            { label: '点位名称:', key: 'siteName' }
          ]
        },
        {
          title: '设备信息',
          items: [
            { label: '设备商名称:', key: 'deviceProviderName' },
            { label: '设备型号编码:', key: 'deviceModel' },
            { label: '设备型号名称:', key: 'deviceModelName' },
            { label: '设备端口类别:', key: 'chargingPileType' }
          ]
        },
        {
          title: '运维信息',
          items: [
            { label: '运维名称:', key: 'manager' },
            { label: '运维手机:', key: 'serviceCall' }
          ]
        }
      ],
      chargeQrcodeDomainValue: '',
      chargeQrcodeDomainOption: [],
      frontQrcodeEnumValue: '',
      frontQrcodeEnumOption: [],
      currentDeviceNumber: '',
      currentSiteName: ''
    };
  },
  computed: {
    currentQrcodeContent () {
      return `${this.chargeQrcodeDomainValue}?source=${this.frontQrcodeEnumValue}&dNo=${this.currentDeviceNumber}`;
    }
  },
  created () {
    this.currentDeviceNumber = this.model.deviceNumber;
    this.currentSiteName = this.model.siteName;
    this.$nextTick(() => {
      this.requestOptions();
    });
  },
  methods: {
    ...mapActions(['getChargeQrcodeDomainList', 'getFrontQrcodeEnumList']),
    drawQrcode () {
      console.log('draw ....');
      this.qrCodeSingle.makeCode(this.currentQrcodeContent);
    },
    _close () {
      this.$emit('on-close');
    },
    _clickDownload () {
      this.mixinImageCreatePic('qrcodeOuter', this.currentDeviceNumber);
    },
    requestOptions () {
      Promise.all([this.getChargeQrcodeDomainList(), this.getFrontQrcodeEnumList()]).then(res => {
        this.chargeQrcodeDomainOption = res[0];
        this.frontQrcodeEnumOption = res[1];
        // 是否是生产环境
        const isProd = this.$config.isProd;
        let v = '';
        if (isProd) {
          v = this.chargeQrcodeDomainOption.find(v => v.label === '生产环境').value;
        } else {
          v = this.chargeQrcodeDomainOption.find(v => v.label === '测试环境').value;
        }
        this.frontQrcodeEnumValue = this.frontQrcodeEnumOption.find(v => v.label.includes(this.model.h5ManufactureDesc)).value;
        this.chargeQrcodeDomainValue = v;
        this.qrCodeSingle = new QRCode(document.getElementById('qrcode'), {
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff'
        });
        this.drawQrcode();
      });
    }
  }
};
</script>
