/**
 * 数据请求操作日志
 *
 * @date:2022/10/26
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import MonitorModel from './MonitorModel';

const MONITOR_KEY = 'REQUEST_MONITOR';
const MONITOR_BLACK_LIST_KEY = 'REQUEST_MONITOR_BLACK_LIST';

class RequestMonitor {
  constructor () {
    const list = window.localStorage.getItem(MONITOR_KEY);
    this.list = [];
    if (list) {
      try {
        this.list = JSON.parse(list);
      } catch (e) {
        console.error(JSON.stringify(e));
      }
    }
    const blackList = window.localStorage.getItem(MONITOR_BLACK_LIST_KEY);
    this.blackList = [];
    if (blackList) {
      try {
        this.blackList = JSON.parse(blackList);
      } catch (e) {
        console.error(JSON.stringify(e));
      }
    }
  }

  // 添加请求日志
  addMonitor (responseObject) {
    const model = new MonitorModel(responseObject);
    const index = this.blackList.findIndex(v => v.apiUrl === model.apiUrl);
    if (index === -1) {
      // 不在黑名单才加进去
      this.list.unshift(model);
      // 超过1000条就删掉
      if (this.list.length > 500) {
        this.list.pop();
      }
      try {
        window.localStorage.setItem(MONITOR_KEY, JSON.stringify(this.list));
      } catch (e) {
        console.log(e);
        // 超过了，删掉一半
        this.list = this.list.slice(0, Math.floor(this.list.length * 0.5));
        window.localStorage.setItem(MONITOR_KEY, JSON.stringify(this.list));
      }
    }
  }

  // 读取请求日志
  getMonitor (page, size) {
    return {
      list: this.list.slice((page - 1) * size, page * size),
      total: this.list.length
    };
  }

  // 删除单个日志
  delMonitor (page, size, index) {
    const targetIndex = (page - 1) * size + index;
    this.list.splice(targetIndex, 1);
    window.localStorage.setItem(MONITOR_KEY, JSON.stringify(this.list));
  }

  // 删除所有日志
  delAllMonitor () {
    this.list = [];
    window.localStorage.setItem(MONITOR_KEY, JSON.stringify([]));
  }

  // 加入黑名单-加入黑名单后不会记录到日志中
  addToMonitorBlackList (apiUrl, title) {
    // 不在黑名单才加进去
    const index = this.blackList.findIndex(v => v.apiUrl === apiUrl);
    if (index === -1) {
      this.blackList.unshift({ apiUrl, title });
      window.localStorage.setItem(MONITOR_BLACK_LIST_KEY, JSON.stringify(this.blackList));
    }
  }

  // 获取黑名单
  getMonitorBlackList () {
    return JSON.parse(JSON.stringify(this.blackList));
  }

  // 删除单个黑名单
  delMonitorBlackList (apiUrl) {
    const index = this.blackList.findIndex(v => v.apiUrl === apiUrl);
    if (index !== -1) {
      this.blackList.splice(index, 1);
      window.localStorage.setItem(MONITOR_BLACK_LIST_KEY, JSON.stringify(this.blackList));
    }
  }
}

export const MonitorSingle = function () {
  if (!window.__xbxRequestMonitor) {
    window.__xbxRequestMonitor = new RequestMonitor();
  }
  return window.__xbxRequestMonitor;
};
