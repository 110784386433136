<!--
   整体布局-内容区域导航

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
  #page-container {
    position: relative;
    transition: all @transitionTime15;
    overflow: auto;
    box-sizing: border-box;
    padding-top: 25px;
    //border-radius: @borderRadiusMin;
    //box-shadow: @shadowMax;
    &.bar-is-open {
      margin-left: @leftBarOpenWidth;
    }
    &.bar-is-close {
      margin-left: @leftBarCloseWidth;
    }
    .nav-bread {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      overflow: hidden;
      color: @textColorLight;
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: @textColorNormal;
        &.span-light {
          font-weight: 400;
          color: @textColorLight;
        }
      }
      .ivu-icon.arrow {
        //margin: 0 5px;
        font-size: 12px;
        //transform: rotateZ(135deg);
      }
    }
    .page-content {
      height: 100%;
      padding: @containerGap;
      background-color: @backColorStrong;
      box-sizing: border-box;
    }
  }
</style>

<template>
  <div
    id="page-container"
    :class="containerOpenClass"
    :style="containerHeightStyle"
  >
    <div class="nav-bread">
      <span
        v-if="currentRoute.meta.parentTitle"
        class="span-light"
      >{{ currentRoute.meta.parentTitle }}</span>
      <Icon
        v-if="currentRoute.meta.parentTitle"
        type="md-remove"
        class="arrow"
      />
      <span>{{ currentRoute.meta.title }}</span>
    </div>
    <div class="page-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { onHandler, offHandler } from '@/libs/utils.js';
export default {
  components: {},
  props: {
    currentRoute: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      containerHeightStyle: 'height:100%'
    };
  },
  computed: {
    containerOpenClass () {
      return this.$store.state.app.leftBarOpen ? 'bar-is-open' : 'bar-is-close';
    },
    routerHistoryList () {
      return this.$store.state.app.routerHistory;
    }
  },
  mounted () {
    this.$nextTick(() => {
      onHandler(window, 'resize', this.handleResize);
      this.handleResize();
    });
  },
  destroyed () {
    offHandler(window, 'resize', this.handleResize);
  },
  methods: {
    handleResize () {
      const windowH = window.innerHeight;
      // 50 底部导航栏高度，15上下间距
      this.containerHeightStyle = `height:${windowH - 50 - 10 - 15}px`;
    }
  }
};
</script>
