/**
 * 数据请求操作日志
 *
 * @date:2022/10/26
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

import { getNowTime } from '../utils.js';

class MonitorModel {
  /**
   * ResponseObject对象
   * @param responseObject
   */
  constructor (responseObject) {
    // 请求时间
    this.createTime = getNowTime();
    // 请求标题
    this.title = responseObject.api.title;
    // 请求接口
    this.apiUrl = responseObject.api.url;
    // 请求方式
    this.method = responseObject.api.method;
    // 请求内容(JSON)
    this.body = responseObject.api.params;
    // 返回参数(JSON)
    this.response = responseObject.orgData || responseObject.data;
  }
}

export default MonitorModel;
