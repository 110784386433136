
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('tel', '用户手机号').setSpan({ canHidePhone: true }).setRender((h, { row, index }) => {
          return h('yg-button', {
            props: {
              type: 'text',
              phoneNumber: row.tel
            },
            on: {
              click: () => {
                // 充电币交易流水
                this.dialog.parameterType = 1;
                this.dialog.orderListType = 7;
                this.dialog.orderListModel = row;
              }
            }
          }, row.tel);
        }),
        BaseTableColumn.initData('customerCode', '用户编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('', '充电币数量').setWidth(160).setSpan({}).setKeyValueList([
          // { label: '总数量:', labelWidth: 80, key: 'totalMoney', attr: {} },
          { label: '充值:', labelWidth: 80, key: 'rechargeMoney', attr: {} },
          { label: '迁入:', labelWidth: 80, key: 'migrationMoney', attr: {} },
          { label: '赠送:', labelWidth: 80, key: 'giftMoney', attr: {} }
        ]),
        BaseTableColumn.initData('totalMoney', '充电币总数量').setSpan({}),
        BaseTableColumn.initData('cardNum', '在用电卡数量').setSpan({}).setValueButton([], (row, index) => {
          // 充电卡列表
          this.dialog.parameterType = 1;
          this.dialog.orderListType = 8;
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(120).setAlign('center').setButtons([
          { title: '充电业务订单', name: 'rechargeOrder' },
          { title: '充电支付订单', name: 'rechargePayOrder' },
          { title: '购买订单', name: 'chargingCoinSalesOrder' },
          { title: '退币订单', name: 'refundCoinApplicationOrder' }
        ], (e, row, index) => {
          switch (e.name) {
          case 'rechargeOrder':
          case 'rechargePayOrder':
            this.dialog.parameterType = 1;
            this.dialog.orderListType = e.name === 'rechargeOrder' ? 2 : 3;
            this.dialog.orderListModel = row;
            break;
          case 'chargingCoinSalesOrder':
            // 充电币购买订单
            this.dialog.parameterType = 1;
            this.dialog.orderListType = 9;
            this.dialog.orderListModel = row;
            break;
          case 'refundCoinApplicationOrder':
            // 退币申请订单
            this.dialog.parameterType = 1;
            this.dialog.orderListType = 10;
            this.dialog.orderListModel = row;
            break;
          }
        })
      ]
    };
  }
};
