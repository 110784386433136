/**
 * @date:2022/10/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */

// 后端定义的code
export default {
  success: 200, // 请求成功
  tokenPassed: 401, // token失效
  noPermission: 403 // 接口无权限
};
