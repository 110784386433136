<!--
    Created by 程雨喵'mac on 2023/11/20.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    功能概述：省市区已选展示-详情弹窗（点击省份后展示）
-->
<style lang="less">
.__c-region-detail-dialog {
  position: relative;
  padding-top: 46px;
  padding-bottom: @containerGap;
  height: 100%;
  .detail-top-tab {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 46px;
    .__g-tab-primary {
      height: 46px;
      .tab-inner {
        padding: 10px 13px 0;
      }
    }
  }
  .detail-content {
    padding: 10px @containerGap @containerGap;
    display: flex;
    height: 100%;
    background-color: @backColorStrong;
    border-top-right-radius: @borderRadiusMid;
    .left-tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .flex-grow(140px);
      height: 100%;
      overflow: hidden;
      background-color: @backColorNormal;
      border-radius: @borderRadiusMid;
      .move-icon {
        padding: 10px 0;
        font-size: 30px;
        color: rgba(84,110,253,0.5);
        .ivu-icon {
          cursor: pointer;
          &:hover {
            color: @themeColor;
          }
        }
      }
      .province-block {
        width: 100%;
        height: 290px;
        overflow: hidden;
        .block-scroll {
          position: relative;
          transition: all @transitionTime15;
          .province-item {
            position: relative;
            margin-bottom: 5px;
            .display-flex();
            justify-content: flex-end;
            border-radius: 4px 0 0 4px;
            //height: 38px;
            height: 20px;
            white-space: nowrap;
            overflow: hidden;
            font-size: 12px;
            transition: all @transitionTime15;
            cursor: pointer;
            color: @textColorLight;
            //transform: rotateY(180deg) translateZ(100px);
            .pro-inner {
              padding: 0 5px;
              .flex-grow(90px);
              .display-flex();
              border: 1px solid white;
              border-left: 0;
              transition: all @transitionTime15;
            }
            &.is-current {
              height: 40px;
              font-size: 14px;
              font-weight: 600;
              background-color: @backColorStrong;
              color: @textColorNormal;
              .pro-inner {
                .flex-grow(100%);
              }
            }
            &:hover {
              font-weight: 600;
              color: @textColorNormal;
              .pro-inner {
                background-color: @backColorStrong;
              }
            }
          }
        }
      }
    }
    .tab-content {
      padding: 0 @containerGap @containerGap;
      flex: 1;
      height: 100%;
      overflow: hidden;
      background-color: @backColorStrong;
      border-radius: 0 @borderRadiusMid @borderRadiusMid 0;
      .city-block {
        height: 100%;
        overflow: auto;
        .city-item {
          .city-header {
            padding: 12px;
            display: flex;
            align-items: flex-end;
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
            border-bottom: 1px solid @backColorNormal;
            span {
              margin-left: @containerGap;
              font-size: 12px;
              line-height: 12px;
              font-weight: 400;
            }
          }
          .area-block {
            .display-flex();
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: @containerGap 0;
            font-size: 12px;
            .area-item {
              margin: 0 10px 10px 10px;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    width="800"
    :back-type="2"
    :show-bottom="false"
    @on-close="_clickClose"
  >
    <span slot="title">指定小区详情</span>
    <div
      slot="content"
      class="__c-region-detail-dialog"
    >
      <div class="detail-top-tab">
        <yg-tab-primary
          :index="tab.index"
          :items="tab.items"
          :type="2"
          @on-change="onTabChanged"
        />
      </div>
      <div class="detail-content">
        <!--左侧tab-省份维度-->
        <div class="left-tab">
          <div
            class="move-icon"
            :style="currentIndex === 0 ? 'opacity: 0' : ''"
            @mousedown="_moveDown(-1)"
            @mouseup="_moveUp(-1)"
          >
            <Icon type="md-arrow-dropup-circle" />
          </div>
          <!--遍历省份-->
          <div class="province-block">
            <!--可视区域展示11个-->
            <div
              :style="getScrollBlockTop"
              class="block-scroll"
            >
              <div
                v-for="(item, i) in currentList"
                :key="'pro-' + i"
                class="province-item"
                :class="currentIndex === i ? 'is-current' : ''"
                @click.stop="_clickProvince(i)"
              >
                <div class="pro-inner">
                  <span class="pro-text">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="move-icon"
            :style="currentIndex === (currentList.length - 1) ? 'opacity: 0' : ''"
            @mousedown="_moveDown(1)"
            @mouseup="_moveUp(1)"
          >
            <Icon type="md-arrow-dropdown-circle" />
          </div>
        </div>
        <!--选中的省份-->
        <div class="tab-content">
          <div class="city-block">
            <!--遍历城市-->
            <div
              v-for="(item, i) in currentModel.childList"
              :key="'city-' + i"
              class="city-item"
            >
              <div class="city-header">
                {{ item.name }}
                <span>已指定{{ item.childList.length }}个区</span>
              </div>
              <!--遍历区域-->
              <div class="area-block">
                <div
                  v-for="(sub, s) in item.childList"
                  :key="'area-' + s"
                  class="area-item"
                >
                  {{ sub.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import { mapActions } from 'vuex';
const TIMER_GAP = 100;
const quickCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
const CATE_MAX = 4;
export default {
  name: 'YgCommunityDetail',
  components: {},
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      // 物业公司选项
      tab: {
        index: -1,
        items: []
      },
      orgDataList: [],
      currentList: [],
      currentIndex: -1,
      timer: null,
      timerGap: 0
    };
  },
  computed: {
    currentModel () {
      return this.currentList[this.currentIndex] || {};
    },
    // 一行高度为25，选中的高度为45
    getScrollBlockTop () {
      // 一屏展示11个(上面5个，中间选中，下面5个)
      let top = 0;
      if (this.currentIndex < 5) {
        // 要向下偏移
        top = 25 * (5 - this.currentIndex);
      } else {
        top = -25 * (this.currentIndex - 5);
      }
      return `top:${top}px`;
    }
  },
  destroyed () {
    this.clearTimer();
  },
  created () {
    this.currentIndex = this.startIndex;
    this.requestData();
  },
  methods: {
    ...mapActions(['getCommunityData']),
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    startTimer () {
      this.timer = setInterval(() => {
        this.indexMove();
      }, TIMER_GAP);
    },
    indexMove () {
      const i = this.currentIndex + this.timerGap;
      if (i >= 0 && i < this.currentList.length) {
        this.currentIndex = i;
      }
    },
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.clearTimer();
      this.currentIndex = 0;
      this.tab.index = e;
      this.currentList = this.orgDataList[e].childList;
    },
    _clickClose () {
      this.$emit('on-close');
    },
    _moveDown (e) {
      this.timerGap = e;
      // 保证至少执行一次
      this.indexMove();
      this.startTimer();
    },
    _moveUp (e) {
      this.clearTimer();
    },
    _clickProvince (i) {
      this.currentIndex = i;
    },
    requestData () {
      this.getCommunityData().then(resList => {
        const getDefaultFunc = (listArr) => {
          const arr = [];
          listArr.forEach(v => {
            if (v.cate === CATE_MAX) {
              const item = this.data.find(vvv => vvv.id === v.id);

              if (item) {
                arr.push(quickCopy(v));
              }
            } else {
              const subArr = getDefaultFunc(v.childList || []);
              if (subArr.length) {
                const k = quickCopy(v);
                k.childList = subArr;
                arr.push(k);
              }
            }
          });
          return arr;
        };
        // 2.踢掉源数据中的已选数据
        this.orgDataList = getDefaultFunc(resList);
        const tabItems = [];
        this.orgDataList.forEach(v => {
          tabItems.push(v.name);
        });
        this.tab.items = tabItems;
        this.onTabChanged(0);
      });
    }
  }
};
</script>
