
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('outTradeNo', '支付\n订单编码', 250).setKeyValueList([
          { label: '业务订单:', labelWidth: 90, key: 'bizNo', attr: { canClick: true } },
          { label: '支付订单:', labelWidth: 90, key: 'outTradeNo', attr: { canCopy: true } },
          { label: '交易渠道订单:', labelWidth: 90, key: 'gwTradeNo', attr: { canCopy: true } }
        ], (row, index, key) => {
          if (!row.bizNo) return;
          this.dialog.parameterType = 2;
          this.dialog.orderListType = 2;
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('userTel', '用户手机号', 100).setRender((h, { row, index }) => {
          // 判断渠道
          if (row.sourceType === 1) {
            // 小橙
            return h('yg-attribute-span', {
              props: {
                text: row.h5XCUserTel,
                canTooltip: true,
                tooltipText: '此数据为小橙数据来源支付用户编号',
                tooltipWidth: 260
              },
              style: {
                display: 'flex',
                justifyContent: 'center'
              }
            }, row.h5XCUserTel);
          }
          // 内部
          return h('yg-button', {
            props: {
              type: 'text',
              phoneNumber: row.userTel
            },
            on: {
              click: () => {
                this.dialog.parameterType = 1;
                this.dialog.orderListType = 1;
                this.dialog.orderListModel = row;
              }
            }
          }, row.userTel);
        }),
        BaseTableColumn.initData('chargeTypeDesc', '启动方式').setSpan({}),
        BaseTableColumn.initData('amount', '支付金额').setRender((h, { row, index }) => {
          const isXc = row.sourceType === 1;
          let xcStr = '';
          if (isXc) {
            const tit = '<div class="display-flex; align-items: flex-end;" style="font-size: 14px;font-weight: 600">小橙数据来源相关数据</div>';
            const p1 = `<p class="display-flex"><span style="flex: 1; text-align: left">交易单号</span><span class="flex: 1; text-align: right; font-weight: 600;">${row.tripartiteTradeNo || '-'}</span></p>`;
            const p2 = `<p class="display-flex"><span style="flex: 1; text-align: left">商户单号</span><span class="flex: 1; text-align: right; font-weight: 600;">${row.merchantNo || '-'}</span></p>`;
            const p5 = '<p class="display-flex">*注：本条数据由乐摇摇平台提供</p>';
            const line1 = '<div style="border-bottom: 1px solid #ccc; margin: 5px 0"></div>';
            xcStr = '<div style="font-size: 12px; line-height: 22px">' + tit + line1 + p1 + p2 + line1 + p5 + '</div>';
          }
          const innerH = h('yg-attribute-span', {
            props: {
              text: row.amount,
              canPrice: true,
              className: isXc ? 'col-text-light' : 'col-text-strong'
            },
            style: {
              // display: 'flex',
              justifyContent: 'center'
            }
          }, row.amount);
          let itemH = null;
          if (isXc) {
            const iconH = h('Icon', {
              props: {
                type: 'md-information-circle'
              },
              class: 'pay-amount-icon'
            }, '');
            const hoverContent = h('div', {
              slot: 'content'
            }, [h({ template: xcStr })]);
            itemH = h('Poptip',
              {
                props: {
                  trigger: 'hover',
                  wordWrap: true,
                  transfer: true,
                  width: 300
                },
                slot: 'content',
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              },
              [innerH, iconH, hoverContent]);
          } else {
            itemH = h('div',
              {
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }
              },
              [innerH]);
          }
          return itemH;
        }),
        BaseTableColumn.initData('payStatus', '支付状态').setStatus([
          { value: '0', type: 1 },
          { value: '1', type: 2 },
          { value: '2', type: 1 }
        ], 'payStatusDesc'),
        BaseTableColumn.initData('', '支付', 220).setKeyValueList([
          { label: '支付渠道:', labelWidth: 60, key: 'channelName' },
          // { label: '充电卡号:', labelWidth: 60, key: 'transAccount', attr: { canCopy: true } },
          { label: '支付时间:', labelWidth: 60, key: 'payTime' }
        ]),
        BaseTableColumn.initData('', '退款金额', 220).setKeyValueList([
          {
            label: '自动退款:',
            labelWidth: 60,
            key: 'autoRefundMoney',
            attr: {
              canClick: (row) => {
                return row.autoRefundMoney > 0;
              }
            }
          },
          {
            label: '人工退款:',
            labelWidth: 60,
            key: 'manualRefundMoney',
            attr: {
              canClick: (row) => {
                return row.manualRefundMoney > 0;
              }
            }
          }
        ], (row, index, key) => {
          // row.bizNo 业务订单号在退款订单里查
          if (!row.bizNo) return;
          this.dialog.parameterType = 2;
          this.dialog.orderListType = 4;
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('sourceTypeDesc', '数据来源').setSpan({})
      ]
    };
  }
};
