<!--
    Created by 程雨喵'mac on 2022/3/8.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    全局组件：iView-table的column中使用-要展示label:value时使用，例子：支付时间：2023-11-11 11:11:!1
-->
<style lang="less">
.__g-table-key-value {
  line-height: 18px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  .table-key {
    margin-right: 3px;
    text-align: right;
    color: @textColorLight;
  }
  .table-value {
    flex: 1;
    text-align: left;
    color: @textColorNormal;
    &.can-click {
      cursor: pointer;
      color: @buttonColorPrimary;
      text-decoration: underline;
    }
  }
  &+.__g-table-key-value {
    margin-top: 3px;
  }
}
</style>

<template>
  <div
    class="__g-table-key-value"
    :style="eleStyle"
  >
    <div
      v-if="label && labelWidth"
      class="table-key"
      :style="labelWidth ? `width:${labelWidth}px` : ''"
    >
      {{ label }}
    </div>
    <div
      class="table-value"
      :class="(canClick && value) ? 'can-click' : ''"
      :style="valueAlign ? `text-align:${valueAlign}` : ''"
      @click="_click"
    >
      <yg-attribute-span
        :text="value"
        :tooltip-text="tooltipText"
        :tooltip-slot="tooltipSlot"
        :tooltip-width="tooltipWidth"
        :class-name="valueClassName"
        :can-copy="canCopy"
        :can-tooltip="canTooltip"
        :can-hide-phone="canHidePhone"
        :can-price="canPrice"
        :can-text-unit="canTextUnit"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YgAttributeKeyValue',
  props: {
    label: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 0
    },
    valueAlign: {
      type: String,
      default: 'left'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    valueClassName: {
      type: String,
      default: ''
    },
    // 如果传 0或者-1，就没事最大高度限制
    maxHeight: {
      type: Number,
      default: 18
    },
    // 是否支持拷贝，默认为不支持
    canCopy: {
      type: Boolean,
      default: false
    },
    // 是否支持文字过长时...，默认为false(如果此值为false，就不支持copy)
    canTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    tooltipWidth: {
      type: [String, Number],
      default: ''
    },
    tooltipSlot: {
      type: String,
      default: ''
    },
    // 是否支持手机号脱敏，默认为不支持
    canHidePhone: {
      type: Boolean,
      default: false
    },
    canPrice: {
      type: Boolean,
      default: false
    },
    // 是否可以点击
    canClick: {
      type: Boolean,
      default: false
    },
    canTextUnit: {
      type: String,
      default: ''
    },
    hasLine: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // 过长会展示...  过长通过计算判断。(12号字，宽度为12)
      isLong: false
    };
  },
  computed: {
    eleStyle () {
      if (this.maxHeight && this.maxHeight > 0) {
        return `max-height:${this.maxHeight}px`;
      }
      return '';
    }
  },
  watch: {
    value (oldV, newV) {
      if (oldV !== newV) {
        this.isLong = (this.$el.clientWidth - this.labelWidth) < (this.value + '').length * 12;
      }
    }
  },
  created () {
    this.$nextTick(() => {
      this.isLong = (this.$el.clientWidth - this.labelWidth) < (this.value + '').length * 12;
    });
  },
  methods: {
    _click () {
      if (this.canClick) {
        this.$emit('on-click');
      }
    }
  }
};
</script>
