<!--
   整体布局-顶部导航

   @date:2022/09/07
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
.simplify-theme-dark {
  .open-control {
    filter: invert(80%) !important;
  }
}
#top-bar {
  padding: 0 @containerGap;
  position: absolute;
  right: 0;
  top: 0;
  height: @topBarHeight;
  transition: all @transitionTime15;
  //overflow: hidden;
  z-index: @topBarIndex;
  background-color: @backColorStrong;
  box-shadow: @shadowMin;
  user-select: none;
  &.bar-is-open {
    left: @leftBarOpenWidth;
  }
  &.bar-is-close {
    left: @leftBarCloseWidth;
    .top-bar-inner {
      .bread-case {
        .open-control {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  .top-bar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .bread-case {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      .open-control {
        //margin-right: @containerGap;
        .display-flex();
        cursor: pointer;
        img {
          width: 18px;
          height: 18px
        }
      }
    }
    .nav-history {
      position: relative;
      flex: 1;
      margin-right: @containerGap;
      height: @topBarHeight;
      //background-color: @backColorNormal;
      .nav-history-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 10px 0;
        height: @topBarHeight;
        overflow: hidden;
        background-color: @backColorStrong;
        transition: all @transitionTime15;
        box-sizing: border-box;
        &:hover {
          height: auto;
          box-shadow: 0 3px 2px 0 rgba(84,110,253, 0.15);;
        }
        .page-nav-item {
          position: relative;
          margin-bottom: 10px;
          .display-flex();
          height: 30px;
          padding: 0 8px;
          font-size: 12px;
          background-color: @backColorStrong;
          border-radius: @borderRadiusMin;
          cursor: pointer;
          transition: all @transitionTime15;
          color: @textColorLight;
          &:first-child {
            margin-right: 8px;
            background-color: @leftBarSelectInnerColor;
            color: @textColorPrimary;
            font-weight: 600;
            //cursor: not-allowed;
          }
          &:hover {
            padding-right: 25px;
            color: @textColorPrimary;
            background-color: @leftBarSelectInnerColor;
            .nav-item-close {
              opacity: 1;
            }
          }
          .nav-item-close {
            position: absolute;
            opacity: 0;
            .display-flex();
            justify-content: flex-start;
            width: 18px;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            color: @textColorLight;
            &:hover {
              color: @themeColor;
            }
            .ivu-icon {
              cursor: pointer;
            }
          }
        }
      }
    }
    .global-setting {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin-right: @containerGap;
      font-size: 16px;
      border-radius: 50%;
      background-color: @backColorLight;
      color: @textColorLight;
      cursor: pointer;
      .ivu-icon-md-sunny {
        color: @adornColor;
      }
      .ivu-icon-md-moon {
        color: @textColorLight;
      }
    }
    .user-info {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 15px;
      line-height: 15px;
      .avatar {
        .display-flex();
        margin-right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 3px solid @textColorPrimary;
        }
      }
      .user-name {
        cursor: pointer;
      }
    }
  }
  .ivu-select-dropdown {
    max-height: 300px !important;
    .top-bar-dropdown {
      //min-width: 140px;
      .ivu-dropdown-item {
        padding: 0;
        .top-bar-dropdown-item-li {
          padding: 7px 16px;
          .display-flex();
          font-size: 15px;
          .ivu-icon {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    id="top-bar"
    :class="topBarOpenClass"
  >
    <div class="top-bar-inner">
      <!--  面包屑  -->
      <h2 class="bread-case">
        <p
          class="open-control"
          @click.stop="_clickBarOpen"
        >
          <img
            src="/leftBar/barOpenControl.png"
            alt=""
          >
        </p>
      </h2>
      <div class="nav-history">
        <div class="nav-history-inner">
          <div
            v-for="(item, i) in routerHistoryList"
            :key="i"
            class="page-nav-item"
            @click.stop="_clickHistory(item)"
          >
            <span class="long-tit">{{ item.title }}</span>
            <div
              class="nav-item-close"
              @click.stop="_clickHistoryDel(item, i)"
            >
              <Icon type="ios-close-circle" />
            </div>
          </div>
        </div>
      </div>
      <div
        class="global-setting"
        @click.stop="_clickTheme"
      >
        <Icon
          v-if="currentTheme === '2'"
          type="md-moon"
        />
        <Icon
          v-if="currentTheme === '1'"
          type="md-sunny"
        />
      </div>
      <!--全局设置-->
      <div
        class="global-setting"
        @click.stop="showSetting = true"
      >
        <Icon type="md-settings" />
      </div>
      <!--数据看板-->
      <div
        v-if="isServiceProvider || $storage.user.roles.find(v => v.id === 90028)"
        class="global-setting"
        @click.stop="_clickBoard"
      >
        <Icon type="md-analytics" />
      </div>
      <!--<div-->
      <!--  class="global-setting"-->
      <!--  @click.stop="showSetting = true"-->
      <!--&gt;-->
      <!--  <Icon type="md-construct" />-->
      <!--</div>-->
      <div class="user-info">
        <div class="avatar">
          <img
            :src="userAvatar"
            alt=""
          >
        </div>
        <div
          class="user-name"
          @click.stop="showDrawer = true"
        >
          <span>{{ topBarTitle }}</span>
          <Icon type="md-arrow-dropdown" />
        </div>
      </div>
    </div>
    <v-drawer
      v-model="showDrawer"
      :title="topBarTitle"
    />
    <v-setting
      v-model="showSetting"
    />
  </div>
</template>

<script>
import GLOBAL_CONFIG from '@/app.config';
import vDrawer from './drawer.vue';
import vSetting from './setting.vue';

export default {
  components: { vDrawer, vSetting },
  props: {
    currentRoute: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      showDrawer: false,
      showSetting: false,
      showDataDashboard: false,
      currentTheme: '',
      dropdownOptions: [
        // { title: '修改密码', name: 'password', icon: 'md-build' },
        { title: '系统日志', name: 'log', icon: 'md-list-box' },
        { title: '退出登录', name: 'logOut', icon: 'md-hand' }
      ]
    };
  },
  computed: {
    topBarTitle () {
      if (this.$storage.user.username) {
        return 'Hi, ' + this.$storage.user.username;
      }
      return 'Hi, admin';
    },
    topBarOpenClass () {
      return this.$store.state.app.leftBarOpen ? 'bar-is-open' : 'bar-is-close';
    },
    userAvatar () {
      return `/avatar/user${this.$store.state.user.avatarKey}.png`;
    },
    isServiceProvider () {
      return this.$storage.user.userType === this.$config.systemUserType.service;
    },
    routerHistoryList () {
      return this.$store.state.app.routerHistory;
    }
  },
  created () {
    if (GLOBAL_CONFIG.isProd) {
      const index = this.dropdownOptions.findIndex(v => v.name === 'log');
      if (index !== -1) {
        this.dropdownOptions.splice(index, 1);
      }
    }
    this.currentTheme = this.$storage.theme || '1';
    this.$store.commit('setAvatarKey', this.$storage.avatar);
  },
  methods: {
    _clickTheme () {
      this.$Modal.confirm({
        title: '温馨提示',
        content: '是否确定修改系统风格？',
        onOk: () => {
          this.$storage.theme = this.currentTheme === '1' ? '2' : '1';
          this.$router.go(0);
        }
      });
    },
    _clickBarOpen () {
      this.$store.commit('setLeftBarOpen', !this.$store.state.app.leftBarOpen);
    },
    _clickBoard () {
      window.open('/board/dataAnalysis');
    },
    _clickHistory (item) {
      this.$router.push({
        name: item.name
      });
    },
    _clickHistoryDel (item, index) {
      try {
        if (index === 0) {
          if (this.routerHistoryList.length > 1) {
            this.$router.push({
              name: this.routerHistoryList[1].name
            });
          } else {
            if (item.name === 'dashboard_index') {
              return;
            }
            this.$router.push({
              name: 'dashboard_index'
            });
          }
        }
        this.$store.commit('deleteRouterHistory', item);
      } catch (e) {
        console.log(e);
      }
    },
    _clickDropdownList (item) {
      this.$emit('on-click-dropdown', item);
    }
  }
};
</script>
