<!--
    Created by 程雨喵'mac on 2024/2/27.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
-->
<style lang="less">
.__g-community-selector {
  padding-bottom: @containerGap;
  box-sizing: border-box;
  height: 100%;
  .choose-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .tree-box {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: @borderRadiusMid;
      overflow: hidden;
      font-size: 14px;
      // margin-bottom: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ivu-tree-children {
        li {
          .ivu-tree-children {
            li {
              .ivu-tree-children {
                li {
                  .ivu-tree-children {
                    li {
                      margin: 4px 0;
                      display: flex;
                      flex-wrap: wrap;
                      span.ivu-tree-title {
                        flex: 1;
                        min-width: 80%;
                      }
                      .ivu-tree-children {
                        @media screen and (min-width: 1600px) { width: 25%;}
                        @media screen and (max-width: 1600px) { width: 25%;}
                        @media screen and (max-width: 1200px) { width: 33.333%; }
                        @media screen and (max-width: 960px) { width: 50%; }
                        @media screen and (max-width: 700px) { width: 100%; }
                        li {
                          span.ivu-tree-title {
                            display: block;
                            min-width: 0;
                            word-break:break-all;
                            display:-webkit-box;
                            -webkit-line-clamp:1;
                            -webkit-box-orient:vertical;
                            overflow:hidden;
                            height: 18px;
                            line-height: 18px;
                            white-space: normal;
                          }
                          // display: flex;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .left-box,
      .right-box {
        width: 45%;
        height: 100%;
        padding: @containerGap;
        border-radius: @borderRadiusMid;
        background-color: @backColorStrong;
        overflow: hidden;
      }
      .left-box::before {
        content: '全部小区数据';
        display: block;
        height: 26px;
        font-weight: 600;
      }
      .right-box::before {
        content: '已选中的小区';
        display: block;
        height: 26px;
        font-weight: 600;
      }
      .tree-outer {
        height: 100%;
        //height: calc(100% - 26px);
        overflow: scroll;
      }
      .middle-box {
        .display-flex();
        flex-direction: column;
        button:first-child {
          margin-bottom: 20px;
        }
      }
      .region-nodata {
        height: 100%;
        .display-flex();
        flex-direction: column;
        img {
          margin-bottom: @containerGap;
          width: 200px;
        }
      }
    }
  }
}
</style>

<template>
  <yg-drawer
    width="100%"
    :back-type="2"
    @on-close="_clickClose"
    @on-sure="_clickSure"
  >
    <span slot="title">指定小区</span>
    <div
      slot="content"
      class="__g-community-selector"
    >
      <div class="choose-inner">
        <div class="tree-box">
          <div class="left-box">
            <div class="tree-outer">
              <Tree
                :data="regionData"
                show-checkbox
                @on-check-change="_checkChange"
              />
            </div>
          </div>
          <div class="middle-box">
            <Button
              type="primary"
              :disabled="cancelSelectBtnDisabledStatus"
              @click="_clickCancelSelect"
            >
              <Icon type="ios-arrow-back" />
            </Button>
            <Button
              type="primary"
              :disabled="selectBtnDisabledStatus"
              @click="_clickSelect"
            >
              <Icon type="ios-arrow-forward" />
            </Button>
          </div>
          <div class="right-box">
            <div
              v-if="regionSelectData.length"
              class="tree-outer"
            >
              <Tree
                :data="regionSelectData"
                show-checkbox
                @on-check-change="_cancelCheckChange"
              />
            </div>
            <div
              v-else
              class="region-nodata"
            >
              <img
                src="/bg/light-bg-empty.png"
                alt=""
              >
              <p>暂未设置</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </yg-drawer>
</template>

<script>
import { mapActions } from 'vuex';
const createAllSelect = function (list) {
  return [{ id: -1, name: '全选', parentId: -1, cate: 0, childList: list }];
};
const quickCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
const CATE_MAX = 4;
export default {
  name: 'YgCommunitySelector',
  components: {},
  props: {
    // 已选中的数据
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      regionData: [], // 左边源数据
      searchRegionData: [], // 筛选的省市区数据
      regionSelectData: [], // 右边已选数据
      selectBtnDisabledStatus: true, // 选择按钮可选状态
      cancelSelectBtnDisabledStatus: true // 取消选择按钮可选状态
    };
  },
  created () {
    this.requestData();
  },
  methods: {
    ...mapActions(['getCommunityData']),
    // 选择城市，小区
    selectCity (value) {
      if (value[0]) {
        this.regionData[0].children.find(e => {
          if (e.value === value[0]) {
            this.regionData[0].expand = true;
            e.expand = true;
            if (value[1] && e.children && e.children.length) {
              e.children.find(v => {
                if (v.value === value[1]) {
                  v.expand = true;
                } else {
                  v.expand = false;
                }
              });
            }
          } else {
            e.expand = false;
          }
        });
      }
    },
    // 源数据变更
    _checkChange (value) {
      this.selectBtnDisabledStatus = !value.length;
    },
    // 已选数据变更
    _cancelCheckChange (value) {
      this.cancelSelectBtnDisabledStatus = !value.length;
    },
    // 点击关闭弹窗
    _clickClose () {
      this.$emit('on-close');
    },
    // 点击确定
    _clickSure () {
      const getParamsDic = (data, needChild = true) => {
        const d = {
          id: data.value,
          name: data.title,
          cate: data.cate
        };
        if (needChild) {
          d.childList = [];
        }
        return d;
      };
      // 平级的结构的数组(维度：区)
      const paramsList = [];
      // 带层级结构的数组(维度：省/市/区)
      const treeParamsList = [];
      this.regionSelectData.forEach(e => {
        if (e.children && e.children.length) {
          e.children.forEach(province => {
            const p = getParamsDic(province);
            treeParamsList.push(p);
            if (province.children && province.children.length) {
              province.children.forEach(city => {
                const c = getParamsDic(city);
                p.childList.push(c);
                city.children.forEach(area => {
                  c.childList.push(getParamsDic(area));
                  paramsList.push(getParamsDic(area));
                  const d = getParamsDic(area);
                  c.childList.push(c);
                  area.children.forEach(co => {
                    d.childList.push(getParamsDic(co, false));
                    paramsList.push(getParamsDic(co, false));
                  });
                });
              });
            }
          });
        }
      });
      this.$emit('on-sure', {
        list: paramsList,
        treeList: treeParamsList
      });
    },
    // 将数据中的所有选中disabled啥的全都重置
    resetListStatus (list, checkedDefault = false) {
      for (let i = 0; i < list.length; i++) {
        const v = list[i];
        v.checked = checkedDefault;
        v.indeterminate = false;
        v.disabled = false;
        if (v.cate !== CATE_MAX && v.children && v.children.length) {
          this.resetListStatus(v.children);
        }
      }
    },
    // 将取消选择的数据从已选数据中移除
    deleteRegionSelectData (list) {
      const newList = [];
      list.forEach(v => {
        // 如果1，2级有选择中的状态，则查找子集未check的数据
        if (v.indeterminate) {
          v.children = this.deleteRegionSelectData(v.children);
          // 如果1，2级数据还有子数据，则添加到上一级的子集里
          if (v.children.length) {
            newList.push(v);
          }
          v.indeterminate = false;
        } else if (!v.checked) {
          // 如果没有选中，则添加到新数组里
          newList.push(v);
        }
      });
      return newList;
    },
    // 已选数据在源数据中置灰不可选
    disabledRegionData (list, selectList) {
      // 1.处理右边的所有状态
      this.resetListStatus(selectList, true);
      // 2.处理左边的禁用
      const listDisableFunc = (tList, sList) => {
        tList.forEach(targetProvince => {
          targetProvince.checked = false;
          targetProvince.indeterminate = false;
          targetProvince.disabled = false;
          // 这里第一次进来是省，后面进来是市，在后面就是区
          const province = sList.find(v => v.value === targetProvince.value);
          if (province) {
            if (targetProvince.cate !== CATE_MAX) {
              // 只有省，市进来!
              listDisableFunc(targetProvince.children, province.children);
              // 计算儿子们的check数量
              const checkNum = targetProvince.children.filter(v => v.disabled).length;
              targetProvince.disabled = checkNum === targetProvince.children.length;
              targetProvince.indeterminate = !targetProvince.disabled;
            } else {
              targetProvince.disabled = true;
            }
          } else {
            if (targetProvince.cate !== CATE_MAX) {
              this.resetListStatus(targetProvince.children);
            }
          }
        });
      };
      listDisableFunc(list, selectList);
    },
    changeSelectData (list, parent = null, isOrgData = true) {
      const resList = [];
      let newParent = null;
      if (parent) {
        newParent = JSON.parse(JSON.stringify(parent));
        newParent.children = null;
        newParent.childList = null;
      }
      list.forEach(v => {
        const model = {
          value: v.id,
          title: v.name,
          parentId: v.parentId,
          parent: newParent,
          checked: !isOrgData, // 选中 (左边的全部数据不选中，右边的已选中选中)
          expand: !isOrgData, // 展开 (左边的全部数据收起，右边的已选中展开)
          disabled: false, // 不可用
          // 0: 最外层，1：业务公司，2：省，3：市，4：小区
          cate: v.cate,
          indeterminate: false, // 横线
          children: []
        };
        if (v.childList && v.childList.length) {
          model.children = this.changeSelectData(v.childList, model, isOrgData);
        }
        resList.push(model);
      });
      return resList;
    },
    // 确定取消(右边 -> 左边)
    _clickCancelSelect () {
      // 已选的数据中，删除此刻选中的数据
      this.regionSelectData = this.deleteRegionSelectData(this.regionSelectData);
      this.disabledRegionData(this.regionData, this.regionSelectData);
      this.cancelSelectBtnDisabledStatus = true;
    },
    // 确定添加(左边 -> 右边)，右边的数据删掉，然后将左边的禁用的+横线的+选中的放到右边
    _clickSelect () {
      const getListFunction = (targetList) => {
        const list = [];
        targetList.forEach(v => {
          if (v.disabled || v.checked) {
            list.push(quickCopy(v));
          } else if (v.indeterminate && v.children.length && v.cate !== CATE_MAX) {
            const newV = quickCopy(v);
            // 省，市进来
            newV.children = getListFunction(v.children);
            list.push(newV);
          }
        });
        return list;
      };
      this.regionSelectData = getListFunction(this.regionData);
      this.disabledRegionData(this.regionData, this.regionSelectData);
      this.selectBtnDisabledStatus = true;
      this.cancelSelectBtnDisabledStatus = false;
    },
    requestData () {
      this.getCommunityData().then(resList => {
        this.regionData = this.changeSelectData(createAllSelect(resList, null, true));
        // 这里的this.data是只有小区数组的，因此需要处理
        if (this.data.length) {
          const getDefaultFunc = (listArr) => {
            const arr = [];
            listArr.forEach(v => {
              if (v.cate === CATE_MAX) {
                const item = this.data.find(vvv => vvv.id === v.id);
                if (item) {
                  arr.push(quickCopy(v));
                }
              } else {
                const subArr = getDefaultFunc(v.childList || []);
                if (subArr.length) {
                  const k = quickCopy(v);
                  k.childList = subArr;
                  arr.push(k);
                }
              }
            });
            return arr;
          };
          this.regionSelectData = this.changeSelectData(createAllSelect(getDefaultFunc(resList)), null, false);
          this.cancelSelectBtnDisabledStatus = false;
        }
        // 2.踢掉源数据中的已选数据
        this.disabledRegionData(this.regionData, this.regionSelectData);
      });
    }
  }
};
</script>
