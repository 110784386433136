import BaseModel from '@/libs/base/dataModel/BaseModel';

export class ChargingRefundOrderDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 退款订单编码
    this.refundTradeNo = '';
    // 支付渠道退款订单编码
    this.payTradeNo = '';
    // 支付渠道
    this.gwTradeNo = '';
    // 用户手机号
    this.tel = '';
    // 源支付订单金额
    this.sourceAmount = '';
    // 退款金额
    this.amount = '';
    // 源支付订单退款后余额
    this.realMoney = '';
    // 退款交易类型
    this.refundTransactionType = '';
    // 退款交易类型描述
    this.refundTransactionTypeDesc = '';
    // 当前状态
    this.refundCurrentType = '';
    // 当前状态描述
    this.refundCurrentTypeDesc = '';
    // 退款类型:0->无需退款、1->部分退款、2->全部退款
    this.refundType = '';
    // 退款类型描述
    this.refundTypeDesc = '';
    // 退款时间
    this.refundTime = '';
    // 源支付订单编码
    this.orgPayTradeNo = '';
    // 退款类型(1->自动退款、2->人工退款)
    this.refundType = '';
    // 退款类型描述
    this.refundTypeDesc = '';
    // 省
    this.provinceName = '';
    // 市
    this.cityName = '';
    // 小区名称
    this.blockName = '';
    // 网点名称
    this.siteName = '';
    // 设备编码
    this.deviceNumber = '';
    // 设备imei号
    this.imei = '';
    // 设备名称
    this.deviceName = '';
    // 数据来源 1->内部、2->小橙
    this.sourceType = 1;
    // 数据来源描述 1->内部、2->小橙
    this.sourceTypeDesc = '';
    // 设备型号名称
    this.deviceModelName = '';
    // 退款渠道名称
    this.channelName = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 所属省市地
    this.h5AddressName = '';
    // 小橙支付用户编码-判断渠道为小橙时取userTel字段
    this.h5XCUserTel = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    if (this.provinceName && this.cityName) {
      this.h5AddressName = this.provinceName + this.cityName;
    }
    if (this.sourceType === 1) {
      this.h5XCUserTel = this.tel;
      this.tel = '-';
    }
    return this;
  }

  static checkModelIsSelect (dataList, selectList) {
    const len = dataList.length;
    for (let i = 0; i < len; i++) {
      const model = dataList[i];
      model._checked = !!selectList.find(v => v._checkId === model._checkId);
    }
  }

  static createFakeDataList (len = 30) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        province: BaseModel.getFakeTitle(4) + '省',
        city: BaseModel.getFakeTitle(6) + '市',
        area: BaseModel.getFakeTitle(6) + '区',
        mainPrice: parseFloat((Math.random() * 99999).toFixed(2)),
        lightPrice: parseFloat((Math.random() * 30000).toFixed(2))
      };
      list.push(dic);
    }
    return list;
  }
}
