/**
 * Created by 程雨喵'mac on 2023/12/1.
 * Copyright © 2023年 云柜-金星晖. All rights reserved.
 * 名称：directive: v-transfer-dom, value: data-transfer=true
 * 功能：将el插到body中
 */

/* eslint-disable */
function getTarget (node) {
  if (node === void 0) {
    node = document.getElementById('app')
  }
  if (node === true) { return document.getElementById('app') }
  return node instanceof window.Node ? node : document.querySelector(node)
}
function inserted (el, { value }, vnode) {
  if ( el.dataset && el.dataset.transfer !== 'true') return false;
  el.className = el.className ? el.className + ' v-transfer-dom' : 'v-transfer-dom';
  const parentNode = el.parentNode;
  if (!parentNode) return;
  const home = document.createComment('');
  let hasMovedOut = false;

  if (value !== false) {
    parentNode.replaceChild(home, el); // moving out, el is no longer in the document
    getTarget(value).appendChild(el); // moving into new place
    hasMovedOut = true
  }
  if (!el.__transferDomData) {
    el.__transferDomData = {
      parentNode: parentNode,
      home: home,
      target: getTarget(value),
      hasMovedOut: hasMovedOut
    }
  }
}
function componentUpdated (el, { value }) {
  if ( el.dataset && el.dataset.transfer !== 'true') return false;
  // need to make sure children are done updating (vs. `update`)
  const ref$1 = el.__transferDomData;
  if (!ref$1) return;
  // homes.get(el)
  const parentNode = ref$1.parentNode;
  const home = ref$1.home;
  const hasMovedOut = ref$1.hasMovedOut; // recall where home is

  if (!hasMovedOut && value) {
    // remove from document and leave placeholder
    parentNode.replaceChild(home, el);
    // append to target
    getTarget(value).appendChild(el);
    el.__transferDomData = Object.assign({}, el.__transferDomData, { hasMovedOut: true, target: getTarget(value) });
  } else if (hasMovedOut && value === false) {
    // previously moved, coming back home
    parentNode.replaceChild(el, home);
    el.__transferDomData = Object.assign({}, el.__transferDomData, { hasMovedOut: false, target: getTarget(value) });
  } else if (value) {
    // already moved, going somewhere else
    getTarget(value).appendChild(el);
  }
}
function unbind (el) {
  if (el.dataset && el.dataset.transfer !== 'true') return false;
  el.className = el.className.replace('v-transfer-dom', '');
  const ref$1 = el.__transferDomData;
  if (!ref$1) return;
  if (el.__transferDomData.hasMovedOut === true) {
    el.__transferDomData.parentNode && el.__transferDomData.parentNode.appendChild(el)
  }
  el.__transferDomData = null
}
export default {
  install (Vue) {
    Vue.directive('transfer-dom', {
      inserted,
      componentUpdated,
      unbind
    });
  }
};
