/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 外部使用：import {xx} from '@/libs/utils.js';
 */

const monthArr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const getElementTop = (element) => {
  let top = element.offsetTop;
  let current = element.offsetParent;
  while (current !== null) {
    top += current.offsetTop;
    current = current.offsetParent;
  }
  return top;
};

// 计算一个元素在窗口中的高度（算法：窗口高度 - 元素顶部的y - 元素底部到窗口底部的距离）
export const getElementInWidowHeight = (element, bottomGap = 15) => {
  let top = element.offsetTop;
  let current = element.offsetParent;
  while (current !== null) {
    top += current.offsetTop;
    current = current.offsetParent;
  }
  return window.innerHeight - top - 1 - bottomGap;
};

/**
 * 绑定事件 onHandler(element, event, handler)
 * window: resize
 * document: mousedown,mouseup,mousemove
 */
export const onHandler = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false);
      }
    };
  }
  return function (element, event, handler) {
    if (element && event && handler) {
      element.attachEvent('on' + event, handler);
    }
  };
})();

/**
 * @description 解绑事件 offHandler(element, event, handler)
 */
export const offHandler = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false);
      }
    };
  }
  return function (element, event, handler) {
    if (element && event) {
      element.detachEvent('on' + event, handler);
    }
  };
})();

export const formatDate = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return [year, month, day].map(formatNumber).join('-');
};

export const formatDateTime = date => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
};

export const getNowDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return [year, month, day].map(formatNumber).join('-');
};
// 获取当天之前的某一天日期
export const getLastDate = (parameterDay = 1) => {
  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(today.getDate() - parameterDay);
  return formatDate(pastDate);
};
// 获取某年某月的天数
export const getMonthDayAmount = (date) => {
  const dateTemp = new Date(date);
  const currentMonth = dateTemp.getMonth();
  let day = monthArr[currentMonth];
  if (currentMonth === 1) {
    day = dateTemp.getFullYear() % 4 === 0 ? 29 : 28;
  }
  return day;
};

export const getNowTime = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
};

export const getDayDifferenceValue = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // 每天的毫秒数
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);

  // 计算时间差
  const diff = Math.abs(firstDate - secondDate);

  // 返回差值的天数
  return Math.floor(diff / oneDay);
};

const formatNumber = n => {
  n = n.toString();
  return n[1] ? n : '0' + n;
};

export const sortBy = (attr, rev) => {
  rev = (rev) ? 1 : -1;
  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev;
    }
    return 0;
  };
};
// 查看两个对象是否值一样
export const checkValueChange = (obj1, obj2) => {
  try {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  } catch (e) {
    return false;
  }
};
