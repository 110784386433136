import Midware from '@/components/layout/midware.vue';

function createMeta (title, icon, parentTitle, saveInHistory) {
  return {
    title: title,
    parentTitle: parentTitle,
    icon: icon,
    saveInHistory: !!saveInHistory
  };
}

export function createRoute (parent, subList) {
  const list = [];
  subList.forEach(v => {
    list.push({
      path: v.path,
      name: parent.path + '_' + v.path,
      meta: createMeta(v.title, null, parent.title, true),
      component: v.component
    });
  });
  return {
    path: parent.path,
    name: parent.name,
    meta: createMeta(parent.title, parent.path, null),
    redirect: '/page/dashboard/index',
    component: Midware,
    children: list
  };
}
