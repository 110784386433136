<!--
    Created by 王丽莎 on 2024/02/21.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：充电桩管理 - 端口列表
    功能概述：端口列表
-->
<style lang="less">
.port-list-content {
  padding: @containerGap;
  border-radius: @borderRadiusMin;
  height: 100%;
  background-color: @backColorStrong;
}
</style>

<template>
  <yg-drawer
    width="800"
    :back-type="2"
    :show-bottom="false"
    @on-close="onClose"
  >
    <p
      slot="title"
    >
      <span>端口详情</span>
    </p>
    <div
      slot="content"
      class="port-list-content"
    >
      <div class="padding-primary-top">
        <yg-table
          :columns="tableColumns"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <!--弹窗：查看各类型订单-->
      <yg-order-list
        v-if="!!dialog.orderListModel"
        :parameter-type="dialog.parameterType"
        :type="dialog.orderListType"
        :model="dialog.orderListModel"
        @on-close="dialog.orderListModel = null"
        @on-sure="dialog.orderListModel = null"
      />
    </div>
  </yg-drawer>
</template>

<script>
import DeviceApiSet from '@/request/api/DeviceApiSet';
import { DevicePortModel } from '../model/DevicePortModel';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      page: { current: 1, total: 0 },
      tableData: [],
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('displayCode', '端口号').setSpan({}),
        BaseTableColumn.initData('useStatus', '使用状态').setStatus([
          { value: 0, type: 1 },
          { value: 1, type: 2 }
        ], 'h5UseStatus'),
        BaseTableColumn.initData('h5StatusSwitch', '启用状态').setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: row.useStatus === 1 ? '当前设备正在使用中，暂不可操作' : `是否确认${e ? '启用' : '停用'}`,
            okText: row.useStatus === 1 ? '我知道了' : '是的',
            onOk: () => {
              if (row.useStatus === 1) {
                row.h5StatusSwitch = !e;
              } else {
                this.requestClosePort(row, e);
              }
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }),
        BaseTableColumn.initData('h5ItemId', '充电订单').setValueButton([], (row, index) => {
          console.log(row);
          this.dialog.parameterType = 2;
          this.dialog.orderListType = 2;
          row.bizNo = row.itemId + '';
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '刷新端口', name: 'refresh', permission: 'system:device:port:refresh' }
        ], ({ name }, row, index) => {
          this.requestRefresh(row);
        })
      ],
      dialog: {
        orderListModel: null,
        orderListType: null,
        parameterType: null
      }
    };
  },
  created () {
    console.log(this.model);
    this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    // 分页
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    onClose () {
      this.$emit('on-close');
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = DeviceApiSet.portList;
      api.params = {
        deviceId: this.model.id,
        pageSize: this.$config.pageSize,
        pageNo: this.page.current
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = DevicePortModel.initListWithDataList(resData.rows);
      });
    },
    // 关闭端口
    requestClosePort (row, status) {
      const api = DeviceApiSet.portChangeStatus;
      api.params = {
        id: row.id,
        status: status ? 1 : 0
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          row.status = status ? 1 : 0;
          row.h5StatusSwitch = status;
          this.requestData();
        })
        .catch(e => {
          row.status = status ? 0 : 1;
          row.h5StatusSwitch = !status;
        });
    },
    // 刷新端口
    requestRefresh (row) {
      const api = DeviceApiSet.devicePortRefresh;
      api.params = {
        id: row.id
      };
      this.$http(api)
        .then(res => {
          console.log(res);
          this.$Message.success('操作成功');
          this.requestData();
        });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
