/**
 * 全局配置文件
 * 外部使用：最好不要直接使用，已挂载到Vue，使用Vue.$config使用
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
const isDev = process.env.VUE_APP_ENV === 'dev';
const isProd = process.env.VUE_APP_ENV === 'prod';
// const isIphone = navigator.userAgent.includes('iPhone');
// const isIpad = navigator.userAgent.includes('iPad');
// const isIOS = isIphone || isIpad;

const GLOBAL_CONFIG = {
  // isIOS: isIOS,
  /**
     * 替代 process.env.NODE_ENV === 'production'
     * 使用时直接 this.$config.iDev
     */
  isDev: isDev,
  isProd: isProd,
  env: process.env.VUE_APP_ENV,
  title: '智能充电管理平台',
  // token有效天数(后端是30天)
  tokenExpires: 1,
  baseUrl: process.env.VUE_APP_API_URL,
  requestTimeout: 10000,
  version: '1.11.0', // 按时长 + 尚亿
  pageSize: 20,
  moneyUnit: '￥',
  exportLimit: 100,
  mainKeyValue: [
    { label: '支付渠道', key: 'payTypeChannel' },
    { label: '设备类别', key: 'deviceType' },
    { label: '端口类别', key: 'chargingPileType' },
    { label: '对接厂商', key: 'factoryType' },
    { label: '设备功率', key: 'devicePowerType' },
    { label: '主体类型', key: 'principalType' },
    // { label: '外部渠道', key: 'outsideChannel' },
    // { label: '外部数据', key: 'outsideDataType' },
    { label: '外部订单数据', key: 'outsideOrderDataType' },
    { label: '外部设备数据', key: 'outsideDeviceDataType' },
    { label: '外部客户数据', key: 'outsideCustomerDataType' },
    { label: '订单数据来源', key: 'orderDataSourceType' },
    { label: '设备数据来源', key: 'deviceDataSourceType' },
    { label: '充电二维码域名', key: 'chargeQrcodeDomain' },
    { label: '前端二维码渠道枚举', key: 'frontQrcodeEnum' }
  ],
  // 20: 服务商 serviceProvider
  // 30: 设备商 deviceProvider
  // 40: 物业公司 propertyCompany
  systemUserType: {
    service: 20,
    device: 30,
    property: 40,
    admin: 99
  },
  // 订单列表抽屉嵌套极限
  orderListDrawerCountLimit: 5
};
export default GLOBAL_CONFIG;
