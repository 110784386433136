/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 一些全局的接口
 */

import BaseApi from '@/request/base/BaseApi';

class CommonApiSet {
  constructor () {
    this.sendMsgCode = BaseApi.createModel('发送短信验证码(已登录）', '/opmp/common/sendMsgCode', true).setMethod(1);
    this.sendMsgCodeUnauth = BaseApi.createModel('发送短信验证码(未登录）', '/opmp/common/sendMsgCodeUnauth', false).setMethod(1);
    this.passwordModify = BaseApi.createModel('忘记密码', '/auth/opmp/password/modify', false).setMethod(1);
    this.otherPassword = BaseApi.createModel('修改员工密码', '/opmp/user/otherPassword/update', true).setMethod(1);
    this.passwordUpdate = BaseApi.createModel('修改本人密码', '/opmp/user/password/update', true).setMethod(1);
    // 下拉数据 -------------------- 开始
    this.getPayTypeChannel = BaseApi.createModel('获取支付渠道下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getPayTypeChannel.params = { dictType: 'payTypeChannel', pageSize: 100, pageNum: 1, status: 1 };
    this.getDeviceType = BaseApi.createModel('获取设备类别下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getDeviceType.params = { dictType: 'deviceType', pageSize: 100, pageNum: 1, status: 1 };
    this.getChargingPileType = BaseApi.createModel('获取端口类别下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getChargingPileType.params = { dictType: 'chargingPileType', pageSize: 100, pageNum: 1, status: 1 };
    this.getFactoryType = BaseApi.createModel('获取对接厂商下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getFactoryType.params = { dictType: 'factoryType', pageSize: 100, pageNum: 1, status: 1 };
    this.getDevicePowerType = BaseApi.createModel('获取设备功率下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getDevicePowerType.params = { dictType: 'devicePowerType', pageSize: 100, pageNum: 1, status: 1 };
    this.getPrincipalType = BaseApi.createModel('获取主体类型下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getPrincipalType.params = { dictType: 'principalType', pageSize: 100, pageNum: 1, status: 1 };

    // this.getOutsideChannel = BaseApi.createModel('获取外部渠道下拉', '/opmp/dict/data/list', true).setMethod(0);
    // this.getOutsideChannel.params = { dictType: 'outsideChannel', pageSize: 100, pageNum: 1, status: 1 };

    this.getOutsideOrderDataType = BaseApi.createModel('获取外部订单数据下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getOutsideOrderDataType.params = { dictType: 'outsideOrderDataType', pageSize: 100, pageNum: 1, status: 1 };
    this.getOutsideDeviceDataType = BaseApi.createModel('获取外部设备数据下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getOutsideDeviceDataType.params = { dictType: 'outsideDeviceDataType', pageSize: 100, pageNum: 1, status: 1 };
    this.getOutsideCustomerDataType = BaseApi.createModel('获取外部客户数据下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getOutsideCustomerDataType.params = { dictType: 'outsideCustomerDataType', pageSize: 100, pageNum: 1, status: 1 };
    this.getOrderDataSourceType = BaseApi.createModel('获取订单数据来源下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getOrderDataSourceType.params = { dictType: 'orderDataSourceType', pageSize: 100, pageNum: 1, status: 1 };
    this.getDeviceDataSourceType = BaseApi.createModel('获取设备数据来源下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getDeviceDataSourceType.params = { dictType: 'deviceDataSourceType', pageSize: 100, pageNum: 1, status: 1 };

    this.getChargeQrcodeDomain = BaseApi.createModel('获取充电二维码域名下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getChargeQrcodeDomain.params = { dictType: 'chargeQrcodeDomain', pageSize: 100, pageNum: 1, status: 1 };
    this.getFrontQrcodeEnum = BaseApi.createModel('获取前端二维码渠道枚举下拉', '/opmp/dict/data/list', true).setMethod(0);
    this.getFrontQrcodeEnum.params = { dictType: 'frontQrcodeEnum', pageSize: 100, pageNum: 1, status: 1 };

    this.getPcDropList = BaseApi.createModel('获取物业公司下拉', '/opmp/pc/dropList', true).setMethod(0);
    this.getRegionByPc = BaseApi.createModel('获取物业公司所属省下拉菜单列表', '/opmp/pc/region/dropList', true).setMethod(0);
    this.getCommunityDropList = BaseApi.createModel('获取小区下拉', '/opmp/community/dropList', true).setMethod(0);
    this.getSiteDropList = BaseApi.createModel('获取点位下拉', '/opmp/site/dropList', true).setMethod(0);
    this.dpList = BaseApi.createModel('获取设备商信息列表', '/opmp/dp/list', true).setMethod(0);
    this.dpList.params = { pageSize: 100, pageNum: 1 };
    this.officeList = BaseApi.createModel('获取办事处信息列表', '/opmp/office/qryOffice', true);
    this.officeList.params = { pageSize: 100, pageNum: 1 };
    this.getRegionalCompanyList = BaseApi.createModel('获取物业区域公司', '/opmp/pc/getRegionalCompany', true);
    this.getEndReasonList = BaseApi.createModel('获取结束原因', '/opmp/admin/biz/order/endReason', true);
    // 下拉数据 -------------------- 结束
    this.fileUpload = BaseApi.createModel('上传文件', '/opmp/file/upload', true).setMethod(1);
  }
}

export default new CommonApiSet();
