
export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('refundTradeNo', '退款\n订单编码').setWidth(250).setKeyValueList([
          { label: '退款订单:', labelWidth: 90, key: 'refundTradeNo', attr: { canCopy: true } },
          { label: '交易渠道订单:', labelWidth: 90, key: 'gwTradeNo', attr: { canCopy: true } }
        ]),
        BaseTableColumn.initData('tel', '用户手机号', 100).setRender((h, { row, index }) => {
          // 判断渠道
          if (row.sourceType === 1) {
            // 小橙
            return h('yg-attribute-span', {
              props: {
                text: row.h5XCUserTel,
                canTooltip: true,
                tooltipText: '此数据为小橙数据来源支付用户编号',
                tooltipWidth: 260
              },
              style: {
                display: 'flex',
                justifyContent: 'center'
              }
            }, row.h5XCUserTel);
          }
          // 内部
          return h('yg-button', {
            props: {
              type: 'text',
              phoneNumber: row.tel
            },
            on: {
              click: () => {
                this.dialog.parameterType = 1;
                this.dialog.orderListType = 1;
                this.dialog.orderListModel = row;
              }
            }
          }, row.tel);
        }),
        BaseTableColumn.initData('sourceAmount', '源支付\n订单金额').setSpan({ canPrice: true, className: 'col-text-strong' }),
        BaseTableColumn.initData('amount', '退款金额').setSpan({ canPrice: true, className: 'col-text-finish' }),
        BaseTableColumn.initData('realMoney', '源支付订单\n退款后余额').setSpan({ canPrice: true, className: 'col-text-strong' }),
        BaseTableColumn.initData('refundTypeDesc', '退款业务类型').setSpan({}),
        BaseTableColumn.initData('refundCurrentTypeDesc', '当前状态').setSpan({}),
        BaseTableColumn.initData('channelName', '退款渠道').setSpan({}),
        BaseTableColumn.initData('refundTime', '退款时间').setSpan({}).setWidth(150),
        BaseTableColumn.initData('orgPayTradeNo', '源支付\n订单编码', 160).setSpan({ canCopy: true }).setValueButton([], (row, index) => {
          console.log(row);
          this.dialog.parameterType = 2;
          this.dialog.orderListType = 3;
          this.dialog.orderListModel = row;
        }),
        BaseTableColumn.initData('sourceTypeDesc', '数据来源').setSpan({})
      ]
    };
  }
};
