<!--
    Created by 程雨喵'mac on 2024/2/18.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：物业公司/小区/点位-级联动
-->
<style lang="less">

</style>

<template>
  <Cascader
    :value="currentValue"
    :disabled="disabled"
    :load-data="requestChildren"
    placeholder="请选择"
    change-on-select
    :transfer="true"
    :data="dataList"
    @on-change="_change"
  />
</template>

<script>
import CommonApiSet from '@/request/api/CommonApiSet';
import SiteCascaderModel from './model/SiteCascaderModel';
import { mapActions } from 'vuex';
export default {
  name: 'YgSiteCascader',
  components: {},
  props: {
    level: {
      type: Number,
      /**
       * 1: 物业公司，省份，城市，小区，点位
       * 2: 物业公司，省份，城市，小区
       * 3: 物业公司，省份，城市
       * 4: 物业公司，省份
       * 5: 物业公司
       */
      default: 1
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // 数据源
      orgData: [],
      defaultValue: [],
      currentValue: [],
      dataList: []
    };
  },
  watch: {
    value (val) {
      this._change(val);
    }
  },
  created () {
    // 获取物业公司/省/市/小区数据
    this.getCommunityData().then(res => {
      if (this.value && this.value.length) {
        this.defaultValue = this.value;
      }
      console.log(this.defaultValue);
      this.dataList = this.dataConversion(res, 1);
    });
  },
  methods: {
    ...mapActions(['getCommunityData']),
    _change (value) {
      // 如果 value 和 currentValue 值一致，则return
      if (JSON.stringify(this.currentValue) === JSON.stringify(value)) return;
      console.log(value);
      this.currentValue = value;
      this.$emit('input', value);
      this.$emit('on-change', value);
    },
    requestChildren (item, callback) {
      // 无法再请求下级
      const noChildrenFun = () => {
        item.loading = false;
        callback();
        return true;
      };
      item.loading = true;
      const api = CommonApiSet.getSiteDropList;
      api.params = { communityId: item.value };
      this.$http(api).then(res => {
        if (res.data.rows && res.data.rows.length > 0) {
          item.children = SiteCascaderModel.initListWithDataList(res.data.rows, 5, false);
          noChildrenFun();
        } else {
          this.$Message.info('无子数据');
          noChildrenFun();
          setTimeout(() => {
            delete item.loading;
            delete item.children;
            this._change([...this.currentValue, ...[item.value]]);
          }, 100);
        }
      });
    },
    // 数据转换
    dataConversion (list, type) {
      const arr = [];
      let needChildren = true;
      if (this.level === 1 && type >= 5) {
        needChildren = false;
      } else if (this.level === 2 && type >= 4) {
        needChildren = false;
      } else if (this.level === 3 && type >= 3) {
        needChildren = false;
      } else if (this.level === 4 && type >= 2) {
        needChildren = false;
      } else if (this.level === 5 && type >= 1) {
        needChildren = false;
      }
      list.forEach(v => {
        const model = SiteCascaderModel.initModelWithData(v, type, needChildren);
        if (needChildren && Array.isArray(v.childList)) {
          model.children = this.dataConversion(v.childList, type + 1);
        } else if (this.defaultValue.length === 5 && model.type === 4 && model.value === this.defaultValue[3]) {
          model.children = [];
          model.loading = false;
          this.requestDefaultSite(model);
        }
        arr.push(model);
      });
      return arr;
    },
    requestDefaultSite (item) {
      const api = CommonApiSet.getSiteDropList;
      api.params = { communityId: item.value };
      this.$http(api).then(res => {
        if (res.data.rows && res.data.rows.length > 0) {
          item.children = SiteCascaderModel.initListWithDataList(res.data.rows, 5, false);
          this._change([...this.defaultValue]);
        } else {
          this.$Message.info('未找到点位');
        }
      });
    }
  }
};
</script>
