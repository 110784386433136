<template>
  <div
    id="app"
    class="simplify-theme"
    :class="theme"
  >
    <router-view />
    <yg-modal
      v-if="showKey"
      title="请输入"
      @on-sure="_clickShowKey"
      @on-close="showKey=false"
    >
      <div
        slot="content"
        style="padding-bottom: 30px"
      >
        <Input
          ref="inputRef"
          v-model="showKeyValue"
          @on-enter="_clickShowKey"
        />
      </div>
    </yg-modal>
  </div>
</template>

<script>
import { onHandler, offHandler } from '@/libs/utils.js';
export default {
  name: 'App',
  data () {
    return {
      randomData: [],
      showKey: false,
      showKeyValue: '',
      isChrome: false,
      theme: ''
    };
  },
  created () {
    this.theme = this.$storage.theme === '1' ? 'simplify-theme-light' : 'simplify-theme-dark';
    console.log('app-create ========> ');
    this.$nextTick(() => {
      // 1.判断当前是否是小屏-vuex赋值
      this.handleResize();
      // 2.全局设置-vuex赋 值
      this.$store.commit('setGlobalSetting', this.$storage.globalSetting);
      onHandler(window, 'resize', this.handleResize);
      onHandler(window, 'keydown', this.addKeyboardObserver);
      this.isChrome = !!this.judgeChrome();
    });
  },
  destroyed () {
    offHandler(window, 'resize', this.handleResize);
    offHandler(window, 'keydown', this.addKeyboardObserver);
  },
  methods: {
    addKeyboardObserver (e) {
      if (e.altKey && e.ctrlKey && e.shiftKey && e.which === 76) { // 76是L
        this.showKeyValue = '';
        this.showKey = true;
        setTimeout(() => {
          this.$refs.inputRef.focus();
        }, 100);
      }
    },
    judgeChrome () {
      // 判断是否为谷歌
      // 取得浏览器的userAgent字符串
      const arr = navigator.userAgent.split(' ');
      let chromeVersion = '';
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].includes('Chrome') || arr[i].includes('chrome')) {
          chromeVersion = arr[i];
        }
        if (i === (arr.length - 1)) {
          if (chromeVersion) {
            return Number(chromeVersion.split('/')[1].split('.')[0]);
          }
          return 0;
        }
      }
      return 0;
    },
    handleResize () {
      this.$store.commit('setSmallScreen', window.innerWidth < 700);
    },
    _clickShowKey () {
      this.showKey = false;
      setTimeout(() => {
        this.$store.commit('setHostInputValue', this.showKeyValue);
      }, 100);
    }
  }
};
</script>

<style lang="less">
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#app {
  position: relative;
  font-family: 'PingFang SC', Arial, 'Avenir', Helvetica, sans-serif;
  //font-family: 'Montserrat', 'PingFang SC', Arial, 'Avenir', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: @textColorNormal;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  min-width: 700px;
}
</style>
