/**
 * @date:2022/09/17
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 */
import Vue from 'vue';
import Vuex from 'vuex';

import user from './module/user';
import selectList from './module/selectList';
import region from './module/region';
import app from './module/app';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    selectList,
    region,
    app
  }
});
