import Vue from 'vue';
import Router from 'vue-router';
import iView from 'view-design';
import routers from './routers.js';
import GLOBAL_CONFIG from '../app.config.js';
import Storage from '@/libs/storage/index.js';

Vue.use(Router);
// const test = routers()[4].children;
// console.log(test);
// test.forEach(v => {
//   delete v.component;
//   v.children.forEach(v1 => {
//     delete v1.component;
//   });
// });
// console.log(JSON.stringify(test));
const router = new Router({
  routes: routers(),
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  const storage = new Storage();
  // 1.如果是同路径那就不跳转
  if (to.fullPath === from.fullPath) {
    console.log('ROUTER: 路由一致');
    return;
  }

  // 非登录界面需要查看是否有token
  if (to.name !== 'login') {
    if (!storage.token) {
      next({ replace: true, name: 'login' });
      return;
    }
  }
  next();
});

router.afterEach(to => {
  window.document.title = to.meta.title ? `${to.meta.title} - ${GLOBAL_CONFIG.title}` : GLOBAL_CONFIG.title;
  iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});

export default router;
