/**
 * @date:2024/1/3
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 用户管理相关请求
 */

import BaseApi from '@/request/base/BaseApi';

class UserApiSet {
  constructor () {
    // 登录相关
    this.loginApi = BaseApi.createModel('登录接口', '/auth/opmp/login', false);
    this.captchaApi = BaseApi.createModel('获取图形验证码', '/code', false).setMethod(0);
    this.routers = BaseApi.createModel('获取菜单', '/opmp/menu/routers', true).setMethod(0);
    this.roleList = BaseApi.createModel('获取菜单', '/opmp/role/list', true).setMethod(0);
    this.buttonAuth = BaseApi.createModel('获取按钮权限', '/opmp/user/buttonAuth', true).setMethod(0);
    this.menuListAll = BaseApi.createModel('获取全部菜单列表', '/opmp/menu/listAll', true).setMethod(0);
    this.roleInfo = BaseApi.createModel('获取角色信息', '/opmp/role/info', true).setMethod(0, false);
    this.roleInfoAdd = BaseApi.createModel('获取角色信息', '/opmp/role/add', true).setMethod(1);
    this.roleInfoEdit = BaseApi.createModel('获取角色信息编辑', '/opmp/role/edit', true).setMethod(1);
    /**
     * userType字段说明
     * 20: 服务商 serviceProvider
     * 30: 设备商 deviceProvider
     * 40: 物业公司 propertyCompany
     */
    this.personalInfo = BaseApi.createModel('个人信息', '/opmp/user/personalInfo', true).setMethod(0);
  }
}

export default new UserApiSet();
