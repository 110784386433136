/**
 * @date:2022/10/19
 * @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
 * @copyright 南京云柜<yun-gui.com>
 * 物业公司/小区/点位级联动数据模型
 */

import BaseModel from '@/libs/base/dataModel/BaseModel';

class SiteCascaderModel extends BaseModel {
  constructor () {
    super();
    // id
    this.value = '';
    // 名称
    this.label = '';
    // 1: 物业公司 2:省份 3:城市 4:小区 5:点位
    this.type = 1;
  }

  initData (resData, modelType, needChildren) {
    this.value = resData.id;
    this.label = resData.name;
    this.type = modelType;
    if (needChildren) {
      this.children = [];
      // 级联动使用
      this.loading = false;
    }
  }

  // 遍历构造器：返回单个对象
  static initModelWithData (data, modelType, needChildren) {
    const model = new this();
    model.initData(data, modelType, needChildren);
    return model;
  }

  static initListWithDataList (dataList, modelType, needChildren) {
    const list = [];
    if (dataList && Array.isArray(dataList) && dataList.length) {
      const len = dataList.length;
      for (let i = 0; i < len; i++) {
        const data = dataList[i];
        list.push(this.initModelWithData(data, modelType, needChildren));
      }
    }
    return list;
  }
}

export default SiteCascaderModel;
