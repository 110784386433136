<!--
    Created by 程雨喵'mac on 2024/1/23.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：操作（分账比例+计费规则）
-->
<style lang="less">
#charging-pie-setting-dialog {
  .__g-modal-con {
    .__g-modal-header {
      padding-bottom: 0;
    }
  }
  .charging-pie-setting-dialog-content {
    margin-bottom: @containerGap;
    padding: @containerGap;
    background-color: @backColorStrong;
    border-radius: 0 @borderRadiusMin @borderRadiusMin @borderRadiusMin;
  }
}
</style>

<template>
  <yg-modal
    id="charging-pie-setting-dialog"
    :width="tab.index === 0 ? 700 : 600"
    :show-bottom="false"
    :back-type="2"
    @on-close="onClose"
  >
    <div
      slot="title"
    >
      <yg-tab-primary
        :index="tab.index"
        :items="tab.items"
        :permission-items="tab.permissionItems"
        :type="2"
        tab-size="small"
        @on-change="onTabChanged"
      />
    </div>
    <div
      slot="content"
      class="charging-pie-setting-dialog-content"
    >
      <v-money-rules
        v-if="tab.index === 0"
        :model="model"
        @on-refresh="needRefreshList = true"
      />
      <v-bill-ratio
        v-else-if="tab.index === 1"
        :model="model"
      />
      <v-operate
        v-else-if="tab.index === 2"
        :model="model"
        @on-refresh="needRefreshList = true"
      />
    </div>
  </yg-modal>
</template>

<script>
import vMoneyRules from './priceSetting.vue';
import vBillRatio from './billSetting.vue';
import vOperate from './operateSetting.vue';
export default {
  components: { vMoneyRules, vBillRatio, vOperate },
  props: {
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      tab: {
        index: 0,
        // system:device:charge:set 设备功率设置
        items: ['设备计费设置', '分账比例设置', '运营设置'],
        permissionItems: ['system:device:price:set', 'system:device:cost:set', 'system:device:operationType:set']
      },
      buttonDisabled: true,
      buttonDisabledMsg: '',
      needRefreshList: false
    };
  },
  created () {
    this.tab.index = this.index;
  },
  methods: {
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.buttonDisabled = false;
      this.buttonDisabledMsg = '';
      this.tab.index = e;
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    }
  }
};
</script>
