<!--
    Created by 程雨喵'mac on 2022/11/25.
    Copyright © 2022年 云柜-金星晖. All rights reserved.
    功能概述：全局的按钮组件，因为需要绑定权限，因此单拎出来使用
-->
<style lang="less">
.__g-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all @transitionTime15;
  white-space: nowrap;
  &:hover {
    opacity: 0.9;
  }
  @keyframes globalBtnErrorAnim1 {
    0% { transform: rotateZ(-1deg)}
    10% { transform: rotateZ(1deg)}
    20% { transform: rotateZ(-1deg)}
    30% { transform: rotateZ(1deg)}
    40% { transform: rotateZ(-1deg)}
    50% { transform: rotateZ(1deg)}
    60% { transform: rotateZ(-1deg)}
    70% { transform: rotateZ(1deg)}
    80% { transform: rotateZ(-1deg)}
    90% { transform: rotateZ(1deg)}
    100% { transform: rotateZ(-1deg)}
  }
  @keyframes globalBtnErrorAnim2 {
    0% { transform: rotateZ(-2deg)}
    10% { transform: rotateZ(2deg)}
    20% { transform: rotateZ(-2deg)}
    30% { transform: rotateZ(2deg)}
    40% { transform: rotateZ(-2deg)}
    50% { transform: rotateZ(2deg)}
    60% { transform: rotateZ(-2deg)}
    70% { transform: rotateZ(2deg)}
    80% { transform: rotateZ(-2deg)}
    90% { transform: rotateZ(2deg)}
    100% { transform: rotateZ(-2deg)}
  }
  &-error {
    &-1 {
      animation: globalBtnErrorAnim1 0.5s;
    }
    &-2 {
      animation: globalBtnErrorAnim2 0.5s;
    }
  }
}
.__g-btn-size {
  &-small {
    padding: 0 18px;
    height: ~'calc(@{formHeight} * 0.8)';
    font-size: 12px;
  }
  &-normal {
    padding: 0 22px;
    height: @formHeight;
  }
}
.__g-btn-type {
  &-default {
    border-color: @buttonColorPrimary;
    background-color: @backColorStrong;
    color: @textColorNormal;
  }
  &-primary {
    border-color: @buttonColorPrimary;
    background-color: @buttonColorPrimary;
    color: @textColorFlip;
  }
  &-adorn {
    border-color: @buttonColorAdorn;
    background-color: @backColorStrong;
    color: @buttonColorAdorn;
  }
  &-adorn-fill {
    border-color: @buttonColorAdorn;
    background-color: @buttonColorAdorn;
    color: @textColorFlip;
  }
  &-text {
    padding: 0;
    border-color: transparent;
    background-color: transparent;
    color: @buttonColorPrimary;
    text-decoration: underline;
  }
  &-disabled {
    border-color: transparent !important;
    background-color: @buttonColorDisabled !important;
    color: @textColorFlip !important;
    border-radius: @borderRadiusMin;
    cursor: not-allowed;
  }
  &-disabled-text {
    color: @buttonColorDisabled;
    cursor: not-allowed;
  }
}
.__g-btn-border {
  //&-has { border-radius: @buttonHeight * 0.5; }
  &-no { border-radius: @borderRadiusMin; }
}
</style>

<template>
  <div
    ref="globalButton"
    v-hide-phone="!!phoneNumber"
    :data-value="phoneNumber"
    class="__g-button"
    :class="getButtonClass"
    @click.stop="_click"
  >
    <slot v-if="!phoneNumber" />
  </div>
</template>

<script>
export default {
  name: 'YgButton',
  components: {},
  props: {
    /**
     * 按钮类型
     * default: 蓝色边框，白色背景，黑色字
     * primary: 蓝色边框，蓝色背景，白色字
     * adorn:   桔色边框，白色背景，桔色字
     * adorn-fill: 桔色边框，桔色背景，白色字
     * text: 无边框，无背景色，蓝色字
     */
    type: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 禁用时 点击后弹窗的提示文案(默认为不提示)
    disabledText: {
      type: String,
      default: ''
    },
    canHidePhone: {
      type: Boolean,
      default: false
    },
    phoneNumber: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      // normal, small
      default: 'normal'
    }
  },
  data () {
    return {
      buttonIsError: false,
      timer: null
    };
  },
  computed: {
    getButtonClass () {
      if (this.disabled) {
        if (this.type === 'text') {
          return '__g-btn-type-disabled-text';
        }
        return `__g-btn-type-disabled __g-btn-size-${this.size}`;
      }
      return `__g-btn-size-${this.size} __g-btn-type-${this.type} __g-btn-border-${this.borderRadius ? 'has' : 'no'}`;
    }
  },
  destroyed () {
    this.clearTimer();
  },
  methods: {
    startTimer () {
      this.clearTimer();
    },
    clearTimer () {
      if (!this.timer) return;
      clearTimeout(this.timer);
      this.timer = null;
    },
    _click () {
      if (this.disabled) {
        if (!this.buttonIsError) {
          this.buttonIsError = true;
          const refButton = this.$refs.globalButton;
          const orgClass = refButton.className;
          refButton.className = orgClass + ' __g-button-error';
          if (refButton.clientWidth > 200) {
            refButton.className = refButton.className + ' __g-button-error-1';
          } else {
            refButton.className = refButton.className + ' __g-button-error-2';
          }
          if (this.disabledText) {
            this.$Message.info(this.disabledText);
          }
          this.clearTimer();
          this.timer = setTimeout(() => {
            refButton.className = orgClass;
            this.buttonIsError = false;
          }, 500);
        }
        return;
      }
      this.$emit('click');
    }
  }
};
</script>
